import { routesSettings } from '../../navigation/routes'
import users from '../../assets/images/setting/users.svg'
import click from '../../assets/images/notFound/click.svg'
import trophy from '../../assets/images/setting/trophy.svg'
import mail from '../../assets/images/setting/mail.png'
import crown from '../../assets/images/setting/crown.svg'
import mushroom from '../../assets/images/setting/mushroom.svg'
import flame from '../../assets/images/setting/flame.svg'

import { IconUsers, IconLicense, IconApi, IconBrandTabler, IconClipboardData, IconSettings } from '@tabler/icons-react'

// Form Icons
import textInput from '../../assets/images/forms/textInput.svg'
import graph from '../../assets/images/forms/graph.svg'
import briefcase from '../../assets/images/forms/briefcase.svg'
import { typeRegex } from '../dataRegex'

//Icons screens
import hash from '../../assets/images/navMenu/hash.svg'
import layoutGrid from '../../assets/images/navMenu/layout-grid.svg'
import hatchecks from '../../assets/images/navMenu/hatchecks.svg'
import arrowUpRight from '../../assets/images/navMenu/corner-up-right.svg'
import settings from '../../assets/images/navMenu/settings-2.svg'

export const menuSettings = [
    {
        text: 'Usuarios',
        path: routesSettings.users,
        icon: IconUsers,
        restricted: 'users',
        id: 1,
        content: 'Configuración de usuarios',
    },
    // {
    //   text: "Módulos",
    //   path: routesSettings.modules,
    //   icon: toggle,
    //   restricted: "modules",
    // },
    {
        text: 'Permisos',
        path: routesSettings.permissions,
        icon: IconLicense,
        restricted: 'permissions',
        id: 2,
        content: 'Configuración de permisos',
    },
    {
        text: 'API',
        path: routesSettings.api,
        icon: IconApi,
        restricted: 'api',
        id: 3,
        content: 'Información de la API',
    },
    // {
    //   text: "Información de la empresa",
    //   path: routesSettings.companyInformation,
    //   icon: building,
    //   restricted: "companyInformation",
    // },
    {
        text: 'Dominios',
        path: routesSettings.domains,
        icon: IconBrandTabler,
        restricted: 'domains',
    },
    {
        text: 'Lista blanca',
        path: routesSettings.whitelist,
        icon: IconClipboardData,
        restricted: 'whitelist',
    },
    {
        text: 'Preferencias globales',
        path: routesSettings.configurationAsterisk,
        icon: IconSettings,
        restricted: 'users',
    },
]

export const dataScreenUsers = {
    notFound: {
        icon: { img: click, alt: 'click' },

        title: 'No hay ningun usuario creado',
        subtitle: 'Empieza por configurar un nuevo usuario para visualizar en tu escritorio',
        textButton: 'Crear un nuevo usuario',
    },
    icon: {
        users,
        trophy,
        mail,
    },
}

export const menusTitleAndSubtitle = [
    {
        title: 'Numeraciones',
        id: 2,
        icon: hash,
        subtitle: [
            { text: 'Listado de numeración', id: 1 },
            { text: 'Extensiones', id: 2 },
        ],
    },
    {
        title: 'Aplicaciones',
        id: 3,
        icon: layoutGrid,
        subtitle: [
            { text: 'Conferencias', id: 6 },
            { text: 'Locuciones', id: 7 },
            { text: 'Buzones', id: 8 },
            { text: 'Ivr', id: 9 },
            { text: 'Música en espera', id: 10 },
            { text: 'Marcación abreviada', id: 11 },
            { text: 'Colas', id: 12 },
        ],
    },
    {
        title: 'Registro de llamadas',
        id: 4,
        icon: hatchecks,
        subtitle: [{ text: "CDR's", id: 20 }],
    },
    {
        title: 'Rutas de salida',
        id: 8,
        icon: arrowUpRight,
        subtitle: [
            { text: 'Grupo Catetorias', id: 21 },
            { text: 'Troncales', id: 22 },
        ],
    },
    {
        title: 'Configuración',
        id: 6,
        icon: settings,
        subtitle: [
            { text: 'Calendario y horario', id: 13 },
            { text: 'Configuración de protocolo', id: 14 },
        ],
    },
]

export const formCreateUser = {
    textButton: 'Crear Usuario',
    title: 'Creación de un nuevo usuario',
    titleEdit: 'Edita el usuario',
    steps: [
        {
            number: '1',
            title: 'Información del usuario',
            id: 1,
            nextStepText: 'Siguiente Paso',
            input: [
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Nombre',
                    placeholder: 'Nombre',
                    icon: textInput,
                    ref: { form: 'user', element: 'firstname' },
                    defaultValues: null,
                    required: {
                        text: '* Nombre',
                        validation: true,
                        errorMessage: '* Debe tener entre 3 y 50 caracteres',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Primer Apellido',
                    placeholder: 'Primer Apellido',
                    icon: textInput,
                    ref: { form: 'user', element: 'lastname' },
                    defaultValues: null,
                    required: {
                        text: '* Apellido',
                        validation: true,
                        errorMessage: '* Debe tener entre 3 y 50 caracteres',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Segundo Apellido',
                    icon: textInput,
                    placeholder: 'Segundo Apellido',
                    ref: { form: 'user', element: 'secondname' },
                    defaultValues: null,
                    required: {
                        text: '* Segundo Apellido',
                        validation: true,
                        errorMessage: '* Debe tener entre 3 y 50 caracteres',
                        regex: typeRegex.inputText,
                    },
                },
            ],
        },
        {
            number: '2',
            title: 'Información de la cuenta',
            nextStepText: 'Siguiente Paso',
            lastStep: true,
            id: 2,
            input: [
                {
                    layoutId: 2,
                    cardType: 'input',
                    label: 'Correo Electrónico',
                    placeholder: 'example@example.com',
                    icon: briefcase,
                    ref: { form: 'user', element: 'email' },
                    defaultValues: null,
                    required: {
                        text: '* Email',
                        validation: true,
                        errorMessage: '* Debe ser un email correcto',
                        regex: typeRegex.inputMail,
                    },
                },
                {
                    layoutId: 2,
                    cardType: 'input',
                    placeholder: '············',
                    label: 'Contraseña',
                    ref: { form: 'user', element: 'password' },
                    defaultValues: null,
                    required: {
                        text: '* Contraseña',
                        validation: true,
                        errorMessage: '* 8 caracters mínimo 1 letra 1 número',
                        regex: typeRegex.inputPassword,
                    },
                },
                {
                    layoutId: 2,
                    cardType: 'select-option',
                    placeholder: 'Selecciona un rol',
                    label: 'Rol',
                    ref: { form: 'user', element: 'role' },
                    icon: graph,
                    required: {
                        text: '* Rol',
                        validation: true,
                        errorMessage: 'Debe tener entre 3 y 50 caracteres',
                        regex: typeRegex.inputText,
                    },
                    options: [
                        {
                            label: 'superadmin',
                            value: '10',
                        },
                        {
                            label: 'admin',
                            value: '11',
                        },
                        {
                            label: 'operator',
                            value: '12',
                        },
                        {
                            label: 'agent',
                            value: '13',
                        },
                    ],
                    defaultValues: {},
                },
            ],
        },
    ],
}

export const dataScreenSettings = {
    permissions: {
        profile: 'perfil',
        profiles: 'perfiles',
        member: 'Miembro de Tpartner',
        allFunctionalitiesText: 'Perfil con todas las funcionalidades',
        functionalitiesText: 'Perfil con permisos en las siguientes pantallas',
        allFunctionalitiesRole: 'superadmin',
    },
    icon: {
        trophy,
        crown,
        mushroom,
        flame,
    },
    edit: {
        title: 'Permisos del perfil',
    },
}

export const formCreateApiKey = {
    title: 'Creación de una nueva API Key',
    textButton: 'Guardar API Key',
    steps: [
        {
            number: '1',
            title: 'Esta es tu nueva Api Key',
            id: 1,
            nextStepText: 'Siguiente Paso',
            lastStep: true,
            input: [
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Nombre',
                    placeholder: 'Nombre para la API Key',
                    icon: textInput,
                    ref: { form: 'apiKey', element: 'name' },
                    defaultValues: null,
                    required: {
                        text: '* Nombre para la API Key',
                        validation: true,
                        errorMessage: '* Debe tener entre 3 y 50 caracteres',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'generateKey',
                    ref: { form: 'apiKey', element: 'apiKey' },
                },
            ],
        },
    ],
}

export const dataScreenApi = {
    notFound: {
        icon: { img: click, alt: 'click' },

        title: 'No hay niguna ApiKey creada',
        subtitle: 'Empieza por configurar una nueva ApiKey para visualizar en tu escritorio',
        textButton: 'Crear API Key',
    },
    icon: {
        // users,
        // home,
    },
}

export const formCreateDomains = {
    title: 'Configuración de un nuevo dominio',
    titleEdit: 'Edita el dominio',
    textButton: 'Crear dominio',
    steps: [
        {
            number: '1',
            title: 'Dominio',
            id: 1,
            nextStepText: 'Siguiente Paso',
            lastStep: true,
            input: [
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Doninio',
                    placeholder: 'Doninio',
                    icon: textInput,
                    ref: { form: 'domains', element: 'domain' },
                    defaultValues: null,
                    required: {
                        text: '* Dominio',
                        validation: true,
                        errorMessage: '* Debe ser un dominio correcto',
                        regex: typeRegex.domainRegex,
                    },
                },
            ],
        },
    ],
}

export const dataScreenDomains = {
    notFound: {
        icon: { img: click, alt: 'click' },

        title: 'No hay ningun dominio creado',
        subtitle: 'Empieza por configurar un nuevo dominio para visualizar en tu escritorio',
        textButton: 'Crear un nuevo dominio',
    },
    icon: {
        // users,
        // trophy,
        // mail,
    },
}
export const dataFilterDomains = {
    filters: [
        {
            label: 'Dominio',
            type: 'select',
            ref: 'domains',
            options: [],
        },
    ],
}

export const formCreateWhitelist = {
    title: 'Configuración de una nueva whitelist',
    titleEdit: 'Edita la whitelist',
    textButton: 'Crear whitelist',
    steps: [
        {
            number: '1',
            title: 'Ip_addr',
            id: 1,
            nextStepText: 'Siguiente Paso',
            lastStep: true,
            input: [
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Ip_addr',
                    placeholder: 'Ip_addr',
                    icon: textInput,
                    ref: { form: 'whitelist', element: 'ip_addr' },
                    defaultValues: null,
                    required: {
                        text: '* Ip_addr',
                        validation: true,
                        errorMessage: '* Debe ser una ip correcta',
                        regex: typeRegex.inputIP,
                    },
                },
            ],
        },
    ],
}

export const dataScreenWhiteList = {
    notFound: {
        icon: { img: click, alt: 'click' },

        title: 'No hay ninguna lista blanca creada',
        subtitle: 'Empieza por configurar un nueva lista blanca para visualizar en tu escritorio',
        textButton: 'Crear una nueva lista blanca',
    },
    icon: {
        // users,
        // trophy,
        // mail,
    },
}

export const dataFilterWhiteList = {
    filters: [
        {
            label: 'ip_addr',
            type: 'select',
            ref: 'ip_addr',
            options: [],
        },
    ],
}

export const dataConfiguracionAsterisk = {
    title: 'Configuraciones globales',
    titleEdit: 'Edita la configuación',
    tabs: [
        {
            textButton: 'Crear configuracion',
            id: 1,
            text: 'Configurar SBC SIP/SJSIP',
            steps: [
                {
                    number: '1',
                    title: 'SBC',
                    id: 1,
                    nextStepText: 'Siguiente Paso',
                    lastStep: true,
                    input: [
                        {
                            cardType: 'input',
                            label: 'IP Local',
                            placeholder: 'IP Local',
                            icon: textInput,
                            ref: { form: 'config', element: 'ipLocal' },
                            defaultValues: null,
                            required: {
                                text: '* IP Local',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Dominio',
                            placeholder: 'Dominio',
                            icon: textInput,
                            ref: { form: 'config', element: 'domain' },
                            defaultValues: null,
                            required: {
                                text: '* Dominio',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Puerto WSS',
                            placeholder: 'Puerto WSS',
                            icon: textInput,
                            ref: { form: 'config', element: 'portWss' },
                            defaultValues: null,
                            required: {
                                text: '* Puerto WSS',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Path WSS',
                            placeholder: 'Path WSS',
                            icon: textInput,
                            ref: { form: 'config', element: 'pathWss' },
                            defaultValues: null,
                            required: {
                                text: '* Path WSS',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Contraseña',
                            placeholder: 'Contraseña',
                            icon: textInput,
                            ref: { form: 'config', element: 'password' },
                            defaultValues: null,
                            required: {
                                text: '* Contraseña',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                    ],
                },
            ],
        },
        {
            text: 'Configuración del sistema',
            id: 2,
            textButton: 'Crear configuración del sistema',

            steps: [
                {
                    number: '1',
                    title: 'Protocolo de interfaz: SIP/',
                    id: 1,
                    nextStepText: 'Siguiente Paso',
                    input: [
                        {
                            cardType: 'input',
                            label: 'Azure subscripción key',
                            placeholder: 'Azure subscripción key',
                            icon: textInput,
                            ref: { form: 'config', element: 'azure_subscripcion_key' },
                            defaultValues: null,
                            required: {
                                text: '* Azure subscripción key',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Ruta indicación de sonido',
                            placeholder: 'Ruta indicación de sonido',
                            icon: textInput,
                            ref: { form: 'config', element: 'ruta_indicacion_de_sonido' },
                            defaultValues: null,
                            required: {
                                text: '* Ruta indicación de sonido',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Número máximo de extensiones',
                            placeholder: 'Número máximo de extensiones',
                            icon: textInput,
                            ref: { form: 'config', element: 'numero_maximo_de_extensiones' },
                            defaultValues: null,
                            required: {
                                text: '* Número máximo de extensiones',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Digito de marcación',
                            placeholder: 'Digito de marcación',
                            icon: textInput,
                            ref: { form: 'config', element: 'digito_de_marcacion' },
                            defaultValues: null,
                            required: {
                                text: '* Digito de marcación',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Administrador secreto',
                            placeholder: 'Administrador secreto',
                            icon: textInput,
                            ref: { form: 'config', element: 'administrador_secreto' },
                            defaultValues: null,
                            required: {
                                text: '* Administrador secreto',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Usurario administrador',
                            placeholder: 'Usurario administrador',
                            icon: textInput,
                            ref: { form: 'config', element: 'usurario_administrador' },
                            defaultValues: null,
                            required: {
                                text: '* Usurario administrador',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Puerto administrador',
                            placeholder: 'Puerto administrador',
                            icon: textInput,
                            ref: { form: 'config', element: 'puerto_administrador' },
                            defaultValues: null,
                            required: {
                                text: '* Puerto administrador',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'IP administrador',
                            placeholder: 'IP administrador',
                            icon: textInput,
                            ref: { form: 'config', element: 'ip_administrador' },
                            defaultValues: null,
                            required: {
                                text: '* IP administrador',
                                validation: false,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'witheSpace',
                        },
                        {
                            cardType: 'titleInputPair',
                            label: 'Extensión',
                            inputs: [
                                {
                                    placeholder: 'Inicio',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'extension_start' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Inicio',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                                {
                                    placeholder: 'Fin',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'extension_end' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Fin',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                            ],
                        },
                        {
                            cardType: 'titleInputPair',
                            label: 'Grupo',
                            inputs: [
                                {
                                    placeholder: 'Inicio',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'group_start' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Inicio',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                                {
                                    placeholder: 'Fin',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'group_end' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Fin',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                            ],
                        },
                        {
                            cardType: 'titleInputPair',
                            label: 'Búzon',
                            inputs: [
                                {
                                    placeholder: 'Inicio',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'mailBoxes_start' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Inicio',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                                {
                                    placeholder: 'Fin',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'mailBoxes_end' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Fin',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                            ],
                        },
                        {
                            cardType: 'titleInputPair',
                            label: 'Conferencia',
                            inputs: [
                                {
                                    placeholder: 'Inicio',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'conference_start' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Inicio',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                                {
                                    placeholder: 'Fin',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'conference_end' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Fin',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                            ],
                        },
                        {
                            cardType: 'titleInputPair',
                            label: 'Marcación abreviada',
                            inputs: [
                                {
                                    placeholder: 'Inicio',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'shortMarking_start' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Inicio',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                                {
                                    placeholder: 'Fin',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'shortMarking_end' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Fin',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                            ],
                        },
                        {
                            cardType: 'titleInputPair',
                            label: 'Cola',
                            inputs: [
                                {
                                    placeholder: 'Inicio',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'cola_start' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Inicio',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                                {
                                    placeholder: 'Fin',
                                    icon: textInput,
                                    ref: { form: 'config', element: 'cola_end' },
                                    defaultValues: null,
                                    required: {
                                        text: 'Fin',
                                        validation: false,
                                        errorMessage: '* Número',
                                        regex: /^/,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    number: '2',
                    title: 'Variables del sistema/ Dial',
                    id: 2,
                    nextStepText: 'Siguiente Paso',
                    input: [
                        {
                            cardType: 'input',
                            label: 'OPT',
                            placeholder: 'OPT',
                            icon: textInput,
                            ref: { form: 'config', element: 'opt' },
                            defaultValues: null,
                            required: {
                                text: 'OPT',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Tiemout',
                            placeholder: 'Timeout',
                            icon: textInput,
                            ref: { form: 'config', element: 'timeout' },
                            defaultValues: null,
                            required: {
                                text: 'Timeout',
                                validation: true,
                                errorMessage: '* Solo números',
                                //regex: typeRegex.inputOnlyNumbers,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Tiemout_CF',
                            placeholder: 'Timeout_CF',
                            icon: textInput,
                            ref: { form: 'config', element: 'timeout_cf' },
                            defaultValues: null,
                            required: {
                                text: 'Timeout_CF',
                                validation: true,
                                errorMessage: '* Solo números',
                                //regex: typeRegex.inputOnlyNumbers,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Out_Timeout',
                            placeholder: 'Out_Timeout',
                            icon: textInput,
                            ref: { form: 'config', element: 'out_timeout' },
                            defaultValues: null,
                            required: {
                                text: 'Out_Timeout',
                                validation: true,
                                errorMessage: '* Solo números',
                                //regex: typeRegex.inputOnlyNumbers,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Out_options',
                            placeholder: 'Out_options',
                            icon: textInput,
                            ref: { form: 'config', element: 'out_options' },
                            defaultValues: null,
                            required: {
                                text: 'Out_options',
                                validation: true,
                                errorMessage: '* Solo números',
                                //regex: typeRegex.inputOnlyNumbers,
                                regex: /^/,
                            },
                        },
                    ],
                },
                {
                    number: '3',
                    title: 'Variables del sistema/ Principales',
                    id: 3,
                    nextStepText: 'Siguiente Paso',
                    input: [
                        {
                            cardType: 'input',
                            label: 'CONFRECPATH',
                            placeholder: 'CONFRECPATH',
                            icon: textInput,
                            ref: { form: 'config', element: 'confrecpath' },
                            defaultValues: null,
                            required: {
                                text: 'CONFRECPATH',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'VMGREETPATH',
                            placeholder: 'VMGREETPATH',
                            icon: textInput,
                            ref: { form: 'config', element: 'vmgreetpath' },
                            defaultValues: null,
                            required: {
                                text: 'VMGREETPATH',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'CALLRECPATH',
                            placeholder: 'CALLRECPATH',
                            icon: textInput,
                            ref: { form: 'config', element: 'callrecpath' },
                            defaultValues: null,
                            required: {
                                text: 'CALLRECPATH',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'MYDYNAMIC_FEATURES',
                            placeholder: 'MYDYNAMIC_FEATURES',
                            icon: textInput,
                            ref: { form: 'config', element: 'mydynamic_fearures' },
                            defaultValues: null,
                            required: {
                                text: 'MYDYNAMIC_FEATURES',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'DF_RINGTIMEOUT',
                            placeholder: 'DF_RINGTIMEOUT',
                            icon: textInput,
                            ref: { form: 'config', element: 'df_ringtimeout' },
                            defaultValues: null,
                            required: {
                                text: 'DF_RINGTIMEOUT',
                                validation: true,
                                errorMessage: '* Solu números',
                                //regex: typeRegex.inputOnlyNumbers,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'FORMATO_DEFAULT_VM',
                            placeholder: 'FORMATO_DEFAULT_VM',
                            icon: textInput,
                            ref: { form: 'config', element: 'formato_default_vm' },
                            defaultValues: null,
                            required: {
                                text: 'FORMATO_DEFAULT_VM',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'BXFER_COUNT',
                            placeholder: 'BXFER_COUNT',
                            icon: textInput,
                            ref: { form: 'config', element: 'bxfer_count' },
                            defaultValues: null,
                            required: {
                                text: 'BXFER_COUNT',
                                validation: true,
                                errorMessage: '* Solu números',
                                //regex: typeRegex.inputOnlyNumbers,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'SELTIMEOUT',
                            placeholder: 'SELTIMEOUT',
                            icon: textInput,
                            ref: { form: 'config', element: 'seltimeout' },
                            defaultValues: null,
                            required: {
                                text: 'SELTIMEOUT',
                                validation: true,
                                errorMessage: '* Solu números',
                                //regex: typeRegex.inputOnlyNumbers,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'PREFIX_MOV_CORP',
                            placeholder: 'PREFIX_MOV_CORP',
                            icon: textInput,
                            ref: { form: 'config', element: 'prefix_mov_corp' },
                            defaultValues: null,
                            required: {
                                text: 'PREFIX_MOV_CORP',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'PRI_MOV',
                            placeholder: 'PRI_MOV',
                            icon: textInput,
                            ref: { form: 'config', element: 'pri_mov' },
                            defaultValues: null,
                            required: {
                                text: 'PRI_MOV',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'NUMCORTA_ORIGIN',
                            placeholder: 'NUMCORTA_ORIGIN',
                            icon: textInput,
                            ref: { form: 'config', element: 'numcorta_origin' },
                            defaultValues: null,
                            required: {
                                text: 'NUMCORTA_ORIGIN',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'CODELEN',
                            placeholder: 'CODELEN',
                            icon: textInput,
                            ref: { form: 'config', element: 'codelen' },
                            defaultValues: null,
                            required: {
                                text: 'CODELEN',
                                validation: true,
                                errorMessage: '* Solu números',
                                //regex: typeRegex.inputOnlyNumbers,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'OPC_COLA',
                            placeholder: 'OPC_COLA',
                            icon: textInput,
                            ref: { form: 'config', element: 'opc_cola' },
                            defaultValues: null,
                            required: {
                                text: 'OPC_COLA',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'MAX_INBOUND_CHAN',
                            placeholder: 'MAX_INBOUND_CHAN',
                            icon: textInput,
                            ref: { form: 'config', element: 'max_inbound_chan' },
                            defaultValues: null,
                            required: {
                                text: 'MAX_INBOUND_CHAN',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'toggleButton',
                            label: 'Multimáquina/Monomáquina',
                            textLeft: 'Multimáquina',
                            textRight: 'Monomáquina',
                            ref: { form: 'config', element: 'maquina' },
                        },
                    ],
                },
                {
                    number: '4',
                    title: 'Variables del sistema/ Selección múltiple',
                    id: 4,
                    lastStep: true,
                    nextStepText: 'Siguiente Paso',
                    input: [
                        {
                            cardType: 'radio',
                            label: 'Selección múltiple según preferencias',
                            placeholder: 'Selección múltiple según preferencias',
                            ref: { form: 'config', element: 'preferences' },
                            defaultValues: '',
                            radioType: 'multi',
                            options: [
                                { label: 'QSHOWNAME', value: 'QSHOWNAME', id: 1 },
                                { label: 'VM_AUTO_CREATION', value: 'VM_AUTO_CREATION', id: 2 },
                                { label: 'WEBEXTERNALDB', value: 'WEBEXTERNALDB', id: 3 },
                                { label: 'ERESPON', value: 'ERESPON', id: 4 },
                                { label: 'MODO_ENCUESTAS', value: 'MODO_ENCUESTAS', id: 5 },
                                { label: 'DebugMode', value: 'DebugMode', id: 6 },
                                {
                                    label: 'CALLBACK_MODE',
                                    value: 'CALLBACK_MODE',
                                    id: 7,
                                    selectData: {
                                        label: 'CALLBACK_AUDIO_ID',
                                        placeholder: 'Selecciona locución',
                                        ref: { form: 'config', element: 'callback_audio_id' },
                                        options: [],
                                        defaultValues: {},
                                    },
                                },
                                {
                                    label: 'sub_notify_API',
                                    value: 'sub_notify_API',
                                    id: 8,
                                    inputData: {
                                        label: 'external_API_URL',
                                        placeholder: 'external_API_URL',
                                        ref: { form: 'config', element: 'external_api_url' },
                                        options: [],
                                        defaultValues: null,
                                        required: {
                                            text: 'external_API_URL',
                                            validation: false,
                                            errorMessage: 'Selecciona una opción',
                                            regex: typeRegex.inputText,
                                        },
                                    },
                                },
                            ],
                            required: {
                                text: 'Selección múltiple según preferencias',
                                validation: false,
                                errorMessage: 'Selecciona una opción',
                                regex: typeRegex.inputText,
                            },
                        },
                    ],
                },
                {
                    number: '5',
                    title: 'Variables del sistema/ Características',
                    id: 5,
                    lastStep: true,
                    nextStepText: 'Siguiente Paso',
                    input: [
                        {
                            cardType: 'input',
                            label: 'Capturar llamada',
                            placeholder: 'Capturar llamada',
                            icon: textInput,
                            ref: { form: 'config', element: 'capturar_llamada' },
                            defaultValues: null,
                            required: {
                                text: 'Capturar llamada',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Transferencia ciega',
                            placeholder: 'Transferencia ciega',
                            icon: textInput,
                            ref: { form: 'config', element: 'transferencia_ciega' },
                            defaultValues: null,
                            required: {
                                text: 'Transferencia ciega',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Colgar llamada',
                            placeholder: 'Colgar llamada',
                            icon: textInput,
                            ref: { form: 'config', element: 'colgar_llamada' },
                            defaultValues: null,
                            required: {
                                text: 'Colgar llamada',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                        {
                            cardType: 'input',
                            label: 'Transferencia atendida',
                            placeholder: 'Transferencia atendida',
                            icon: textInput,
                            ref: { form: 'config', element: 'tranferencia_atendida' },
                            defaultValues: null,
                            required: {
                                text: 'Transferencia atendida',
                                validation: true,
                                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                                //regex: typeRegex.inputText,
                                regex: /^/,
                            },
                        },
                    ],
                },
            ],
        },
    ],
}
