import React from 'react'
import { InputWrapper } from './Input.style'

export const Input = ({ item, form, inputValue, setErrorInput, setInputValue, setValidationNormalInput, validationNormalInput, arrScreen, setInputValueUnique, type }) => {
    const handleInputChange = (e, item, requiredRegex) => {
        setInputValue((prevState) => ({
            ...prevState,
            [item.ref.element]: e.target.value,
        }))

        if (e.target.value === '' || !requiredRegex.test(e.target.value)) {
            setErrorInput(true)
            setValidationNormalInput((prevState) => [...prevState, item.ref.element])
            setInputValueUnique((prevState) => prevState.filter((obj) => obj.element !== item.ref.element))
        } else {
            if (item.ref.unique) {
                let refItemName = item.ref.element
                let namesInBd = []
                arrScreen.map((item) => namesInBd.push(item[refItemName]))

                if (namesInBd.includes(e.target.value.trim())) {
                    setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.ref.element))
                    if (validationNormalInput.length === 0) setErrorInput(false)
                    setInputValueUnique((prevState) => [...prevState, item.ref])
                } else {
                    setInputValueUnique((prevState) => prevState.filter((obj) => obj.element !== item.ref.element))
                    setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.ref.element))
                    if (validationNormalInput.length === 0) setErrorInput(false)
                }
            } else {
                setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.ref.element))
                if (validationNormalInput.length === 0) setErrorInput(false)
            }
        }

        let action = { action: 'input', value: e.target.value }
        form(action, item.ref)
    }

    return (
        <InputWrapper>
            {type === 'number' ? (
                <input
                    className="input"
                    id={item.ref.element}
                    type="number"
                    placeholder={item.placeholder}
                    value={inputValue[item.ref.element] ? inputValue[item.ref.element] : ''}
                    onChange={(e) => handleInputChange(e, item, item.required.regex)}
                />
            ) : (
                <input
                    className="input"
                    id={item.ref.element}
                    type={item.ref.element === 'password' ? 'password' : 'text'}
                    placeholder={item.placeholder}
                    value={inputValue[item.ref.element] ? inputValue[item.ref.element] : ''}
                    onChange={(e) => handleInputChange(e, item, item.required.regex)}
                />
            )}
        </InputWrapper>
    )
}
