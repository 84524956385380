import React from "react";
import { Route, Routes } from "react-router-dom";
import { AlertsScreens } from "../../screens/EMScreen/AlertsScreen/AlertsScreens";
import { DiffusionListScreen } from "../../screens/EMScreen/DiffusionListScreen/DiffusionListScreen";
import { DiffusionListForm } from "../../screens/EMScreen/DiffusionListScreen/DiffusionListForm";
import { HistoryScreen } from "../../screens/EMScreen/HistoryScreen/HistoryScreen";
import { OverviewEMScreen } from "../../screens/EMScreen/OverviewEMScreen/OverviewEMScreen";
import { routesEM } from "../routes";
import { EmergencyWrapper } from "./EMNavigate.style";
import { AlertsFormScreen } from "../../screens/EMScreen/AlertsScreen/AlertsFormScreen";
import { HistoryToLinkedeId } from "../../screens/EMScreen/HistoryScreen/HistoryToLinkedeId/HistoryToLinkedeId";

export const EMNavigation = () => {
  return (
    <EmergencyWrapper>
      <Routes>
        <Route path="/" element={<OverviewEMScreen />} />
        <Route
          path={routesEM.diffusionLists}
          element={<DiffusionListScreen />}
        />
        <Route path={routesEM.alerts} element={<AlertsScreens />} />
        <Route path={routesEM.history} element={<HistoryScreen />} />
        <Route
          path={routesEM.createDiffusionList}
          element={<DiffusionListForm crudType="create" />}
        />
        <Route
          path={routesEM.updateDiffusionList}
          element={<DiffusionListForm crudType="edit" />}
        />
        <Route
          path={routesEM.createAlerts}
          element={<AlertsFormScreen crudType="create" />}
        />
        <Route
          path={routesEM.updateAlerts}
          element={<AlertsFormScreen crudType="edit" />}
        />
        <Route path={routesEM.historyItem} element={<HistoryToLinkedeId />} />
      </Routes>
    </EmergencyWrapper>
  );
};
