import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

const getColor = (props) => {
    if (props.isDragAccept) {
        return colors.blue
    }
    if (props.isDragReject) {
        return colors.red
    }

    return colors.greyLine
}

export const MyDropzoneWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .contain__dropzone {
        width: 100%;
        height: 32px;
        border: 1px dashed ${(props) => getColor(props)};
        border-radius: 7px;
        outline: none;
        transition: border 0.24s ease-in-out;

        p {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            height: 34px;
            width: 100%;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
        }
    }
`
export const ContainData = styled.div`
    width: 100%;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    height: 10px;

    .dropzone__file {
        display: flex;

        .dropzone__success,
        .dropzone__current {
            font-size: ${newFontSize.span};
            margin: 0;
            text-align: center;
            font-weight: ${weight.bold};
            color: ${colors.green};
            margin-left: 5px;
        }

        .dropzone__error {
            font-size: ${newFontSize.span};
            margin: 0;
            text-align: center;
            font-weight: ${weight.bold};
            color: ${colors.red};
        }
        .dropzone__item {
            font-size: ${newFontSize.span};
            margin: 0 0 5px 5px;
            display: flex;
            text-align: center;
            font-weight: ${weight.bold};
            color: ${colors.black};
            height: 10px;
        }
    }
`
