import { motion } from 'framer-motion'
import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const DropDownWrapper = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    margin: 0;
    padding: 0;
`
export const Wrapper = styled.div`
    height: ${(props) => props.height};
    width: 100%;
    background-color: ${(props) => props.backgroundColor};
    width: ${(props) => props.width};
    border-radius: 10px;
    border: 0px;
    transition: all 0.3s;
    z-index: 1050;
    border: ${(props) => props.border};

    .title-dropDown {
        color: ${(props) => props.color};
        font-size: ${newFontSize.button};
        font-weight: ${weight.medium};
        margin: 0;
        letter-spacing: 0.25px;
        line-height: 16px;
        transition: all 0.3s;
    }

    .icon-dropDown {
        margin-right: 6px;
        transition: all 0.3s;
        color: ${(props) => props.iconColor};
    }

    &:hover {
        background-color: ${(props) => props.backgroundColorHover};
        transition: all 0.3s;
        border: ${(props) => props.borderHover};
        .title-dropDown {
            color: ${(props) => props.colorHover};
            transition: all 0.3s;
        }

        .icon-dropDown {
            color: ${(props) => props.iconHover};
            transition: all 0.3s;
        }
    }
`

export const OptionsWrapper = styled.div`
    background-color: ${colors.white};
    position: absolute;
    top: 30px;
    right: 40px;
    z-index: 10;
    display: flex;
    border: 0.5px solid ${colors.greyHover};
    border-radius: 10px;
    width: auto;
    height: auto;
    flex-direction: ${(props) => props.direction};
    box-shadow: 26px 32px 17px rgba(73, 80, 87, 0.01), 15px 18px 14px rgba(73, 80, 87, 0.04), 7px 8px 10px rgba(73, 80, 87, 0.06), 2px 2px 6px rgba(73, 80, 87, 0.07),
        2px 2px 6px rgba(73, 80, 87, 0.07), 0px 0px 0px rgba(73, 80, 87, 0.07);
    .contain__option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 145px;
        padding: 10px;

        &:first-child {
            border-right: 1px solid ${colors.greyLine};
        }
    }

    .title {
        display: flex;
    }

    .contain__title {
        color: ${colors.grey};
        margin: 0;
        font-style: normal;
        font-weight: ${weight.medium};
        font-size: ${newFontSize.button};
        line-height: 16px;
        letter-spacing: 0.25px;
        padding: 10px 0;
    }

    .contain__name {
        color: ${colors.grey};
        margin: 0;
        font-style: normal;
        font-weight: ${weight.regular};
        font-size: ${newFontSize.label};
        line-height: 16px;
        letter-spacing: 0.25px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 10px;
        transition: background-color 0.3s;

        &:hover {
            background-color: ${colors.greyBorder};
        }
    }

    .contain__option-table {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        transition: background-color 0.3s;

        &:hover {
            background-color: ${colors.greyBorder};
        }
    }
`
