import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { fontFamily, newFontSize, weight } from '../../theme/fonts'

export const InputRandomWrapper = styled.div`
    .inputRandom {
        height: 34px;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &__input {
            border: 1px solid ${colors.greyBorder};
            border-radius: 5px;
            font-weight: ${weight.regular};
            font-size: ${newFontSize.body};

            color: ${colors.black};
            width: 100%;
            height: 34px;
            padding: 0 30px 0 10px;
            height: 98%;
            width: 100%;
        }

        &__icon {
            transform: rotate(0deg);
            transition: all 0.3s ease-in-out;
            position: absolute;
            right: 10px;

            &:hover {
                cursor: pointer;
            }
        }

        &__icon-rotate {
            transform: rotate(360deg);
            transition: all 0.3s ease-in-out;
            position: absolute;
            right: 10px;

            &:hover {
                cursor: pointer;
            }
        }
    }
`
