import { actionTypesApplications } from './actionTypes'

//conferences actions
export const getConferencesAction = (conferences) => {
    return {
        type: actionTypesApplications.getConferences,
        conferences,
    }
}

export const createConferenceAction = (conference) => {
    return {
        type: actionTypesApplications.createConference,
        conference,
    }
}

export const updateConferenceAction = (conference) => {
    return {
        type: actionTypesApplications.updateConference,
        conference,
    }
}

export const deleteConferenceAction = (conference) => {
    return {
        type: actionTypesApplications.deleteConference,
        conference,
    }
}

//conference form actions

export const changeConferenceFormNameAction = (payload) => {
    return {
        type: actionTypesApplications.changeConferenceFormName,
        payload,
    }
}

export const changeConferenceFormNumberAction = (payload) => {
    return {
        type: actionTypesApplications.changeConferenceFormNumber,
        payload,
    }
}

export const changeConferenceFormPinAction = (payload) => {
    return {
        type: actionTypesApplications.changeConferenceFormPin,
        payload,
    }
}

export const changeConferenceFormRecordAction = (payload) => {
    return {
        type: actionTypesApplications.changeConferenceFormRecord,
        payload,
    }
}

export const addConferenceFormCompanyAction = (payload) => {
    return {
        type: actionTypesApplications.addConferenceFormCompany,
        payload,
    }
}

export const clearConferenceFormAction = (payload) => {
    return {
        type: actionTypesApplications.clearConferenceForm,
        payload,
    }
}

export const changeConferenceFormIdAction = (payload) => {
    return {
        type: actionTypesApplications.changeConferenceFormId,
        payload,
    }
}

export const removeConferencesCompanyAction = () => {
    return {
        type: actionTypesApplications.removeConferencesCompany,
    }
}

//locutions actions
export const getLocutionsAction = (locutions) => {
    return {
        type: actionTypesApplications.getLocutions,
        locutions,
    }
}

export const createApplicationsLocutions = (locution) => {
    return {
        type: actionTypesApplications.createApplicationsLocutions,
        locution,
    }
}

export const deleteAPPLocutions = (locution) => {
    return {
        type: actionTypesApplications.deleteApplicationsLocutions,
        locution,
    }
}

export const getAudios = (payload) => {
    return {
        type: actionTypesApplications.getAudios,
        payload,
    }
}

// actions form locutions
export const changeApplicationsLocutionsFormName = (payload) => {
    return {
        type: actionTypesApplications.changeApplicationsLocutionsFormName,
        payload,
    }
}

export const addApplicationsLocutionsFormHeadquarters = (payload) => {
    return {
        type: actionTypesApplications.addApplicationsLocutionsFormHeadquarters,
        payload,
    }
}

export const removeApplicationsLocutionsFormHeadquarters = (payload) => {
    return {
        type: actionTypesApplications.removeApplicationsLocutionsFormHeadquarters,
        payload,
    }
}

export const addApplicationsLocutionsFormFile = (payload) => {
    return {
        type: actionTypesApplications.addApplicationsLocutionsFormFile,
        payload,
    }
}

export const clearLocutionsFormAction = (payload) => {
    return {
        type: actionTypesApplications.clearLocutionsForm,
        payload,
    }
}

export const changeApplicationsLocutionsFormId = (payload) => {
    return {
        type: actionTypesApplications.changeApplicationsLocutionsFormId,
        payload,
    }
}

//mailboxes actions
export const getMailboxesAction = (mailboxes) => {
    return {
        type: actionTypesApplications.getMailboxes,
        mailboxes,
    }
}

//mailboxes form actions

export const changeMailboxesFormNumberAction = (payload) => {
    return {
        type: actionTypesApplications.changeMailboxesFormNumber,
        payload,
    }
}

export const changeMailboxesFormNameAction = (payload) => {
    return {
        type: actionTypesApplications.changeMailboxesFormName,
        payload,
    }
}

export const changeMailboxesFormPinAction = (payload) => {
    return {
        type: actionTypesApplications.changeMailboxesFormPin,
        payload,
    }
}

export const addMailboxesFormCompanyAction = (payload) => {
    return {
        type: actionTypesApplications.addMailboxesFormCompany,
        payload,
    }
}

export const changeMailboxesFormEmailAction = (payload) => {
    return {
        type: actionTypesApplications.changeMailboxesFormEmail,
        payload,
    }
}

export const addMailboxesFormSoundPromptAction = (payload) => {
    return {
        type: actionTypesApplications.addMailboxesFormSoundPrompt,
        payload,
    }
}

export const changeMailboxesFormIdAction = (payload) => {
    return {
        type: actionTypesApplications.changeMailboxesFormId,
        payload,
    }
}

export const changeMailboxesFormAstVoicemailIdAction = (payload) => {
    return {
        type: actionTypesApplications.changeMailboxesFormAstVoicemailId,
        payload,
    }
}

export const clearMailboxesFormAction = (payload) => {
    return {
        type: actionTypesApplications.clearMailboxesForm,
        payload,
    }
}

export const removeMailboxesCompanyAction = () => {
    return {
        type: actionTypesApplications.removeMailboxesCompany,
    }
}

export const removeMailboxesSoundPromptAction = () => {
    return {
        type: actionTypesApplications.removeMailboxesSoundPrompt,
    }
}

//songWaiting actions
export const getSongWaitingAction = (songWaitings) => {
    return {
        type: actionTypesApplications.getSongWaiting,
        songWaitings,
    }
}

export const getSongWaitingLinkAction = (songWaitingLink) => {
    return {
        type: actionTypesApplications.getSongWaitingLink,
        songWaitingLink,
    }
}

//song waiting form actions
export const changeSongWaitingFormNameAction = (payload) => {
    return {
        type: actionTypesApplications.changeSongWaitingFormName,
        payload,
    }
}

export const addSongWaitingFormCompanyAction = (payload) => {
    return {
        type: actionTypesApplications.addSongWaitingFormCompany,
        payload,
    }
}

export const changeSongWaitingFormIdAction = (payload) => {
    return {
        type: actionTypesApplications.changeSongWaitingFormId,
        payload,
    }
}

export const addSongWaitingFormSoundPromptAction = (payload) => {
    return {
        type: actionTypesApplications.addSongWaitingFormSoundPrompt,
        payload,
    }
}

export const removeSongWaitingFormSoundPromptAction = (payload) => {
    return {
        type: actionTypesApplications.removeSongWaitingFormSoundPrompt,
        payload,
    }
}

export const clearSongWaitingFormAction = (payload) => {
    return {
        type: actionTypesApplications.clearSongWaitingForm,
        payload,
    }
}

export const removeSongWaitingFormCompanyAction = () => {
    return {
        type: actionTypesApplications.removeSongWaitingFormCompany,
    }
}

export const clearSongWaitingFormSoundPromptAction = () => {
    return {
        type: actionTypesApplications.clearSongWaitingFormSoundPrompt,
    }
}

//ivr actions
export const getIvrAction = (ivrs) => {
    return {
        type: actionTypesApplications.getIvr,
        ivrs,
    }
}

export const getDestinationAction = (destination) => {
    return {
        type: actionTypesApplications.getDestination,
        destination,
    }
}

//ivr form actions
export const changeIvrFormNameAction = (payload) => {
    return {
        type: actionTypesApplications.changeIvrFormName,
        payload,
    }
}

export const changeIvrFormIdAction = (payload) => {
    return {
        type: actionTypesApplications.changeIvrFormId,
        payload,
    }
}

export const addIvrFormCompanyAction = (payload) => {
    return {
        type: actionTypesApplications.addIvrFormCompany,
        payload,
    }
}

export const addIvrFormSoundPromptAction = (payload) => {
    return {
        type: actionTypesApplications.addIvrFormSoundPrompt,
        payload,
    }
}

export const changeIvrFormId = (payload) => {
    return {
        type: actionTypesApplications.changeIvrFormId,
        payload,
    }
}

export const clearIvrFormAction = (payload) => {
    return {
        type: actionTypesApplications.clearIvrForm,
        payload,
    }
}

export const addIvrFormDestinationAction = (payload) => {
    return {
        type: actionTypesApplications.changeIvrFormDestinations,
        payload,
    }
}

export const removeIvrFormDestinationAction = (payload) => {
    return {
        type: actionTypesApplications.removeIvrFormDestinations,
        payload,
    }
}

export const removeIvrCompanyAction = () => {
    return {
        type: actionTypesApplications.removeIvrCompany,
    }
}

export const removeIvrSoundPromptAction = () => {
    return {
        type: actionTypesApplications.removeIvrSoundPrompt,
    }
}

//shortMarking actions
export const getShortMarkingAction = (shortMarking) => {
    return {
        type: actionTypesApplications.getShortMarking,
        shortMarking,
    }
}

//shortMarking form actions

export const changeShortMarkingNombreAction = (payload) => {
    return {
        type: actionTypesApplications.changeShortMarkingFormNombre,
        payload,
    }
}

export const changeShortMarkingFormNumCortoAction = (payload) => {
    return {
        type: actionTypesApplications.changeShortMarkingFormNumCorto,
        payload,
    }
}

export const changeShortMarkingFormDestinoAction = (payload) => {
    return {
        type: actionTypesApplications.changeShortMarkingFormDestino,
        payload,
    }
}

export const addShortMarkingFormCompanyAction = (payload) => {
    return {
        type: actionTypesApplications.addShortMarkingFormCompany,
        payload,
    }
}

export const changeShortMarkingFormIdAction = (payload) => {
    return {
        type: actionTypesApplications.changeShortMarkingFormId,
        payload,
    }
}

export const clearShortMarkingFormAction = (payload) => {
    return {
        type: actionTypesApplications.clearShortMarkingForm,
        payload,
    }
}

export const removeShortMarkingCompanyAction = () => {
    return {
        type: actionTypesApplications.removeShortMarkingCompany,
    }
}

//queue actions
export const getQueueAction = (queues) => {
    return {
        type: actionTypesApplications.getQueue,
        queues,
    }
}

//queue form actions

export const changeQueueFormNameAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormName,
        payload,
    }
}

export const changeQueueFormNumberAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormNumber,
        payload,
    }
}
export const changeQueueFormMaxWaitingUsersAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormMaxWaitingUsers,
        payload,
    }
}
export const changeQueueFormCallToUseExtensionAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormCallToUseExtensions,
        payload,
    }
}
export const changeQueueFormTimeoutQueueAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormTimeoutQueue,
        payload,
    }
}
export const changeQueueFormRetryAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormRetry,
        payload,
    }
}
export const changeQueueFormTimeoutPriorityAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormTimeoutPriority,
        payload,
    }
}
export const changeQueueFormCompanyIdAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormCompanyId,
        payload,
    }
}
export const changeQueueFormMusicClassAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormMusicClass,
        payload,
    }
}
export const changeQueueFormDestinationAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormDestination,
        payload,
    }
}
export const changeQueueFormPreferencesAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormPreferences,
        payload,
    }
}

export const addQueueFormCompanyIdAction = (payload) => {
    return {
        type: actionTypesApplications.addQueueFormCompanyId,
        payload,
    }
}

export const addQueueFormMusicClassAction = (payload) => {
    return {
        type: actionTypesApplications.addQueueFormMusicClass,
        payload,
    }
}

export const addQueueFormDestinationAction = (payload) => {
    return {
        type: actionTypesApplications.addQueueFormDestination,
        payload,
    }
}

export const addQueueFormPreferencesAction = (payload) => {
    return {
        type: actionTypesApplications.addQueueFormPreferences,
        payload,
    }
}

export const addQueueFormSoundPromptAction = (payload) => {
    return {
        type: actionTypesApplications.addQueueFormSoundPrompt,
        payload,
    }
}

export const addQueueFormStrategyAction = (payload) => {
    return {
        type: actionTypesApplications.addQueueFormStrategy,
        payload,
    }
}

export const addQueueFormAgentsAction = (payload) => {
    return {
        type: actionTypesApplications.addQueueFormAgents,
        payload,
    }
}

export const changeQueueFormAgentIsActiveAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormAgentIsActive,
        payload,
    }
}

export const changeQueueFormAgentIsExternalAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormAgentIsExternal,
        payload,
    }
}

export const removeQueueFormAgentDeleteAction = (payload) => {
    return {
        type: actionTypesApplications.removeQueueFormAgentDelete,
        payload,
    }
}

export const clearQueueFormMusicClassAction = (payload) => {
    return {
        type: actionTypesApplications.clearQueueFormMusicClass,
        payload,
    }
}

export const clearQueueFormCompanyIdAction = (payload) => {
    return {
        type: actionTypesApplications.clearQueueFormCompanyId,
        payload,
    }
}

export const clearQueueFormDestinationAction = (payload) => {
    return {
        type: actionTypesApplications.clearQueueFormDestination,
        payload,
    }
}

export const clearQueueFormAction = () => {
    return {
        type: actionTypesApplications.clearQueueForm,
    }
}

export const changeQueueFormIdAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormId,
        payload,
    }
}

export const changeQueueFormIdAstQueueAction = (payload) => {
    return {
        type: actionTypesApplications.changeQueueFormIdAstQueue,
        payload,
    }
}
