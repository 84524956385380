import React from 'react'
import { Navigate } from 'react-router-dom'
import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig'
import { menuPreferences } from '../../../data/dataPreferences'
import { OverviewPreferencesWrapper } from './OverviewPreferencesScreen.style'

export const OverviewPreferencesScreen = () => {
    return (
        <OverviewPreferencesWrapper>
            <Navigate to="/preferences/calendar" />
            <LinkButtonBig menuTop={menuPreferences} />
        </OverviewPreferencesWrapper>
    )
}
