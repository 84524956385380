import styled from 'styled-components'
import { colors } from '../../theme/colors'

export const InputNumberWrapper = styled.div`
    height: 100%;
    width: 100%;
    .inputNumber {
        height: 100%;
        width: 100%;
        position: relative;

        &__display {
            max-width: 290px;
            border: 1px solid ${colors.greyLine};
            border-radius: 5px;
            padding: 0 8px;
            height: 32px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            // -webkit-appearance: none;
            // margin: 0;
            padding: 10px;
            opacity: 0;
            z-index: 222;

            &:hover {
                cursor: pointer;
            }
        }

        &__buttons {
            height: 34px;
            position: absolute;
            bottom: 3px;
            right 10px;
        }
        &__button {
            justify-self: end;
            cursor: pointer;
            

            
            img {
              height: 11px;
              width: 11px;
            }
        }
    }
`
