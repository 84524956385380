import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGird } from '../../../components/CardGrid/CardGird'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../components/TableInfo/TableInfo'
import { dataFilterLocutions, dataScreenLocutions } from '../../../data/dataApplications'
import { nameTables } from '../../../data/dataTable'
import useApplications from '../../../hooks/useApplications'
import { useSwal } from '../../../hooks/useSwal'
import useView from '../../../hooks/useView'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'

import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter'
import { LocutionsScreenWrapper } from './LocutionsScreen.style'

import { routesApplications, routesWide } from '../../../navigation/routes'

export const LocutionsScreen = () => {
    const { arrayLocutionsHook, getApplicationsLocutions, deleteAPPLocutions, uploadFileLocutions, audios, loading } = useApplications()
    const { typeSwal } = useSwal()
    const { changeView, isCardView } = useView()

    const [search, setSearch] = useState([])
    const [, setIsBottom] = useState(false)
    const [isFilter, setIsFilter] = useState({
        open: false,
        active: false,
    })

    const { notFound, icon } = dataScreenLocutions

    let navigate = useNavigate()

    const filesMP3 = audios.audio

    useEffect(() => {
        getApplicationsLocutions()
        setSearch([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSearch, getApplicationsLocutions])

    function foundFile(filename) {
        let file
        if (filesMP3.find((file) => file.includes(filename))) {
            file = filename
        }
        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
            return process.env.REACT_APP_URL.replace('api', 'uploads') + '/mp3/' + file
        } else {
            return process.env.REACT_APP_UPLOAD_FOLDER + '/mp3/' + file
        }
    }

    const DATA = arrayLocutionsHook.map(({ filename, description, createdAt, id, companyId, Company }) => ({
        filename,
        description,
        createdAt,
        id,
        companyId,
        group: Company?.name,
        filepath: foundFile(filename),
    }))

    const COLUMNS = [
        {
            Header: 'Nombre',
            accessor: 'description',
            maxWidth: 700,
            minWidth: 540,
            width: 600,
        },
        {
            Header: 'Sede',
            accessor: 'group',
        },
        {
            Header: 'Identificación',
            accessor: 'filename',
        },
        {
            Header: 'Locución',
            accessor: 'filepath',
            disableSortBy: true,
            Cell: ({ row }) => (
                <div>
                    <audio src={row.original.filepath} controls />
                </div>
            ),
        },
    ]

    const dataExport = arrayLocutionsHook.map(({ filename, description, Company }) => ({
        Identificación: filename,
        Descripción: description,
        Grupo: Company?.name,
    }))

    const dataCard = () => {
        if (search.length > 0) {
            return search.map(({ filename, description, createdAt, id, companyId, group }) => ({
                title: filename,
                icon: icon.locutions,
                subtitle: description,
                id,
                icon2: icon.home,
                text: group,
                audio: foundFile(filename),
                component: 'audio',
                filename,
                description,
                createdAt,
                companyId,
                group,
            }))
        } else {
            return DATA.map(({ filename, description, createdAt, id, companyId, Company }) => ({
                title: filename,
                icon: icon.locutions,
                subtitle: description,
                id,
                icon2: icon.home,
                text: Company?.name,
                audio: foundFile(filename),
                component: 'audio',
                filename,
                description,
                createdAt,
                companyId,
                group: Company?.name,
            }))
        }
    }

    const dataToCards = dataCard()

    const handleOpenFormCreate = () => {
        navigate(`/${routesWide.applications}/${routesApplications.createLocutions}`, {})
    }

    const handleOpenFormEdit = (item) => {
        navigate(`/${routesWide.applications}/${routesApplications.updateLocutions}`, {
            state: item.original ? item.original : item,
        })
    }

    const handleDelete = async (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response
                    if (item.original) {
                        response = await deleteAPPLocutions(item.original)
                    } else {
                        response = await deleteAPPLocutions(item)
                    }
                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        getApplicationsLocutions()
                        typeSwal('delete')
                    }
                    if (response.status === 203) {
                        let table = Object.keys(nameTables)
                            .filter((p) => p.includes(response.data.table))
                            .reduce((obj, key) => {
                                obj[key] = nameTables[key]
                                return obj[key]
                            }, {})
                        getApplicationsLocutions()
                        typeSwal('error', table)
                    }
                } catch (err) {
                    return err
                }
            }
        })
    }

    const tableInstance = useRef(null)

    const template = [
        {
            Nombre: '',
            'Grupo Sede id': '',
            Identificación: '',
        },
    ]

    const handleUploadFile = async (file) => {
        try {
            let response = await uploadFileLocutions(file)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                getApplicationsLocutions()
                typeSwal('upload')
            }
        } catch (err) {
            return err
        } finally {
            // window.location.reload()
        }
    }

    return (
        <LocutionsScreenWrapper className="screen__inside-size">
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },

                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        file: {
                            position: 'right',
                            data: dataExport,
                            template,
                        },
                        filter: {
                            position: 'left',
                        },
                    }}
                    importFile={handleUploadFile}
                    setSearch={setSearch}
                    setIsFilter={setIsFilter}
                    isFilter={isFilter}
                />
                {arrayLocutionsHook[0]?.status ? null : (
                    <div className="contain__menuFilter">
                        <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterLocutions} setSearch={setSearch} />
                    </div>
                )}
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : arrayLocutionsHook[0]?.status ? (
                <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
                    {/* <div className={'contain__menuFilter'}>
                        <MenuFilter
                            isFilter={isFilter}
                            setIsFilter={setIsFilter}
                            data={items}
                            filter={dataFilterLocutions}
                            setSearch={setSearch}
                            whichFilters={whichFilters}
                            setWhichFilters={setWhichFilters}
                        />
                    </div> */}
                </div>
            ) : (
                <>
                    <div className="contain__table">
                        <TableInfo
                            COLUMNS={COLUMNS}
                            DATA={search.length === 0 ? DATA : search}
                            format={'list'}
                            deleteFunction={handleDelete}
                            updateFunction={handleOpenFormEdit}
                            ref={tableInstance}
                            onClick={handleOpenFormEdit}
                            setIsBottom={setIsBottom}
                        />
                        {/* <div className={'contain__menuFilter'}>
                            <MenuFilter
                                isFilter={isFilter}
                                setIsFilter={setIsFilter}
                                data={items}
                                filter={dataFilterLocutions}
                                setSearch={setSearch}
                                whichFilters={whichFilters}
                                setWhichFilters={setWhichFilters}
                            />
                        </div> */}
                    </div>
                </>
            )}
        </LocutionsScreenWrapper>
    )
}
