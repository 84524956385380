import { useEffect, useState } from 'react'
import { IconTrash } from '@tabler/icons-react'
import { motion, AnimatePresence } from 'framer-motion'

import { NavTableWrapper } from './NavTable.style'

export const NavTable = ({ checkboxesActives, deleteFunction }) => {
    const [selectedItem, setSelectedItem] = useState([])

    useEffect(() => {
        setSelectedItem(checkboxesActives)
    }, [checkboxesActives])

    const handleDelete = () => {
        deleteFunction(checkboxesActives)
    }

    return (
        <AnimatePresence exitBeforeEnter>
            {selectedItem.length !== 0 && (
                <motion.div className="bodyMultiForm__select" initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 10, opacity: 0 }} transition={{ duration: 0.3 }}>
                    <NavTableWrapper>
                        <div className="navTable">
                            <p className="navTable__selectedItems">{selectedItem.length} seleccionados</p>
                            <div className="navTable__trash" onClick={handleDelete}>
                                <IconTrash height={16} className="navTable__trash__icon" />

                                <p className="navTable__trash__text">Eliminar</p>
                            </div>
                            {/* <div className="navTable__copy">
                                <IconCopy height={16} className="navTable__copy__icon" />

                                <p className="navTable__copy__text">Duplicar</p>
                            </div>
                            <div className="navTable__export">
                                <IconFileExport height={16} className="navTable__export__icon" />

                                <p className="navTable__export__text">Exportar</p>
                            </div> */}
                        </div>
                    </NavTableWrapper>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
