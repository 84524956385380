export const menuHelp = [
    // {
    //     text: "FAQs",
    //     path: routesHelp.faqs,
    //     icon: messageCircle,
    //     restricted: "faqs"
    // },
    // {
    //     text: "Tutoriales",
    //     path: routesHelp.tutorials,
    //     icon: youtube,
    //     restricted: "tutorials"
    // },
    // {
    //     text: "Guía primeros pasos",
    //     path: routesHelp.guide,
    //     icon: fileText,
    //     restricted: "guide"
    // }
]
