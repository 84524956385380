import React from "react";
import { ModuleCard } from "../../../components/ModuleCard/ModuleCard";
import { ModuleScreenWrapper } from "./ModuleScreen.style";

export const ModulesScreen = () => {
  const modules = [
    {
      title: "Emergency manager",
      description:
        "Sistema de notificación que permite registrar, difundir y verificar alarmas",
    },
    {
      title: "VoiceBot",
      description:
        "Sistema de notificación que permite registrar, difundir y verificar alarmas",
    },
    {
      title: "Emergency manager",
      description:
        "Sistema de notificación que permite registrar, difundir y verificar alarmas",
    },
    {
      title: "Emergency manager",
      description:
        "Sistema de notificación que permite registrar, difundir y verificar alarmas",
    },
    {
      title: "Emergency manager",
      description:
        "Sistema de notificación que permite registrar, difundir y verificar alarmas",
    },
    {
      title: "Emergency manager",
      description:
        "Sistema de notificación que permite registrar, difundir y verificar alarmas",
    },
    {
      title: "Emergency manager",
      description:
        "Sistema de notificación que permite registrar, difundir y verificar alarmas",
    },
    {
      title: "Emergency manager",
      description:
        "Sistema de notificación que permite registrar, difundir y verificar alarmas",
    },
  ];
  return (
    <ModuleScreenWrapper>
      <div className="contain__modules">
        {modules.map((module, index) => (
          <div className="contain__module" key={index}>
            <ModuleCard module={module} />
          </div>
        ))}
      </div>
    </ModuleScreenWrapper>
  );
};
