import React from "react";
import { LinkButtonBig } from "../../../components/LinkButtonBig/LinkButtonBig";
import { menuHelp } from "../../../data/dataHelp";
import { OverviewHelpWrapper } from "./OverviewHelp.style";

export const OverviewHelp = () => {
    return (
        <OverviewHelpWrapper>
            <LinkButtonBig menuTop={menuHelp} />
        </OverviewHelpWrapper>
    );
};
