import React, { useEffect, useState } from 'react'
import { IconPlus, IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons-react'

import { Button } from '../../Button/Button'
import { MySelect } from '../../MySelect/MySelect'
import { useRenderModal } from '../../../hooks/useRenderModal'

import { colors } from '../../../theme/colors'
import { NavCardSliderWrapper } from './NavCardSlider.style'

export const NavCardSlider = ({
    item,
    form,
    handleIsShowCard,
    isShowCard,
    setInputValue,
    setErrorInput,
    setValidationNormalInput,
    validationNormalInput,
    allSteps,
    dataModalButtonToTable,
    setDataCard,
    setStrategy,
}) => {
    const { renderModal } = useRenderModal()
    const { buttonAdd, selectStrategy } = item.navSlider
    const [modalContent, setModalContent] = useState(null)

    const { dataModal, columnsModal } = dataModalButtonToTable

    const handleCloseModal = () => {
        setModalContent(null)
    }
    const handleClick = () => {
        const content = 'table'

        const closeModal = renderModal(content, handleCloseModal, dataModal, columnsModal, setDataCard)
        setModalContent(closeModal)
    }

    useEffect(() => {
        setStrategy(selectStrategy.defaultValues.value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectStrategy.defaultValues.value])

    return (
        <NavCardSliderWrapper>
            <div className="navCardSlider">
                <div className="navCardSlider__left">
                    <div className="navCardSlider__left__button">
                        <Button
                            text={buttonAdd?.text}
                            backgroundColor={colors.primaryColor}
                            border={'0px'}
                            colorText={colors.white}
                            width={'150px'}
                            Icon={IconPlus}
                            onClick={() => handleClick()}
                            hoverBackgroundColor={colors.secondaryColor}
                            hoverIcon={colors.primaryColor}
                            hoverColorText={colors.primaryColor}
                        />
                    </div>
                    {modalContent}
                    <div className="navCardSlider__left__select">
                        <span className="navCardSlider__left__select__label">{selectStrategy.label}</span>
                        <MySelect
                            defaultValue={selectStrategy.defaultValues}
                            item={selectStrategy}
                            form={form}
                            placeholder={selectStrategy.placeholder}
                            options={selectStrategy.options}
                            setInputValue={setInputValue}
                            setErrorInput={setErrorInput}
                            setValidationNormalInput={setValidationNormalInput}
                            validationNormalInput={validationNormalInput}
                            allSteps={allSteps}
                        />
                    </div>
                </div>
                <div className="navCardSlider__right">
                    <div className="navCardSlider__right__icon" onClick={handleIsShowCard}>
                        {isShowCard ? <IconArrowsMaximize height={18} width={18} /> : <IconArrowsMinimize height={18} width={18} />}
                    </div>
                    {/* <div className="navCardSlider__right__select">
                        <MySelect defaultValue={{}} />
                    </div> */}
                </div>
            </div>
        </NavCardSliderWrapper>
    )
}
