import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const RadioWrapper = styled.div`
    border: ${(props) => (props.isSelected ? `1px solid ${colors.primaryColor}` : `1px solid ${colors.greyLine}`)};
    border-radius: 5px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.isSelected ? colors.primaryColor : colors.grey)};
    background-color: ${(props) => (props.isSelected ? colors.secondaryColor : colors.white)};
    font-weight: ${weight.regular};
    font-size: ${newFontSize.body};
    transition: all 0.3s;
    text-align: center;
    min-width: 60px;
    padding: 2px;
    max-height: 40px;

    &:hover {
        cursor: pointer;
        color: ${colors.primaryColor};
        background-color: ${colors.secondaryColor};
        transition: all 0.3s;
        border: 1px solid ${colors.primaryColor};
    }
`

export const RadioWrapperSelect = styled.div`
    border: ${(props) => (props.isSelected ? `1px solid ${colors.primaryColor}` : `1px solid ${colors.greyLine}`)};
    border-radius: 5px;
    height: 100%;
    width: 100%;
    color: ${(props) => (props.isSelected ? colors.primaryColor : colors.grey)};
    background-color: ${(props) => (props.isSelected ? colors.secondaryColor : colors.white)};
    font-weight: ${weight.regular};
    font-size: ${newFontSize.body};
    transition: all 0.3s;
    padding: 2px;

    &:hover {
        cursor: pointer;
        color: ${colors.primaryColor};
        background-color: ${colors.secondaryColor};
        transition: all 0.3s;
        border: 1px solid ${colors.primaryColor};
    }

    .radio__withSelect {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
        padding: 5px;
        z-index: 1000;

        &__label {
            color: ${colors.grey};
            font-size: ${newFontSize.label};
            margin-bottom: 2px;
        }
    }
`
export const RadioContainWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    width: 100%;
    grid-auto-rows: minmax(50px, auto);
`
