import Axios from 'axios'

import {
    getNumberingListAction,
    updateNumberingListAction,
    deleteNumberingListAction,
    getExtensionAction,
    createExtensionAction,
    updateExtensionAction,
    createNumberingListAction,
    getDdiLinkAction,
} from '../actions/numbering/actionCreator'

const urlApi = process.env.REACT_APP_URL
const token = process.env.REACT_APP_TOKEN

export const getNumberingListThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const arrayNumbering = await Axios.get(`${urlApi}numbering/numberingList`, {
            headers: {
                Authorization: token,
                //apiKey: 'po3oef9aoc7t35fglvpxl',
            },
        })
        const ddiLink = await Axios.get(`${urlApi}numbering/numberingList/link`, {
            headers: {
                Authorization: token,
                //apiKey: 'po3oef9aoc7t35fglvpxl',
            },
        })

        dispatch(getNumberingListAction(arrayNumbering.data))
        dispatch(getDdiLinkAction(ddiLink.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const getExtensionThunk = (setLoading) => async (dispatch) => {
    try {
        const arrayNumbering = await Axios.get(`${urlApi}numbering/extensions`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getExtensionAction(arrayNumbering.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const createExtensionThunk = (payload, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.post(
            `${urlApi}numbering/extensions/create`,
            {
                data: payload,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(createExtensionAction(payload))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteExtensionThunk = (payload, setLoading) => async (dispatch) => {
    setLoading(true)
    let response
    try {
        response = await Axios.delete(`${urlApi}numbering/extensions/delete`, {
            headers: {
                Authorization: token,
            },
            data: payload,
        })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const updateExtensionThunk = (payload, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.patch(
            `${urlApi}numbering/extensions/update`,
            {
                data: payload,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(updateExtensionAction(payload))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const changeExtensionPasswordThunk = (newPassword) => async () => {
    try {
        const response = await Axios.patch(
            `${urlApi}numbering/extensions/updatePassword`,
            { data: newPassword },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        return response
    } catch (error) {
        return error
    }
}

export const createNumberingListThunk = (payload, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.post(
            `${urlApi}numbering/numberingList/create`,
            {
                data: payload,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(createNumberingListAction(payload))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateNumberingListThunk = (payload, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.patch(
            `${urlApi}numbering/numberingList/update`,
            {
                data: payload,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(updateNumberingListAction(payload))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteNumberingListThunk = (payload, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.delete(`${urlApi}numbering/numberingList/delete`, {
            headers: {
                Authorization: token,
            },
            data: payload,
        })
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(deleteNumberingListAction(payload))
        }
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const getSipPasswordThunk = async (extension) => {
    try {
        const response = await Axios.post(
            `${urlApi}numbering/extensions/password`,
            { data: extension },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200) return response.data
        else if (response.status === 404) return 'Not defined'
    } catch (error) {
        return error
    }
}

export const updateFileExtensionThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}numbering/extensions/updateFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const uploadFileListNumberingThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}numbering/numberingList/updateFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}
