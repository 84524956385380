import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardGird } from '../../../components/CardGrid/CardGird'

import { HideColumn } from '../../../components/HideColumn/HideColumn'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../components/TableInfo/TableInfo'
import { dataScreenApi } from '../../../data/dataSettings'
import useSettings from '../../../hooks/useSettings'
import useView from '../../../hooks/useView'
import { routesSettings, routesWide } from '../../../navigation/routes'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'

import { ApiScreenWrapper } from './ApiScreen.style'

export const ApiScreen = () => {
    const { api, getApi, loading } = useSettings()

    let navigate = useNavigate()

    const { changeView, isCardView } = useView()
    const [search, setSearch] = useState([])
    const [, setIsBottom] = useState(false)

    const { notFound } = dataScreenApi

    useEffect(() => {
        getApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenFormCreate = () => {
        navigate(`/${routesWide.settings}/${routesSettings.createApi}`, {})
    }

    const DATA = api.map(({ apiKey, id, userId, name }) => ({
        name,
        apiKey,
        id,
        userId,
    }))
    const COLUMNS = [
        {
            Header: 'Nombre',
            accessor: 'name',
        },
        {
            Header: 'API Key',
            accessor: 'apiKey',
            disableSortBy: true,
            Cell: ({ row }) => <HideColumn row={row} id={row.original.id} data={row.original.apiKey} text={'apiKey'} />,
        },
    ]

    const dataCard = () => {
        if (search.length > 0) {
            return search.map(({ apiKey, id, userId, name }) => ({
                title: apiKey,
                subtitle: name,
                apiKey,
                id,
                userId,
                name,
            }))
        } else {
            return api.map(({ apiKey, id, userId, name }) => ({
                title: apiKey,
                subtitle: name,
                apiKey,
                id,
                userId,
                name,
            }))
        }
    }

    const dataToCards = dataCard()

    return (
        <ApiScreenWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },

                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        // file: {
                        //     position: 'right',
                        //     data: dataExport,
                        //     headers: headersExport,
                        //     template,
                        // },
                    }}
                    // importFile={handleUploadFile}
                    setSearch={setSearch}
                />
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : api[0]?.status ? (
                <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={() => {}} setIsBottom={setIsBottom} />
                </div>
            ) : (
                <>
                    <div className="contain__table">
                        <TableInfo
                            COLUMNS={COLUMNS}
                            DATA={search.length === 0 ? DATA : search}
                            format={'list'}
                            deleteFunction={() => {}}
                            updateFunction={() => {}}
                            onClick={() => {}}
                            setIsBottom={setIsBottom}
                            isOptions={false}
                            isCheckbox={false}
                        />
                    </div>
                </>
            )}
        </ApiScreenWrapper>
    )
}
