import React, { useRef, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { LayoutGrid, LayoutList } from 'tabler-icons-react'
import { IconPlus, IconFileHorizontal } from '@tabler/icons-react'

import { iconButton, menuButtonsDropDownFiles } from '../../data/dataButtons'
import { colors } from '../../theme/colors'
import { Button } from '../Button/Button'
import { DropDown } from '../DropDown/DropDown'
import { Filter } from '../Filter/Filter'
import { Search } from '../Search/Search'

import { NavButtonsWrapper } from './NavButtons.style'
import 'react-tooltip/dist/react-tooltip.css'

export const NavButtons = ({ optionButtons, importFile, setSearch, setIsFilter, isFilter }) => {
    const [selectedId, setSelectedId] = useState('')
    const refContainer = useRef(null)
    const refFilter = useRef(null)

    menuButtonsDropDownFiles.forEach((item) => {
        if (item.id === 1) {
            if (optionButtons.file?.import === false) {
                item.active = false
            } else {
                item.active = true
                item.buttons.forEach((item2) => {
                    if (item2.id === 3) {
                        item2.data = optionButtons.file?.template
                    }
                })
            }
        }

        if (item.id === 2) {
            item.buttons.forEach((item2) => {
                if (item2.id === 1) {
                    item2.data = optionButtons.file?.data
                    item2.headers = optionButtons.file?.headers
                } else if (item2.id === 2) {
                    item2.data = optionButtons.file?.data
                    item2.headers = optionButtons.file?.headers
                } else if (item2.id === 3) {
                    item2.data = optionButtons.file?.data
                }
            })
        }
    })

    return (
        <NavButtonsWrapper>
            <div className="container">
                <div className="left">
                    {optionButtons.return?.position === 'left' && (
                        <Button
                            id="return-button"
                            backgroundColor={colors.grey}
                            border={'0px'}
                            colorText={colors.white}
                            width={'76px'}
                            icon={iconButton.return.icon}
                            onClick={() => optionButtons.return.function()}
                            hoverBackgroundColor={colors.greyHover}
                            hoverIcon={'invert(92%) sepia(5%) saturate(107%) hue-rotate(177deg) brightness(105%) contrast(90%);'}
                            text={iconButton.return.text}
                            hoverColorText={colors.grey}
                        />
                    )}
                    {optionButtons.add?.position === 'left' && (
                        <Button
                            id="add-button"
                            backgroundColor={colors.primaryColor}
                            border={'0px'}
                            colorText={colors.white}
                            width={'100px'}
                            Icon={IconPlus}
                            onClick={() => optionButtons.add.function()}
                            hoverBackgroundColor={colors.secondaryColor}
                            hoverIcon={colors.primaryColor}
                            text={iconButton.plus.text}
                            hoverColorText={colors.primaryColor}
                        />
                    )}
                    {optionButtons.search?.position === 'left' && <Search id={'search-button'} data={optionButtons.search.data} setSearch={setSearch} columns={optionButtons.search.columns} />}
                    {optionButtons.filter?.position === 'left' && <Filter id={'filter-button'} isFilter={isFilter} setIsFilter={setIsFilter} refContainer={refFilter} />}
                </div>
                <div className="right">
                    {optionButtons.changeView?.position === 'right' && (
                        <div id="changeView-button" className="changeView" onClick={() => optionButtons.changeView.function()}>
                            {!optionButtons.changeView.isCardView ? <LayoutGrid strokeWidth={2} color={colors.black} /> : <LayoutList strokeWidth={2} color={colors.black} />}
                        </div>
                    )}

                    {optionButtons.file?.position === 'right' && (
                        <>
                            <DropDown
                                id={'file-button'}
                                setSelectedId={setSelectedId}
                                selectedId={selectedId}
                                layout={{ id: 1 }}
                                data={{ id: 1 }}
                                Icon={IconFileHorizontal}
                                text={'Archivos'}
                                menuButtonsDropDown={menuButtonsDropDownFiles}
                                backgroundColor={colors.primaryColor}
                                width={'110px'}
                                backgroundColorHover={colors.secondaryColor}
                                colorHover={colors.primaryColor}
                                iconColor={colors.white}
                                iconHover={colors.primaryColor}
                                position={'calc(5% + 0px);'}
                                importFile={importFile}
                                refContainer={refContainer}
                                isFilter={isFilter}
                                border={`1px solid ${colors.primaryColor} `}
                                height={'32px'}
                                borderHover={`1px solid ${colors.secondaryColor}`}
                            />
                        </>
                    )}
                </div>
                <ReactTooltip className="tooltip" anchorId="add-button" place="bottom" content="Crea un nuevo elemento" delayShow={1000} />
                <ReactTooltip className="tooltip" anchorId="return-button" place="bottom" content="Vuelve atrás" delayShow={1000} />
                <ReactTooltip className="tooltip" anchorId="file-button" place="bottom" content="Importa y/o exporta archivos" delayShow={1000} />
                <ReactTooltip className="tooltip" anchorId="search-button" place="bottom" content="Busca cualquier coincidencia" delayShow={1000} />
                <ReactTooltip className="tooltip" anchorId="filter-button" place="bottom" content="Filtra los resultados" delayShow={1000} />
                <ReactTooltip className="tooltip" anchorId="changeView-button" place="bottom" content="Cambia la vista entre listado y cuadrícula" delayShow={1000} />
            </div>
        </NavButtonsWrapper>
    )
}
