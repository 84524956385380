import { useCallback, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
    addUserFormRolesAction,
    changeUserFormEmailAction,
    changeUserFormFirstSurnameAction,
    changeUserFormNameAction,
    changeUserFormPasswordAction,
    changeUserFormSecondSurnameAction,
    changeUserFormIdAction,
    removeUserFormRolesAction,
    clearUserFormAction,
    clearUserFormRolesAction,
} from '../redux/actions/settingsScreen/user/actionCreators'
import { usersDeleteThunk, usersLoginThunk, usersCreateThunk, usersUpdateThunk, getUsersPermissionsThunk, changeUserPassword, uploadFileUsersThunk } from '../redux/thunks/usersThunks'

const useUsers = () => {
    const [loading, setLoading] = useState(true)

    const userLogin = useSelector((login) => login.login)
    const usersFormReducer = useSelector((user) => user.usersFormReducer)

    const dispatch = useDispatch()
    const { getState } = useStore()

    const permissionsUser = useSelector((permissions) => permissions.arrayUsersPermission)

    const getPermissionsUser = useCallback(
        (user) => {
            dispatch(getUsersPermissionsThunk(user))
        },
        [dispatch]
    )

    const getLoginUser = async (user) => {
        let response = await dispatch(usersLoginThunk(user))
        return response
    }

    const postCreateUser = useCallback(() => {
        const newUser = getState()['usersFormReducer']

        let response = dispatch(usersCreateThunk(newUser, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const deleteUser = (user) => {
        let response = dispatch(usersDeleteThunk(user, setLoading))
        return response
    }

    const updateUser = useCallback(() => {
        const newUser = getState()['usersFormReducer']
        newUser.role = +newUser.role.value
        let response = dispatch(usersUpdateThunk(newUser, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const userForm = useCallback((arg, ref, inputValue) => {
        if (arg.action) {
            switch (ref.form) {
                case 'user':
                    switch (arg.action) {
                        case 'select-option':
                            dispatch(addUserFormRolesAction(inputValue))
                            break
                        case 'remove-value':
                            dispatch(removeUserFormRolesAction(arg.removedValue))
                            break
                        case 'clearForm':
                            dispatch(clearUserFormAction())
                            break
                        case 'clear':
                            dispatch(clearUserFormRolesAction())
                            break
                        case 'input':
                            switch (ref.element) {
                                case 'firstname':
                                    dispatch(changeUserFormNameAction(arg.value))
                                    break
                                case 'lastname':
                                    dispatch(changeUserFormFirstSurnameAction(arg.value))
                                    break
                                case 'secondname':
                                    dispatch(changeUserFormSecondSurnameAction(arg.value))
                                    break
                                case 'email':
                                    dispatch(changeUserFormEmailAction(arg.value))
                                    break
                                case 'password':
                                    dispatch(changeUserFormPasswordAction(arg.value))
                                    break
                                default:
                                    break
                            }
                            break
                        case 'id':
                            dispatch(changeUserFormIdAction(arg.value))
                            break
                        default:
                            break
                    }
                    break
                default:
                    break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changePassword = useCallback(
        (newPassword, id) => {
            const newPasswordUser = {
                id,
                newPassword,
            }
            let response = dispatch(changeUserPassword(newPasswordUser))
            return response
        },
        [dispatch]
    )

    const uploadFileUser = useCallback(
        (file) => {
            let response = dispatch(uploadFileUsersThunk(file, setLoading))
            return response
        },
        [dispatch]
    )

    return {
        userLogin,
        getLoginUser,
        usersFormReducer,
        postCreateUser,
        deleteUser,
        updateUser,
        userForm,
        changePassword,
        uploadFileUser,
        // permissionsUser
        permissionsUser,
        getPermissionsUser,

        loading,
    }
}

export default useUsers
