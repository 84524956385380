import React from "react";
import { ProtocolSetupScreenWrapper } from "./ProtocolSetupScreen.style";

export const ProtocolSetupScreen = () => {
  return (
    <ProtocolSetupScreenWrapper>
      <h1>ProtocolSetupScreen</h1>
    </ProtocolSetupScreenWrapper>
  );
};
