import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGird } from '../../../components/CardGrid/CardGird'
import { ChangePassword } from '../../../components/ChangePassword/ChangePassword'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../components/TableInfo/TableInfo'
import { dataScreenUsers } from '../../../data/dataSettings'
import useSettings from '../../../hooks/useSettings'
import { useSwal } from '../../../hooks/useSwal'
import useUsers from '../../../hooks/useUsers'
import useView from '../../../hooks/useView'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'

import { UsersScreenWrapper } from './UserScreen.style'

export const UsersScreen = () => {
    const { users, getUsers, loading } = useSettings()
    const { deleteUser, uploadFileUser } = useUsers()
    const { typeSwal } = useSwal()
    const { changeView, isCardView } = useView()

    const [search, setSearch] = useState([])
    const [, setIsBottom] = useState(false)

    const { notFound, icon } = dataScreenUsers

    let navigate = useNavigate()

    useEffect(() => {
        getUsers()
        setSearch([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSearch])

    const COLUMNS = [
        {
            Header: 'Nombre',
            accessor: 'firstname',
        },
        {
            Header: 'Apellido',
            accessor: 'lastname',
        },
        {
            Header: 'Rol',
            accessor: 'role',
        },
        {
            Header: 'Password',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row }) => <ChangePassword row={row} />,
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
    ]

    const DATA = users.map(({ firstname, lastname, secondname, email, id, password, Role }) => ({
        firstname,
        lastname,
        secondname,
        email,
        id,
        password,
        role: Role.name,
        roleId: Role.id,
    }))

    const dataExport = users.map(({ firstname, lastname, secondname, email, Role }) => ({
        Nombnre: firstname,
        Apellido: lastname,
        'Segundo apellido': secondname,
        Email: email,
        Role: Role.name,
    }))

    const dataCard = () => {
        if (search.length > 0) {
            return search.map(({ firstname, lastname, secondname, email, id, password, role, roleId }) => ({
                title: firstname,
                icon: icon.users,
                subtitle: lastname,
                icon2: icon.mail,
                text: email,
                icon3: icon.trophy,
                text2: role,
                firstname,
                lastname,
                secondname,
                email,
                id,
                password,
                role,
                roleId,
            }))
        } else {
            return users.map(({ firstname, lastname, secondname, email, id, password, Role }) => ({
                title: firstname,
                icon: icon.users,
                subtitle: lastname,
                icon2: icon.mail,
                text: email,
                icon3: icon.trophy,
                text2: Role.name,
                firstname,
                lastname,
                secondname,
                email,
                id,
                password,
                role: Role.name,
                roleId: Role.id,
            }))
        }
    }
    const dataToCards = dataCard()

    const handleOpenFormCreate = () => {
        navigate('/settings/users/create', {})
    }

    const handleOpenFormEdit = (item) => {
        navigate('/settings/users/update', {
            state: item.original ? item.original : item,
        })
    }

    const handleUserDelete = (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response
                    if (item.original) {
                        response = await deleteUser(item.original)
                    } else {
                        response = await deleteUser(item)
                    }

                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        typeSwal('delete')
                        getUsers()
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        })
    }

    const handleUploadFile = async (file) => {
        try {
            let response = await uploadFileUser(file)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                typeSwal('upload')
                getUsers()
            }
        } catch (err) {
            return err
        }
    }

    const tableInstance = useRef(null)

    const template = [
        {
            Nombre: '',
            Apellido: '',
            'Rol *(id)': '',
            Password: '',
            Email: '',
        },
    ]

    return (
        <UsersScreenWrapper>
            <div className={'contain__buttons'}>
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },

                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        file: {
                            position: 'right',
                            data: dataExport,
                            template,
                        },
                    }}
                    importFile={handleUploadFile}
                    setSearch={setSearch}
                />
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : users[0].status ? (
                <div className="contain__table">
                    <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
                </div>
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
                </div>
            ) : (
                <div className="contain__table">
                    <TableInfo
                        format={'list'}
                        COLUMNS={COLUMNS}
                        DATA={search.length === 0 ? DATA : search}
                        updateFunction={handleOpenFormEdit}
                        deleteFunction={handleUserDelete}
                        ref={tableInstance}
                        onClick={handleOpenFormEdit}
                        setIsBottom={setIsBottom}
                    />
                </div>
            )}
        </UsersScreenWrapper>
    )
}
