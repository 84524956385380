import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import usePreferences from '../../hooks/usePreferences'
import { notScheduleForm } from '../../data/dataPreferences'
import { colors } from '../../theme/colors'
import { weight, newFontSize } from '../../theme/fonts'

// Form icons
import trash from '../../assets/images/forms/trash.svg'
import plus from '../../assets/images/forms/plus.svg'

const DayWeekWrapper = styled.div`
    border-bottom: 1px solid #c8c8c8;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;

    h4 {
        font-weight: ${weight.medium};
        font-size: ${newFontSize.body};
        padding: 0;
        width: 50px;
    }

    h5 {
        font-weight: ${weight.regular};
        font-size: ${newFontSize.h5};
    }

    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        margin-left: 5px;
        border-radius: 12px;

        &:hover {
            background-color: ${colors.secondaryColor};
        }
    }

    .input_box {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .inputs {
        display: flex;
        align-items: center;
        gap: 8px;

        input {
            border: 1px solid #000000;
            border-radius: 10px;
            padding: 0px 10px;
            width: 150px;
        }
    }
`

const DayWeek = ({ dayWeek, dayValue, editHours, crudType }) => {
    const [numInputs, setNumInputs] = useState([])

    const location = useLocation()
    const { refactorSchedule } = usePreferences()

    useEffect(() => {
        if (crudType === 'edit') {
            const scheduleRefactor = refactorSchedule(location.state.schedule)
            setNumInputs(scheduleRefactor[dayValue])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnChange = (event, id) => {
        event.preventDefault()

        const newNumInputs = numInputs.map((input) => {
            if (input.id === id)
                return {
                    ...input,
                    [event.target.name]: event.target.value,
                }
            else return input
        })

        setNumInputs(newNumInputs)

        editHours(dayValue, newNumInputs)
    }

    const handleAddInput = () => {
        setNumInputs((prevState) => [
            ...prevState,
            {
                id: numInputs.length !== 0 ? numInputs[numInputs.length - 1].id + 1 : 0,
                initialHour: '',
                finalHour: '',
            },
        ])
    }

    const handleDeleteInput = (id) => {
        if (numInputs.length !== 0) {
            const newNumInputs = numInputs.filter((input) => input.id !== id)
            setNumInputs(newNumInputs)

            editHours(dayValue, newNumInputs)
        }
    }

    return (
        <DayWeekWrapper>
            <h4> {dayWeek} </h4>
            <div className="input_box">
                {numInputs.length !== 0 ? (
                    numInputs.map((input) => (
                        <div className="inputs" key={input.id}>
                            <input type="time" name="initialHour" value={input.initialHour} onChange={(event) => handleOnChange(event, input.id)} />
                            <div>-</div>
                            <input type="time" name="finalHour" value={input.finalHour} onChange={(event) => handleOnChange(event, input.id)} />
                            <button onClick={() => handleDeleteInput(input.id)}>
                                {' '}
                                <img src={trash} width="24" height="auto" alt="Delete icon" />{' '}
                            </button>
                        </div>
                    ))
                ) : (
                    <h5>{notScheduleForm}</h5>
                )}
            </div>
            <div className="add_copy_buttons">
                <button onClick={handleAddInput}>
                    {' '}
                    <img src={plus} width="24" height="auto" alt="Delete icon" />{' '}
                </button>
                {/* <button> <img src = {copy} width = "24" height = "auto" alt = "Delete icon" /> </button> */}
            </div>
        </DayWeekWrapper>
    )
}

export default DayWeek
