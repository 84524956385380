import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const ModalWrapper = styled.div`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999999;
    background: rgba(217, 217, 217, 0.59);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        border-radius: 10px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        height: 95%;
        width: 95%;
        background-color: ${colors.white};
        overflow: hidden;
        position: relative;

        &__title {
            padding: 0 36px;
            position: relative;

            &__item {
                font-size: ${newFontSize.h3};
                color: ${colors.black};
                font-weight: ${weight.medium};
                margin: 32px 0 0 0;
            }

            &__icon {
                position: absolute;
                top: -10px;
                right: 10px;

                &__item {
                    color: #7a7a7a;

                    &:hover {
                        color: black;
                        cursor: pointer;
                    }
                }
            }
        }

        &__search {
            position: relative;
            margin-top: 23px;
            padding: 0 36px;
            z-index: 999;
        }

        &__children {
            height: 68%;
        }

        &__notFound {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__button {
            position: absolute;
            bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            height: 34px;
            align-items: center;
        }
    }
`
