import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import usePreferences from '../../../hooks/usePreferences'
import { useSwal } from '../../../hooks/useSwal'

import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { calendarScreen, monthNames, weekDays } from '../../../data/dataPreferences'
import { CalenderScreenWrapper } from './CalendarScreen.style'
import { DistributionTable } from '../../../components/DistributionTable/DistributionTable'
import Calendar from '../../../components/Calendar/Calendar'
import { menuButtonsDropDownTable } from '../../../data/dataButtons'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'

export const CalendarScreen = () => {
    const navigate = useNavigate()
    const { getCalendar, loading, calendarArrayHook, enable, handleClickCalendar, actualCalendar, deleteCalendar } = usePreferences()
    const [selectedId, setSelectedId] = useState('')
    const { typeSwal } = useSwal()
    const [selectedRangeDays, setSelectedRangeDays] = useState([])

    useEffect(() => {
        getCalendar()
    }, [getCalendar])

    useEffect(() => {
        if (actualCalendar !== []) setSelectedRangeDays(actualCalendar)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualCalendar])

    const handleOpenFormCreate = () => {
        navigate('/preferences/calendar/create', {})
    }

    const handleOpenFormEdit = (item) => {
        const allInfoCalendar = calendarArrayHook.find((calendar) => calendar[0].id === item.id)

        navigate(`/preferences/calendar/update`, {
            state: {
                calendar: actualCalendar,
                calendarId: allInfoCalendar[0].calendarNameId,
                calendarDaysId: allInfoCalendar.map((items) => items.calendarDaysId),
            },
        })
    }

    const handleDelete = async (item) => {
        const deleteCalendarItem = calendarArrayHook.find((calendar) => calendar[0].id === item.id)

        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await deleteCalendar(deleteCalendarItem)
                    if (response.status === 200) {
                        getCalendar()
                        typeSwal('delete')
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        })
    }

    menuButtonsDropDownTable.map((item) => {
        if (item.id === 1) item.function = handleOpenFormEdit
        else if (item.id === 2) item.function = handleDelete
    })

    const prevOptions = calendarArrayHook
        .map((item) => {
            if (!item?.status) {
                return {
                    text: item[0].CalendarName.name,
                    id: item[0].id,
                }
            } else {
                return null
            }
        })
        .filter((item) => item !== null)

    const options = [{ text: 'Todos', id: 'all' }, ...prevOptions]

    return (
        <CalenderScreenWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },
                    }}
                />
            </div>
            <div className="distributionTable">
                {loading ? (
                    <LoaderSpinner />
                ) : (
                    <DistributionTable
                        title={calendarScreen.title}
                        option={options}
                        component={
                            <Calendar selectedDays={selectedRangeDays} setSelectedDays={setSelectedRangeDays} monthNames={monthNames} weekDays={weekDays} monthsDisplayed={12} crudType={'View'} />
                        }
                        selectedId={selectedId}
                        setSelectId={setSelectedId}
                        handleClick={!calendarArrayHook[0]?.status ? handleClickCalendar : () => {}}
                        menuButtonsDropDown={menuButtonsDropDownTable}
                        enable={enable}
                        isDisableFirstOption={true}
                    />
                )}
            </div>
        </CalenderScreenWrapper>
    )
}
