import { useState } from 'react'

export const useLoginForm = () => {
    const initialData = {
        email: '',
        password: '',
    }

    const [userData, setUserData] = useState(initialData)

    const handleChange = (event) => {
        setUserData({
            ...userData,
            [event.target.id]: event.target.value,
        })
    }

    return {
        userData,
        handleChange,

        setUserData,
        initialData,
    }
}
