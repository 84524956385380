export const actionsTypes = {
    // CRUD Numbering List
    getNumberingList: 'GET_NUMBERING_LIST',
    createNumberingList: 'CREATE_NUMBERING_LIST',
    updateNumberingList: 'UPDATE_NUMBERING_LIST',
    deleteNumberingList: 'DELETE_NUMBERING_LIST',
    cleanGetNumberingList: 'CLEAN_GET_NUMBERING_LIST',
    importFileNumberingList: 'IMPORT_FILE_NUMBERING_LIST',
    getDdiLink: 'GET_DDI_LINK',

    // CRUD Extensions
    getExtension: 'GET_EXTENSIONS',
    createExtension: 'CREATE_EXTENSION',
    updateExtension: 'UPDATE_EXTENSION',
    deleteExtension: 'DELETE_EXTENSION',
    importFileExtension: 'IMPORT_FILE_EXTENSION',

    // Form Extensions
    changeExtensionId: 'CHANGE_EXTENSION_ID',
    changeExtensionNumber: 'CHANGE_EXTENSION_NUMBER',
    changeExtensionName: 'CHANGE_EXTENSION_NAME',
    changeExtensionPassword: 'CHANGE_EXTENSION_PASSWORD',
    changeExtensionRingTimeout: 'CHANGE_EXTENSION_RING_TIMEOUT',
    addExtensionIdentification: 'ADD_EXTENSION_IDENTIFICATION',
    removeExtensionIdentification: 'REMOVE_EXTENSION_IDENTIFICATION',
    addTypeExtension: 'ADD_EXTENSION_TYPE',
    removeTypeExtension: 'REMOVE_EXTENSION_TYPE',
    clearExtensionForm: 'CLEAR_EXTENSION_FORM',
    changeExtensionCallForward: 'CHANGE_EXTENSION_CALL_FORWARD',
    changeExtensionCallForwardNA: 'CHANGE_EXTENSION_CALL_FORWARD_NA',
    addExtensionCompanyId: 'ADD_EXTENSION_COMPANY_ID',
    removeExtensionCompanyId: 'REMOVE_EXTENSION_COMPANY_ID',
    addExtensionRecord: 'ADD_EXTENSION_RECORD',
    removeExtensionRecord: 'REMOVE_EXTENSION_RECORD',
    addExtensionPikedGroup: 'ADD_EXTENSION_PIKED_GROUP',
    removeExtensionPikedGroup: 'REMOVE_EXTENSION_PIKED_GROUP',
    clearExtensionsPikedGroup: 'CLEAR_EXTENSIONS_PIKED_GROUP',
    changeExtensionVoicemailActive: 'CHANGE_EXTENSION_VOICEMAIL_ACTIVE',
    addExtensionDepartment: 'ADD_EXTENSION_DEPARTMENT',
    addExtensionCategories: 'ADD_EXTENSION_CATEGORIES',
    changeExtensionCallWaiting: 'CHANGE_EXTENSION_CALL_WAITING',
    changeExtensionSipPeerId: 'CHANGE_EXTENSION_SIP_PEER_ID',
    addExtensionAllow: 'ADD_EXTENSION_ALLOW',
    clearExtensionAllow: 'CLEAR_EXTENSION_ALLOW',
    removeExtensionDepartment: 'REMOVE_EXTENSION_DEPARTMENT',
    removeExtensionCategories: 'REMOVE_EXTENSION_CATEGORIES',

    //listNumbering actions (Numbering)
    changeNumberingListId: 'CHANGE_NUMBERING_LIST_ID',
    changeNumberingListNumber: 'CHANGE_NUMBERING_LIST_NUMBER',
    changeNumberingListName: 'CHANGE_NUMBERING_LIST_NAME',
    addNumberingListHq: 'ADD_NUMBERING_LIST_HQ',
    removeNumberingListHq: 'REMOVE_NUMBERING_LIST_HQ',
    clearNumberingListForm: 'CLEAR_NUMBERING_LIST_FORM',
    addNumberingListCompanyId: 'ADD_NUMBERING_LIST_COMPANY_ID',
    removeNumberingListCompanyId: 'REMOVE_NUMBERING_LIST_COMPANY_ID',
    addNumberingListDepartment: 'ADD_NUMBERING_LIST_DEPARTMENT',
    removeNumberingListDepartment: 'REMOVE_NUMBERING_LIST_DEPARTMENT',
    addNumberingListCalendar: 'ADD_NUMBERING_LIST_CALENDAR',
    addNumberingListSchedule: 'ADD_NUMBERING_LIST_SCHEDULE',
    addNumberingListSoundPrompt: 'ADD_NUMBERING_LIST_SOUND_PROMPT',
    addNumberingListDestination: 'ADD_NUMBERING_LIST_DESTINATION',
    addNumberingListSoundPromptClose: 'ADD_NUMBERING_LIST_SOUND_PROMPT_CLOSE',
    addNumberingListDestinationClose: 'ADD_NUMBERING_LIST_DESTINATION_CLOSE',
    addDestinationCalendar: 'ADD_NEW_DESTINATION_CALENDAR',
    removeDestinationCalendar: 'REMOVE_DESTINATION_CALENDAR',
    addDestinationOpen: 'ADD_DESTINATION_OPEN',
    clearNumberingListCalendar: 'CLEAR_NUMBERING_LIST_CALENDAR',
    clearNumberingListSchedule: 'CLEAR_NUMBERING_LIST_SCHEDULE',
    clearNumberingListSoundPrompt: 'CLEAR_NUMBERING_LIST_SOUND_PROMPT',
    clearNumberingListDestination: 'CLEAR_NUMBERING_LIST_DESTINATION',
    clearNumberingListSoundPromptClose: 'CLEAR_NUMBERING_LIST_SOUND_PROMPT_CLOSE',
    clearNumberingListDestinationClose: 'CLEAR_NUMBERING_LIST_DESTINATION_CLOSE',
    clearNumberingListCompanyId: 'CLEAR_NUMBERING_LIST_COMPANY',
    clearListNumberingDepartment: 'CLEAR_LIST_NUMBERING_DEPARTMENT',
    removeDestinationOpen: 'REMOVE_DESTINATION_OPEN',
}
