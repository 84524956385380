import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { number } from 'prop-types'

import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { useHeadquarter } from '../../../hooks/useHeadquarter'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'
import { dataScreenCompany } from '../../../data/dataPreferences'
import { DistributionTable } from '../../../components/DistributionTable/DistributionTable'
import { CardGird } from '../../../components/CardGrid/CardGird'
import { menuButtonsDropDownTable } from '../../../data/dataButtons'
import { useSwal } from '../../../hooks/useSwal'
import { nameTables } from '../../../data/dataTable'

import { CompanyScreenWrapper } from './CompanyScreen.style'

export const CompanyScreen = () => {
    const {
        arrayHeadquarterHook,
        getHeadquarter,
        companyLink,
        deleteCompany,
        loading,
    } = useHeadquarter()
    const { typeSwal } = useSwal()

    let navigate = useNavigate()

    const { notFound, title } = dataScreenCompany

    const [selectedId, setSelectedId] = useState('')

    const [enable, setEnable] = useState({})

    const [objectCompanyLink, setObjectCompanyLink] = useState({
        departments: [],
        title: '',
        id: number,
        ip: number,
        port: number,
        ipBackup: number,
        portBackup: number,
    })

    useEffect(() => {
        getHeadquarter()
    }, [getHeadquarter])

    useEffect(() => {
        if (
            objectCompanyLink.title === '' ||
            objectCompanyLink.title === undefined
        ) {
            let newName = companyLink.filter(
                (item) => item.Company?.id === arrayHeadquarterHook[0]?.id
            )
            setObjectCompanyLink({
                departments: newName,
                title: arrayHeadquarterHook[0]?.name,
                id: arrayHeadquarterHook[0]?.id,
                ip: arrayHeadquarterHook[0]?.ip,
                port: arrayHeadquarterHook[0]?.port,
                ipBackup: arrayHeadquarterHook[0]?.ipBackup,
                portBackup: arrayHeadquarterHook[0]?.portBackup,
            })
            setEnable(arrayHeadquarterHook[0]?.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyLink, arrayHeadquarterHook])

    const option = arrayHeadquarterHook.map(
        ({ name, id, ip, port, ipBackup, portBackup }) => ({
            text: name,
            id,
            name,
            ip,
            port,
            ipBackup,
            portBackup,
        })
    )

    const handleClick = (item) => {
        let newName = companyLink.filter(
            (item2) => item2.Company?.id === item.id
        )
        setObjectCompanyLink({
            departments: newName,
            title: item.text,
            id: item.id,
            ip: item.ip,
            port: item.port,
            ipBackup: item.ipBackup,
            portBackup: item.portBackup,
        })
        setEnable(item.id)
    }

    const handleOpenFormCreate = () => {
        navigate('/preferences/company/create', {})
    }

    const handleOpenFormEdit = (item) => {
        handleClick(item)
        navigate('/preferences/company/update', {
            state: objectCompanyLink,
        })
    }

    const handleDelete = async (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response = await deleteCompany(item)
                    if (
                        response.status === 200 ||
                        response.status === 201 ||
                        response.status === 204
                    ) {
                        typeSwal('delete')
                        getHeadquarter()
                    }
                    if (response.status === 203) {
                        let belongTables = []
                        response.data.map((item) =>
                            Object.keys(nameTables).forEach((key) => {
                                if (key.includes(item)) {
                                    belongTables.push(nameTables[key])
                                }
                            })
                        )
                        typeSwal('error', belongTables[0])
                        getHeadquarter()
                    }
                } catch (err) {
                    return err
                }
            }
        })
    }

    const dataToCards = objectCompanyLink.departments.map(
        ({ Department, Company }) => ({
            title: Department.name,
        })
    )

    menuButtonsDropDownTable.forEach((item) => {
        if (item.id === 1) {
            item.function = handleOpenFormEdit
        } else if (item.id === 2) {
            item.function = handleDelete
        }
    })

    return (
        <CompanyScreenWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },
                    }}
                />
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : arrayHeadquarterHook[0].status ? (
                <div className="contain__table">
                    <NotFoundScreen
                        icon={notFound.icon}
                        title={notFound.title}
                        subtitle={notFound.subtitle}
                        textButton={notFound.textButton}
                        onClick={handleOpenFormCreate}
                    />
                </div>
            ) : (
                <div className="distributionTable">
                    <DistributionTable
                        title={title}
                        option={option}
                        component={<CardGird data={dataToCards} />}
                        selectedId={selectedId}
                        setSelectId={setSelectedId}
                        handleClick={handleClick}
                        menuButtonsDropDown={menuButtonsDropDownTable}
                        enable={enable}
                    />
                </div>
            )}
        </CompanyScreenWrapper>
    )
}
