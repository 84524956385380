import React, { useEffect, useRef } from 'react'
import { IconSearch } from '@tabler/icons-react'

import { iconButton } from '../../data/dataButtons'
import { SearchWrapper } from './Search.style'

export const Search = ({ data, setSearch, columns, id }) => {
    const [active, setActive] = React.useState(false)
    const ref = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setActive(false)
            }
        }

        document.addEventListener('click', handleClickOutside)
        return () => document.removeEventListener('click', handleClickOutside)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleInputChange = (e) => {
        const search = e.target.value.toLowerCase()
        let arrSearch = []
        let arrColumns = []

        columns.map((item) => arrColumns.push(item.accessor))

        data.forEach((item) => {
            let arrValues = Object.values(item)
            let arrKeys = Object.keys(item)
            let arrIndexUndefined = []

            for (let i = 0; i < arrValues.length; i++) {
                if (arrValues[i] === undefined) {
                    arrIndexUndefined.push(i)
                }
            }

            for (let i = 0; i < arrValues.length; i++) {
                if (arrColumns.includes(arrKeys[i]) && !arrIndexUndefined.includes(i) && arrValues[i].toString().toLowerCase().includes(search)) {
                    arrSearch.push(item)
                    break
                }
            }
        })
        if (search === '' || search.length <= 1) {
            setSearch([])
        }
        if (arrSearch.length === 0) {
            setSearch([{ notFound: iconButton.search.notFound }])
        }
        if (search.length > 1 && arrSearch.length > 0) {
            setSearch(arrSearch)
        }
    }
    return (
        <SearchWrapper id={id} active={active} onClick={setActive} ref={ref}>
            <IconSearch height={15} className="search__icon" />

            {active && (
                <input
                    type="text"
                    // placeholder={iconButton.search.placeholder}
                    onChange={(e) => handleInputChange(e)}
                />
            )}
        </SearchWrapper>
    )
}
