import React, { useEffect, useState } from 'react'
import { IconX } from '@tabler/icons-react'

import { ShowHideCardWrapper } from './ShowHideCard.style'
import { ToggleButton } from '../ToggleButton/ToggleButton'
import { ToggleSwitch } from '../ToggleSwich/ToggleSwitch'
import { colors } from '../../theme/colors'

export const ShowHideCard = ({ isShowCard, item, dataCard, setDataCard, form, dataForm, inactive, external }) => {
    const [isCardActive, setIsCardActive] = useState(true)
    const [isToggleSwitchActive, setIsToggleSwitchActive] = useState(true)

    const toggleSwitch = (data, isOn, setIsOn) => {
        setIsOn(!isOn)
        setIsToggleSwitchActive(!isToggleSwitchActive)
        const action = {
            action: dataForm.ref.element,
        }
        const ref = {
            form: dataForm.ref.form,
            element: 'switchButton',
        }

        form(action, ref, item)
    }

    const toggleButton = () => {
        setIsCardActive(!isCardActive)
        const action = {
            action: dataForm.ref.element,
        }
        const ref = {
            form: dataForm.ref.form,
            element: 'toggleButton',
        }

        form(action, ref, item)
    }

    const handleDeleteCard = (item) => {
        let newDataCard = dataCard.filter((data) => data.id !== item.id)
        setDataCard(newDataCard)
        const action = {
            action: dataForm.ref.element,
        }
        const ref = {
            form: dataForm.ref.form,
            element: 'delete',
        }

        form(action, ref, item)
    }

    useEffect(() => {
        setIsCardActive(!inactive)
        setIsToggleSwitchActive(!external)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ShowHideCardWrapper isCardActive={isCardActive} isToggleSwitchActive={isToggleSwitchActive} isShowCard={isShowCard} className={'dragHandle'}>
            {isShowCard ? (
                <div className="showHideCard">
                    <div className="showHideCard__header" onClick={toggleButton}>
                        <p className="showHideCard__header__text">
                            {item.extension} {item.name}
                        </p>
                        <IconX className="showHideCard__header__icon" height={14} width={14} onClick={() => handleDeleteCard(item)} />
                    </div>
                    <div className="showHideCard__body" onClick={toggleButton}>
                        <div className={'showHideCard__body__toggleButton'}>
                            <ToggleButton isActive={isCardActive} isShowCard={isShowCard} textLeft={'Inactivo'} textRight={'Activo'} />
                        </div>
                    </div>
                    <div className="showHideCard__footer">
                        <p className="showHideCard__footer__text">Usuario externo</p>
                        <div className="showHideCard__footer__toggleSwitch">
                            <ToggleSwitch isActive={external ? 1 : 0} toggleSwitch={toggleSwitch} color={colors.primaryColor} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="showHideCard">
                    <div className="showHideCard__header">
                        <p className="showHideCard__header__text">
                            {item.extension} {item.name}
                        </p>
                        <IconX className="showHideCard__header__icon" height={14} width={14} onClick={() => handleDeleteCard(item)} />
                    </div>
                    <div className="showHideCard__body opacity" onClick={toggleButton}>
                        <div className={'showHideCard__body__toggleButton'}>
                            <ToggleButton isActive={isCardActive} isShowCard={isShowCard} textLeft={'Inactivo'} textRight={'Activo'} />
                        </div>
                    </div>
                    <div className="showHideCard__footer opacity">
                        <p className="showHideCard__footer__text">Usuario externo</p>
                        <div className="showHideCard__footer__toggleSwitch">
                            <ToggleSwitch isActive={true} toggleSwitch={toggleSwitch} color={colors.primaryColor} />
                        </div>
                    </div>
                </div>
            )}
        </ShowHideCardWrapper>
    )
}
