import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import { AnimatePresence } from 'framer-motion'
import { IconToggleLeft, IconToggleRight, IconDotsVertical } from '@tabler/icons-react'

import { menuButtonsDropDownTable } from '../../data/dataButtons'
import { DropDown } from '../DropDown/DropDown.jsx'
import { Checkbox } from '../Checkbox/Checkbox'

import { TableInfoWrapper } from './TableInfo.style'
import { dataTable } from '../../data/dataTable'
import { colors } from '../../theme/colors'
import { NavTable } from '../NavTable/NavTable'

export const TableInfo = React.forwardRef(
    ({ format, COLUMNS, DATA, deleteFunction, updateFunction, isOptions = true, onClick, setIsBottom, setShowPassword, showPassword, isCheckbox = true, isNavTable = true, setDataCard }, ref) => {
        const [checkBoxAllSelected, setCheckBoxAllSelected] = useState(false)
        const [checkboxesActives, setCheckboxesActives] = useState([])

        useEffect(() => {
            if (!isNavTable) {
                setDataCard(checkboxesActives)
            }
        }, [checkboxesActives])

        const scrollContainerRef = useRef(null)

        // function handleUserScroll() {
        //     const scrollContainer = scrollContainerRef.current

        //     const scrollTop = scrollContainer.scrollTop
        //     const scrollHeight = scrollContainer.scrollHeight

        //     if (scrollTop + 720 >= scrollHeight) {
        //         setIsBottom(true)
        //     }
        // }

        // useEffect(() => {
        //     const scrollContainer = scrollContainerRef.current
        //     scrollContainer.addEventListener('scroll', handleUserScroll)
        //     return () => scrollContainer.removeEventListener('scroll', handleUserScroll)
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, [])

        menuButtonsDropDownTable.forEach((item) => {
            if (item.id === 1) {
                item.function = updateFunction
            } else if (item.id === 2) {
                item.function = deleteFunction
            }
        })

        const sortItems = (prev, curr, columnId) => {
            if (typeof prev.original[columnId] === 'number') {
                if (prev.original[columnId] > curr.original[columnId]) {
                    return 1
                } else if (prev.original[columnId] < curr.original[columnId]) {
                    return -1
                } else {
                    return 0
                }
            } else if (prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
                return 1
            } else if (prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
                return -1
            } else {
                return 0
            }
        }

        COLUMNS.map(
            (item) =>
                (item.sortType = (prev, curr, columnId) => {
                    return sortItems(prev, curr, columnId)
                })
        )

        function addColumnOption() {
            const found = COLUMNS.some((item) => item.Header === 'options')
            if (!found) {
                COLUMNS.push({
                    Header: 'options',
                    className: 'options',
                    Cell: ({ row }) => {
                        let refContainer = useRef(null)
                        return (
                            <DropDown
                                layout={row.id}
                                data={row}
                                menuButtonsDropDown={menuButtonsDropDownTable}
                                Icon={IconDotsVertical}
                                direction={'column'}
                                position={'calc(5% + 30px);'}
                                refContainer={refContainer}
                                iconColor={colors.grey}
                            />
                        )
                    },
                })
                return COLUMNS
            }
        }
        if (isOptions) {
            addColumnOption()
        }

        function addColumnCheckbox() {
            const found = COLUMNS.some((item) => item.Header === 'checkbox')
            if (!found) {
                COLUMNS.unshift({
                    Header: 'checkbox',
                    className: 'checkbox',

                    disableSortBy: true,
                })
            }
        }
        if (isCheckbox) {
            addColumnCheckbox()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const columns = useMemo(() => COLUMNS, [])
        const data = useMemo(() => DATA, [DATA])

        const tableInstance = useTable(
            { columns, data },
            // useFilters,
            // useGlobalFilter
            useSortBy
        )

        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

        useImperativeHandle(ref, () => tableInstance)

        const handleShowPassword = () => {
            setShowPassword(!showPassword)
        }

        useEffect(() => {}, [checkboxesActives, checkBoxAllSelected])

        return (
            <>
                {isNavTable && <NavTable checkboxesActives={checkboxesActives} deleteFunction={deleteFunction} />}

                <TableInfoWrapper id="table" ref={scrollContainerRef}>
                    {format === 'list' ? (
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroups) => (
                                    <tr {...headerGroups.getHeaderGroupProps()}>
                                        {headerGroups.headers.map((column, index) => (
                                            <React.Fragment key={index}>
                                                <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    <div>
                                                        <div>
                                                            {column.Header === 'options' ? (
                                                                ''
                                                            ) : column.Header === 'checkbox' ? (
                                                                <Checkbox
                                                                    checkBoxAllSelected={checkBoxAllSelected}
                                                                    setCheckBoxAllSelected={setCheckBoxAllSelected}
                                                                    checkboxesActives={checkboxesActives}
                                                                    setCheckboxesActives={setCheckboxesActives}
                                                                    DATA={DATA}
                                                                />
                                                            ) : (
                                                                column.render('Header')
                                                            )}
                                                        </div>
                                                        <div className="image-header">
                                                            {column.Header === 'options' || (column.disableSortBy && column.Header !== 'Contraseña SIP') ? (
                                                                ''
                                                            ) : column.Header === 'Contraseña SIP' ? (
                                                                <div className="image-showPassword" onClick={() => handleShowPassword()}>
                                                                    {showPassword ? (
                                                                        <IconToggleRight size={'18px'} color={colors.primaryColor} />
                                                                    ) : (
                                                                        <IconToggleLeft size={'18px'} color={colors.black} />
                                                                    )}
                                                                </div>
                                                            ) : column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <img className="image-header" src={dataTable.sortDescending.image} alt={dataTable.sortDescending.alt} />
                                                                ) : (
                                                                    <img className="image-header" src={dataTable.sortAscending.image} alt={dataTable.sortAscending.alt} />
                                                                )
                                                            ) : (
                                                                <img className="image-header" src={dataTable.sort.image} alt={dataTable.sort.alt} />
                                                            )}
                                                        </div>
                                                    </div>
                                                </th>
                                                {index !== 0 && <th className="spacer"></th>}
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                <AnimatePresence>
                                    {rows.map((row) => {
                                        prepareRow(row)

                                        return (
                                            <tr style={{ cursor: 'pointer' }} {...row.getRowProps()}>
                                                {row.cells.map((cell, index) => {
                                                    return isOptions && index === row.cells.length - 1 ? (
                                                        <React.Fragment key={index}>
                                                            <td {...cell.getCellProps()}>
                                                                {cell.render('Cell', {
                                                                    // selectedId:
                                                                    //     selectedId,
                                                                    // setSelectedId:
                                                                    //     setSelectedId,
                                                                    // refContainer:
                                                                    //     refContainer,
                                                                })}
                                                            </td>
                                                            {index !== 0 && <th className="spacer"></th>}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment key={index}>
                                                            <td
                                                                onClick={
                                                                    cell?.column?.Header === 'Contraseña SIP' ||
                                                                    cell?.column?.Header === 'Registrar' ||
                                                                    cell?.column?.Header === 'Llamar y grabar' ||
                                                                    cell?.column?.Header === 'Password' ||
                                                                    cell?.column?.Header === 'checkbox' ||
                                                                    cell?.column?.Header === 'Contraseña'
                                                                        ? () => {}
                                                                        : () => onClick(row)
                                                                }
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell?.column?.Header === 'checkbox' ? (
                                                                    <Checkbox
                                                                        checkBoxAllSelected={checkBoxAllSelected}
                                                                        setCheckBoxAllSelected={setCheckBoxAllSelected}
                                                                        row={cell.row?.original}
                                                                        id={cell.row?.original.id}
                                                                        checkboxesActives={checkboxesActives}
                                                                        setCheckboxesActives={setCheckboxesActives}
                                                                    />
                                                                ) : (
                                                                    cell.render('Cell')
                                                                )}
                                                            </td>
                                                            {index !== 0 && <td className="spacer" onClick={() => onClick(row)}></td>}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </AnimatePresence>
                            </tbody>
                        </table>
                    ) : (
                        ''
                    )}
                </TableInfoWrapper>
            </>
        )
    }
)
