import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { dataScreenDiffusionList } from '../../../data/dataEM'
import useEM from '../../../hooks/useEM'
import { DistributionTable } from '../../../components/DistributionTable/DistributionTable'
import { CardGird } from '../../../components/CardGrid/CardGird'
import { menuButtonsDropDownTable } from '../../../data/dataButtons'
import { nameTables } from '../../../data/dataTable'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'
import { useSwal } from '../../../hooks/useSwal'

import { DiffusionListWrapper } from './DiffusionListScreen.style'
import { routesEM, routesNumbering, routesWide } from '../../../navigation/routes'

export const DiffusionListScreen = () => {
    const { arrayEM, extensions, deleteEMListExtensions, getListDiffusionExtension, loading } = useEM()
    const { typeSwal } = useSwal()

    let navigate = useNavigate()

    const [objectExtensions, setObjectExtensions] = useState({
        arrayExtensions: [],
        title: '',
    })
    const [enable, setEnable] = useState({})

    useEffect(() => {
        getListDiffusionExtension()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let newListDiffusion = extensions.filter((item) => item.moduleEmListId === arrayEM[0]?.id)
        setObjectExtensions({
            arrayExtensions: newListDiffusion,
            title: arrayEM[0]?.name,
        })

        setEnable(arrayEM[0]?.id)
    }, [extensions, arrayEM])

    const handleClick = (item) => {
        let newExtension = extensions.filter((object) => object.moduleEmListId === item.id)
        setObjectExtensions({
            arrayExtensions: newExtension,
            title: item.text,
        })
        setEnable(item.id)
    }

    const handleOpenFormCreate = () => {
        navigate(`/${routesWide.EM}/${routesEM.createDiffusionList}`, {})
    }

    const handleOpenFormEdit = (item) => {
        handleClick(item)
        navigate(`/${routesWide.EM}/${routesEM.updateDiffusionList}`, {
            state: {
                title: item.text,
                id: item.id,
                arrayExtensions: objectExtensions.arrayExtensions,
            },
        })
    }

    const handleOpenFormEdit2 = (item) => {
        navigate(`/${routesWide.numbering}/${routesNumbering.updateExtensions}`, {
            state: {
                state: item.data,
                route: `/${routesWide.EM}/${routesEM.diffusionLists}`,
            },
        })
    }

    const handleDelete = async (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await deleteEMListExtensions(item)
                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        getListDiffusionExtension()
                        typeSwal('delete')
                    }

                    if (response.status === 203) {
                        let table = Object.keys(nameTables)
                            .filter((p) => p.includes(response.data.table))
                            .reduce((obj, key) => {
                                obj[key] = nameTables[key]
                                return obj[key]
                            }, {})
                        typeSwal('error', table)
                    }
                } catch (err) {
                    return err
                }
            }
        })
    }

    function dataEditExtensions(data) {
        if (!data) return
        const {
            extension,
            name,
            password,
            id,
            ringTimeout,
            typeExtension,
            Ddi,
            callForward,
            callForwardNA,
            companyId,
            record,
            voicemailActive,
            Company,
            Department,
            CategoriesGroup,
            callWaiting,
            sipPeerId,
            AstSipPeer,
        } = data

        return {
            extension,
            name,
            password,
            id,
            ringTimeout,
            typeExtension,
            identification: Ddi?.ddi,
            ddiId: Ddi?.id,
            callForward,
            callForwardNA,
            companyId,
            record,
            voicemailActive,
            company: Company?.name,
            department: Department?.name,
            departmentId: Department?.id,
            categoryGroup: CategoriesGroup?.description,
            categoryGroupId: CategoriesGroup?.id,
            callWaiting,
            sipPeerId,
            namedpickupgroup: AstSipPeer?.namedpickupgroup,
            allow: AstSipPeer?.allow,
        }
    }

    const dataToCards = objectExtensions.arrayExtensions.map((item) => ({
        title: item.Extension?.name,
        icon: dataScreenDiffusionList.icon.phone,
        subtitle: item.Extension?.extension,
        icon2: dataScreenDiffusionList.icon.home,
        text: item.Extension?.typeExtension,
        icon3: dataScreenDiffusionList.icon.hierarchy,
        text2: item.Extension?.callForward,
        id: item.Extension?.id,
        boolean: item.Extension?.voicemailActive,
        // data: item.Extension,
        data: dataEditExtensions(item.Extension),
    }))

    menuButtonsDropDownTable.forEach((item) => {
        if (item.id === 1) {
            item.function = handleOpenFormEdit
        } else if (item.id === 2) {
            item.function = handleDelete
        }
    })

    const { title, notFound } = dataScreenDiffusionList
    const option = arrayEM.map((item) => ({
        text: item.name,
        id: item.id,
    }))

    return (
        <DiffusionListWrapper className="screen__inside-size">
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },
                    }}
                />
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : arrayEM[0]?.status ? (
                <div className="contain__table">
                    <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
                </div>
            ) : (
                <>
                    <div className="distributionTable">
                        <DistributionTable
                            title={title}
                            option={option}
                            component={<CardGird data={dataToCards} onClick={handleOpenFormEdit2} />}
                            handleClick={handleClick}
                            menuButtonsDropDown={menuButtonsDropDownTable}
                            enable={enable}
                        />
                    </div>
                </>
            )}
        </DiffusionListWrapper>
    )
}
