import { routesCallRegister } from '../../navigation/routes'
import general from '../../assets/images/icons/chart-arcs.svg'
import activity from '../../assets/images/icons/activity.svg'
import productivity from '../../assets/images/icons/chart-line.svg'
import customer from '../../assets/images/icons/headset.svg'
import positiveArrow from '../../assets/images/graphs/ArrowRise.svg'
import negativeArrow from '../../assets/images/graphs/ArrowFall.svg'

import { IconChartArcs3, IconActivity, IconChartLine, IconHeadset } from '@tabler/icons-react'

export const menuCallRegister = [
    {
        text: 'General',
        path: routesCallRegister.general,
        icon: IconChartArcs3,
        restricted: 'general',
        id: 1,
        content: 'General',
    },
    {
        text: 'Actividad',
        path: routesCallRegister.activity,
        icon: IconActivity,
        restricted: 'activity',
        id: 2,
        content: 'Mira la actividad',
    },
    {
        text: 'Productividad',
        path: routesCallRegister.productivity,
        icon: IconChartLine,
        restricted: 'productivity',
        id: 3,
        content: 'Mira la productividad',
    },
    {
        text: 'Customer service',
        path: routesCallRegister.customer,
        icon: IconHeadset,
        restricted: 'costumer',
        id: 4,
        content: 'Mira el customer service',
    },
]

export const dataActivityGraphs = {
    topGraphs: [{ title: 'Llamadas entrantes' }, { title: 'Duración llamadas entrantes' }, { title: 'Llamadas salientes' }, { title: 'Duración llamadas salientes' }],
    lateralGraph: {
        title: 'Mapa Mundi',
    },
    bottomgraphs: {
        first: { title: 'Gráfico barras' },
        second: { title: "Gráfico 'Pie'" },
        third: { title: 'Tabla Info' },
        fourth: { title: "Gráfico 'Pie'" },
    },
    upArrow: positiveArrow,
    downArrow: negativeArrow,
}

export const dataProductivityGraphs = {
    lateralGraph: {
        barChart: {
            title: 'Razones llamadas no respondidas',
        },
        bottomgraphs: {
            left: {
                title: 'Promedio IVR',
                text: '25 segundos',
            },
            right: {
                title: 'Promedio tiempo espera',
                text: '3,5 minutos',
            },
        },
    },
    bottomGraph: {
        pieChart: {
            title: 'Índice de satisfacción',
        },
        status: {
            title: 'Estado de las llamadas',
        },
    },
}
