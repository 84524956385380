import styled from "styled-components";

export const ModuleScreenWrapper = styled.div`
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: center;
  .contain__modules {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    height: 430px;
    width: 80%;
    padding: 0 50px;
  }
`;
