import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formCreateConference } from '../../../data/dataApplications'
import useApplications from '../../../hooks/useApplications'
import { useHeadquarter } from '../../../hooks/useHeadquarter'
import { useSwal } from '../../../hooks/useSwal'
import { routesApplications, routesWide } from '../../../navigation/routes'

export const ConferenceFormScreen = ({ crudType }) => {
    const { applicationsForm, conferenceForm, getConferences, createConference, updateConference } = useApplications()

    const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter()
    const { typeSwal } = useSwal()

    const location = useLocation()

    const [loadingCounter, setLoadingCounter] = useState()
    const [dataSave, setDataSave] = useState(false)
    const [loading, setLoading] = useState(true)

    const data = formCreateConference

    useEffect(() => {
        getHeadquarter()
        applicationsForm({ action: 'clearForm' }, { form: 'conference' })

        if (crudType === 'edit') {
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.id,
                },
                {
                    form: 'conference',
                    element: 'id',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.name,
                },
                {
                    form: 'conference',
                    element: 'name',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.number,
                },
                {
                    form: 'conference',
                    element: 'number',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.pin,
                },
                {
                    form: 'conference',
                    element: 'pinConf',
                }
            )
            applicationsForm(
                {
                    action: 'toggle',
                    value: location.state.record,
                },
                {
                    form: 'conference',
                    element: 'record',
                }
            )
            applicationsForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'conference',
                    element: 'company',
                },
                {
                    label: location.state?.company,
                    value: String(location.state?.companyId),
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0) {
                data.steps.map((item) => {
                    item.input.map((item2) => {
                        if (item2.ref.element === 'company') {
                            arrayHeadquarterHook.forEach((item3) => {
                                if (item3.name === null) {
                                    item3.name = 'not defined'
                                }
                                const company = {
                                    label: item3.name,
                                    value: item3.id.toString(),
                                }
                                const found = item2.options.some((e) => e.value === company.value)
                                if (!found && company.value !== undefined) {
                                    item2.options.push(company)
                                }
                            })
                        }
                        return item2
                    })
                    return item
                })
                if (crudType === 'edit' && (conferenceForm.name === '' || conferenceForm.name !== location.state.name)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit') {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'name':
                                    item2.defaultValues = conferenceForm.name
                                    break
                                case 'number':
                                    item2.defaultValues = conferenceForm.number
                                    break
                                case 'pinConf':
                                    item2.defaultValues = conferenceForm.pin
                                    break
                                case 'record':
                                    item2.defaultValues = conferenceForm.record === 'Si' ? true : false
                                    break
                                case 'company':
                                    item2.options.filter((item3) => {
                                        if (item3.value === conferenceForm.company.value) {
                                            return (item2.defaultValues = item3)
                                        }
                                        return false
                                    })
                                    break
                                default:
                                    break
                            }
                            return item2
                        })
                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 100)
    })

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            typeSwal('createLoader', '', applicationsForm, getConferences, `/${routesWide.applications}/${routesApplications.conference}`, 'conferences', createConference)
        } else if (crudType === 'edit') {
            typeSwal('createLoader', '', applicationsForm, getConferences, `/${routesWide.applications}/${routesApplications.conference}`, 'conferences', updateConference)
        }
    }

    return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={applicationsForm} dataSave={dataSave} crudType={crudType} />}</>
}
