import { combineReducers } from 'redux'
import {
    EMListExtensionReducer,
    EMReducer,
    EMFormListExtensionsReducer,
    EMGetAlarmsReducer,
    EMFormAlertsReducer,
    EMAlertsCallAndRecordReducer,
    EMAlertsDeleteReducer,
    EMHistoryReducer,
    loadListHistoryReducer,
} from './EM/EMReducer'
import { loginReducer } from './login/loginReducer'
import {
    NBDeleteExtensionReducer,
    formExtensionsReducer,
    FormListNumberingReducer,
    numberingExtensionsReducer,
    numberingReducer,
    importFileExtensionReducer,
    createNumberingListReducer,
    importFileListNumberingReducer,
    getDdiLinkReducer,
} from './numbering/numberingReducer'
import { usersReducer, usersPermissionReducer, usersFormReducer } from './settingsReducers/userPosts/userReducer'
import {
    getPrivilegesReducer,
    settingMenusSubmenusReducer,
    getRolesReducer,
    getApiReducer,
    createApiKeyReducer,
    getDomainsReducer,
    getWhitelistReducer,
    domainsFormReducer,
    whitelistFormReducer,
    configFormReducer,
    getConfigReducer,
} from './settingsReducers/globalGets/settingsReducer'
import {
    applicationsLocutionsGetReducer,
    APPLocutionsDeleteReducer,
    locutionsObjectFormReducer,
    getConferencesReducer,
    conferenceFormReducer,
    getMailboxesReducer,
    mailboxesFormReducer,
    getSongWaitingReducer,
    getSongWaitingLinkReducer,
    songWaitingFormReducer,
    getShortMarkingReducer,
    shortMarkingFormReducer,
    getAudiosReducer,
    getIvrReducer,
    ivrFormReducer,
    getDestinationReducer,
    getQueueReducer,
    queueFormReducer,
} from './applications/applicationsReducer'
import { headquarterGetReducer, getCompanyLinkReducer, companyFormReducer } from './headquarter/headquarterReducer'
import {
    outboundRoutesGetTrunksReducer,
    outboundRoutesFormReducer,
    getCategoriesLinkReducer,
    getCategoriesGroupReducer,
    categoriesGroupFormReducer,
    getCategoriesReducer,
    categoriesFormReducer,
} from './outboundRoutes/outboundRoutesReducer'
import { departmentGetReducer, formDepartmentReducer } from './department/departmentReducer'
import { scheduleFormReducer, scheduleReducer, calendarReducer, calendarFormReducer } from './preferences/preferencesReducer'
import { view } from './view/viewReducer'

const rootReducer = combineReducers({
    login: loginReducer,
    // EM reducers
    arrayEM: EMReducer,
    EMListExtensionReducer,
    arrayEMFormListExtensions: EMFormListExtensionsReducer,
    arrayEMAlerts: EMGetAlarmsReducer,
    EMFormAlertsReducer,
    objectEMAlertsCallAndRecord: EMAlertsCallAndRecordReducer,
    objectEMAlertDelete: EMAlertsDeleteReducer,
    EMHistoryReducer,
    loadListHistoryReducer,
    // Numbering reducers
    arrayNumbering: numberingReducer,
    arrayNumberingExtensions: numberingExtensionsReducer,
    objectDeleteNBExtension: NBDeleteExtensionReducer,
    importFileListNumberingReducer,
    formExtensionsReducer,
    FormListNumberingReducer,
    importFileExtensionReducer,
    createNumberingListReducer,
    getDdiLinkReducer,
    // Users reducers
    usersReducer,
    arrayUsersPermission: usersPermissionReducer,
    usersFormReducer,
    // Settings reducers
    getPrivilegesReducer,
    settingMenusSubmenusReducer,
    getRolesReducer,
    getApiReducer,
    createApiKeyReducer,
    getDomainsReducer,
    getWhitelistReducer,
    domainsFormReducer,
    whitelistFormReducer,
    configFormReducer,
    getConfigReducer,
    // Applications
    getApplicationsLocutions: applicationsLocutionsGetReducer,
    objectCreateAPPFormLocutions: locutionsObjectFormReducer,
    objectDeleteAPPLocution: APPLocutionsDeleteReducer,
    getConferencesReducer,
    conferenceFormReducer,
    getMailboxesReducer,
    mailboxesFormReducer,
    getSongWaitingReducer,
    getSongWaitingLinkReducer,
    songWaitingFormReducer,
    getShortMarkingReducer,
    shortMarkingFormReducer,
    getAudiosReducer,
    getIvrReducer,
    ivrFormReducer,
    getDestinationReducer,
    getQueueReducer,
    queueFormReducer,
    // Headquarters reducers
    arrayHeadquarters: headquarterGetReducer,
    getCompanyLinkReducer,
    companyFormReducer,
    //outbound reducers
    arrayOutboundRoutesTrunks: outboundRoutesGetTrunksReducer,
    outboundRoutesFormReducer,
    getCategoriesLinkReducer,
    getCategoriesGroupReducer,
    categoriesGroupFormReducer,
    getCategoriesReducer,
    categoriesFormReducer,
    //departments reducers
    departmentGetReducer,
    formDepartmentReducer,
    // schedule reducers
    scheduleFormReducer,
    scheduleReducer,
    // calendar reducers
    calendarReducer,
    calendarFormReducer,
    //view reducers
    view,
})

export default rootReducer
