import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useStore } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import PermissionProvider from '../Permission/PermissionProvider'
import Restricted from '../Permission/PermissionConsumer'

import { MenuTopWrapper } from './MenuTop.style'

export const MenuTop = ({ menu, isRestricted = true }) => {
    const { pathname } = useLocation()

    const pathNameSeparate = pathname.split('/')[2]

    const { getState } = useStore()

    const permissions = getState()['arrayUsersPermission']

    const subtitlePermissionsUser = permissions.map((item) => item.subtitle)
    return (
        <PermissionProvider permissionsUser={subtitlePermissionsUser}>
            <MenuTopWrapper>
                <div className="container">
                    {menu.map((item, index) =>
                        isRestricted === true ? (
                            <Restricted key={index} to={item.restricted}>
                                <Link
                                    // className={
                                    //     item.path !== pathNameSeparate
                                    //         ? 'item-link'
                                    //         : 'link-active'
                                    // }
                                    to={item.path}
                                    replace
                                >
                                    <div id={item.id} className={item.path !== pathNameSeparate ? 'container__link ' : 'container__link-active'}>
                                        <div className={item.path !== pathNameSeparate ? 'item-link' : 'link-active'}>
                                            <item.icon height={13} width={13} strokeWidth={2} className={'menuTop__icon'} />
                                            {item.text}
                                        </div>
                                    </div>
                                </Link>
                            </Restricted>
                        ) : (
                            <Link
                                key={index}
                                // className={
                                //     item.path !== pathNameSeparate
                                //         ? 'item-link'
                                //         : 'link-active'
                                // }
                                to={item.path}
                                replace
                            >
                                <div id={item.id} className={item.path !== pathNameSeparate ? 'container__link item-link' : 'container__link-active link-active'}>
                                    <div className={item.path !== pathNameSeparate ? 'item-link' : 'link-active'}>
                                        <item.icon height={13} width={13} strokeWidth={2} className={'menuTop__icon'} />
                                        {item.text}
                                    </div>
                                </div>
                            </Link>
                        )
                    )}
                </div>
                {/* {menu.map((item) => (
                    <ReactTooltip
                        key={item.id}
                        className="tooltip"
                        anchorId={item.id}
                        place="bottom"
                        content={item.content}
                        delayShow={1000}
                    />
                ))} */}
            </MenuTopWrapper>
        </PermissionProvider>
    )
}

MenuTop.propType = {
    menuNumbering: PropTypes.array.isRequired,
}
