import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formCreateDomains } from '../../../data/dataSettings'
import useSettings from '../../../hooks/useSettings'
import { useSwal } from '../../../hooks/useSwal'
import { routesSettings, routesWide } from '../../../navigation/routes'

export const DomainsForm = ({ crudType }) => {
    const { settingForm, getDomains, createDomain, updateDomain, domainsForm } = useSettings()

    const { typeSwal } = useSwal()

    const location = useLocation()

    const [loadingCounter, setLoadingCounter] = useState(0)
    const [loading, setLoading] = useState(true)
    const [dataSave, setDataSave] = useState(false)

    const data = formCreateDomains

    useEffect(() => {
        settingForm(
            { action: 'clearForm' },
            {
                form: 'domains',
            }
        )
        if (crudType === 'edit') {
            settingForm(
                {
                    action: 'id',
                    value: location.state.id,
                },
                { form: 'domains', element: 'id' }
            )
            settingForm(
                {
                    action: 'input',
                    value: location.state.domain,
                },
                { form: 'domains', element: 'domain' }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (crudType === 'edit' && (location.state.domain === '' || location.state.domain !== domainsForm.domain)) {
                setLoadingCounter(loadingCounter + 1)
            } else if (crudType === 'edit') {
                data.steps.map((item) => {
                    item.input.map((item2) => {
                        switch (item2.ref.element) {
                            case 'domain':
                                item2.defaultValues = location.state.domain
                                break
                            default:
                                break
                        }
                        return item2
                    })
                    return item
                })
                setLoading(false)
            } else if (crudType === 'create') {
                setLoading(false)
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            typeSwal('createLoader', '', settingForm, getDomains, `/${routesWide.settings}/${routesSettings.domains}`, 'domains', createDomain)
        } else if (crudType === 'edit') {
            typeSwal('createLoader', '', settingForm, getDomains, `/${routesWide.settings}/${routesSettings.domains}`, 'domains', updateDomain)
        }
    }

    return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={settingForm} dataSave={dataSave} crudType={crudType} />}</>
}
