import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formCategories } from '../../../data/dataOutbound'
import { useOutbound } from '../../../hooks/useOutboundRoutes'
import { useSwal } from '../../../hooks/useSwal'
import { routesOutbound, routesWide } from '../../../navigation/routes'

export const CategoriesForm = ({ crudType }) => {
    const { outboundRoutesForm, createCategories, categoriesFormReducer, updateCategories, categoriesHook, arrayOutboundRoutesTrunksHook, getOutboundRoutesTrunks, getCategoriesHook } = useOutbound()
    const { typeSwal } = useSwal()

    const location = useLocation()

    const [loading, setLoading] = useState(true)
    const [loadingCounter, setLoadingCounter] = useState(0)
    const [dataSave, setDataSave] = useState(false)

    const data = formCategories

    useEffect(() => {
        getOutboundRoutesTrunks()
        getCategoriesHook()
        outboundRoutesForm({ action: 'clearForm' }, { form: 'categories' })
        if (crudType === 'edit') {
            outboundRoutesForm(
                {
                    action: 'input',
                    value: location.state.idCategories,
                },
                {
                    form: 'categories',
                    element: 'id',
                }
            )

            outboundRoutesForm(
                {
                    action: 'input',
                    value: location.state.name,
                },
                {
                    form: 'categories',
                    element: 'name',
                }
            )
            outboundRoutesForm(
                {
                    action: 'input',
                    value: location.state.prefix,
                },
                {
                    form: 'categories',
                    element: 'prefix',
                }
            )
            outboundRoutesForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'categories',
                    element: 'outboundRoutesId',
                },
                {
                    label: location.state.outboundRoute,

                    value: location.state.outboundRoutesId,
                }
            )
            outboundRoutesForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'categories',
                    element: 'failOverOutboundRoutesId',
                },
                {
                    label: location.state.outboundRouteBackup,
                    value: location.state.failOverOutboundRoutesId,
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (typeof categoriesFormReducer === 'object' && categoriesHook.length > 0 && arrayOutboundRoutesTrunksHook.length > 0) {
                data.steps.map((item) => {
                    item.input.map((item2) => {
                        if (item2.ref.element === 'outboundRoutesId') {
                            arrayOutboundRoutesTrunksHook.forEach((item3) => {
                                if (item3.description === null) {
                                    item3.description = 'not defined'
                                }
                                let outboundRoute = {
                                    label: item3.description,
                                    value: item3.id?.toString(),
                                }
                                const foundOption = item2.options.some((option) => option.value === outboundRoute.value)
                                if (!foundOption && outboundRoute.value !== undefined) {
                                    item2.options.push(outboundRoute)
                                }
                            })
                        }
                        if (item2.ref.element === 'failOverOutboundRoutesId') {
                            arrayOutboundRoutesTrunksHook.forEach((item3) => {
                                if (item3.description === null) {
                                    item3.description = 'not defined'
                                }
                                let outboundRoute = {
                                    label: item3.description,
                                    value: item3.id?.toString(),
                                }
                                const foundOption = item2.options.some((option) => option.value === outboundRoute.value)
                                if (!foundOption && outboundRoute.value !== undefined) {
                                    item2.options.push(outboundRoute)
                                }
                            })
                        }
                        return item2
                    })
                    return item
                })

                if (crudType === 'edit' && (categoriesFormReducer.name === '' || categoriesFormReducer.name !== location.state.name)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit') {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'name':
                                    item2.defaultValues = categoriesFormReducer.name
                                    break
                                case 'prefix':
                                    item2.defaultValues = categoriesFormReducer.prefix
                                    break
                                case 'outboundRoutesId':
                                    item2.options.filter((item3) => {
                                        if (item3.label === categoriesFormReducer.outboundRoutesId.label) {
                                            return (item2.defaultValues = item3)
                                        } else if (categoriesFormReducer.outboundRoutesId.label === 'Sin valor') {
                                            return (item2.defaultValues = {})
                                        }
                                        return false
                                    })
                                    break
                                case 'failOverOutboundRoutesId':
                                    item2.options.filter((item3) => {
                                        if (item3.label === categoriesFormReducer.failOverOutboundRoutesId.label) {
                                            return (item2.defaultValues = item3)
                                        } else if (categoriesFormReducer.failOverOutboundRoutesId.label === 'Sin valor') {
                                            return (item2.defaultValues = {})
                                        }
                                        return false
                                    })
                                    break
                                default:
                                    break
                            }
                            return item2
                        })
                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crudType, loadingCounter])

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            try {
                typeSwal('createLoader', '', outboundRoutesForm, getCategoriesHook, `/${routesWide.outboundRoutes}/${routesOutbound.categories}`, 'categories', createCategories)
            } catch (err) {
                return err
            }
        } else if (crudType === 'edit') {
            try {
                typeSwal('createLoader', '', outboundRoutesForm, getCategoriesHook, `/${routesWide.outboundRoutes}/${routesOutbound.categories}`, 'categories', updateCategories)
            } catch (err) {
                return err
            }
        }
    }
    return <>{loading === true ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={outboundRoutesForm} dataSave={dataSave} crudType={crudType} />}</>
}
