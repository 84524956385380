import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGird } from '../../../components/CardGrid/CardGird'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../components/TableInfo/TableInfo'
import { ToggleSwitch } from '../../../components/ToggleSwich/ToggleSwitch'
import { dataScreenTrunks, formTrunks } from '../../../data/dataOutbound'
import { useOutbound } from '../../../hooks/useOutboundRoutes'
import { useSwal } from '../../../hooks/useSwal'
import { colors } from '../../../theme/colors'
import { nameTables } from '../../../data/dataTable'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'
import { HideColumn } from '../../../components/HideColumn/HideColumn'
import useView from '../../../hooks/useView'

import { TrunksOutboundScreenWrapper } from './TrunksOutboundScreen.style'

export const TrunksOutboundScreen = () => {
    const { arrayOutboundRoutesTrunksHook, getOutboundRoutesTrunks, updateOutboundRoutesTrunks, deleteOutboundRoutesTrunks, outboundRoutesForm, uploadFileTrunks, loading } = useOutbound()
    const { typeSwal } = useSwal()
    const { changeView, isCardView } = useView()

    const [search, setSearch] = useState([])
    const [, setIsBottom] = useState(false)

    const { notFound, icon } = dataScreenTrunks

    const navigate = useNavigate()

    const dataTrunks = formTrunks.steps

    useEffect(() => {
        getOutboundRoutesTrunks()
        setSearch([])
    }, [getOutboundRoutesTrunks, setSearch])

    const updateRegserver = (data, isOn, setIsOn) => {
        typeSwal('modify').then(async (result) => {
            if (result.isConfirmed) {
                Object.keys(data).map((item) =>
                    dataTrunks.map((item2) =>
                        item2.input.forEach((item3) => {
                            if (item === item3.ref.element) {
                                return outboundRoutesForm(
                                    {
                                        action: item3.cardType,
                                        value: item3.ref.element === 'regServer' ? !isOn : data[item],
                                    },
                                    {
                                        form: item3.ref.form,
                                        element: item3.ref.element,
                                    },
                                    {
                                        label: data[item],
                                        value: String(data[item]),
                                    }
                                )
                            }
                        })
                    )
                )
                setIsOn(!isOn)
                updateOutboundRoutesTrunks()
                typeSwal('success')
            }
        })
    }

    const COLUMNS = [
        {
            Header: 'Nombre',
            accessor: 'name',
        },
        {
            Header: 'Descripción',
            accessor: 'description',
        },
        {
            Header: 'Contraseña',
            accessor: 'password',
            disableSortBy: true,
            Cell: ({ row }) => <HideColumn row={row} id={row.original.id} data={row.original.password} text={'Contraseña'} />,
        },
        {
            Header: 'Fromdomain',
            accessor: 'fromDomain',
        },
        {
            Header: 'Servidor',
            accessor: 'server',
        },
        {
            Header: 'Sede',
            accessor: 'company',
        },
        {
            Header: 'Usuario defecto',
            accessor: 'userDefault',
        },
        {
            Header: 'Outbound prefix',
            accessor: 'outboundPrefix',
        },
        {
            Header: 'Registrar',
            accessor: 'regServer',
            disableSortBy: true,
            Cell: ({ row }) => <ToggleSwitch isActive={row.values.regServer.toggle} data={row.original} toggleSwitch={updateRegserver} color={colors.primaryColor} />,
        },
    ]

    const DATA = arrayOutboundRoutesTrunksHook.map(({ kamDispatcherId, AstSipPeer, description, id, companyId, sipPeerId, Company }) => ({
        name: AstSipPeer?.name,
        description,
        password: AstSipPeer?.secret,
        id,
        companyId,
        sipPeerId,
        company: Company?.name,
        server: AstSipPeer?.host,
        userDefault: AstSipPeer?.defaultuser,
        outboundPrefix: AstSipPeer?.port,
        fromDomain: AstSipPeer?.fromdomain,
        regServer: { toggle: +AstSipPeer?.regserver, id: AstSipPeer?.id },
        allow: AstSipPeer?.allow,
        disallow: AstSipPeer?.disallow,
        Ipaddr: AstSipPeer?.ipaddr,
        port: AstSipPeer?.port,
        context: AstSipPeer?.context,
        permit: AstSipPeer?.permit,
        deny: AstSipPeer?.deny,
        transport: AstSipPeer?.transport,
        nat: AstSipPeer?.nat,
        dtmfmode: AstSipPeer?.dtmfmode,
        qualify: AstSipPeer?.qualify,
        kamDispatcherId,
    }))

    const dataExport = arrayOutboundRoutesTrunksHook.map(({ AstSipPeer, description, Company }) => ({
        Nombre: AstSipPeer?.name,
        Descripción: description,
        Compañia: Company?.name,
        Server: AstSipPeer?.host,
        UserDefault: AstSipPeer?.defaultuser,
        'Outbound Prefix': AstSipPeer?.port,
        Dominio: AstSipPeer?.fromdomain,
        'Reg Server': +AstSipPeer?.regserver,
    }))

    const dataCard = () => {
        if (search.length > 0) {
            return search.map(
                ({
                    name,
                    description,
                    password,
                    id,
                    companyId,
                    sipPeerId,
                    company,
                    server,
                    userDefault,
                    outboundPrefix,
                    fromDomain,
                    regServer,
                    allow,
                    disallow,
                    Ipaddr,
                    port,
                    context,
                    permit,
                    deny,
                    transport,
                    nat,
                    dtmfmode,
                    qualify,
                }) => ({
                    title: description,
                    icon: icon.code,
                    subtitle: name,
                    icon2: icon.home,
                    text: company,
                    icon3: icon.alarm,
                    text2: userDefault,
                    isActive: regServer?.toggle,
                    name,
                    description,
                    password,
                    id,
                    companyId,
                    sipPeerId,
                    company,
                    server,
                    userDefault,
                    outboundPrefix,
                    fromDomain,
                    regServer,
                    allow,
                    disallow,
                    Ipaddr,
                    port,
                    context,
                    permit,
                    deny,
                    transport,
                    nat,
                    dtmfmode,
                    qualify,
                })
            )
        } else {
            return DATA.map(({ AstSipPeer, description, id, companyId, sipPeerId, Company }) => ({
                title: description,
                icon: icon.code,
                subtitle: AstSipPeer?.name,
                icon2: icon.home,
                text: Company?.name,
                icon3: icon.alarm,
                text2: AstSipPeer?.defaultuser,
                isActive: AstSipPeer?.regserver,

                name: AstSipPeer?.name,
                description,
                password: AstSipPeer?.pbxpassword,
                id,
                companyId,
                sipPeerId,
                company: Company?.name,
                server: AstSipPeer?.host,
                userDefault: AstSipPeer?.defaultuser,
                outboundPrefix: AstSipPeer?.port,
                fromDomain: AstSipPeer?.fromdomain,
                regServer: {
                    toggle: +AstSipPeer?.regserver,
                    id: AstSipPeer?.id,
                },

                allow: AstSipPeer?.allow,
                disallow: AstSipPeer?.disallow,
                Ipaddr: AstSipPeer?.ipaddr,
                port: AstSipPeer?.port,
                context: AstSipPeer?.context,
                permit: AstSipPeer?.permit,
                deny: AstSipPeer?.deny,
                transport: AstSipPeer?.transport,
                nat: AstSipPeer?.nat,
                dtmfmode: AstSipPeer?.dtmfmode,
                qualify: AstSipPeer?.qualify,
            }))
        }
    }

    const dataToCards = dataCard()

    const handleOpenFormCreate = () => {
        navigate('/outboundRoutes/trunks/create', {})
    }

    const handleOpenFormEdit = (item) => {
        navigate('/outboundRoutes/trunks/update', {
            state: item.original ? item.original : item,
        })
    }

    // const functionAsync = async (response) => {
    //     let categoriesDelete = []
    //     response.data.map(async (item2) => {
    //         let idCategories = { idCategories: item2.id }
    //         await deleteCategories(idCategories).then((value) => {
    //             categoriesDelete.push(value.status)
    //         })
    //     })
    //     return categoriesDelete
    // }

    const handleDelete = async (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response
                    if (item.original) {
                        response = await deleteOutboundRoutesTrunks(item.original)
                    } else {
                        response = await deleteOutboundRoutesTrunks(item)
                    }
                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        typeSwal('delete')
                        getOutboundRoutesTrunks()
                    }
                    if (response.status === 203) {
                        let table = Object.keys(nameTables)
                            .filter((p) => p.includes(response.data.table))
                            .reduce((obj, key) => {
                                obj[key] = nameTables[key]
                                return obj[key]
                            }, {})
                        typeSwal('error', table)
                        getOutboundRoutesTrunks()
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        })
    }

    const handleUploadFile = async (file) => {
        try {
            let response = await uploadFileTrunks(file)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                getOutboundRoutesTrunks()
                typeSwal('upload').then(() => {
                    window.location.reload()
                })
            }
        } catch (err) {
            return err
        }
    }

    const tableInstance = useRef(null)

    const template = [
        {
            Nombre: '',
            Descripción: '',
            Contraseña: '',
            FromDomain: '',
            Servidor: '',
            'Sede Id': '',
            Usuario: '',
            'Outbound Prefijo': '',
            Registrar: '1 o 0',
            Ipaddr: '',
            Port: '',
            Context: '',
            Permit: '',
            Deny: '',
            // transport,
            Nat: '',
            // dtmfmode,
            Allow: '',
            Disallow: '',
            Qualify: '',
        },
    ]

    return (
        <TrunksOutboundScreenWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },

                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        file: {
                            position: 'right',
                            data: dataExport,
                            template,
                        },
                    }}
                    importFile={handleUploadFile}
                    setSearch={setSearch}
                />
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : arrayOutboundRoutesTrunksHook[0]?.status ? (
                <div className="contain__table">
                    <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
                </div>
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={handleOpenFormEdit} toggleSwitch={updateRegserver} setIsBottom={setIsBottom} />
                </div>
            ) : (
                <div className="contain__table">
                    <TableInfo
                        format={'list'}
                        COLUMNS={COLUMNS}
                        DATA={search.length === 0 ? DATA : search}
                        updateFunction={handleOpenFormEdit}
                        deleteFunction={handleDelete}
                        ref={tableInstance}
                        onClick={handleOpenFormEdit}
                        setIsBottom={setIsBottom}
                    />
                </div>
            )}
        </TrunksOutboundScreenWrapper>
    )
}
