import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { fontFamily, newFontSize, weight } from '../../theme/fonts'

export const FormScreenWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
    overflow-x: hidden;

    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.greyBorder};

    &::-webkit-scrollbar {
        width: 2px;
        background: white;
        height: 5px;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background: ${colors.primaryColor};
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
    }
    &::-webkit-scrollbar-track-piece {
        height: 80%;
    }

    .container__title {
        box-sizing: border-box;
        width: 100%;
        min-height: 80px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-bottom: 1px solid ${colors.greyBorder};

        .contain__title {
            margin-left: 10px;
            color: ${colors.black};
            font-size: ${newFontSize.h3};
            font-weight: ${weight.medium};
            letter-spacing: 0.25px;
        }
    }

    .container_form {
        box-sizing: border-box;
        width: 100%;
        max-width: ${(props) => (props.maxwidth === undefined ? '800px' : props.maxwidth)};
        margin-top: 20px;
        margin-bottom: 50px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        // All InputCard's Component
        .container__cards {
            width: 100%;
            display: grid;
            row-gap: 26px;

            .contain__inputCard-component {
                display: grid;
                justify-self: center;
                width: 100%;
            }
        }

        // NextStep Buttons and Save Button
        .contain__button-save {
            box-sizing: border-box;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            margin-top: 45px;
            button {
                width: 40%;
                height: 30px;

                border: none;
                color: ${colors.white};
                font-size: 11px;
                font-family: ${fontFamily.font};
                font-weight: ${weight.light};
                transition: all 0.5s;
                border-radius: 8px;
                font-weight: ${weight.bold};
                // margin-left: 40px;
            }

            .save_button {
                background-color: ${colors.primaryColor};

                &:hover {
                    cursor: pointer;
                    color: ${colors.grey};
                    background-color: ${colors.greyHover};
                    transition: all 0.5s;
                }
            }

            .save_edit_button {
                background-color: ${colors.primaryColor};

                &:hover {
                    cursor: pointer;
                    color: ${colors.grey};
                    background-color: ${colors.greyHover};
                    transition: all 0.5s;
                }
            }

            .next_step {
                background-color: ${colors.grey};

                &:hover {
                    cursor: pointer;
                    color: ${colors.grey};
                    background-color: ${colors.greyHover};
                    transition: all 0.5s;
                }
            }
        }
    }
`
