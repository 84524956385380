import { actionsTypesHeadquarter } from '../../actions/headquarter/actionTypes'

export const headquarterGetReducer = (headquarters = [], action) => {
    let newHeadquarters
    switch (action.type) {
        case actionsTypesHeadquarter.getHeadquarter:
            newHeadquarters = [...action.headquarters]
            break
        default:
            newHeadquarters = headquarters
    }
    return newHeadquarters
}

export const getCompanyLinkReducer = (companyLink = [], action) => {
    let newCompanyLink
    switch (action.type) {
        case actionsTypesHeadquarter.getCompanyLink:
            newCompanyLink = [...action.companyLink]
            break
        default:
            newCompanyLink = companyLink
            break
    }
    return newCompanyLink
}

export const companyFormReducer = (
    state = {
        id: null,
        name: '',
        departments: [],
        ip: '',
        port: '',
        ipBackup: '',
        portBackup: '',
    },
    action
) => {
    let arrayDepartments = { ...state }.departments
    switch (action.type) {
        case actionsTypesHeadquarter.changeCompanyFormName:
            state = {
                ...state,
                name: action.payload,
            }
            break
        case actionsTypesHeadquarter.changeCompanyFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionsTypesHeadquarter.addCompanyFormDepartment:
            arrayDepartments.push(action.payload)
            state = {
                ...state,
                departments: arrayDepartments,
            }
            break
        case actionsTypesHeadquarter.removeCompanyFormDepartment:
            const index = arrayDepartments
                .map((p) => p.value)
                .indexOf(action.payload.value)

            if (index > -1) {
                arrayDepartments.splice(index, 1)
            }
            state = {
                ...state,
                departments: arrayDepartments,
            }
            break
        case actionsTypesHeadquarter.clearMultiselectCompanyForm:
            state = {
                ...state,
                departments: [],
            }
            break

        case actionsTypesHeadquarter.changeCompanyFormIp:
            state = {
                ...state,
                ip: action.payload,
            }
            break
        case actionsTypesHeadquarter.changeCompanyFormPort:
            state = {
                ...state,
                port: action.payload,
            }
            break
        case actionsTypesHeadquarter.changeCompanyFormIpBackup:
            state = {
                ...state,
                ipBackup: action.payload,
            }
            break
        case actionsTypesHeadquarter.changeCompanyFormPortBackup:
            state = {
                ...state,
                portBackup: action.payload,
            }
            break

        case actionsTypesHeadquarter.clearCompanyForm:
            state = {
                id: null,
                name: '',
                departments: [],
                ip: '',
                port: '',
                ipBackup: '',
                portBackup: '',
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}
