import React from 'react'

import { CardWrapper } from './Card.style'

export const Card = ({
    title,
    subtitle,
    text,
    text2,
    icon,
    icon2,
    icon3,
    component,
    onClick,
    data,
}) => {
    return (
        <CardWrapper onClick={() => onClick(data)}>
            <div className="card">
                <h5
                    className="card__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div className="contain__iconSubtitle">
                    {icon && (
                        <img
                            className="card__subtitle-icon"
                            src={icon}
                            alt={subtitle}
                        />
                    )}
                    <p className="card__subtitle">{subtitle}</p>
                </div>
                <div className="contain__text">
                    {icon2 && (
                        <img
                            className="card__text-icon"
                            src={icon2}
                            alt={text}
                        />
                    )}
                    <p
                        className="card__text"
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </div>
                <div className="contain__component">
                    {icon && (
                        <img
                            className="card__component-icon"
                            src={icon3}
                            alt={text2}
                        />
                    )}
                    {text2 && <p className="card__text-component">{text2}</p>}
                    {component && (
                        <div className="card__component">{component}</div>
                    )}
                </div>
            </div>
        </CardWrapper>
    )
}

Card.propType = {}

Card.defaultProps = {}
