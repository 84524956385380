
import styled from "styled-components";

export const ActivityScreenWrapper = styled.div`

    height: 100%;
    overflow-y: scroll;

    .border {
        border: 1px solid #EEECEC;
        border-radius: 16px;
    }

    .centered {
        display: flex;
        flex-direction: column;
    }

    .chart_contain {
        width: 100%;
        height: 100%;
    }

    .pie_first {
        width: 80%;
        height: 70%;
    }

    .pie_second {
        width: 90%;
        height: 100%;
    }

    .selector {
        height: 65px;
        display: flex;
        align-items: center;
        padding-left: 40px;
    }

    h3 {
        font-weight: 500;
        font-size: 16px;
        padding-left: 10px;
    }

    .graph_grid {

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(20, 1fr);
        height: 1200px;
        padding-left: 40px;
        gap: 30px;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            
            border-radius: 16px;
            padding-left: 25px;

            h5 {
                font-weight: 400;
                font-size: 12px;
                margin: 0;
            }

            .stats {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                padding-right: 25px;

                .result {
                    display: flex;
                    gap: 5px;
                }

                .percentatge {
                    font-weight: 400;
                    font-size: 12px;
                }

                .positive {
                    color: #0E9A0C;
                }

                .negative {
                    color: #F23939;
                }
            }
        }
     
        .first {
            grid-row: 1 / 4;
            grid-column: 1;
            background-color: rgba(115, 87, 246, 0.08);
        }

        .second {
            grid-row: 1 / 4;
            grid-column: 2;
            background-color: rgba(132, 187, 240, 0.24);
        }

        .third {
            grid-row: 1 / 4;
            grid-column: 3;
            background-color: rgba(240, 189, 88, 0.27);
        }

        .fourth {
            grid-row: 1 / 4;
            grid-column: 4;
            background-color: rgba(151, 223, 216, 0.3);
        }

        .lateral {
            grid-row: 1 / 7;
            grid-column: 5;
            position: relative;
            height: 100%;
            width: 100%;

         
        }

        .fixed {
            position: fixed;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 13%;
            height: 350px;
        }

        .graph {
            grid-row: 4 / 11;
            grid-column: 1 / 5;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 30px 20px 0;

            font-size: 13px;
        }

        .bottom_one {
            grid-row: 11 / 16;
            grid-column: 1 / 3;
        }

        .bottom_two {
            grid-row: 11 / 16;
            grid-column: 3 / 5;
        }

        .bottom_three {
            grid-row: 16 / 21;
            grid-column: 1 / 4;

        }

        .bottom_fourth {
            grid-row: 16 / 21;
            grid-column: 4 / 5;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 10px;
        }

    }

`