import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import usePreferences from '../../../hooks/usePreferences'
import { useSwal } from '../../../hooks/useSwal'
import { formCalendar } from '../../../data/dataPreferences'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { FormScreen } from '../../../components/FormScreen/FormScreen'

export const CalendarFormScreen = ({ crudType }) => {
    const { loading, createCalendar, updateCalendar, preferencesForm, getCalendar } = usePreferences()
    const { typeSwal } = useSwal()

    const location = useLocation()

    useEffect(() => {
        preferencesForm({ action: 'clear', value: '' }, { form: 'calendar', element: '' })

        if (crudType === 'edit') {
            preferencesForm({ action: 'input', value: location.state.calendar[0].name }, { form: 'calendar', element: 'name' })
            preferencesForm({ action: 'input', value: location.state.calendarId }, { form: 'calendar', element: 'id' })
            preferencesForm({ action: 'input', value: location.state.calendar[0].color }, { form: 'calendar', element: 'color' })
            preferencesForm({ action: 'input', value: location.state.calendarDaysId }, { form: 'calendar', element: 'daysId' })
            preferencesForm({ action: 'input', value: location.state.calendar[0].days }, { form: 'calendar', element: 'days' })

            formCalendar.steps[0].input[0].defaultValues = location.state.calendar[0].name
            formCalendar.steps[0].input[1].defaultValues = location.state.calendar[0].color
            formCalendar.steps[1].input[0].defaultValues = [
                {
                    name: location.state.calendar[0].name,
                    color: location.state.calendar[0].color,
                    days: location.state.calendar[0].days,
                },
            ]
        }

        return () => preferencesForm({ action: 'clear', value: '' }, { form: 'calendar', element: '' })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async () => {
        if (crudType === 'create') typeSwal('createLoader', '', preferencesForm, getCalendar, '/preferences/calendar', 'calendar', createCalendar)
        else if (crudType === 'edit') typeSwal('updateLoader', '', preferencesForm, getCalendar, '/preferences/calendar', 'calendar', updateCalendar)
    }

    return <>{loading ? <LoaderSpinner /> : <FormScreen data={formCalendar} onSubmit={onSubmit} form={preferencesForm} crudType={crudType} />}</>
}
