import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const ToggleButtonWrapper = styled.div`
    height: 100%;
    width: 100%;

    &:hover {
        cursor: pointer;
    }

    .toggleButton {
        height: 100%;
        width: 100%;
        border: 1px solid ${colors.greyBorder};
        border-radius: 8px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        background-color: ${colors.greyBorder};
        padding: 2px;

        &__left,
        &__right {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            font-size: ${newFontSize.body};
            font-weight: ${weight.regular};
            color: ${(props) => (props.isActive && !props.twoButtonActive ? colors.primaryColor : colors.greyText)};
        }

        &__left {
            border-right: 1px solid ${colors.greyBorder};
            background-color: ${(props) => (props.isActive ? colors.greyBorder : colors.white)};
            border-radius: 6px;
            transition: all 0.5s;
            color: ${(props) => (!props.isActive && props.twoButtonActive ? colors.primaryColor : colors.greyText)};
        }

        &__right {
            background-color: ${(props) => (props.isActive ? colors.white : colors.greyBorder)};
            border-radius: 6px;
            transition: all 0.5s;
            color: ${(props) => (props.isActive && props.twoButtonActive ? colors.primaryColor : colors.greyText)};
        }
    }
`
