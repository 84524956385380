import styled from 'styled-components'
import { colors } from '../../../theme/colors'
import { fontFamily, newFontSize, weight } from '../../../theme/fonts'

export const MenuFilterWrapper = styled.div`
    height: 100%;
    width: 100%;
    border-left: 1px solid ${colors.greyBorder};
    border-bottom: 1px solid ${colors.greyBorder};

    .menuFilter {
        padding: 6%;
        display: grid;
        height: 100%;
        grid-template-rows: 1fr 18fr 1fr;

        .menuFilter__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .menuFilter__title {
            display: flex;
            align-items: center;
            img {
                height: 16px;
                width: 16px;
            }
            p {
                margin: 0;
                color: ${colors.black};
                font-weight: ${weight.medium};
                font-size: ${newFontSize.button};
                font-family: ${fontFamily.font};
            }
        }

        .menuFilter__close {
            display: flex;
            align-items: start;
            cursor: pointer;
            img {
                height: 18px;
                width: 18px;

                &:hover {
                    filter: invert(30%) sepia(41%) saturate(1358%) hue-rotate(240deg) brightness(97%) contrast(94%);
                }
            }
        }

        .menuFilter__content {
            .menuFilter__content__select {
                p {
                    color: ${colors.black};
                    font-weight: ${weight.medium};
                    font-size: ${newFontSize.button};
                    font-family: ${fontFamily.font};
                }

                .css-b62m3t-container {
                    width: 100%;

                    .css-1s2u09g-control {
                        border: 1px solid ${colors.greyBorder};
                        height: 30px;
                        min-height: 28px;
                        width: 100%;
                        display: flex;
                        align-content: center;
                        cursor: pointer;
                    }
                    .css-1okebmr-indicatorSeparator {
                        width: 0px;
                    }

                    .css-26l3qy-menu {
                        z-index: 6;
                    }

                    .css-1pahdxg-control {
                        display: flex;
                        align-content: center;
                        height: 34px;
                        width: 100%;
                        border-color: ${colors.primaryColor};
                        border: 1px solid ${colors.primaryColor};
                        box-shadow: 0 0 1px ${colors.primaryColor};
                        &:hover {
                            border-color: ${colors.secondaryColor};
                        }
                    }
                }
                .css-tj5bde-Svg {
                    color: ${colors.primaryColor};
                }
                .css-1n7v3ny-option {
                    background-color: ${colors.secondaryColor};
                }

                .css-g1d714-ValueContainer {
                    display: flex;
                    flex-wrap: nowrap;
                }

                .css-9gakcf-option {
                    background-color: ${colors.primaryColor};
                }
            }
        }

        .menuFilter__button {
            height: 30px;
        }
    }
`
