import styled from 'styled-components'

export const CalenderScreenWrapper = styled.div`

    height: 100%;
    
    .distributionTable {
        height: calc(100% - 55px);
        overflow-y: hidden;
        padding-left: 23px;
        padding-right: 23px;
    }
    
`
