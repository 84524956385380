import React from "react";
import { Header } from "../../components/Header/Header";
import { MenuTop } from "../../components/MenuTop/MenuTop";
import { menuNavLeft } from "../../data/dataNavMenuLeft";
import { menuOutbound } from "../../data/dataOutbound";
import { OutboundNavigation } from "../../navigation/OutboundNavigation/OutboundNavigation";
import { OutboundScreenWrapper } from "./OutboundScreen.style";

export const OutboundScreen = () => {
  return (
    <OutboundScreenWrapper>
      <Header
        image={menuNavLeft.menuTop[4].image}
        text={menuNavLeft.menuTop[4].text}
      />
      <MenuTop menu={menuOutbound} />
      <OutboundNavigation />
    </OutboundScreenWrapper>
  );
};
