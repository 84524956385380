import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const ButtonPasswordWrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5px;

    width: fit-content;
    border-radius: 10px;
    padding: 5px 5px;
    border: 1px solid ${colors.greyLine};
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: ${colors.secondaryColor};
        border: 1px solid ${colors.primaryColor};
        transition: all 0.3s ease-in-out;
    }

    .text__password {
        font-weight: ${weight.regular};
        font-size: ${newFontSize.label};
        color: ${colors.black};
        line-height: 1.1;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;

        p {
            margin: 0;
        }
    }
`
