import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

const styleVertical = {
    top: '50%',
    right: '-20%',
    transform: 'translate(0, -50%)',
    lineHeight: '34px',
}

const PieCharts = ({ data, pieProps, wrapperStyle }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie data={data} dataKey="num" nameKey="name" innerRadius={pieProps.innerRadius} outerRadius="100%" cornerRadius={5}>
                    {data.map((entry, index) => (
                        <Cell key={index} fill={entry.color} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend iconType={'circle'} iconSize={8} wrapperStyle={wrapperStyle} layout={pieProps.layout} verticalAlign={pieProps.verticalAlign} align={pieProps.align} width={pieProps.width} />
            </PieChart>
        </ResponsiveContainer>
    )
}

export default PieCharts
