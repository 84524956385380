import React from 'react'
import { Header } from '../../components/Header/Header'
import { MenuTop } from '../../components/MenuTop/MenuTop'
import { menuApplications } from '../../data/dataApplications'
import { menuNavLeft } from '../../data/dataNavMenuLeft'
import { ApplicationsNavigation } from '../../navigation/ApplicationsNavigation/ApplicationsNavigation'
import { ApplicationsWrapper } from './ApplicationsScreen.style'

export const ApplicationsScreen = () => {
    return (
        <ApplicationsWrapper>
            <Header text={menuNavLeft.menuTop[2].text} />
            <MenuTop menu={menuApplications} />
            <ApplicationsNavigation />
        </ApplicationsWrapper>
    )
}
