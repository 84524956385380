import { actionsTypesSettings } from '../../../actions/settingsScreen/settingsActions/actionsTypes'

export const getPrivilegesReducer = (privileges = [], action) => {
    let newArraySettings
    switch (action.type) {
        case actionsTypesSettings.getPrivileges:
            newArraySettings = [...action.privileges]
            break
        default:
            newArraySettings = privileges
    }

    return newArraySettings
}

//don't us yet and get menus and submenus
export const settingMenusSubmenusReducer = (arrayMenusSubmenus = [], action) => {
    let newArrayMenusSubmenus

    switch (action.type) {
        // action for looking menus and submenus
        case actionsTypesSettings.getMenuSubmenu:
            newArrayMenusSubmenus = [...action.arrayMenuSubmenu]
            break
        default:
            newArrayMenusSubmenus = arrayMenusSubmenus
    }
    return newArrayMenusSubmenus
}

export const getRolesReducer = (roles = [], action) => {
    let newRoles
    switch (action.type) {
        case actionsTypesSettings.getRoles:
            newRoles = [...action.roles]
            break

        default:
            newRoles = roles
            break
    }
    return newRoles
}

export const getApiReducer = (apiKeys = [], action) => {
    let newApiKeys
    switch (action.type) {
        case actionsTypesSettings.getApi:
            newApiKeys = [...action.apiKeys]
            break
        default:
            newApiKeys = apiKeys
    }
    return newApiKeys
}

export const createApiKeyReducer = (state = { apiKey: '', name: '' }, action) => {
    switch (action.type) {
        case actionsTypesSettings.changeApiForm:
            state = {
                ...state,
                apiKey: action.apiKey,
            }
            break
        case actionsTypesSettings.changeNameApiForm:
            state = {
                ...state,
                name: action.name,
            }
            break
        case actionsTypesSettings.clearApiForm:
            state = {
                apiKey: '',
                name: '',
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const getDomainsReducer = (domains = [], action) => {
    let newDomains
    switch (action.type) {
        case actionsTypesSettings.getDomains:
            newDomains = [...action.domains]
            break
        default:
            newDomains = domains
    }
    return newDomains
}

export const domainsFormReducer = (state = { domain: '', id: '' }, action) => {
    switch (action.type) {
        case actionsTypesSettings.changeDomainsFormDomain:
            state = {
                ...state,
                domain: action.domain,
            }
            break
        case actionsTypesSettings.changeDomainsFormId:
            state = {
                ...state,
                id: action.id,
            }
            break
        case actionsTypesSettings.clearFormDomains:
            state = {
                domain: '',
                id: '',
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const getWhitelistReducer = (whitelist = [], action) => {
    let newWhitelist
    switch (action.type) {
        case actionsTypesSettings.getWhitelist:
            newWhitelist = [...action.whitelist]
            break
        default:
            newWhitelist = whitelist
    }
    return newWhitelist
}

export const whitelistFormReducer = (state = { ipAddr: '', id: '' }, action) => {
    switch (action.type) {
        case actionsTypesSettings.changeWhitelistFormIpAddr:
            state = {
                ...state,
                ipAddr: action.ipAddr,
            }
            break
        case actionsTypesSettings.changeWhitelistFormId:
            state = {
                ...state,
                id: action.id,
            }
            break
        case actionsTypesSettings.clearFormWhitelist:
            state = {
                ipAddr: '',
                id: '',
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const getConfigReducer = (config = {}, action) => {
    let newConfig
    switch (action.type) {
        case actionsTypesSettings.getConfig:
            newConfig = { ...action.config }
            break
        default:
            newConfig = config
    }
    return newConfig
}

export const configFormReducer = (
    state = {
        ipLocal: '',
        domain: '',
        portWss: '',
        pathWss: '',
        password: '',
        azure_subscripcion_key: '',
        ruta_indicacion_de_sonido: '',
        numero_maximo_de_extensiones: '',
        digito_de_marcacion: '',
        administrador_secreto: '',
        usurario_administrador: '',
        puerto_administrador: '',
        ip_administrador: '',
        extension_start: '',
        extension_end: '',
        group_start: '',
        group_end: '',
        mailBoxes_start: '',
        mailBoxes_end: '',
        conference_start: '',
        conference_end: '',
        shortMarking_start: '',
        shortMarking_end: '',
        cola_start: '',
        cola_end: '',
        opt: '',
        timeout: '',
        timeout_cf: '',
        out_timeout: '',
        out_options: '',
        confrecpath: '',
        vmgreetpath: '',
        callrecpath: '',
        mydynamic_fearures: '',
        df_ringtimeout: '',
        formato_default_vm: '',
        bxfer_count: '',
        seltimeout: '',
        prefix_mov_corp: '',
        pri_mov: '',
        numcorta_origin: '',
        codelen: '',
        opc_cola: '',
        max_inbound_chan: '',
        maquina: '',
        preferences: [],
        external_api_url: '',
        callback_audio_id: '',
        capturar_llamada: '',
        transferencia_ciega: '',
        colgar_llamada: '',
        tranferencia_atendida: '',
    },
    action
) => {
    let arrayPreferences = { ...state }.preferences

    switch (action.type) {
        case actionsTypesSettings.changeConfigFormIpLocal:
            state = {
                ...state,
                ipLocal: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormDomain:
            state = {
                ...state,
                domain: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormPortWss:
            state = {
                ...state,
                portWss: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormPathWss:
            state = {
                ...state,
                pathWss: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormPassword:
            state = {
                ...state,
                password: action.payload,
            }

            break
        case actionsTypesSettings.changeConfigFormAzureSubscripcionKey:
            state = {
                ...state,
                azure_subscripcion_key: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormRutaIndicacionDeSonido:
            state = {
                ...state,
                ruta_indicacion_de_sonido: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormNumeroMaximoDeExtensiones:
            state = {
                ...state,
                numero_maximo_de_extensiones: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormDigitoDeMarcacion:
            state = {
                ...state,
                digito_de_marcacion: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormAdministradorSecreto:
            state = {
                ...state,
                administrador_secreto: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormUsuarioAdministrador:
            state = {
                ...state,
                usurario_administrador: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormPuertoAdministrador:
            state = {
                ...state,
                puerto_administrador: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormIpAdministrador:
            state = {
                ...state,
                ip_administrador: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormExtensionStart:
            state = {
                ...state,
                extension_star: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormExtensionEnd:
            state = {
                ...state,
                extension_end: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormGroupStart:
            state = {
                ...state,
                group_start: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormGroupEnd:
            state = {
                ...state,
                group_end: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormMailBoxesStart:
            state = {
                ...state,
                mailBoxes_start: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormMailBoxesEnd:
            state = {
                ...state,
                mailBoxes_end: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormConferenceStart:
            state = {
                ...state,
                conference_start: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormConferenceEnd:
            state = {
                ...state,
                conference_end: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormShortMarkingStart:
            state = {
                ...state,
                shortMarking_start: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormShortMarkingEnd:
            state = {
                ...state,
                shortMarking_end: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormColaStart:
            state = {
                ...state,
                cola_start: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormColaEnd:
            state = {
                ...state,
                cola_end: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormOpt:
            state = {
                ...state,
                opt: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormTimeout:
            state = {
                ...state,
                timeout: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormTimeoutCf:
            state = {
                ...state,
                timeout_cf: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormOutTimeout:
            state = {
                ...state,
                out_timeout: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormOutOptions:
            state = {
                ...state,
                out_options: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormConfrecpath:
            state = {
                ...state,
                confrecpath: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormVmgreetpath:
            state = {
                ...state,
                vmgreetpath: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormCallrecpath:
            state = {
                ...state,
                callrecpath: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormMydynamicFearures:
            state = {
                ...state,
                mydynamic_fearures: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormDfRingtimeout:
            state = {
                ...state,
                df_ringtimeout: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormFormatoDefaultVm:
            state = {
                ...state,
                formato_default_vm: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormBxferCount:
            state = {
                ...state,
                bxfer_count: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormSeltimeout:
            state = {
                ...state,
                seltimeout: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormPrefixMovCorp:
            state = {
                ...state,
                prefix_mov_corp: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormPriMov:
            state = {
                ...state,
                pri_mov: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormNumcortaOrigin:
            state = {
                ...state,
                numcorta_origin: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormCodelen:
            state = {
                ...state,
                codelen: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormOpcCola:
            state = {
                ...state,
                opc_cola: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormMaxInboundChan:
            state = {
                ...state,
                max_inbound_chan: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormMaquina:
            const maquina = action.payload ? 'Monomáquina' : 'Multimáquina'
            state = {
                ...state,
                maquina,
            }
            break
        case actionsTypesSettings.addConfigFormPreferences:
            let index = arrayPreferences.map((p) => p?.value).indexOf(action.payload?.value)

            if (index > -1) {
                arrayPreferences.splice(index, 1)
            } else {
                arrayPreferences.push(action.payload)
            }
            state = {
                ...state,
                preferences: arrayPreferences,
            }
            break
        case actionsTypesSettings.changeConfigFormSubNotifyApi:
            state = {
                ...state,
                external_api_url: action.payload,
            }
            break

        case actionsTypesSettings.addConfigFormCallbackAudioId:
            state = {
                ...state,
                callback_audio_id: action.payload.value.toString(),
            }
            break
        case actionsTypesSettings.changeConfigFormCapturarLlamada:
            state = {
                ...state,
                capturar_llamada: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormTransferenciaCiega:
            state = {
                ...state,
                transferencia_ciega: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormColgarLlamada:
            state = {
                ...state,
                colgar_llamada: action.payload,
            }
            break
        case actionsTypesSettings.changeConfigFormTransferenciaAtendida:
            state = {
                ...state,
                tranferencia_atendida: action.payload,
            }
            break

        default:
            state = { ...state }
    }
    return state
}
