import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const MyImportFileWrapper = styled.div`
    height: 100%;
    width: 100%;

    .fileDrop {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        color: ${colors.grey};
        margin: 0;
        font-style: normal;
        font-weight: ${weight.regular};
        font-size: ${newFontSize.label};
        line-height: 16px;
        letter-spacing: 0.25px;
        width: 100%;
        text-align: center;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
        }
    }
`
