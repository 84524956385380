import React from "react";
import { Route, Routes } from "react-router-dom";
import { CategoriesForm } from "../../screens/OutboundScreen/CategoriesOutboundScreen/CategoriesForm";
import { CategoriesOutboundScreen } from "../../screens/OutboundScreen/CategoriesOutboundScreen/CategoriesOutboundScreen";
import { GroupCategoriesForm } from "../../screens/OutboundScreen/GroupCategories/GroupCategoriesForm";
import { GroupCategoriesScreen } from "../../screens/OutboundScreen/GroupCategories/GroupCategoriesScreen";
import { OverviewOutboundScreen } from "../../screens/OutboundScreen/OverviewOutboundScreen/OverviewOutboundScreen";
import { TrunksOutboundScreen } from "../../screens/OutboundScreen/TrunksOutboundScreen/TrunksOutboundScreen";
import { TrunksOutboundScreenForm } from "../../screens/OutboundScreen/TrunksOutboundScreen/TrunksOutboundScreenForm";
import { routesOutbound } from "../routes";
import { OutboundNavigationWrapper } from "./OutboundNavigation.style";

export const OutboundNavigation = () => {
    return (
        <OutboundNavigationWrapper>
            <Routes>
                <Route 
                    path="/" 
                    element={<OverviewOutboundScreen />} 
                />
                <Route
                    path={routesOutbound.categories}
                    element={<CategoriesOutboundScreen />}
                />
                <Route
                    path={routesOutbound.createCategory}
                    element={<CategoriesForm crudType="create" />}
                />
                <Route
                    path={routesOutbound.updateCategory}
                    element={<CategoriesForm crudType="edit" />}
                />

                <Route
                    path={routesOutbound.groupCategories}
                    element={<GroupCategoriesScreen />}
                />
                <Route
                    path={routesOutbound.createGroupCategory}
                    element={<GroupCategoriesForm crudType={"create"} />}
                />
                <Route
                    path={routesOutbound.updateGroupCategory}
                    element={<GroupCategoriesForm crudType={"edit"} />}
                />
                <Route
                    path={routesOutbound.trunks}
                    element={<TrunksOutboundScreen />}
                />
                <Route
                    path={routesOutbound.createTrunks}
                    element={<TrunksOutboundScreenForm crudType={"create"} />}
                />
                <Route
                    path={routesOutbound.updateTrunks}
                    element={<TrunksOutboundScreenForm crudType={"edit"} />}
                />
            </Routes>
        </OutboundNavigationWrapper>
    );
};
