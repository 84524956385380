import styled from 'styled-components'

export const HistoryWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;

    .contain__cardGrid {
        height: calc(100% - 55px);
        box-sizing: border-box;
        padding: 20px 36px 0;
        transition: all 1s ease-out;
    }

    .contain__notFound {
        height: calc(100% - 55px);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contain__table-statistics {
        height: calc(100% - 55px);
        box-sizing: border-box;
        display: grid;
        grid-template-columns: calc(100% - 400px) 400px;
        padding: 20px;
    }

    .contain__statistics {
        position: relative;
        overflow-x: hidden;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        div::-webkit-scrollbar {
            display: none;
        }
    }

    .stadistics_charts {
        height: calc(100% - 20px);

        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        padding-right: 36px;

        .graph {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 30px 20px 0;

            height: 180px;

            font-size: 13px;
        }

        .border {
            border: 1px solid #eeecec;
            border-radius: 16px;
        }
    }
`
