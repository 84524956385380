import React from 'react'
import logo from '../../assets/images/logo/zerovoz-mini.svg'
import { FooterWrapper } from './Footer.style'

export const Footer = () => {
    return (
        <FooterWrapper>
            <p>v1.4.3 TPartner Network Services</p>
            <img src={logo} alt="logo" />
        </FooterWrapper>
    )
}
