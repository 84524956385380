import React from 'react'
import { iconButton } from '../../data/dataButtons'
import { IconFilter } from '@tabler/icons-react'

import { FilterWrapper } from './Filter.style'

export const Filter = ({ setIsFilter, isFilter, refContainer, id }) => {
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (
    //             refContainer.current &&
    //             !refContainer.current.contains(event.target)
    //         ) {
    //             setIsFilter(false)
    //         }
    //     }

    //     document.addEventListener('click', handleClickOutside)
    //     return () => document.removeEventListener('click', handleClickOutside)
    // }, [])

    return (
        <FilterWrapper ref={refContainer} id={id}>
            <div
                className={isFilter.active ? 'filter__active' : 'filter'}
                onClick={() =>
                    setIsFilter({
                        ...isFilter,
                        open: !isFilter.open,
                        active: isFilter.active,
                    })
                }
            >
                <IconFilter height={15} className="filter__icon" />

                <p>{iconButton.filter.text}</p>
            </div>
        </FilterWrapper>
    )
}
