import { Tooltip as ReactTooltip } from 'react-tooltip'

import { useSwal } from '../../hooks/useSwal'
import useNumbering from '../../hooks/useNumbering'
import { iconButton } from '../../data/dataButtons'

import { ButtonPassword } from '../ButtonPassword/ButtonPassword'

import { PasswordWatcherWrapper } from './PasswordWatcher.style'

const PasswordWatcher = ({ row, showPassword, text }) => {
    const { getSipPassword } = useNumbering()
    const { typeSwal } = useSwal()

    const { copy } = iconButton

    const handlePassword = async () => {
        const sipPassword = await getSipPassword(row.original)

        if (sipPassword.pbxpassword) {
            typeSwal('password', sipPassword.pbxpassword)
        } else {
            typeSwal('password', sipPassword.password)
        }
    }

    const handleCopy = async () => {
        const sipPassword = await getSipPassword(row.original)

        if (sipPassword.pbxpassword) {
            navigator.clipboard.writeText(sipPassword.pbxpassword)
        } else {
            navigator.clipboard.writeText(sipPassword.password)
        }
    }

    return (
        <PasswordWatcherWrapper>
            {showPassword ? (
                <p>{row.original.password}</p>
            ) : (
                <>
                    <ButtonPassword onClick={handlePassword} text={text} />
                    <div className="passwordWatcher__contain" onClick={handleCopy}>
                        <img className="passwordWatcher__copy" src={copy.icon} alt={copy.alt} />
                        <div className="passwordWatcher__tooltip" id={row.original.id} data-tooltip-delay-hide={1000} />
                    </div>
                    <ReactTooltip className="tooltip" anchorId={row.original.id} place="top" content={'¡Copiado!'} events={['click']} />
                </>
            )}
        </PasswordWatcherWrapper>
    )
}

export default PasswordWatcher
