import styled from 'styled-components'

export const GroupCategoriesWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    .distributionTable {
        height: calc(100% - 55px);
        overflow-y: hidden;
        padding-left: 23px;
        padding-right: 23px;
    }
`
