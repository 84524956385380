import styled from 'styled-components'

export const SongWaitingWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    position: relative;

    .distributionTable {
        height: calc(100% - 55px);
        overflow-y: hidden;
        padding-left: 23px;
        padding-right: 23px;
    }
`
