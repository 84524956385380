import styled from 'styled-components'
import { colors } from '../../../theme/colors'
import { newFontSize } from '../../../theme/fonts'

export const NavCardSliderWrapper = styled.div`
    height: 100%;
    width: 100%;
    margin-top: 20px;

    .navCardSlider {
        heigh: 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__left, &__right {
          width: 100%;
          display flex;
          gap: 15px;
          align-items: center;
        }

        &__left {
          &__button {
            height: 100%;

            
          }
          &__select {
            width: 100%;
            position: relative;

            &__label {
              position: absolute;
              top: -12px;
              color: ${colors.grey};
              font-size: ${newFontSize.span};
            }
          }
        }

        &__right {
          justify-content: flex-end;

          &__icon {
            border: 1px solid ${colors.greyLine};
            height: 28px;
            width: 38px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: ${colors.greyHover};
              cursor: pointer;
            }
          }

          &__select {
            width: 140px;
            position: relative;

            
          }
        }

      
    }
`
