import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const BoxWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
        height: 80%;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${(props) => props.backgroundColor};
        border-radius: 10px;
        border: 1px solid ${colors.greyLine};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transition: all 0.3s;

        &__title {
            color: ${colors.black};
            font-weight: ${weight.regular};
            font-size: ${newFontSize.label};
        }
    }
`
