import styled from 'styled-components'

export const BoxStatisticWrapper = styled.div`
    height: 100%;
    width: 100%;
    .boxStatistic_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        border-radius: 16px;
        background-color: ${(props) => props.backgroundColor};
        padding-left: 25px;
        height: 100%;
        width: 100%;

        h5 {
            font-weight: 400;
            font-size: 12px;
            margin: 0;
        }

        .stats {
            font-weight: 500;
            font-size: 16px;
            padding-right: 25px;
        }
    }
`
