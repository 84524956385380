import { useCallback, useState } from 'react'

import { useDispatch, useSelector, useStore } from 'react-redux'
import {
    createDepartmentThunk,
    deleteDepartmentThunk,
    getDepartmentsThunk,
    updateDepartmentThunk,
    uploadDepartmentThunk,
} from '../redux/thunks/departmentThunk'

export const useDepartments = () => {
    const dispatch = useDispatch()
    const { getState } = useStore()
    const [loading, setLoading] = useState(true)

    const departmentsHook = useSelector(
        (departments) => departments.departmentGetReducer
    )

    const departmentForm = useSelector((item) => item.formDepartmentReducer)
    const loadListDepartments = useSelector(
        (item) => item.loadListDepartmentReducer
    )

    const getDepartments = useCallback(() => {
        dispatch(getDepartmentsThunk(setLoading))
    }, [dispatch])

    const createDepartment = useCallback(() => {
        const department = getState()['formDepartmentReducer']
        let response = dispatch(createDepartmentThunk(department, setLoading))
        return response
    }, [getState, dispatch])

    const updateDepartment = useCallback(() => {
        const department = getState()['formDepartmentReducer']
        let response = dispatch(updateDepartmentThunk(department, setLoading))
        return response
    }, [dispatch, getState])

    const deleteDepartment = useCallback(
        (department) => {
            let response = dispatch(
                deleteDepartmentThunk(department, setLoading)
            )
            return response
        },
        [dispatch]
    )

    const uploadDepartment = useCallback(
        (file) => {
            let response = dispatch(uploadDepartmentThunk(file, setLoading))
            return response
        },
        [dispatch]
    )

    return {
        departmentsHook,
        getDepartments,
        createDepartment,
        updateDepartment,
        deleteDepartment,
        departmentForm,
        uploadDepartment,
        loadListDepartments,
        loading,
    }
}
