import styled from 'styled-components'

export const PermissionsScreenWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;

    .contain__cardGrid {
        height: calc(100% - 55px);
        box-sizing: border-box;
        padding: 20px 36px 0;
    }
`
