import styled from 'styled-components'
import { newFontSize, weight } from '../../theme/fonts'
import { colors } from '../../theme/colors'

export const TitleInputPairWrapper = styled.div`
    margin-top: 10px;
    .titleInputPair {
        &__title {
            font-size: ${newFontSize.body};
            color: ${colors.black};
            font-weight: ${weight.regular};
        }

        &__inputs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }
    }
`
