import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const CardDropDownWrapper = styled.div`
    height: auto;
    width: 395px;

    border: 1px solid ${colors.greyLine};
    box-shadow: 26px 32px 17px rgba(73, 80, 87, 0.01), 15px 18px 14px rgba(73, 80, 87, 0.04), 7px 8px 10px rgba(73, 80, 87, 0.06), 2px 2px 6px rgba(73, 80, 87, 0.07),
        2px 2px 6px rgba(73, 80, 87, 0.07), 0px 0px 0px rgba(73, 80, 87, 0.07);
`

export const CardWrapper = styled.div`
    height: 100%;
    cursor: pointer;
    .cardDropDown {
        height: 100%;
        &__head {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            height: 100%;
            border-bottom: 1px solid ${colors.greyLine};
        }

        &__title {
            display: grid;
            align-items: center;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-template-columns: repeat(5, 1fr);

            img {
                height: 16px;
                justify-self: center;
                grid-column-start: 1;
                grid-column-end: 2;
            }

            p {
                font-weight: ${weight.medium};
                font-size: ${newFontSize.button};
                color: ${colors.black};
                grid-column-start: 2;
                grid-column-end: 5;
                margin: 0;
            }
        }

        &__eye {
            text-align: end;
            align-self: start;
            grid-row-start: 3;
            grid-row-end: 4;
            margin: 0 20px 0 0;
        }

        &__eyeOff {
            text-align: end;
            margin-right: 20px;
            z-index: 99;
        }
    }
`

export const CardOptionsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: auto;

    .cardDropDown__option {
        margin-top: 10px;
        grid-column-start: 2;
        grid-column-end: 5;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-self: start;
        align-self: center;
        width: 100%;
        z-index: 10;

        .cardDropDown__body {
            grid-column-start: 1;
            grid-column-end: 7;
            p {
                font-weight: ${weight.medium};
                font-size: ${newFontSize.button};
                color: ${colors.black};
                margin: 0;
            }
        }

        &__toggle {
            grid-column-start: 7;
            grid-column-end: 8;
        }
    }
`
