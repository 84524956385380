import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formCreateDiffusionList } from '../../../data/dataEM'
import useEM from '../../../hooks/useEM'
import useNumbering from '../../../hooks/useNumbering'
import { useSwal } from '../../../hooks/useSwal'
import { routesEM, routesWide } from '../../../navigation/routes'

export const DiffusionListForm = ({ crudType }) => {
    const { createEMListExtension, updateEMListExtension, EMform, EMFormListExtensions, getEMListExtension, arrayEM } = useEM()
    const { arrayNumberingExtensionsHook, getExtensions } = useNumbering()
    const { typeSwal } = useSwal()

    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [loadingCounter, setLoadingCounter] = useState(0)
    const [dataSave, setDataSave] = useState(false)

    const data = formCreateDiffusionList

    useEffect(() => {
        getExtensions()
        EMform({ action: 'clearForm' }, { form: 'diffusionList' })

        if (crudType === 'edit') {
            let actionId = {
                action: 'id',
                value: location.state.id,
            }

            EMform(actionId, { form: 'diffusionList', element: 'id' })
            let actionName = { action: 'input', value: location.state.title }
            EMform(actionName, { form: 'diffusionList', element: 'name' })

            location.state.arrayExtensions.forEach((item) => {
                EMform(
                    {
                        action: 'select-option',
                        option: {
                            label: item.Extension.extension,
                            value: String(item.Extension.id),
                        },
                    },
                    {
                        form: 'diffusionList',
                        element: 'listExtensions',
                    }
                )
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (typeof arrayNumberingExtensionsHook === 'object' && arrayNumberingExtensionsHook.length > 0) {
                data.steps.map((item) => {
                    if (item.input[0].cardType === 'multiselect' || item.input[0].cardType === 'select') {
                        item.input[0].options = []
                    }
                    const result =
                        item.input[0].cardType === 'multiselect'
                            ? arrayNumberingExtensionsHook.map((item2) => {
                                  if (item2.extension === null) {
                                      item2.extension = 'not defined'
                                  }
                                  const extension = {
                                      label: item2.extension,
                                      value: item2?.id?.toString(),
                                  }
                                  const found = item.input[0].options.some((e) => e.value === extension.value)
                                  if (!found && extension.value !== undefined) {
                                      item.input[0].options.push(extension)
                                  }
                                  return item
                              })
                            : null
                    return result
                })
                if (crudType === 'edit' && (EMFormListExtensions.listName === '' || EMFormListExtensions.listName !== location.state.title)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit') {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'name':
                                    item2.defaultValues = EMFormListExtensions.listName
                                    break
                                case 'listExtensions':
                                    item2.defaultValues = []
                                    item2.options.filter((item3) => {
                                        if (EMFormListExtensions.extensions.map((item4) => item4.value.toString()).includes(item3.value)) {
                                            if (!item2.defaultValues.map((item5) => item5.value).includes(item3.value)) {
                                                return item2.defaultValues.push(item3)
                                            }
                                        }
                                        return false
                                    })
                                    break
                                default:
                                    break
                            }

                            return item2
                        })
                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crudType, loadingCounter])

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            try {
                typeSwal('createLoader', '', EMform, getEMListExtension, `/${routesWide.EM}/${routesEM.diffusionLists}`, 'diffusionList', createEMListExtension)
            } catch (err) {
                return err
            }
        } else if (crudType === 'edit') {
            try {
                typeSwal('createLoader', '', EMform, getEMListExtension, `/${routesWide.EM}/${routesEM.diffusionLists}`, 'diffusionList', updateEMListExtension)
            } catch (err) {
                console.log(err)
            }
        }
    }

    return (
        <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={EMform} dataSave={dataSave} initialData={EMFormListExtensions} crudType={crudType} arrScreen={arrayEM} />}</>
    )
}
