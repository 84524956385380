import React from 'react'
import { BoxWrapper } from './Box.style'

export const Box = ({ name, backgroundColor }) => {
    return (
        <BoxWrapper backgroundColor={backgroundColor}>
            <div className="box">
                <h5 className="box__title">{name}</h5>
            </div>
        </BoxWrapper>
    )
}
