import React from "react";
import PropTypes from "prop-types";

import { LogoDiv } from "./Logo.style";

export const Logo = ({ logo }) => {
  return (
    <LogoDiv>
      <div>
        <img src={logo} alt="logo company" />
      </div>
    </LogoDiv>
  );
};

Logo.propType = {
  logo: PropTypes.string.isRequired,
};
