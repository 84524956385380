import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formGroupCategories } from '../../../data/dataOutbound'
import { useOutbound } from '../../../hooks/useOutboundRoutes'
import { useSwal } from '../../../hooks/useSwal'
import { routesOutbound, routesWide } from '../../../navigation/routes'

export const GroupCategoriesForm = ({ crudType }) => {
    const { outboundRoutesForm, outboundRoutesFormCategoriesGroup, createCategoriesGroup, categoriesHook, getCategoriesHook, updateCategoriesGroup, getCategoriesGroupHook } = useOutbound()
    const { typeSwal } = useSwal()

    const location = useLocation()

    const [loading, setLoading] = useState(true)
    const [loadingCounter, setLoadingCounter] = useState(0)
    const [dataSave, setDataSave] = useState(false)

    const data = formGroupCategories

    useEffect(() => {
        getCategoriesHook()
        outboundRoutesForm(
            {
                action: 'clear',
            },
            { form: 'groupCategories' }
        )
        if (crudType === 'edit') {
            let actionId = {
                action: 'id',
                value: location.state.arrayCategoriesGroup[0].CategoriesGroup.id,
            }
            outboundRoutesForm(actionId, {
                form: 'groupCategories',
                element: 'id',
            })
            let actionName = { action: 'input', value: location.state.title }
            outboundRoutesForm(actionName, {
                form: 'groupCategories',
                element: 'name',
            })
            location.state.arrayCategoriesGroup.map((item) =>
                outboundRoutesForm(
                    {
                        action: 'select-option',
                        option: {
                            label: item.Category.description,
                            value: String(item.Category.id),
                        },
                    },
                    {
                        form: 'groupCategories',
                        element: 'listCategories',
                    }
                )
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (categoriesHook.length === 0) {
                setLoadingCounter(loadingCounter + 1)
            }
            if (categoriesHook.length > 0) {
                data.steps.map((item) => {
                    if (item.input[0].cardType === 'multiselect' || item.input[0].cardType === 'select') {
                        item.input[0].options = []
                    }
                    const result =
                        item.input[0].cardType === 'multiselect'
                            ? categoriesHook.forEach((item2) => {
                                  if (item2.description === null) {
                                      item2.description = 'not defined'
                                  }
                                  const category = {
                                      label: item2.description,
                                      value: item2.id?.toString(),
                                  }
                                  const found = item.input[0].options.some((e) => e.value === category.value)
                                  if (!found && category.value !== undefined) {
                                      item.input[0].options.push(category)
                                  }
                              })
                            : null

                    return result
                })
                if (crudType === 'edit' && (outboundRoutesFormCategoriesGroup.name === '' || outboundRoutesFormCategoriesGroup.name !== location.state.title)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit' && outboundRoutesFormCategoriesGroup.name === location.state.title) {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'name':
                                    item2.defaultValues = outboundRoutesFormCategoriesGroup.name
                                    break
                                case 'listCategories':
                                    item2.defaultValues = []
                                    item2.options.filter((item3) =>
                                        outboundRoutesFormCategoriesGroup.categories.forEach((item4) => {
                                            if (item3.value === item4.value) {
                                                item2.defaultValues.push(item4)
                                            }
                                        })
                                    )
                                    break
                                default:
                                    break
                            }

                            return item2
                        })
                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crudType, loadingCounter])

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            try {
                typeSwal('createLoader', '', outboundRoutesForm, getCategoriesGroupHook, `/${routesWide.outboundRoutes}/${routesOutbound.groupCategories}`, 'groupCategories', createCategoriesGroup)
            } catch (err) {
                return err
            }
        } else if (crudType === 'edit') {
            try {
                typeSwal('createLoader', '', outboundRoutesForm, getCategoriesGroupHook, `/${routesWide.outboundRoutes}/${routesOutbound.groupCategories}`, 'groupCategories', updateCategoriesGroup)
            } catch (err) {
                return err
            }
        }
    }

    return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={outboundRoutesForm} dataSave={dataSave} crudType={crudType} />}</>
}
