import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { fontFamily, newFontSize, weight } from '../../theme/fonts'

export const TableInfoWrapper = styled.div`
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 36px;

    &::-webkit-scrollbar {
        width: 2px;
        background: white;
        height: 5px;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        width: 1px;
        background: ${colors.primaryColor};
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
    }
    &::-webkit-scrollbar-track-piece {
        height: 80%;
    }

    table {
        max-height: 100%;
        font-family: ${fontFamily.font};
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        background-color: ${colors.white};
        border-radius: 10px;
    }

    table thead tr th {
        border-right: none;
        border-left: none;
        text-align: start;
    }

    table td {
        border-top: 1px solid ${colors.greyLine};
        border-left: none;
        height: 55px;
        font-size: ${newFontSize.body};
        font-weight: ${weight.regular};
        line-height: 16px;
        letter-spacing: 0.25px;
        color: ${colors.black};
        margin: 0;
        text-align: start;
    }

    table tbody {
        tr {
            background-color: ${colors.white};
            transition: all 0.3s;
        }
        tr:hover {
            background-color: ${colors.greyHover};
            transition: all 0.3s;
        }
    }

    table thead {
        // background-color: none;
        background-color: white;
        position: sticky;
        top: 0;
        z-index: 2;
    }

    table th {
        font-size: ${newFontSize.button};
        color: ${colors.black};
        letter-spacing: 0.25px;
        font-weight: ${weight.medium};
        line-height: 16px;
        border-right: none;
        height: 61px;
        div {
            display: flex;
            gap: 5px;
            align-items: center;
        }
    }

    table td:last-child {
        border-right: none;
    }

    table th:last-child {
        border-right: none;
    }

    table td:first-child {
        border-left: none;
    }

    table th:first-child {
        border-left: none;
    }

    .spacer {
        width: 30px !important;
    }

    .image-header {
        width: 14px;
        height: 16px;
    }

    .image-showPassword {
        &:hover {
            cursor: pointer;
        }
    }
    .checkbox {
        width: 30px !important;
    }
`
