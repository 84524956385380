import Axios from 'axios'

import {
    createApplicationsLocutions,
    createConferenceAction,
    deleteAPPLocutions,
    deleteConferenceAction,
    getAudios,
    getConferencesAction,
    getDestinationAction,
    getIvrAction,
    getLocutionsAction,
    getMailboxesAction,
    getQueueAction,
    getShortMarkingAction,
    getSongWaitingAction,
    getSongWaitingLinkAction,
    updateConferenceAction,
} from '../actions/applications/actionCreators'

const urlApi = process.env.REACT_APP_URL
const token = process.env.REACT_APP_TOKEN

export const getApplicationsLocutionsThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const locutions = await Axios.get(`${urlApi}applications/locutions`, {
            headers: {
                Authorization: token,
            },
        })
        var response = await Axios.get(`${urlApi}applications/locutions/getAudios`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getLocutionsAction(locutions.data))
        dispatch(getAudios(response.data.files))
    } catch (error) {
        return error
    } finally {
        setLoading(false)
    }
}

export const createApplicationsLocutionsThunk = (locution, setLoading) => async (dispatch) => {
    setLoading(true)
    const data = new FormData()
    data.append('file', locution.file[0])
    data.append('locutionName', locution.locutionName)
    data.append('headquarter', +locution.headquarters.value)
    data.append('fileName', locution.file[0].path.replace(/\s+/g, '_'))
    try {
        var response = await Axios.post(`${urlApi}applications/locutions/create`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
        if (response.status === 201) {
            dispatch(createApplicationsLocutions(locution))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateApplicationsLocutionsThunk = (locution, setLoading) => async (dispatch) => {
    setLoading(true)
    let response
    if (locution.file[0] !== undefined) {
        const data = new FormData()

        data.append('file', locution.file[0])
        data.append('locutionName', locution.locutionName)
        data.append('headquarter', +locution.headquarters.value)
        data.append('fileName', locution.file[0].path.replace(/\s+/g, '_'))
        data.append('id', locution.id)
        try {
            response = await Axios.patch(`${urlApi}applications/locutions/edit`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
            if (response.status === 201) {
                dispatch(createApplicationsLocutions(locution))
            }
        } catch (err) {
            return err
        } finally {
            setLoading(false)
            return response
        }
    } else {
        try {
            response = await Axios.patch(`${urlApi}applications/locutions/editNoFile`, locution, { headers: { Authorization: token } })
            if (response.status === 201) {
                dispatch(createApplicationsLocutions(locution))
            }
        } catch (err) {
            return err
        } finally {
            setLoading(false)
            return response
        }
    }
}

export const getAudioLocutionsThunk = (setLoading) => async (dispatch) => {
    try {
        var response = await Axios.get(`${urlApi}applications/locutions/getAudios`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getAudios(response.data.files))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const deleteAPPLocutionsThunk = (locution, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.delete(
            `${urlApi}applications/locutions/delete`,

            {
                headers: {
                    Authorization: token,
                },
                data: locution,
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(deleteAPPLocutions(locution))
        }
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const uploadFileLocutionsThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}applications/locutions/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const getConferencesThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const response = await Axios.get(`${urlApi}applications/conferences`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getConferencesAction(response.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const createConferenceThunk = (conference, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.post(
            `${urlApi}applications/conferences/create`,
            {
                data: conference,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(createConferenceAction(conference))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateConferenceThunk = (conference, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.patch(
            `${urlApi}applications/conferences/edit`,
            {
                data: conference,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(updateConferenceAction(conference))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteConferenceThunk = (conference, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.delete(
            `${urlApi}applications/conferences/delete`,

            {
                headers: {
                    Authorization: token,
                },
                data: conference,
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(deleteConferenceAction(conference))
        }
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const uploadFileConferenceThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}applications/conferences/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const getMailboxesThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const response = await Axios.get(`${urlApi}applications/mailboxes`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getMailboxesAction(response.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const createMailboxesThunk = (mailboxes, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.post(
            `${urlApi}applications/mailboxes/create`,
            {
                data: mailboxes,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(createConferenceAction(mailboxes))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateMailboxesThunk = (mailboxes, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.patch(
            `${urlApi}applications/mailboxes/edit`,
            {
                data: mailboxes,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(updateConferenceAction(mailboxes))
        }
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteMailboxesThunk = (mailboxes, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.delete(
            `${urlApi}applications/mailboxes/delete`,

            {
                headers: {
                    Authorization: token,
                },
                data: mailboxes,
            }
        )
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            dispatch(deleteConferenceAction(mailboxes))
        }
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const uploadFileMailboxesThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}applications/mailboxes/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const getSongWaitingThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const response = await Axios.get(`${urlApi}applications/songWaiting`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getSongWaitingAction(response.data))
    } catch (err) {
        return err
    }
}

export const getSongWaitingLinkThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const songWaitingLink = await Axios.get(`${urlApi}applications/getSongWaitingLink`, {
            headers: {
                Authorization: token,
            },
        })
        const songWaiting = await Axios.get(`${urlApi}applications/songWaiting`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getSongWaitingAction(songWaiting.data))
        dispatch(getSongWaitingLinkAction(songWaitingLink.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const createSongWaitingThunk = (songWaiting, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.post(
            `${urlApi}applications/songWaiting/create`,
            {
                data: songWaiting,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateSongWaitingThunk = (songWaiting, setLoading) => async () => {
    try {
        var response = await Axios.patch(
            `${urlApi}applications/songWaiting/edit`,
            {
                data: songWaiting,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteSongWaitingThunk = (songWaiting, setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        var response = await Axios.delete(
            `${urlApi}applications/songWaiting/delete`,

            {
                headers: {
                    Authorization: token,
                },
                data: songWaiting,
            }
        )
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const uploadFileSongWaitingThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}applications/songWaiting/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const getShortMarkingThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const response = await Axios.get(`${urlApi}applications/shortMarking`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getShortMarkingAction(response.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const createShortMarkingThunk = (shortMarking, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.post(
            `${urlApi}applications/shortMarking/create`,
            {
                data: shortMarking,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateShortMarkingThunk = (shortMarking, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.patch(
            `${urlApi}applications/shortMarking/edit`,
            {
                data: shortMarking,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteShortMarkingThunk = (shortMarking, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.delete(
            `${urlApi}applications/shortMarking/delete`,

            {
                headers: {
                    Authorization: token,
                },
                data: shortMarking,
            }
        )

        return response
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const uploadFileShortMarkingThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}applications/shortMarking/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const getIvrThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const response = await Axios.get(`${urlApi}applications/ivr`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getIvrAction(response.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const createIvrThunk = (ivr, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.post(
            `${urlApi}applications/ivr/create`,
            {
                data: ivr,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateIvrThunk = (ivr, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.patch(
            `${urlApi}applications/ivr/edit`,
            {
                data: ivr,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteIvrThunk = (ivr, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.delete(
            `${urlApi}applications/ivr/delete`,

            {
                headers: {
                    Authorization: token,
                },
                data: ivr,
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const getDestinationThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const response = await Axios.get(`${urlApi}applications/destination`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getDestinationAction(response.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const getQueueThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const response = await Axios.get(`${urlApi}applications/queues`, {
            headers: {
                Authorization: token,
            },
        })
        dispatch(getQueueAction(response.data))
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const createQueueThunk = (queue, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.post(
            `${urlApi}applications/queues/create`,
            {
                data: queue,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateQueueThunk = (queue, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.patch(
            `${urlApi}applications/queues/edit`,
            {
                data: queue,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteQueueThunk = (queue, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.delete(
            `${urlApi}applications/queues/delete`,

            {
                headers: {
                    Authorization: token,
                },
                data: queue,
            }
        )

        return response
    } catch (err) {
        return err
    } finally {
        setLoading(false)
    }
}

export const uploadFileQueueThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}applications/queue/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}
