import { useStore } from 'react-redux'

export const useValidationForm = () => {
    const { getState } = useStore()

    const validationForm = (stateReducer, data, item) => {
        const reducerForm = getState()[stateReducer]
        let errors = []
        const dataValidate = []

        const { steps } = data

        steps.forEach((step) => {
            step.input.forEach((input) => {
                //It's case to ddi special multiform
                if (input.dataRef) {
                    input.dataRef.forEach((item) => {
                        if (item.destinationOpen) {
                            item.destinationOpen.forEach((item2) => {
                                item2.input.forEach((item3) => {
                                    if (item3.required?.validation) {
                                        let newObjValidate = {
                                            name: item3.ref.element,
                                            regex: item3.required.regex,
                                        }
                                        dataValidate.push(newObjValidate)
                                    }
                                })
                            })
                        } else {
                            item.input.forEach((item2) => {
                                if (item2.required?.validation) {
                                    let newObjValidate = {
                                        name: item2.ref.element,
                                        regex: item2.required.regex,
                                    }
                                    dataValidate.push(newObjValidate)
                                }
                            })
                        }
                    })
                } else {
                    //It's same in all dataForms
                    if (input.required?.validation) {
                        let newObjValidate = {
                            name: input.ref.element,
                            regex: input.required.regex,
                        }
                        dataValidate.push(newObjValidate)
                    }
                }
            })
        })

        const keys = Object.keys(reducerForm)
        for (const key of keys) {
            if (typeof reducerForm[key] === 'object') {
                if (Array.isArray(reducerForm[key])) {
                    // Si es un array, iterar sobre cada objeto y comprobar sus propiedades
                    for (const subObj of reducerForm[key]) {
                        const subKeys = Object.keys(subObj)
                        for (const subKey of subKeys) {
                            if (typeof subObj[subKey] === 'object' && Object.keys(subObj[subKey]).length === 0) {
                                errors.push({
                                    idPage: subObj.id,
                                    idDestination: null,
                                    name: subKey,
                                    initialState: subObj[subKey].initialState,
                                })
                            } else if (Array.isArray(subObj[subKey])) {
                                // Si es un array, iterar sobre cada objeto y comprobar sus propiedades
                                for (const subSubObj of subObj[subKey]) {
                                    const subSubKeys = Object.keys(subSubObj)
                                    for (const subSubKey of subSubKeys) {
                                        if (typeof subSubObj[subSubKey] === 'object' && Object.keys(subSubObj[subSubKey]).length === 0) {
                                            errors.push({
                                                idPage: subObj.id,
                                                idDestination: subSubObj.id,
                                                name: subSubKey,
                                                initialState: subSubObj[subSubKey].initialState,
                                            })
                                        } else if (subSubObj[subSubKey]?.label === '') {
                                            errors.push({
                                                idPage: subObj.id,
                                                idDestination: subSubObj.id,
                                                name: subSubKey,
                                                initialState: subSubObj[subSubKey]?.initialState,
                                            })
                                        }
                                    }
                                }
                            } else if (subObj[subKey]?.label === '') {
                                errors.push({
                                    idPage: subObj.id,
                                    idDestination: null,
                                    name: subKey,
                                    initialState: subObj[subKey]?.initialState,
                                })
                            }
                        }
                    }
                } else if (Object.keys(reducerForm[key]).length === 0) {
                    errors.push({ idPage: null, idDestination: null, name: `${key}`, initialState: reducerForm[key].initialState })
                } else if (reducerForm[key]?.label === '') {
                    errors.push({
                        idPage: null,
                        idDestination: null,
                        name: `${key}`,
                        initialState: reducerForm[key].initialState,
                    })
                }
            } else if (reducerForm[key] === '') {
                if (key !== 'id') {
                    errors.push({ idPage: null, idDestination: null, name: `${key}` })
                }
            }
        }

        for (let i = 0; i < errors.length; i++) {
            // eslint-disable-next-line no-loop-func
            const foundObj = dataValidate.find((item) => item.name === errors[i].name)
            if (!foundObj) {
                errors.splice(i, 1)
                i--
            }
        }

        if (item !== undefined) {
            if (item.action === 'select-option') {
                const index = errors.findIndex((obj) => obj.idPage === item.idPage && obj.idDestination === item.idDestination && obj.name === item.element)
                errors.splice(index, 1)
                let newErrors = errors.filter((item) => item.initialState !== true)
                errors = newErrors
            } else {
                const foundItemValidate = dataValidate.some((obj) => obj.name === item.element)

                if (foundItemValidate) {
                    errors.push({
                        idPage: item.idPage,
                        idDestination: item.idDestination,
                        name: item.element,
                    })
                    let newErrors = errors.filter((item) => item.initialState !== true)
                    errors = newErrors
                }
            }
        }

        return errors
    }

    return {
        validationForm,
    }
}
