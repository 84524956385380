export const colors = {
    white: '#FEFEFE',
    black: '#1E2530',
    primaryColor: '#444972',
    secondaryColor: '#C1CEE8',
    grey: '#4A5056',
    greyHover: '#EAECEF',
    greyHoverCard: '#f5f5f5',
    greyBorder: '#E6E3E8',
    greyActive: '#B9C0CA',
    greyLine: '#D2D3D4',
    greyText: '#B7B6B6',
    greySpanLabel: '#7A7A7A',
    blue: '#444972',
    blueHover: '#EBFAFF',
    green: '#40A644',
    greenHover: '#DAF1DB',
    red: '#CD3434',
    redHover: '#FDCFCF',
}
