import { useEffect } from 'react'
import { ToggleButtonWrapper } from './ToggleButton.style'

export const ToggleButton = ({ isActive, setIsActive, isShowCard, textRight, textLeft, twoButtonActive = 'false', defaultValue }) => {
    useEffect(() => {
        if (defaultValue !== undefined) {
            setIsActive(defaultValue)
        }
    }, [defaultValue, setIsActive])
    return (
        <ToggleButtonWrapper isActive={isActive} isShowCard={isShowCard} twoButtonActive={twoButtonActive}>
            <div className={'toggleButton'}>
                <div className="toggleButton__left">{textLeft}</div>
                <div className="toggleButton__right">{textRight}</div>
            </div>
        </ToggleButtonWrapper>
    )
}
