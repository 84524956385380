import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { MySelect } from '../MySelect/MySelect'

import { RadioContainWrapper, RadioWrapper, RadioWrapperSelect } from './Radio.style'
import { Input } from '../Input/Input'

export const Radio = ({
    form,
    item,
    defaultValueEdit,
    voiceMail,
    dataForm,
    inputRef,
    setInputValue,
    setValidationNormalInput,
    validationNormalInput,
    setErrorInput,
    allSteps,
    inputValue,
    arrScreen,
    inputValueUnique,
    setInputValueUnique,
}) => {
    const [selectedIdRadio, setSelectedIdRadio] = useState(0)
    const [isSelected, setIsSelected] = useState({})

    const onChangeRadio = (option, item) => {
        let action = { action: 'select-option', value: option }
        let ref = item.ref

        form(action, ref)
    }

    useEffect(() => {
        item.options.map((item2) =>
            setIsSelected((prevstate) => ({
                ...prevstate,
                [item2.id]: false,
            }))
        )
    }, [item])

    useEffect(() => {
        if (defaultValueEdit.length !== 0)
            defaultValueEdit.forEach((rec) => {
                if (!Object.keys(isSelected).includes(rec.id))
                    setIsSelected((prevState) => ({
                        ...prevState,
                        [rec.id]: true,
                    }))
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValueEdit])

    useEffect(() => {
        setSelectedIdRadio(voiceMail.id)
        setIsSelected((prevState) => ({
            ...prevState,
            [voiceMail.id]: true,
        }))
    }, [voiceMail])

    const onChangeRadioClick = (item2, item) => {
        if (item.radioType === 'multi') {
            setIsSelected((prevstate) => ({
                ...prevstate,
                [item2.id]: !isSelected[item2.id],
            }))
        }
        setSelectedIdRadio(item2.id)
        onChangeRadio(item2, item, item2.id)
    }

    return (
        <RadioContainWrapper>
            {item.options.map((item2) => (
                <AnimatePresence key={item2.id}>
                    {selectedIdRadio === item2.id && item.radioType === 'mono' ? (
                        <RadioWrapper option={item2} onClick={() => onChangeRadioClick(item2, item)} layoutId={item2.id} isSelected={true}>
                            {item2.label}
                        </RadioWrapper>
                    ) : selectedIdRadio === item2.id && item.radioType === 'multi' && item2.selectData ? (
                        <RadioWrapperSelect option={item2} onClick={() => onChangeRadioClick(item2, item)} layoutId={item2.id} isSelected={isSelected[item2.id]}>
                            <div className="radio__withSelect">
                                {item2.label}
                                <span className="radio__withSelect__label">{item2.selectData.label}</span>
                                <MySelect
                                    item={item2.selectData}
                                    placeholder={item2.selectData.placeholder}
                                    options={item2.selectData.options}
                                    inputRef={inputRef}
                                    defaultValue={item2.selectData.defaultValues}
                                    isSearchable={true}
                                    form={form}
                                    dataForm={dataForm}
                                    setInputValue={setInputValue}
                                    setErrorInput={setErrorInput}
                                    setValidationNormalInput={setValidationNormalInput}
                                    validationNormalInput={validationNormalInput}
                                    allSteps={allSteps}
                                />
                            </div>
                        </RadioWrapperSelect>
                    ) : selectedIdRadio === item2.id && item.radioType === 'multi' && item2.inputData ? (
                        <RadioWrapperSelect option={item2} onClick={() => onChangeRadioClick(item2, item)} layoutId={item2.id} isSelected={isSelected[item2.id]}>
                            <div className="radio__withSelect">
                                {item2.label}
                                <span className="radio__withSelect__label">{item2.inputData.label}</span>
                                <Input
                                    item={item2.inputData}
                                    form={form}
                                    inputValue={inputValue}
                                    setErrorInput={setErrorInput}
                                    setInputValue={setInputValue}
                                    setValidationNormalInput={setValidationNormalInput}
                                    validationNormalInput={validationNormalInput}
                                    arrScreen={arrScreen}
                                    inputValueUnique={inputValueUnique}
                                    setInputValueUnique={setInputValueUnique}
                                />
                            </div>
                        </RadioWrapperSelect>
                    ) : selectedIdRadio === item2.id && item.radioType === 'multi' ? (
                        <RadioWrapper option={item2} onClick={() => onChangeRadioClick(item2, item)} layoutId={item2.id} isSelected={isSelected[item2.id]}>
                            {item2.label}
                        </RadioWrapper>
                    ) : selectedIdRadio !== item2.id && item.radioType === 'multi' && item2.selectData ? (
                        <RadioWrapperSelect option={item2} onClick={() => onChangeRadioClick(item2, item)} layoutId={item2.id} isSelected={isSelected[item2.id]}>
                            <div className="radio__withSelect">
                                {item2.label}
                                <span className="radio__withSelect__label">{item2.selectData.label}</span>

                                <MySelect
                                    item={item2.selectData}
                                    placeholder={item2.selectData.placeholder}
                                    options={item2.selectData.options}
                                    inputRef={inputRef}
                                    defaultValue={item2.selectData.defaultValues}
                                    isSearchable={true}
                                    form={form}
                                    dataForm={dataForm}
                                    setInputValue={setInputValue}
                                    setErrorInput={setErrorInput}
                                    setValidationNormalInput={setValidationNormalInput}
                                    validationNormalInput={validationNormalInput}
                                    allSteps={allSteps}
                                />
                            </div>
                        </RadioWrapperSelect>
                    ) : selectedIdRadio !== item2.id && item.radioType === 'multi' && item2.inputData ? (
                        <RadioWrapperSelect option={item2} onClick={() => onChangeRadioClick(item2, item)} layoutId={item2.id} isSelected={isSelected[item2.id]}>
                            <div className="radio__withSelect">
                                {item2.label}
                                <span className="radio__withSelect__label">{item2.inputData.label}</span>
                                <Input
                                    item={item2.inputData}
                                    form={form}
                                    inputValue={inputValue}
                                    setErrorInput={setErrorInput}
                                    setInputValue={setInputValue}
                                    setValidationNormalInput={setValidationNormalInput}
                                    validationNormalInput={validationNormalInput}
                                    arrScreen={arrScreen}
                                    inputValueUnique={inputValueUnique}
                                    setInputValueUnique={setInputValueUnique}
                                />
                            </div>
                        </RadioWrapperSelect>
                    ) : selectedIdRadio !== item2.id && item.radioType === 'multi' ? (
                        <RadioWrapper option={item2} onClick={() => onChangeRadioClick(item2, item)} layoutId={item2.id} isSelected={isSelected[item2.id]}>
                            {item2.label}
                        </RadioWrapper>
                    ) : (
                        <RadioWrapper option={item2} onClick={() => onChangeRadioClick(item2, item)} layoutId={item2.id} isSelected={false}>
                            {item2.label}
                        </RadioWrapper>
                    )}
                </AnimatePresence>
            ))}
        </RadioContainWrapper>
    )
}
