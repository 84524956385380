import styled from 'styled-components'
import { colors } from '../../theme/colors'

export const ToggleSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .switch {
        width: 30px;
        height: 16px;
        background-color: ${(props) => props.color};
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // border-radius: 50px;
        cursor: pointer;
        border: 0.307932px solid #adb5bd;
        box-shadow: inset 0px 0.461899px 3.07932px rgba(0, 0, 0, 0.15);
        border-radius: 15.3966px;
    }

    .switch[data-isOn='true'] {
        justify-content: flex-end;
        border: 0.307932px solid ${(props) => props.color};
    }

    .handle {
        width: 15px;
        height: 15px;
        background: linear-gradient(180deg, #ffffff 20.59%, #ffffff 85.35%);
        box-shadow: 0px 0px 0.620393px rgba(0, 0, 0, 0.35);
        border-radius: 15.5098px;
        border: 0.31px solid ${colors.greyBorder};
    }

    .handle[data-isOn='true'] {
        border: 0.31px solid ${(props) => props.color};
    }
`
