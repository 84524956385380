import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { changeViewAction } from '../redux/actions/view/actionCreator'

const useView = () => {
    const view = useSelector((state) => state.view)

    const [isCardView, setIsCardView] = useState(view.isCardView)

    const dispatch = useDispatch()

    const changeView = () => {
        dispatch(changeViewAction())
        setIsCardView(!isCardView)
    }

    return { isCardView, changeView }
}

export default useView
