import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formDepartment } from '../../../data/dataPreferences'
import { useDepartments } from '../../../hooks/useDepartments'
import usePreferences from '../../../hooks/usePreferences'
import { useSwal } from '../../../hooks/useSwal'
import { routesPreferences, routesWide } from '../../../navigation/routes'

export const DepartmentForm = ({ crudType }) => {
    const { createDepartment, updateDepartment, departmentForm, getDepartments } = useDepartments()
    const { preferencesForm } = usePreferences()
    const { typeSwal } = useSwal()

    const [loading, setLoading] = useState(true)
    const [dataSave, setDataSave] = useState(false)
    const [loadingCounter, setLoadingCounter] = useState(0)

    const location = useLocation()

    const data = formDepartment

    useEffect(() => {
        preferencesForm(
            { action: 'clear' },
            {
                form: 'department',
            }
        )
        if (crudType === 'edit') {
            Object.keys(location.state).map((item) =>
                data.steps.map((item2) =>
                    item2.input.map((item3) => {
                        if (item === item3.ref.element) {
                            return preferencesForm(
                                {
                                    action: item3.cardType === 'id' ? 'input' : item3.cardType,
                                    value: location.state[item],
                                },
                                {
                                    form: item3.ref.form,
                                    element: item3.ref.element,
                                }
                            )
                        } else {
                            return null
                        }
                    })
                )
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (typeof departmentForm === 'object') {
                if (crudType === 'edit' && (departmentForm.name === '' || departmentForm.name !== location.state.name)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit') {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'name':
                                    item2.defaultValues = departmentForm.name
                                    break
                                default:
                                    break
                            }
                            return item2
                        })
                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCounter, crudType])

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            typeSwal('createLoader', '', preferencesForm, getDepartments, `/${routesWide.preferences}/${routesPreferences.department}`, 'department', createDepartment)
        } else if (crudType === 'edit') {
            typeSwal('createLoader', '', preferencesForm, getDepartments, `/${routesWide.preferences}/${routesPreferences.department}`, 'department', updateDepartment)
        }
    }

    return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={preferencesForm} dataSave={dataSave} crudType={crudType} />}</>
}
