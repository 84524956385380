import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ApiScreen } from '../../screens/SettingsScreen/ApiScreen/ApiScreen'
import { ApiScreenForm } from '../../screens/SettingsScreen/ApiScreen/ApiScreenForm'

import { CompanyInformationScreen } from '../../screens/SettingsScreen/CompanyInformationScreen/CompanyInformationScreen'
import { ConfigurationAsteriskScreen } from '../../screens/SettingsScreen/ConfigurationAsteriskScreen/ConfigurationAsteriskScreen'
import { Domains } from '../../screens/SettingsScreen/Domains/Domains'
import { DomainsForm } from '../../screens/SettingsScreen/Domains/DomainsForm'
import { ModulesScreen } from '../../screens/SettingsScreen/ModulesScreen/ModulesScreen'
import { OverviewSettingsScreen } from '../../screens/SettingsScreen/OverviewSettingsScreen/OverviewSettingsScreen'
import { EditPermissions } from '../../screens/SettingsScreen/PermissionsScreen/EditPermissions/EditPermissions'
import { PermissionsScreen } from '../../screens/SettingsScreen/PermissionsScreen/PermissionsScreen'
import { UserScreenForm } from '../../screens/SettingsScreen/UsersScreen/UserScreenForm'
import { UsersScreen } from '../../screens/SettingsScreen/UsersScreen/UsersScreen'
import { WhiteList } from '../../screens/SettingsScreen/WhiteList/WhiteList'
import { WhiteListForm } from '../../screens/SettingsScreen/WhiteList/WhiteListForm'
import { routesSettings } from '../routes'

import { SettingsNavigationWrapper } from './SettingsNavigation.style'

export const SettingsNavigation = () => {
    return (
        <SettingsNavigationWrapper>
            <Routes>
                <Route path="" element={<OverviewSettingsScreen />} />
                <Route path={routesSettings.users} element={<UsersScreen />} />
                <Route path={routesSettings.modules} element={<ModulesScreen />} />
                <Route path={routesSettings.permissions} element={<PermissionsScreen />} />
                <Route path={routesSettings.permissionsEdit} element={<EditPermissions />} />
                <Route path={routesSettings.companyInformation} element={<CompanyInformationScreen />} />
                <Route path={routesSettings.createUser} element={<UserScreenForm crudType={'create'} />} />
                <Route path={routesSettings.updateUser} element={<UserScreenForm crudType={'edit'} />} />
                <Route path={routesSettings.configurationAsterisk} element={<ConfigurationAsteriskScreen />} />
                <Route path={routesSettings.api} element={<ApiScreen />} />
                <Route path={routesSettings.createApi} element={<ApiScreenForm crudType={'create'} />} />
                <Route path={routesSettings.domains} element={<Domains />} />
                <Route path={routesSettings.createDomains} element={<DomainsForm crudType={'create'} />} />
                <Route path={routesSettings.updateDomains} element={<DomainsForm crudType={'edit'} />} />
                <Route path={routesSettings.whitelist} element={<WhiteList />} />
                <Route path={routesSettings.createWhitelist} element={<WhiteListForm crudType={'create'} />} />
                <Route path={routesSettings.updateWhitelist} element={<WhiteListForm crudType={'edit'} />} />
            </Routes>
        </SettingsNavigationWrapper>
    )
}
