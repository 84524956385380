import { actionTypesApplications } from '../../actions/applications/actionTypes'

export const applicationsLocutionsGetReducer = (locutions = [], action) => {
    let newLocutions
    switch (action.type) {
        case actionTypesApplications.getLocutions:
            newLocutions = [...action.locutions]
            break
        default:
            newLocutions = locutions
    }
    return newLocutions
}

export const locutionsObjectFormReducer = (state = { locutionName: '', file: [], headquarters: '', id: '' }, action) => {
    switch (action.type) {
        case actionTypesApplications.changeApplicationsLocutionsFormName:
            state = {
                ...state,
                locutionName: action.payload,
            }
            break

        case actionTypesApplications.addApplicationsLocutionsFormHeadquarters:
            state = {
                ...state,
                headquarters: action.payload,
            }
            break

        case actionTypesApplications.removeApplicationsLocutionsFormHeadquarters:
            state = {
                ...state,
                headquarters: '',
            }
            break
        case actionTypesApplications.addApplicationsLocutionsFormFile:
            state = {
                ...state,
                file: action.payload,
            }
            break
        case actionTypesApplications.changeApplicationsLocutionsFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionTypesApplications.clearLocutionsForm:
            state = { locutionName: '', file: [], headquarters: '', id: '' }
            break

        default:
            state = { ...state }
            break
    }
    return state
}

export const APPLocutionsDeleteReducer = (locution = {}, action) => {
    let mewLocutionDelete
    switch (action.type) {
        case actionTypesApplications.deleteApplicationsLocutions:
            mewLocutionDelete = { ...action.locution }
            break
        default:
            mewLocutionDelete = locution
    }
    return mewLocutionDelete
}

export const getConferencesReducer = (conferences = [], action) => {
    let newConferences
    switch (action.type) {
        case actionTypesApplications.getConferences:
            newConferences = [...action.conferences]
            break
        default:
            newConferences = conferences
            break
    }
    return newConferences
}

export const conferenceFormReducer = (
    state = {
        name: '',
        number: '',
        pin: '',
        record: false,
        company: [],
        id: '',
    },
    action
) => {
    switch (action.type) {
        case actionTypesApplications.changeConferenceFormName:
            state = {
                ...state,
                name: action.payload,
            }
            break
        case actionTypesApplications.changeConferenceFormNumber:
            state = {
                ...state,
                number: action.payload,
            }
            break
        case actionTypesApplications.changeConferenceFormPin:
            state = {
                ...state,
                pin: action.payload,
            }
            break
        case actionTypesApplications.changeConferenceFormRecord:
            state = {
                ...state,
                record: action.payload,
            }
            break
        case actionTypesApplications.addConferenceFormCompany:
            state = {
                ...state,
                company: action.payload,
            }
            break
        case actionTypesApplications.changeConferenceFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionTypesApplications.removeConferencesCompany:
            state = {
                ...state,
                company: [],
            }
            break
        case actionTypesApplications.clearConferenceForm:
            state = {
                name: '',
                number: '',
                pin: '',
                record: false,
                company: [],
                id: '',
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const getMailboxesReducer = (mailboxes = [], action) => {
    let newMailboxes
    switch (action.type) {
        case actionTypesApplications.getMailboxes:
            newMailboxes = [...action.mailboxes]
            break
        default:
            newMailboxes = mailboxes
            break
    }
    return newMailboxes
}

export const mailboxesFormReducer = (
    state = {
        number: '',
        name: '',
        pin: '',
        company: [],
        email: '',
        soundPrompt: [],
        id: '',
        astVoicemailId: '',
    },
    action
) => {
    switch (action.type) {
        case actionTypesApplications.changeMailboxesFormNumber:
            state = {
                ...state,
                number: action.payload,
            }
            break
        case actionTypesApplications.changeMailboxesFormName:
            state = {
                ...state,
                name: action.payload,
            }
            break
        case actionTypesApplications.changeMailboxesFormPin:
            state = {
                ...state,
                pin: action.payload,
            }
            break
        case actionTypesApplications.addMailboxesFormCompany:
            state = {
                ...state,
                company: action.payload,
            }
            break
        case actionTypesApplications.changeMailboxesFormEmail:
            state = {
                ...state,
                email: action.payload,
            }
            break
        case actionTypesApplications.addMailboxesFormSoundPrompt:
            state = {
                ...state,
                soundPrompt: action.payload,
            }
            break
        case actionTypesApplications.changeMailboxesFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionTypesApplications.changeMailboxesFormAstVoicemailId:
            state = {
                ...state,
                astVoicemailId: action.payload,
            }
            break
        case actionTypesApplications.removeMailboxesCompany:
            state = {
                ...state,
                company: [],
            }
            break
        case actionTypesApplications.removeMailboxesSoundPrompt:
            state = {
                ...state,
                soundPrompt: [],
            }
            break

        case actionTypesApplications.clearMailboxesForm:
            state = {
                number: '',
                name: '',
                pin: '',
                company: [],
                email: '',
                soundPrompt: [],
                id: '',
                astVoicemailId: '',
            }
            break
        default:
            state = { ...state }
    }
    return state
}

export const getSongWaitingReducer = (songWaiting = [], action) => {
    let newSongWaiting
    switch (action.type) {
        case actionTypesApplications.getSongWaiting:
            newSongWaiting = [...action.songWaitings]
            break

        default:
            newSongWaiting = songWaiting
            break
    }
    return newSongWaiting
}

export const getSongWaitingLinkReducer = (songWaitingLink = [], action) => {
    let newSongWaitingLink
    switch (action.type) {
        case actionTypesApplications.getSongWaitingLink:
            newSongWaitingLink = [...action.songWaitingLink]
            break

        default:
            newSongWaitingLink = songWaitingLink
            break
    }
    return newSongWaitingLink
}

export const songWaitingFormReducer = (
    state = {
        name: '',
        company: [],
        soundPrompt: [],
        id: '',
    },
    action
) => {
    let arraySoundPrompt = { ...state }.soundPrompt
    switch (action.type) {
        case actionTypesApplications.changeSongWaitingFormName:
            state = {
                ...state,
                name: action.payload,
            }
            break

        case actionTypesApplications.addSongWaitingFormCompany:
            state = {
                ...state,
                company: action.payload,
            }
            break
        case actionTypesApplications.changeSongWaitingFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionTypesApplications.addSongWaitingFormSoundPrompt:
            arraySoundPrompt.push(action.payload)
            state = {
                ...state,
                soundPrompt: arraySoundPrompt,
            }
            break
        case actionTypesApplications.removeSongWaitingFormSoundPrompt:
            const index = arraySoundPrompt.map((p) => p.value).indexOf(action.payload.value)
            if (index > -1) {
                arraySoundPrompt.splice(index, 1)
            }
            state = {
                ...state,
                soundPrompt: arraySoundPrompt,
            }
            break
        case actionTypesApplications.removeSongWaitingFormCompany:
            state = {
                ...state,
                company: [],
            }
            break

        case actionTypesApplications.clearSongWaitingFormSoundPrompt:
            state = {
                ...state,
                soundPrompt: [],
            }
            break

        case actionTypesApplications.clearSongWaitingForm:
            state = {
                name: '',
                company: [],
                soundPrompt: [],
                id: '',
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const getShortMarkingReducer = (shortMarking = [], action) => {
    let newShortMarking
    switch (action.type) {
        case actionTypesApplications.getShortMarking:
            newShortMarking = [...action.shortMarking]
            break
        default:
            newShortMarking = shortMarking
            break
    }
    return newShortMarking
}

export const shortMarkingFormReducer = (state = { nombre: '', numCorto: '', destino: '', company: [], id: '' }, action) => {
    switch (action.type) {
        case actionTypesApplications.changeShortMarkingFormNombre:
            state = {
                ...state,
                nombre: action.payload,
            }
            break
        case actionTypesApplications.changeShortMarkingFormNumCorto:
            state = {
                ...state,
                numCorto: action.payload,
            }
            break
        case actionTypesApplications.changeShortMarkingFormDestino:
            state = {
                ...state,
                destino: action.payload,
            }
            break
        case actionTypesApplications.addShortMarkingFormCompany:
            state = {
                ...state,
                company: action.payload,
            }
            break
        case actionTypesApplications.changeShortMarkingFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionTypesApplications.removeShortMarkingCompany:
            state = {
                ...state,
                company: [],
            }
            break
        case actionTypesApplications.clearShortMarkingForm:
            state = {
                nombre: '',
                numCorto: '',
                destino: '',
                company: [],
                id: '',
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const getAudiosReducer = (state = { audio: [] }, action) => {
    switch (action.type) {
        case actionTypesApplications.getAudios:
            state = {
                ...state,
                audio: action.payload,
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const getIvrReducer = (ivr = [], action) => {
    let newIvr
    switch (action.type) {
        case actionTypesApplications.getIvr:
            newIvr = [...action.ivrs]
            break
        default:
            newIvr = ivr
            break
    }
    return newIvr
}

export const ivrFormReducer = (state = { name: '', company: [], soundPrompt: [], id: '', destination: [] }, action) => {
    switch (action.type) {
        case actionTypesApplications.changeIvrFormName:
            state = {
                ...state,
                name: action.payload,
            }
            break
        case actionTypesApplications.addIvrFormCompany:
            state = {
                ...state,
                company: action.payload,
            }
            break
        case actionTypesApplications.addIvrFormSoundPrompt:
            state = {
                ...state,
                soundPrompt: action.payload,
            }
            break
        case actionTypesApplications.changeIvrFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionTypesApplications.removeIvrCompany:
            state = {
                ...state,
                company: [],
            }
            break
        case actionTypesApplications.removeIvrSoundPrompt:
            state = {
                ...state,
                soundPrompt: [],
            }
            break
        case actionTypesApplications.changeIvrFormDestinations:
            const { item, value } = action.payload
            if (value === 'timeOut') {
                state.destination.forEach((item2) => {
                    if (item2.key === 'Si no se pulsa') {
                        item2.timeOut = item
                    }
                })
            } else {
                const newDestination = { ...item, key: value }
                const existingDestinationIndex = state.destination.findIndex((dest) => dest.key === value)
                if (existingDestinationIndex !== -1) {
                    state = {
                        ...state,
                        destination: [...state.destination.slice(0, existingDestinationIndex), newDestination, ...state.destination.slice(existingDestinationIndex + 1)],
                    }
                } else {
                    state = {
                        ...state,
                        destination: [...state.destination, newDestination],
                    }
                }
            }
            break
        case actionTypesApplications.removeIvrFormDestinations:
            const { title } = action.payload.item
            const destinationIndex = state.destination.findIndex((dest) => dest.key === title)
            if (destinationIndex !== -1) {
                state = {
                    ...state,
                    destination: [...state.destination.slice(0, destinationIndex), ...state.destination.slice(destinationIndex + 1)],
                }
            }
            break
        case actionTypesApplications.clearIvrForm:
            state = {
                name: '',
                company: [],
                soundPrompt: [],
                id: '',
                destination: [],
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const getDestinationReducer = (destination = [], action) => {
    let newDestination
    switch (action.type) {
        case actionTypesApplications.getDestination:
            newDestination = [...action.destination]
            break
        default:
            newDestination = destination
            break
    }
    return newDestination
}

export const getQueueReducer = (queues = [], action) => {
    let newQueues
    switch (action.type) {
        case actionTypesApplications.getQueue:
            newQueues = [...action.queues]
            break
        default:
            newQueues = queues
            break
    }
    return newQueues
}

export const queueFormReducer = (
    state = {
        id: '',
        idAstQueue: '',
        name: '',
        number: '',
        max_waiting_users: '',
        call_to_use_extensions: '',
        timeout_queue: '',
        retry: '',
        timeout_priority: '',
        companyId: { label: '', value: '' },
        musicclass: { label: '', value: '' },
        destination: { label: '', value: '', ref: '' },
        preferences: [],
        soundPrompt: { label: '', value: '' },
        strategy: { label: 'Todos a la vez', value: 1 },
        agents: [],
        agentsInactive: [],
        agentsExternal: [],
    },
    action
) => {
    let arrayPreferences = { ...state }.preferences
    let arrayAgents = { ...state }.agents
    let arrayAgentsInactive = { ...state }.agentsInactive
    let arrayAgentsExternal = { ...state }.agentsExternal
    switch (action.type) {
        case actionTypesApplications.changeQueueFormName:
            state = {
                ...state,
                name: action.payload,
            }
            break
        case actionTypesApplications.changeQueueFormNumber:
            state = {
                ...state,
                number: action.payload,
            }
            break
        case actionTypesApplications.changeQueueFormMaxWaitingUsers:
            state = {
                ...state,
                max_waiting_users: action.payload,
            }
            break
        case actionTypesApplications.changeQueueFormCallToUseExtensions:
            state = {
                ...state,
                call_to_use_extensions: action.payload,
            }
            break
        case actionTypesApplications.changeQueueFormTimeoutQueue:
            state = {
                ...state,
                timeout_queue: action.payload,
            }
            break
        case actionTypesApplications.changeQueueFormRetry:
            state = {
                ...state,
                retry: action.payload,
            }
            break
        case actionTypesApplications.changeQueueFormTimeoutPriority:
            state = {
                ...state,
                timeout_priority: action.payload,
            }
            break
        case actionTypesApplications.addQueueFormCompanyId:
            state = {
                ...state,
                companyId: {
                    label: action.payload.label,
                    value: action.payload.value,
                },
            }
            break
        case actionTypesApplications.addQueueFormMusicClass:
            state = {
                ...state,
                musicclass: {
                    label: action.payload.label,
                    value: action.payload.value,
                },
            }
            break

        case actionTypesApplications.addQueueFormDestination:
            state = {
                ...state,
                destination: {
                    label: action.payload.label,
                    value: action.payload.value,
                    ref: action.payload.ref,
                },
            }
            break

        case actionTypesApplications.addQueueFormPreferences:
            let index = arrayPreferences.map((p) => p?.value).indexOf(action.payload?.value)

            if (index > -1) {
                arrayPreferences.splice(index, 1)
            } else {
                arrayPreferences.push(action.payload)
            }
            state = {
                ...state,
                record: arrayPreferences,
            }
            break

        case actionTypesApplications.addQueueFormSoundPrompt:
            state = {
                ...state,
                soundPrompt: {
                    label: action.payload.label,
                    value: action.payload.value,
                },
            }
            break

        case actionTypesApplications.addQueueFormStrategy:
            state = {
                ...state,
                strategy: {
                    label: action.payload.label,
                    value: action.payload.value,
                },
            }
            break

        case actionTypesApplications.addQueueFormAgents:
            state = {
                ...state,
                agents: action.payload,
            }
            break

        case actionTypesApplications.changeQueueFormAgentIsActive:
            let indexAgents = arrayAgentsInactive.map((p) => p?.id).indexOf(action.payload?.id)

            if (indexAgents > -1) {
                arrayAgentsInactive.splice(indexAgents, 1)
            } else {
                arrayAgentsInactive.push(action.payload)
            }
            state = {
                ...state,
                agentsInactive: arrayAgentsInactive,
            }
            break
        case actionTypesApplications.changeQueueFormAgentIsExternal:
            let indexAgentsExternal = arrayAgentsExternal.map((p) => p?.id).indexOf(action.payload?.id)

            if (indexAgentsExternal > -1) {
                arrayAgentsExternal.splice(indexAgentsExternal, 1)
            } else {
                arrayAgentsExternal.push(action.payload)
            }
            state = {
                ...state,
                agentsExternal: arrayAgentsExternal,
            }
            break

        case actionTypesApplications.removeQueueFormAgentDelete:
            let iExternal = arrayAgentsExternal.map((p) => p.id).indexOf(action.payload?.id)
            let iAgent = arrayAgents.map((p) => p.id).indexOf(action.payload?.id)

            if (iExternal > -1) {
                arrayAgentsExternal.splice(iExternal, 1)
            }
            if (iAgent > -1) {
                arrayAgents.splice(iAgent, 1)
            }

            state = {
                ...state,
                agentsExternal: arrayAgentsExternal,
                agents: arrayAgents,
            }
            break

        case actionTypesApplications.clearQueueFormCompanyId:
            state = {
                ...state,
                companyId: {
                    label: '',
                    value: '',
                },
            }
            break

        case actionTypesApplications.clearQueueFormMusicClass:
            state = {
                ...state,
                musicclass: {
                    label: '',
                    value: '',
                },
            }
            break

        case actionTypesApplications.clearQueueFormDestination:
            state = {
                ...state,
                destination: {
                    label: '',
                    value: '',
                    ref: '',
                },
            }
            break

        case actionTypesApplications.changeQueueFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break

        case actionTypesApplications.changeQueueFormIdAstQueue:
            state = {
                ...state,
                idAstQueue: action.payload,
            }
            break
        case actionTypesApplications.clearQueueForm:
            state = {
                id: '',
                idAstQueue: '',
                name: '',
                number: '',
                max_waiting_users: '',
                call_to_use_extensions: '',
                timeout_queue: '',
                retry: '',
                timeout_priority: '',
                companyId: { label: '', value: '' },
                musicclass: { label: '', value: '' },
                destination: { label: '', value: '', ref: '' },
                preferences: [],
                soundPrompt: { label: '', value: '' },
                strategy: { label: 'Todos a la vez', value: 1 },
                agents: [],
                agentsInactive: [],
                agentsExternal: [],
            }
            break

        default:
            state = { ...state }
            break
    }
    return state
}
