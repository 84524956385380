import { actionsTypes } from './actionsTypes'

// CRUD Numbering List

export const getNumberingListAction = (arrayNumbering) => {
    return {
        type: actionsTypes.getNumberingList,
        arrayNumbering,
    }
}

export const cleanGetNumberingListAction = () => {
    return {
        type: actionsTypes.cleanGetNumberingList,
    }
}

export const createNumberingListAction = (payload) => {
    return {
        type: actionsTypes.createNumberingList,
        payload,
    }
}
export const updateNumberingListAction = (payload) => {
    return {
        type: actionsTypes.updateNumberingList,
        payload,
    }
}
export const deleteNumberingListAction = (payload) => {
    return {
        type: actionsTypes.deleteNumberingList,
        payload,
    }
}

export const importFileNumberingListAction = (file) => {
    return {
        type: actionsTypes.importFileNumberingList,
        file,
    }
}

export const addNumberingListCalendarAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListCalendar,
        payload,
    }
}

export const addNumberingListScheduleAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListSchedule,
        payload,
    }
}

export const addNumberingListSoundPromptAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListSoundPrompt,
        payload,
    }
}

export const addNumberingListDestinationAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListDestination,
        payload,
    }
}

export const addNumberingListSoundPromptCloseAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListSoundPromptClose,
        payload,
    }
}

export const addNumberingListDestinationCloseAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListDestinationClose,
        payload,
    }
}

export const addDestinationCalendarAction = (payload) => {
    return {
        type: actionsTypes.addDestinationCalendar,
        payload,
    }
}

export const removeDestinationCalendarAction = (payload) => {
    return {
        type: actionsTypes.removeDestinationCalendar,
        payload,
    }
}

export const addDestinationOpenAction = (payload) => {
    return {
        type: actionsTypes.addDestinationOpen,
        payload,
    }
}

export const getDdiLinkAction = (payload) => {
    return {
        type: actionsTypes.getDdiLink,
        payload,
    }
}

//CRUD Extension

export const getExtensionAction = (arrayNumbering) => {
    return {
        type: actionsTypes.getExtension,
        arrayNumbering,
    }
}

export const createExtensionAction = (payload) => {
    return {
        type: actionsTypes.createExtension,
        payload,
    }
}

export const updateExtensionAction = (payload) => {
    return {
        type: actionsTypes.updateExtension,
        payload,
    }
}
export const deleteExtensionAction = (payload) => {
    return {
        type: actionsTypes.deleteExtension,
        payload,
    }
}

export const importFileExtensionAction = (file) => {
    return {
        type: actionsTypes.importFileExtension,
        file,
    }
}

// Form Extensions

export const changeExtensionIdAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionId,
        payload,
    }
}
export const changeExtensionNumberAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionNumber,
        payload,
    }
}

export const changeExtensionNameAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionName,
        payload,
    }
}

export const changeExtensionPasswordAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionPassword,
        payload,
    }
}

export const changeExtensionGroupAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionGroup,
        payload,
    }
}

export const changeExtensionRingTimeoutAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionRingTimeout,
        payload,
    }
}

export const addExtensionIdentificationAction = (payload) => {
    return {
        type: actionsTypes.addExtensionIdentification,
        payload,
    }
}

export const removeExtensionIdentificationAction = (payload) => {
    return {
        type: actionsTypes.removeExtensionIdentification,
        payload,
    }
}
export const addTypeExtensionAction = (payload) => {
    return {
        type: actionsTypes.addTypeExtension,
        payload,
    }
}
export const removeTypeExtensionAction = (payload) => {
    return {
        type: actionsTypes.removeTypeExtension,
        payload,
    }
}

export const clearExtensionFormAction = (payload) => {
    return {
        type: actionsTypes.clearExtensionForm,
        payload,
    }
}
export const changeExtensionCallForwardAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionCallForward,
        payload,
    }
}
export const changeExtensionCallForwardNAAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionCallForwardNA,
        payload,
    }
}
export const addExtensionCompanyIdAction = (payload) => {
    return {
        type: actionsTypes.addExtensionCompanyId,
        payload,
    }
}
export const removeExtensionCompanyIdAction = (payload) => {
    return {
        type: actionsTypes.removeExtensionCompanyId,
        payload,
    }
}

export const addExtensionDepartmentAction = (payload) => {
    return {
        type: actionsTypes.addExtensionDepartment,
        payload,
    }
}
export const addExtensionRecordAction = (payload) => {
    return {
        type: actionsTypes.addExtensionRecord,
        payload,
    }
}

export const addExtensionPikedGroupAction = (payload) => {
    return {
        type: actionsTypes.addExtensionPikedGroup,
        payload,
    }
}
export const removeExtensionPikedGroupAction = (payload) => {
    return {
        type: actionsTypes.removeExtensionPikedGroup,
        payload,
    }
}

export const clearExtensionsPikedGroupAction = (payload) => {
    return {
        type: actionsTypes.clearExtensionsPikedGroup,
        payload,
    }
}

export const changeExtensionVoicemailActiveAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionVoicemailActive,
        payload,
    }
}

export const addExtensionCategoriesAction = (payload) => {
    return {
        type: actionsTypes.addExtensionCategories,
        payload,
    }
}

export const changeExtensionCallWaitingAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionCallWaiting,
        payload,
    }
}

export const addExtensionAllowAction = (payload) => {
    return {
        type: actionsTypes.addExtensionAllow,
        payload,
    }
}

export const changeExtensionSipPeerIdAction = (payload) => {
    return {
        type: actionsTypes.changeExtensionSipPeerId,
        payload,
    }
}

export const clearExtensionAllowAction = (payload) => {
    return {
        type: actionsTypes.clearExtensionAllow,
        payload,
    }
}

export const removeExtensionDepartmentAction = () => {
    return {
        type: actionsTypes.removeExtensionDepartment,
    }
}

export const removeExtensionCategoriesAction = () => {
    return {
        type: actionsTypes.removeExtensionCategories,
    }
}

// Form Numbering List
export const changeNumberingListIdAction = (payload) => {
    return {
        type: actionsTypes.changeNumberingListId,
        payload,
    }
}
export const changeNumberingListNumberAction = (payload) => {
    return {
        type: actionsTypes.changeNumberingListNumber,
        payload,
    }
}

export const changeNumberingListNameAction = (payload) => {
    return {
        type: actionsTypes.changeNumberingListName,
        payload,
    }
}

export const addNumberingListHqAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListHq,
        payload,
    }
}

export const removeNumberingListHqAction = (payload) => {
    return {
        type: actionsTypes.removeNumberingListHq,
        payload,
    }
}

export const clearNumberingListFormAction = (payload) => {
    return {
        type: actionsTypes.clearNumberingListForm,
        payload,
    }
}

export const addNumberingListCompanyIdAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListCompanyId,
        payload,
    }
}

export const removeNumberingListCompanyIdAction = (payload) => {
    return {
        type: actionsTypes.removeNumberingListCompanyId,
        payload,
    }
}

export const addNumberingListDepartmentAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListDepartment,
        payload,
    }
}

export const addNumberingListRecordAction = (payload) => {
    return {
        type: actionsTypes.addNumberingListRecord,
        payload,
    }
}

export const clearNumberingListCalendarAction = (payload) => {
    return {
        type: actionsTypes.clearNumberingListCalendar,
        payload,
    }
}

export const clearNumberingListScheduleAction = (payload) => {
    return {
        type: actionsTypes.clearNumberingListSchedule,
        payload,
    }
}

export const clearNumberingListSoundPromptAction = (payload) => {
    return {
        type: actionsTypes.clearNumberingListSoundPrompt,
        payload,
    }
}

export const clearNumberingListDestinationAction = (payload) => {
    return {
        type: actionsTypes.clearNumberingListDestination,
        payload,
    }
}

export const clearNumberingListSoundPromptCloseAction = (payload) => {
    return {
        type: actionsTypes.clearNumberingListSoundPromptClose,
        payload,
    }
}

export const clearNumberingListDestinationCloseAction = (payload) => {
    return {
        type: actionsTypes.clearNumberingListDestinationClose,
        payload,
    }
}

export const clearNumberingListCompanyIdAction = (payload) => {
    return {
        type: actionsTypes.clearNumberingListCompanyId,
        payload,
    }
}

export const clearListNumberingDepartmentAction = (payload) => {
    return {
        type: actionsTypes.clearListNumberingDepartment,
        payload,
    }
}

export const removeDestinationOpenAction = (payload) => {
    return {
        type: actionsTypes.removeDestinationOpen,
        payload,
    }
}
