import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const NotFoundWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr 350px 1fr;

    .contain-notFound {
        grid-column-start: 2;
        grid-row-start: 2;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 34px;
        justify-items: center;
    }

    .contain-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: ${colors.secondaryColor};
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contain-title {
        align-self: center;
        text-align: center;

        h4 {
            margin: 0;
            font-size: ${newFontSize.h2};
            font-weight: ${weight.medium};
            color: ${colors.primaryColor};
        }
    }
    .contain-subtitle {
        align-self: start;
        text-align: center;

        p {
            margin: 0;
            font-size: ${newFontSize.h3};
            font-weight: ${weight.medium};
            color: ${colors.black};
        }
    }
    .contain-button {
        justify-self: stretch;
    }
`
