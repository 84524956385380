import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { utils, writeFileXLSX } from 'xlsx'

import { MyImportFile } from '../MyImportFile/MyImportFile'

import { DropDownWrapper, OptionsWrapper, Wrapper } from './DropDown.style'

import { useSelect } from '../../hooks/useSelect'
import { colors } from '../../theme/colors'

export const DropDown = ({
    data,
    menuButtonsDropDown,
    // selectedId,
    // setSelectedId,
    layout,
    Icon,
    text,
    backgroundColor,
    width,
    backgroundColorHover,
    colorHover,
    iconColor,
    iconHover,
    direction,
    position,
    importFile,
    refContainer,
    id,
    isFilter,
    border = '0px',
    height = '100%',
    borderHover = '0px',
    color = colors.white,
}) => {
    const { optionTemplate } = useSelect()

    const variants = {
        initial: {
            opacity: 1,

            transition: { ease: 'easeInOut', duration: 0.3 },
        },
        enter: {
            opacity: 1,
            transition: { ease: 'easeInOut', duration: 0.3 },
        },
        exit: {
            opacity: 0,
            transition: { ease: 'easeInOut', duration: 0.3 },
        },
    }

    // const generatePDF = () => {
    //     const data = menuButtonsDropDown[0]?.buttons[2].data[0]
    //     const unit = 'pt'
    //     const size = 'A4' // Use A1, A2, A3 or A4
    //     const orientation = 'portrait' // portrait or landscape

    //     const marginLeft = 40
    //     const doc = new JsPDF(orientation, unit, size)

    //     doc.setFontSize(15)

    //     const title = 'My Awesome Report'
    //     const headers = []
    //     const body = []

    //     const headersData = Object.keys(data)
    //     headers.push(headersData)

    //     const bodyData = Object.values(data)
    //     body.push(bodyData)

    //     //[['NAME', 'PROFESSION']]

    //     //const data = this.state.people.map((elt) => [elt.name, elt.profession])

    //     let content = {
    //         startY: 50,
    //         head: headers,
    //         body: body,
    //     }

    //     doc.text(title, marginLeft, 40)
    //     autoTable(doc, { content })
    //     doc.save('report.pdf')

    //     // console.log('aqui')
    //     // const pdf = new JsPDF('p', 'pt', 'letter')
    //     // pdf.html(document.querySelector('#table')).then(() => {
    //     //     pdf.setFont('helvetica')
    //     //     // pdf.setFontType('bold')
    //     //     pdf.setFontSize(8)
    //     //     pdf.save('table.pdf')
    //     // })
    // }

    const handleOnExportXLS = (data) => {
        const ws = utils.json_to_sheet(data)
        var wsCols = []
        let lengthData = Object.keys(data[0]).length
        for (let i = 0; i < lengthData; ++i) {
            wsCols.push({ wch: 25 })
        }
        ws['!cols'] = wsCols
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Data')
        writeFileXLSX(wb, 'MyExcel.xlsx')
    }

    const handleExportTemplate = (data) => {
        const optionsTemplate = optionTemplate()
        const ws = utils.json_to_sheet(data)
        const optWs = utils.json_to_sheet(optionsTemplate)
        var wsCols = []
        var optWsCols = []
        let lengthData = Object.keys(data[0]).length
        let lengthOptions = Object.keys(optionsTemplate[0]).length
        for (let i = 0; i < lengthData; ++i) {
            wsCols.push({ wch: 20 })
        }
        for (let i = 0; i < lengthOptions; ++i) {
            optWsCols.push({ wch: 20 })
        }
        ws['!cols'] = wsCols
        optWs['!cols'] = optWsCols
        const wb = utils.book_new()
        const optWb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Data')
        utils.book_append_sheet(optWb, optWs, 'Option')
        writeFileXLSX(wb, 'templateTable.xlsx')
        writeFileXLSX(optWb, 'option.xlsx')
    }

    const openOptions = (id) => {
        setSelectedId(id)
    }

    const [selectedId, setSelectedId] = useState('')

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (refContainer.current && !refContainer.current.contains(event.target)) {
                setSelectedId('')
            }
        }

        document.addEventListener('click', handleClickOutside)
        return () => document.removeEventListener('click', handleClickOutside)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Wrapper
            id={id}
            backgroundColor={selectedId === 1 ? backgroundColorHover : backgroundColor}
            width={width}
            backgroundColorHover={backgroundColorHover}
            colorHover={colorHover}
            iconHover={iconHover}
            ref={refContainer}
            className={layout}
            iconColor={selectedId === 1 ? iconHover : iconColor}
            border={border}
            height={height}
            borderHover={borderHover}
            color={selectedId === 1 ? colorHover : color}
        >
            {selectedId === '' ? (
                <DropDownWrapper onClick={() => openOptions(data.id)} layout={layout} animate={'enter'} exit={'exit'} variants={variants} initial={'initial'}>
                    {Icon && <Icon className="icon-dropDown" height={18} />}
                    {/* <img className="icon-dropDown" src={icon.icon} alt={icon.alt}></img> */}
                    {text && <p className="title-dropDown">{text}</p>}
                </DropDownWrapper>
            ) : selectedId === data.id ? (
                <DropDownWrapper onClick={() => openOptions('')} layout={layout} ref={refContainer}>
                    {Icon && <Icon className="icon-dropDown" height={18} />}
                    {/* {icon && <img className="icon-dropDown" src={icon.icon} alt={icon.alt}></img>} */}
                    {text && <p className="title-dropDown">{text}</p>}
                    <motion.div key={data.id} animate={'enter'} exit={'exit'} variants={variants} initial={'initial'}>
                        <OptionsWrapper direction={direction} position={position}>
                            {menuButtonsDropDown.map((item) =>
                                !item.active ? (
                                    ''
                                ) : item.header ? (
                                    <div className="contain__option" key={item.id}>
                                        <div className="title">
                                            <img src={item.icon} alt={item.header} />
                                            <h5 className="contain__title">{item.header}</h5>
                                        </div>
                                        {item.buttons.map((item2) =>
                                            item2.name === 'CSV' && item.header === 'Exportar' ? (
                                                <CSVLink
                                                    data={item2.data}
                                                    header={item2.headers}
                                                    key={item2.id}
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <p className="contain__name">{item2.name}</p>
                                                </CSVLink>
                                            ) : item2.name === 'PDF' && item.header === 'Exportar' ? (
                                                <div
                                                    key={item2.id}
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                    }}
                                                    onClick={() =>
                                                        // (generatePDF())
                                                        () =>
                                                            ''}
                                                >
                                                    <span className="contain__name">{item2.name}</span>
                                                </div>
                                            ) : item2.name === 'XLS' && item.header === 'Exportar' ? (
                                                <div
                                                    key={item2.id}
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                    }}
                                                    onClick={() => handleOnExportXLS(item2.data)}
                                                >
                                                    <span className="contain__name">{isFilter?.active ? 'Exportar Filtro' : item2.name}</span>
                                                </div>
                                            ) : item2.name === 'XLS' && item.header === 'Importar' ? (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                    }}
                                                    key={item2.id}
                                                >
                                                    <div className="contain__name">
                                                        <MyImportFile onChange={importFile} text={item2.name} />
                                                    </div>

                                                    {/* <MyDropzone
                                                        onChange={handleChange}
                                                    /> */}

                                                    {/* <input
                                                        type="file"
                                                        name="file"
                                                        id="inputGroupFile"
                                                        className="custom-file-input"
                                                        required
                                                        onChange={handleImport}
                                                        accept=".csv,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    /> */}
                                                </div>
                                            ) : item2.name === 'Descargar plantilla' && item.header === 'Importar' && item.active ? (
                                                <div
                                                    key={item2.id}
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                    }}
                                                    onClick={() => handleExportTemplate(item2.data, item2.optionsTemplate)}
                                                >
                                                    <span className="contain__name">{item2.name}</span>
                                                </div>
                                            ) : null
                                        )}
                                    </div>
                                ) : (
                                    <div key={item.id} className="contain__option-table" onClick={() => item.function(data)}>
                                        <span className="contain__name"> {item.name}</span>
                                    </div>
                                )
                            )}
                        </OptionsWrapper>
                    </motion.div>
                </DropDownWrapper>
            ) : (
                <DropDownWrapper onClick={() => openOptions(data.id)} layout={layout} animate={'enter'} exit={'exit'} variants={variants} initial={'initial'}>
                    {Icon && <Icon className="icon-dropDown" height={18} />}
                    {/* {icon && <img className="icon-dropDown" src={icon.icon} alt={icon.alt}></img>} */}
                    {text && <p className="title-dropDown">{text}</p>}
                </DropDownWrapper>
            )}
        </Wrapper>
    )
}
