import Axios from 'axios'

import { getCategoriesAction, getCategoriesGroupAction, getCategoriesLinkAction, getOutboundRoutesTrunksAction } from '../actions/outboundRoutes/actionCreators'

const urlApi = process.env.REACT_APP_URL

export const getOutboundRoutesGetTrunksThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const trunks = await Axios.get(`${urlApi}outboundRoutes/trunks`)
        dispatch(getOutboundRoutesTrunksAction(trunks.data))
    } catch (error) {
        return error
    } finally {
        setLoading(false)
    }
}

export const createOutboundRoutesTrunksThunk = (trunks, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.post(`${urlApi}outboundRoutes/trunks/create`, {
            data: trunks,
        })
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

//update only toggle register
export const updateOutboundRoutesTrunksRegisterThunk = (register, setLoading) => async () => {
    try {
        var response = await Axios.patch(`${urlApi}outboundRoutes/trunks/updateRegister`, {
            data: register,
        })
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

//update all fields
export const updateOutboundRoutesTrunksThunk = (trunks, setLoading) => async () => {
    try {
        var response = await Axios.patch(`${urlApi}outboundRoutes/trunks/update`, {
            data: trunks,
        })
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteOutboundRoutesTrunksThunk = (trunks, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.delete(`${urlApi}outboundRoutes/trunks/delete`, {
            data: trunks,
        })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const uploadFileTrunksThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}outboundRoutes/trunks/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

//categoriesGroup thunks
export const getCategoriesGroupAndLinkThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const categoriesGroup = await Axios.get(`${urlApi}outboundRoutes/categoriesGroup`)
        const categories = await Axios.get(`${urlApi}outboundRoutes/categoriesLink`)
        dispatch(getCategoriesLinkAction(categories.data))
        dispatch(getCategoriesGroupAction(categoriesGroup.data))
    } catch (error) {
        return error
    } finally {
        setLoading(false)
    }
}

export const createCategoriesGroupThunk = (categoriesGroup, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.post(`${urlApi}outboundRoutes/categoriesGroup/create`, {
            data: categoriesGroup,
        })
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteCategoriesGroupThunk = (categoriesGroup, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.delete(`${urlApi}outboundRoutes/categoriesGroup/delete`, {
            data: categoriesGroup,
        })

        return response
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const updateCategoriesGroupThunk = (categoriesGroup, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.patch(`${urlApi}outboundRoutes/categoriesGroup/update`, {
            data: categoriesGroup,
        })
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

//categories thunks

export const getCategoriesThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const categories = await Axios.get(`${urlApi}outboundRoutes/categories`)
        dispatch(getCategoriesAction(categories.data))
    } catch (error) {
        return error
    } finally {
        setLoading(false)
    }
}

export const createCategoriesThunk = (newCategory, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.post(`${urlApi}outboundRoutes/categories/create`, {
            data: newCategory,
        })
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const updateCategoryThunk = (category, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.patch(`${urlApi}outboundRoutes/categories/update`, {
            data: category,
        })
    } catch (err) {
        return err
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteCategoryThunk = (category, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.delete(`${urlApi}outboundRoutes/categories/delete`, {
            data: category,
        })
    } catch (err) {
        return err
    } finally {
        return response
    }
}

export const uploadFileCategoryThunk = (file, setLoading) => async () => {
    setLoading(true)
    const data = new FormData()
    data.append('file', file[0])

    try {
        var response = await Axios.post(`${urlApi}outboundRoutes/categories/uploadFile`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    } catch (err) {
        return err
    } finally {
        return response
    }
}
