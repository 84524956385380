import { useRef, useState } from 'react'

import { GeneralScreenWrapper } from './GeneralScreen.style'

import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../components/TableInfo/TableInfo'

export const GeneralScreen = () => {
    const [, setIsBottom] = useState(false)
    const tableInstance = useRef(null)

    const COLUMNS = [
        {
            Header: 'Fecha',
            accessor: 'date',
        },
        {
            Header: 'Tipo',
            accessor: 'type',
        },
        {
            Header: 'Origen',
            accessor: 'origin',
        },
        {
            Header: 'Ext Origen',
            accessor: 'extOrigin',
        },
        {
            Header: 'Destino',
            accessor: 'destination',
        },
        {
            Header: 'Ext Destino',
            accessor: 'extDestination',
        },
        {
            Header: 'Sede',
            accessor: 'site',
        },
        {
            Header: 'Dpto',
            accessor: 'department',
        },
        {
            Header: 'Duración',
            accessor: 'duration',
        },
        {
            Header: 'Estado',
            accessor: 'status',
        },
    ]

    const DATA = [
        {
            id: '123456',
            date: '2021-01-01',
            type: 'Inbound',
            origin: '123456',
            extOrigin: '12',
            destination: '123456',
            extDestination: '20',
            site: 'CAP',
            department: 'Cardiologia',
            duration: '20s',
            status: '2',
        },
        {
            id: '324324',
            date: '2021-01-01',
            type: 'Inbound',
            origin: '123456',
            extOrigin: '14',
            destination: '123456',
            extDestination: '30',
            site: 'Hospital',
            department: 'Pediatría',
            duration: '10s',
            status: '1',
        },
    ]

    const handleOpenInfo = (id) => {}

    return (
        <GeneralScreenWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                    }}
                />
            </div>

            {false ? (
                <LoaderSpinner />
            ) : (
                <div className="contain__table">
                    <TableInfo
                        format={'list'}
                        ref={tableInstance}
                        DATA={DATA}
                        COLUMNS={COLUMNS}
                        updateFunction={null}
                        deleteItem={null}
                        isOptions={false}
                        onClick={handleOpenInfo}
                        setIsBottom={setIsBottom}
                        isCheckbox={false}
                    />
                </div>
            )}
        </GeneralScreenWrapper>
    )
}
