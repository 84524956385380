import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formCreateLocutions } from '../../../data/dataApplications'
import useApplications from '../../../hooks/useApplications'
import { useHeadquarter } from '../../../hooks/useHeadquarter'
import { useSwal } from '../../../hooks/useSwal'
import { routesApplications, routesWide } from '../../../navigation/routes'

export const LocutionsFormScreen = ({ crudType }) => {
    const { applicationsForm, createApplicationsLocutions, updateApplicationsLocutions, objectCreateAPPFormLocutions, getApplicationsLocutions, arrayLocutionsHook } = useApplications()

    const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter()
    const { typeSwal } = useSwal()

    const location = useLocation()

    const [loadingCounter, setLoadingCounter] = useState()
    const [dataSave, setDataSave] = useState(false)
    const [loading, setLoading] = useState(true)

    const data = formCreateLocutions

    useEffect(() => {
        getHeadquarter()
        applicationsForm({ action: 'clear' }, { form: 'locution' })

        if (crudType === 'edit') {
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.id,
                },
                {
                    form: 'locution',
                    element: 'id',
                }
            )

            applicationsForm(
                {
                    action: 'input',
                    value: location.state.description,
                },
                {
                    form: 'locution',
                    element: 'description',
                }
            )
            applicationsForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'locution',
                    element: 'headquarters',
                },
                {
                    label: location.state?.group,
                    value: String(location.state.companyId),
                }
            )
            applicationsForm(
                {
                    action: 'dropzone',
                },
                {
                    form: 'locution',
                    element: 'file',
                },
                {
                    label: location.state.filename,
                    value: String(location.state.id),
                }
            )
        }

        setLoadingCounter(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0) {
                if (!loading) return
                data.steps.map((item) => {
                    item.input[2].options = []
                    return item.input[2].cardType === 'select'
                        ? arrayHeadquarterHook.map((item2) => {
                              if (item2.name === null) item2.name = 'not defined'

                              const company = {
                                  label: item2.name,
                                  value: item2.id?.toString(),
                              }

                              const found = item.input[2].options.some((e) => e.value === company.value)
                              if (!found && company.value !== undefined) {
                                  item.input[2].options.push(company)
                                  return item
                              }
                              return item
                          })
                        : null
                })

                if (crudType === 'edit' && (objectCreateAPPFormLocutions.locutionName === '' || objectCreateAPPFormLocutions.locutionName !== location.state.description)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit' && objectCreateAPPFormLocutions.headquarters.label === location.state.group) {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'description':
                                    item2.defaultValues = objectCreateAPPFormLocutions.locutionName
                                    break

                                case 'file':
                                    item2.defaultValues = objectCreateAPPFormLocutions.file
                                    break

                                case 'headquarters':
                                    item2.options.filter((item3) => {
                                        if (item3.label === objectCreateAPPFormLocutions.headquarters.label) {
                                            return (item2.defaultValues = item3)
                                        }
                                        return false
                                    })
                                    break

                                default:
                                    break
                            }

                            return item2
                        })
                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') setLoading(false)
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCounter, crudType])

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            typeSwal('createLoader', '', applicationsForm, getApplicationsLocutions, `/${routesWide.applications}/${routesApplications.locutions}`, 'locution', createApplicationsLocutions)
        } else if (crudType === 'edit') {
            typeSwal('createLoader', '', applicationsForm, getApplicationsLocutions, `/${routesWide.applications}/${routesApplications.locutions}`, 'locution', updateApplicationsLocutions)
        }
    }
    return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={applicationsForm} dataSave={dataSave} crudType={crudType} arrScreen={arrayLocutionsHook} />}</>
}
