import React, { useState } from 'react'
import { colors } from '../../../theme/colors'
import { Button } from '../../Button/Button'
import { OptionSelectWrapper } from './OptionSelect.style'

export const OptionSelect = ({ data, handleShowInfo, type, onChange, name, setSelectOptionName, idPage, destinationOpen, itemForm }) => {
    const [valueInput, setValueInput] = useState('')

    const handleInputInfo = (e) => {
        setValueInput({ label: e.target.value, value: 'external', name: 'Número externo' })
    }

    const onClickInput = () => {
        if (valueInput === '') return
        setSelectOptionName(valueInput)

        onChange(valueInput, name, idPage, destinationOpen, itemForm)
        handleShowInfo(false)
    }

    const onClickData = (data, name) => {
        setSelectOptionName(data)
        onChange(data, name, idPage, destinationOpen, itemForm)
    }

    return (
        <OptionSelectWrapper>
            {type === 'option' ? (
                <div className="options-select">
                    <div className="options-select__item" onMouseEnter={() => handleShowInfo(true, data)}>
                        {data.label}
                    </div>
                </div>
            ) : type === 'data' ? (
                <div className="options-select" onClick={() => onClickData(data, name)}>
                    <div className="options-select__item">{data.label}</div>
                </div>
            ) : type === 'optionData' ? (
                <div className="options-select" onClick={() => onClickData(data, name)} onMouseEnter={() => handleShowInfo(false, data)}>
                    <div className="options-select__item">{data.label}</div>
                </div>
            ) : type === 'input' ? (
                <div className="options-select">
                    <div className="options-select__input" onMouseEnter={() => handleShowInfo(true)}>
                        {data.label}
                    </div>
                </div>
            ) : type === 'inputData' ? (
                <div className="option-select__input">
                    {/* <p>Número</p> */}
                    <input type={'number'} placeholder="Escribe número" onChange={(e) => handleInputInfo(e)} />
                    <div className="option-select__input-button">
                        <Button
                            backgroundColor={colors.green}
                            colorText={colors.black}
                            fontSize={'12px'}
                            hoverBackgroundColor={colors.greenHover}
                            width={'50px'}
                            onClick={() => onClickInput()}
                            text={'+'}
                            border={'none'}
                            hoverColorText={colors.green}
                        />
                    </div>
                </div>
            ) : null}
        </OptionSelectWrapper>
    )
}
