export const actionTypesUser = {
    create: 'CREATE_USERS',
    delete: 'DELETE_USERS',
    update: 'UPDATE_USERS',
    get: 'GET_USERS',
    getUsersPermission: 'GET_USERS_PERMISSION',
    changeUserFormId: 'CHANGE_USER_FORM_ID',
    changeUserFormName: 'CHANGE_USER_FORM_NAME',
    changeUserFormFirstSurname: 'CHANGE_USER_FORM_FIRST_SURNAME',
    changeUserFormSecondSurname: 'CHANGE_USER_FORM_SECOND_SURNAME',
    changeUserFormEmail: 'CHANGE_USER_FORM_EMAIL',
    changeUserFormPassword: 'CHANGE_USER_FORM_PASSWORD',
    addUserFormRoles: 'ADD_USER_FORM_ROLES',
    removeUserFormRoles: 'REMOVE_USER_FORM_ROLES',
    clearUserForm: 'CLEAR_USER_FORM',
    clearUserFormRoles: 'CLEAR_USER_FORM_ROLES',
}
