import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import { ToggleSwitchWrapper } from './ToggleSwitch.style'
import { colors } from '../../theme/colors'

export const ToggleSwitch = ({ color, toggleSwitch, data, isActive }) => {
    const [isOn, setIsOn] = useState(false)

    useEffect(() => {
        if (isActive?.regServer === true || isActive === 1 || isActive === '1' || isActive?.record === true || isActive.active === true || isActive.external === true || isActive.callAndRecord) {
            setIsOn(true)
        } else {
            setIsOn(false)
        }
        if (isActive?.voicemailActive === true && data.ref.element === 'voicemailActive') {
            setIsOn(true)
        } else if (isActive?.callWaiting === true && data.ref.element === 'callWaiting') {
            setIsOn(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive])

    const spring = {
        type: 'spring',
        stiffness: 700,
        damping: 30,
    }

    return (
        <ToggleSwitchWrapper color={color}>
            <motion.div
                className={'switch'}
                data-ison={isOn}
                onClick={() => toggleSwitch(data, isOn, setIsOn)}
                initial={{ background: 'white' }}
                animate={isOn ? { background: color } : { background: colors.greyBorder }}
            >
                <motion.div className="handle" layout transition={spring} data-ison={isOn} />
            </motion.div>
        </ToggleSwitchWrapper>
    )
}
