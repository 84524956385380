import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formCreateIvr } from '../../../data/dataApplications'
import useApplications from '../../../hooks/useApplications'
import { useHeadquarter } from '../../../hooks/useHeadquarter'
import useNumbering from '../../../hooks/useNumbering'
import { useSwal } from '../../../hooks/useSwal'
import { routesApplications, routesWide } from '../../../navigation/routes'
import useEM from '../../../hooks/useEM'

export const IvrFormScreen = ({ crudType }) => {
    const {
        applicationsForm,
        getIvr,
        ivr,
        getApplicationsLocutions,
        arrayLocutionsHook: soundPrompt,
        createIvr,
        updateIvr,
        ivrForm,
        getDestination,
        destination,
        getConferences,
        conferences,
        getMailboxes,
        mailboxes,
    } = useApplications()

    const { arrayEMAlerts, getEMAlerts } = useEM()
    const { getExtensions, arrayNumberingExtensionsHook: extensions } = useNumbering()

    const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter()
    const { typeSwal } = useSwal()

    const location = useLocation()

    const [loadingCounter, setLoadingCounter] = useState(0)
    const [dataSave, setDataSave] = useState(false)
    const [loading, setLoading] = useState(true)

    const data = formCreateIvr

    const columnsIdDestination = [
        { destinationOneId: '', key: '1' },
        { destinationTwoId: '', key: '2' },
        { destinationThreeId: '', key: '3' },
        { destinationFourId: '', key: '4' },
        { destinationFiveId: '', key: '5' },
        { destinationSixId: '', key: '6' },
        { destinationSevenId: '', key: '7' },
        { destinationEightId: '', key: '8' },
        { destinationNineId: '', key: '9' },
        { destinationZeroId: '', key: '0' },
        { destinationTimeoutId: '', key: 'timeOut' },
        { timeOut: '', key: 'timeOut' },
    ]

    if (crudType === 'edit') {
        for (let i = 0; i < columnsIdDestination.length; i++) {
            const destination = Object.keys(columnsIdDestination[i])[0]
            if (location.state[destination] !== null) {
                columnsIdDestination[i][destination] = location.state[destination]
            }
        }

        columnsIdDestination.forEach((col) => {
            for (let i = 0; i < destination.length; i++) {
                if (
                    col.destinationOneId === destination[i].id ||
                    col.destinationTwoId === destination[i].id ||
                    col.destinationThreeId === destination[i].id ||
                    col.destinationFourId === destination[i].id ||
                    col.destinationFiveId === destination[i].id ||
                    col.destinationSixId === destination[i].id ||
                    col.destinationSevenId === destination[i].id ||
                    col.destinationEightId === destination[i].id ||
                    col.destinationNineId === destination[i].id ||
                    col.destinationZeroId === destination[i].id ||
                    col.destinationTimeoutId === destination[i].id
                ) {
                    col.destinationType = destination[i].destinationType
                    col.destinationId = destination[i].destinationId
                    break
                }
            }
        })
    }

    columnsIdDestination.forEach((obj) => {
        if (obj.destinationType === 'conference') {
            let match = conferences.find((conf) => conf.id === obj.destinationId)
            if (match) {
                obj.destinationName = match.name
                obj.ref = 'conference'
                obj.name = 'Conferencia'
            }
        } else if (obj.destinationType === 'extension') {
            let match = extensions.find((ext) => ext.id === obj.destinationId)
            if (match) {
                obj.destinationName = match.name
                obj.ref = 'extension'
                obj.name = 'Extension'
            }
        } else if (obj.destinationType === 'voicemail') {
            let match = mailboxes.find((mail) => mail.id === obj.destinationId)
            if (match) {
                obj.destinationName = match.extension
                obj.ref = 'voicemail'
                obj.name = 'Buzón de voz'
            }
        } else if (obj.destinationType === 'ivr') {
            let match = ivr.find((ivr) => ivr.id === obj.destinationId)
            if (match) {
                obj.destinationName = match.name
                obj.ref = 'ivr'
                obj.name = 'Ivr'
            }
        } else if (obj.destinationType === 'soundPrompt') {
            let match = soundPrompt.find((loc) => loc.id === obj.destinationId)
            if (match) {
                obj.destinationName = match.description
                obj.ref = 'soundPrompt'
                obj.name = 'Locución'
            }
        } else if (obj.destinationType === 'emergencyManager') {
            let match = arrayEMAlerts.find((loc) => loc.id === obj.destinationId)
            console.log(match)
            if (match) {
                obj.destinationName = match.name
                obj.ref = 'emergencyManager'
                obj.name = 'Emergency Manager'
            }
        } else if (obj.destinationType === 'hangup') {
            obj.destinationName = 'Colgar'
            obj.ref = 'hangup'
        } else if (obj.destinationType === 'external') {
            let secondProp = Object.keys(obj)[0]
            let secondPropValue = obj[secondProp]
            let match = destination.find((dest) => dest.id === secondPropValue)
            if (match) {
                obj.destinationName = match.destinationValue
                obj.ref = 'external'
                obj.name = 'Número externo'
            }
        }
    })

    useEffect(() => {
        getHeadquarter()
        getApplicationsLocutions()
        getIvr()
        getDestination()
        getConferences()
        getMailboxes()
        getExtensions()
        getEMAlerts()
        applicationsForm({ action: 'clearForm' }, { form: 'ivr' })

        if (crudType === 'edit') {
            const selectedOption = columnsIdDestination
                .filter((obj) => obj.destinationType)
                .map((obj) => ({ title: obj.key !== 'timeOut' ? obj.key : 'Si no se pulsa', label: obj.destinationName, value: obj.destinationId, ref: obj.ref }))

            const timeOut = columnsIdDestination.find((obj) => obj.timeOut)

            selectedOption.forEach((item) => {
                let action = { action: 'addDestination' }
                let ref = {
                    form: 'ivr',
                }
                let inputValue

                if (item.title === 'Si no se pulsa') {
                    inputValue = {
                        item: {
                            label: item.label,
                            value: item.value,
                            ref: item.ref,
                            timeOut: {
                                label: timeOut?.timeOut,
                                value: timeOut?.timeOut,
                                ref: timeOut?.key,
                            },
                        },
                        value: item.title,
                    }
                } else {
                    inputValue = {
                        item: {
                            label: item.label,
                            value: item.ref === 'external' ? item.label : item.value,
                            ref: item.ref,
                        },
                        value: item.title,
                    }
                }
                applicationsForm(action, ref, inputValue)
            })

            applicationsForm(
                {
                    action: 'input',
                    value: location.state.id,
                },
                {
                    form: 'ivr',
                    element: 'id',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.name,
                },
                {
                    form: 'ivr',
                    element: 'name',
                }
            )
            applicationsForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'ivr',
                    element: 'company',
                },
                {
                    label: location.state?.company,
                    value: String(location.state?.companyId),
                }
            )
            applicationsForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'ivr',
                    element: 'soundPrompt',
                },
                {
                    label: location.state?.soundprompt,
                    value: String(location.state?.soundpromptId),
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCounter])

    useEffect(() => {
        setTimeout(() => {
            if (arrayHeadquarterHook.length > 0 && soundPrompt.length > 0) {
                data.steps.map((item) => {
                    item.input.map((item2) => {
                        if (item2.ref.element === 'company') {
                            arrayHeadquarterHook.forEach((item3) => {
                                if (item3.name === null) {
                                    item3.name = 'not defined'
                                }
                                const company = {
                                    label: item3.name,
                                    value: item3.id?.toString(),
                                }
                                const found = item2.options.some((e) => e.value === company.value)
                                if (!found && company.value !== undefined) {
                                    item2.options.push(company)
                                }
                            })
                        }
                        if (item2.ref.element === 'soundPrompt') {
                            soundPrompt.forEach((item3) => {
                                if (item3.description === null) {
                                    item3.description = 'not defined'
                                }
                                const locution = {
                                    label: item3.description,
                                    value: item3.id?.toString(),
                                }
                                const found = item2.options.some((e) => e.value === locution.value)
                                if (!found && locution.value !== undefined) {
                                    item2.options.push(locution)
                                }
                            })
                        }

                        return item2
                    })
                    return item
                })
                if (crudType === 'edit' && (ivrForm.name === '' || ivrForm.name !== location.state.name || ivrForm.destination.length === 0)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit') {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'name':
                                    item2.defaultValues = ivrForm.name
                                    break
                                case 'company':
                                    item2.options.filter((item3) => {
                                        if (item3.value === ivrForm.company.value) {
                                            return (item2.defaultValues = item3)
                                        }
                                        return false
                                    })
                                    break
                                case 'soundPrompt':
                                    item2.options.filter((item3) => {
                                        if (item3.value === ivrForm.soundPrompt.value) {
                                            return (item2.defaultValues = item3)
                                        }
                                        return false
                                    })
                                    break
                                case 'destination':
                                    item2.defaultValues = columnsIdDestination
                                    break
                                default:
                                    break
                            }
                            return item2
                        })
                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCounter, ivrForm.destination.length])

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            typeSwal('createLoader', '', applicationsForm, getIvr, `/${routesWide.applications}/${routesApplications.ivr}`, 'ivr', createIvr)
        } else if (crudType === 'edit') {
            typeSwal('createLoader', '', applicationsForm, getIvr, `/${routesWide.applications}/${routesApplications.ivr}`, 'ivr', updateIvr)
        }
    }

    return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={applicationsForm} dataSave={dataSave} crudType={crudType} />}</>
}
