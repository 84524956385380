import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { fontFamily, newFontSize, weight } from '../../theme/fonts'

export const ModuleCardWrapper = styled.div`
    width: 257px;
    height: 198px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .module__title {
        font-family: ${fontFamily.font};
        font-size: ${newFontSize.h3};
        font-weight: ${weight.bold};
        line-height: 22px;
        color: ${colors.black};
        margin: 0 0 10px 0;

        p {
            margin: 0;
        }
    }

    .module__description {
        font-family: ${fontFamily.font};
        font-size: ${newFontSize.button};
        font-weight: ${weight.light};
        line-height: 16px;
        color: ${colors.greyText};
        width: 70%;
        text-align: center;
        margin: 0 0 10px 0;
    }
`
