import { useCallback, useState } from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux'
import {
    changeExtensionIdAction,
    changeExtensionNumberAction,
    changeExtensionNameAction,
    changeExtensionPasswordAction,
    changeExtensionRingTimeoutAction,
    addExtensionIdentificationAction,
    removeExtensionIdentificationAction,
    addTypeExtensionAction,
    removeTypeExtensionAction,
    clearExtensionFormAction,
    changeNumberingListIdAction,
    changeNumberingListNumberAction,
    changeNumberingListNameAction,
    removeNumberingListHqAction,
    clearNumberingListFormAction,
    addExtensionCompanyIdAction,
    removeExtensionCompanyIdAction,
    addExtensionRecordAction,
    addExtensionPikedGroupAction,
    removeExtensionPikedGroupAction,
    changeExtensionCallForwardAction,
    changeExtensionCallForwardNAAction,
    changeExtensionVoicemailActiveAction,
    addExtensionDepartmentAction,
    addExtensionCategoriesAction,
    changeExtensionCallWaitingAction,
    clearExtensionsPikedGroupAction,
    changeExtensionSipPeerIdAction,
    addNumberingListCompanyIdAction,
    addNumberingListDepartmentAction,
    addExtensionAllowAction,
    clearExtensionAllowAction,
    addNumberingListCalendarAction,
    removeExtensionDepartmentAction,
    removeExtensionCategoriesAction,
    addNumberingListScheduleAction,
    addNumberingListSoundPromptAction,
    addNumberingListDestinationAction,
    addNumberingListSoundPromptCloseAction,
    addNumberingListDestinationCloseAction,
    clearNumberingListScheduleAction,
    clearNumberingListCalendarAction,
    clearNumberingListSoundPromptAction,
    clearNumberingListDestinationAction,
    clearNumberingListSoundPromptCloseAction,
    clearNumberingListDestinationCloseAction,
    clearNumberingListCompanyIdAction,
    clearListNumberingDepartmentAction,
} from '../redux/actions/numbering/actionCreator'

import {
    getNumberingListThunk,
    createNumberingListThunk,
    updateNumberingListThunk,
    deleteNumberingListThunk,
    getExtensionThunk,
    createExtensionThunk,
    updateExtensionThunk,
    deleteExtensionThunk,
    changeExtensionPasswordThunk,
    getSipPasswordThunk,
    updateFileExtensionThunk,
    uploadFileListNumberingThunk,
} from '../redux/thunks/numberingThunks'

const useNumbering = () => {
    const [loading, setLoading] = useState(true)
    const { getState } = useStore()
    const dispatch = useDispatch()

    const arrayNumberingHook = useSelector((numberingArray) => numberingArray.arrayNumbering)

    const loadListNumbering = useSelector((numberingArray) => numberingArray.loadListNumberingReducer)

    const arrayNumberingExtensionsHook = useSelector((numberingArray) => numberingArray.arrayNumberingExtensions)

    const formExtensionsReducer = useSelector((dataExtension) => dataExtension.formExtensionsReducer)

    const FormListNumberingReducer = useSelector((dataListNumbering) => dataListNumbering.FormListNumberingReducer)

    const ddiLink = useSelector((selector) => selector.getDdiLinkReducer)

    const getNumberingList = useCallback(() => {
        let response = dispatch(getNumberingListThunk(setLoading))
        return response
    }, [dispatch])

    const createNumberingList = useCallback(() => {
        const listNumbering = getState()['FormListNumberingReducer']
        let response = dispatch(createNumberingListThunk(listNumbering, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const updateNumberingList = useCallback(() => {
        const payload = getState()['FormListNumberingReducer']
        let response = dispatch(updateNumberingListThunk(payload, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const deleteNumberingList = useCallback(
        (listNumbering) => {
            let response = dispatch(deleteNumberingListThunk(listNumbering, setLoading))
            return response
        },
        [dispatch]
    )

    const getExtensions = useCallback(() => {
        dispatch(getExtensionThunk(setLoading))
    }, [dispatch])

    const createExtension = useCallback(() => {
        const extension = getState()['formExtensionsReducer']
        let response = dispatch(createExtensionThunk(extension, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const updateExtensions = useCallback(() => {
        const extension = getState()['formExtensionsReducer']
        let response = dispatch(updateExtensionThunk(extension, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const deleteExtensions = useCallback(
        (extension) => {
            let response = dispatch(deleteExtensionThunk(extension, setLoading))
            return response
        },
        [dispatch]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const listNumberingForm = useCallback((arg, ref, inputValue) => {
        if (arg.action) {
            switch (ref.form) {
                case 'numberingList':
                    switch (arg.action) {
                        case 'clearForm':
                            dispatch(clearNumberingListFormAction())
                            break
                        case 'clear':
                            if (ref.element === 'companyId') {
                                dispatch(clearNumberingListCompanyIdAction(arg.removedValues[0]))
                            }
                            if (ref.element === 'department') {
                                dispatch(clearListNumberingDepartmentAction(arg.removedValues[0]))
                            }
                            if (ref.element === 'calendar') {
                                dispatch(clearNumberingListCalendarAction(arg.removedValues[0]))
                            }
                            if (ref.element === 'schedule') {
                                dispatch(clearNumberingListScheduleAction(arg.removedValues[0]))
                            }
                            if (ref.element === 'soundPrompt') {
                                dispatch(clearNumberingListSoundPromptAction(arg.removedValues[0]))
                            }
                            if (ref.element === 'destination') {
                                dispatch(clearNumberingListDestinationAction(arg.removedValues[0]))
                            }
                            if (ref.element === 'soundPromptClose') {
                                dispatch(clearNumberingListSoundPromptCloseAction(arg.removedValues[0]))
                            }
                            if (ref.element === 'destinationClose') {
                                dispatch(clearNumberingListDestinationCloseAction(arg.removedValues[0]))
                            }
                            break
                        case 'select-option':
                            // dispatch(addNumberingListHqAction(inputValue.value))
                            if (ref.element === 'companyId') {
                                dispatch(addNumberingListCompanyIdAction(inputValue))
                            }
                            if (ref.element === 'department') {
                                dispatch(addNumberingListDepartmentAction(inputValue))
                            }
                            if (ref.element === 'calendar') {
                                dispatch(addNumberingListCalendarAction(inputValue))
                            }
                            if (ref.element === 'schedule') {
                                dispatch(addNumberingListScheduleAction(inputValue))
                            }
                            if (ref.element === 'soundPrompt') {
                                dispatch(addNumberingListSoundPromptAction(inputValue))
                            }
                            if (ref.element === 'destination') {
                                dispatch(addNumberingListDestinationAction(inputValue))
                            }
                            if (ref.element === 'soundPromptClose') {
                                dispatch(addNumberingListSoundPromptCloseAction(inputValue))
                            }
                            if (ref.element === 'destinationClose') {
                                dispatch(addNumberingListDestinationCloseAction(inputValue))
                            }

                            break

                        case 'remove-value':
                            dispatch(removeNumberingListHqAction(arg.removedValue.value))
                            break
                        case 'input':
                            if (ref.element === 'name') {
                                dispatch(changeNumberingListNameAction(arg.value))
                            }
                            if (ref.element === 'ddi') {
                                dispatch(changeNumberingListNumberAction(arg.value))
                            }
                            break
                        case 'id':
                            if (ref.element === 'ddiId') {
                                dispatch(changeNumberingListIdAction(arg.value))
                            }
                            break

                        default:
                            break
                    }
                    break
                case 'extension':
                    switch (arg.action) {
                        case 'select-option':
                            if (ref.element === 'identification') {
                                dispatch(addExtensionIdentificationAction(inputValue))
                            }
                            if (ref.element === 'typeExtension') {
                                dispatch(addTypeExtensionAction(inputValue))
                            }
                            if (ref.element === 'companyId') {
                                dispatch(addExtensionCompanyIdAction(inputValue))
                            }
                            if (ref.element === 'record') {
                                dispatch(addExtensionRecordAction(arg.value))
                            }
                            if (ref.element === 'pikedGroup') {
                                dispatch(addExtensionPikedGroupAction(inputValue))
                            }
                            if (ref.element === 'allow') {
                                dispatch(addExtensionAllowAction(inputValue))
                            }
                            // if (ref.element === 'voicemailActive') {
                            //     dispatch(
                            //         changeExtensionVoicemailActiveAction(
                            //             arg.value.value
                            //         )
                            //     )
                            // }
                            // if (ref.element === 'callWaiting') {
                            //     dispatch(
                            //         changeExtensionCallWaitingAction(
                            //             arg.value.value
                            //         )
                            //     )
                            // }
                            if (ref.element === 'department') {
                                dispatch(addExtensionDepartmentAction(inputValue))
                            }
                            if (ref.element === 'categories') {
                                dispatch(addExtensionCategoriesAction(inputValue))
                            }

                            break
                        case 'toggle':
                            if (ref.element === 'voicemailActive') {
                                dispatch(changeExtensionVoicemailActiveAction(arg.value))
                            }
                            if (ref.element === 'callWaiting') {
                                dispatch(changeExtensionCallWaitingAction(arg.value))
                            }
                            break

                        case 'remove-value':
                            if (ref.element === 'identification') {
                                dispatch(removeExtensionIdentificationAction(arg.removedValue.value))
                            }
                            if (ref.element === 'typeExtension') {
                                dispatch(removeTypeExtensionAction(arg.removedValue.value))
                            }
                            if (ref.element === 'companyId') {
                                dispatch(removeExtensionCompanyIdAction(arg.removedValue.value))
                            }

                            if (ref.element === 'pikedGroup') {
                                dispatch(removeExtensionPikedGroupAction(arg.removedValue.value))
                            }
                            if (ref.element === 'allow') {
                                dispatch(addExtensionAllowAction(inputValue))
                            }

                            break
                        case 'input':
                            if (ref.element === 'name') {
                                dispatch(changeExtensionNameAction(arg.value))
                            }
                            if (ref.element === 'extension') {
                                dispatch(changeExtensionNumberAction(arg.value))
                            }
                            if (ref.element === 'password') {
                                dispatch(changeExtensionPasswordAction(arg.value))
                            }
                            if (ref.element === 'ringTimeout') {
                                dispatch(changeExtensionRingTimeoutAction(arg.value))
                            }
                            if (ref.element === 'id') {
                                dispatch(changeExtensionIdAction(arg.value))
                            }
                            if (ref.element === 'callForward') {
                                dispatch(changeExtensionCallForwardAction(arg.value))
                            }
                            if (ref.element === 'callForwardNA') {
                                dispatch(changeExtensionCallForwardNAAction(arg.value))
                            }
                            if (ref.element === 'sipPeerId') {
                                dispatch(changeExtensionSipPeerIdAction(arg.value))
                            }

                            break
                        case 'clearForm':
                            dispatch(clearExtensionFormAction())
                            break
                        case 'clear':
                            if (ref.element === 'pikedGroup') {
                                dispatch(clearExtensionsPikedGroupAction())
                            }
                            if (ref.element === 'allow') {
                                dispatch(clearExtensionAllowAction())
                            }
                            if (ref.element === 'identification') {
                                dispatch(removeExtensionIdentificationAction())
                            }
                            if (ref.element === 'typeExtension') {
                                dispatch(removeTypeExtensionAction())
                            }
                            if (ref.element === 'companyId') {
                                dispatch(removeExtensionCompanyIdAction())
                            }
                            if (ref.element === 'department') {
                                dispatch(removeExtensionDepartmentAction())
                            }
                            if (ref.element === 'categories') {
                                dispatch(removeExtensionCategoriesAction())
                            }

                            break
                        default:
                            break
                    }
                    break
                default:
                    break
            }
        }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changePassword = useCallback((newPassword, id) => {
        const newPasswordData = {
            id,
            newPassword,
        }

        const response = dispatch(changeExtensionPasswordThunk(newPasswordData))
        return response
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSipPassword = useCallback((extension) => {
        const response = getSipPasswordThunk(extension)
        return response
    })

    const updateFileExtension = useCallback(
        (file) => {
            const response = dispatch(updateFileExtensionThunk(file, setLoading))
            return response
        },
        [dispatch]
    )

    const uploadFileListNumbering = useCallback(
        (file) => {
            const response = dispatch(uploadFileListNumberingThunk(file, setLoading))
            return response
        },
        [dispatch]
    )
    return {
        arrayNumberingHook,
        loadListNumbering,
        arrayNumberingExtensionsHook,
        getNumberingList,
        createNumberingList,
        updateNumberingList,
        deleteNumberingList,
        getExtensions,
        createExtension,
        deleteExtensions,
        updateExtensions,
        formExtensionsReducer,
        listNumberingForm,
        FormListNumberingReducer,
        getState,
        changePassword,
        getSipPassword,
        updateFileExtension,
        uploadFileListNumbering,
        loading,
        ddiLink,
    }
}

export default useNumbering
