
export const actionTypesPreferences = {

    // Schedules actions
    getSchedules: "GET_SCHEDULE",

    // Form Schedules actions
    changeScheduleName: "CHANGE_SCHEDULE_NAME",
    changeScheduleId: "CHANGE_SCHEDULE_ID",
    changeSchedule: "CHANGE_SCHEDULE",
    addScheduleTimeId: "ADD_SCHEDULE_TIME_ID",
    removeScheduleTimeId: "REMOVE_SCHEDULE_TIME_ID",
    clearScheduleForm: "CLEAR_SCHEDULE_FORM",

    // Calendar actions
    getCalendar: "GET_CALENDAR",

    // Form Calendar actions
    changeCalendarName: "CHANGE_CALENDAR_NAME",
    changeCalendarColor: "CHANGE_CALENDAR_COLOR",
    changeCalendarDays: "CHANGE_CALENDAR_DAYS",
    changeCalendarLinkId: "CHANGE_CALENDAR_LINK_ID",
    addCalendarDaysId: "ADD_CALENDAR_DAYS_ID",
    clearCalendarForm: "CLEAR_CALENDAR_FORM",

}