import React, { useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import { RandomCodeGeneratorWrapper } from './RandomCodeGenerator.style'

export const RandomCodeGenerator = ({ onChange, refForm }) => {
    const [apiKey, setApiKey] = React.useState('')

    const generateApiKey = () => {
        const apiKeyRandom = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        setApiKey(apiKeyRandom)
        onChange(apiKeyRandom, refForm)
    }

    useEffect(() => {
        generateApiKey()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCopy = async () => {
        if (apiKey) {
            navigator.clipboard.writeText(apiKey)
        } else {
            navigator.clipboard.writeText(apiKey)
        }
    }
    return (
        <RandomCodeGeneratorWrapper>
            <div className="randomCodeGenerator" onClick={handleCopy} id={7} data-tooltip-delay-hide={2000}>
                <h4>APIKey</h4>
                <p>{apiKey}</p>
            </div>
            <Tooltip className="tooltip" anchorId={7} place="top" content={'¡Copiado!'} events={['click']} />
        </RandomCodeGeneratorWrapper>
    )
}
