import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { fontFamily, newFontSize, weight } from '../../theme/fonts'

export const InputWrapper = styled.div`
    width: 100%;
    .input {
        border: 1px solid ${colors.greyBorder};
        border-radius: 5px;
        font-weight: ${weight.medium};
        font-size: ${newFontSize.body};

        color: ${colors.black};
        width: 100%;
        height: 34px;
        padding: 0 0 0 10px;
        margin: 0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;

        &::placeholder {
            font-family: ${fontFamily.fontCard};
            font-weight: ${weight.light};
            font-size: ${newFontSize.placeholder};
            color: ${colors.grey};
        }

        // &:focus::placeholder {
        //     padding-left: 10px;
        //     color: transparent;
        // }

        &:focus {
            padding-left: 10px;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`
