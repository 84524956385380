import { actionsTypesHeadquarter } from './actionTypes'

export const getHeadquarterAction = (headquarters) => {
    return {
        type: actionsTypesHeadquarter.getHeadquarter,
        headquarters,
    }
}

export const getCompanyLinkAction = (companyLink) => {
    return {
        type: actionsTypesHeadquarter.getCompanyLink,
        companyLink,
    }
}

export const changeCompanyFormNameAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.changeCompanyFormName,
        payload,
    }
}

export const changeCompanyFormIdAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.changeCompanyFormId,
        payload,
    }
}

export const addCompanyFormDepartmentAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.addCompanyFormDepartment,
        payload,
    }
}

export const removeCompanyFormDepartmentAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.removeCompanyFormDepartment,
        payload,
    }
}

export const clearCompanyFormAction = () => {
    return {
        type: actionsTypesHeadquarter.clearCompanyForm,
    }
}

export const clearMultiselectCompanyFormAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.clearMultiselectCompanyForm,
        payload,
    }
}

export const changeCompanyFormIpAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.changeCompanyFormIp,
        payload,
    }
}

export const changeCompanyFormPortAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.changeCompanyFormPort,
        payload,
    }
}

export const changeCompanyFormIpBackupAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.changeCompanyFormIpBackup,
        payload,
    }
}

export const changeCompanyFormPortBackupAction = (payload) => {
    return {
        type: actionsTypesHeadquarter.changeCompanyFormPortBackup,
        payload,
    }
}
