import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formCreateMailboxes } from '../../../data/dataApplications'
import useApplications from '../../../hooks/useApplications'
import { useHeadquarter } from '../../../hooks/useHeadquarter'
import { useSwal } from '../../../hooks/useSwal'
import { routesApplications, routesWide } from '../../../navigation/routes'

export const MailboxesFormScreen = ({ crudType }) => {
    const { applicationsForm, mailboxesForm, arrayLocutionsHook, getApplicationsLocutions, createMailboxes, updateMailboxes, getMailboxes, mailboxes } = useApplications()

    const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter()
    const { typeSwal } = useSwal()

    const location = useLocation()

    const [loadingCounter, setLoadingCounter] = useState()
    const [dataSave, setDataSave] = useState(false)
    const [loading, setLoading] = useState(true)

    const data = formCreateMailboxes

    useEffect(() => {
        getHeadquarter()
        getApplicationsLocutions()
        applicationsForm({ action: 'clear' }, { form: 'mailboxes' })

        if (crudType === 'edit') {
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.id,
                },
                {
                    form: 'mailboxes',
                    element: 'id',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.astVoicemailId,
                },
                {
                    form: 'mailboxes',
                    element: 'astVoicemailId',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.extension,
                },
                {
                    form: 'mailboxes',
                    element: 'number',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.fullname,
                },
                {
                    form: 'mailboxes',
                    element: 'name',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.password,
                },
                {
                    form: 'mailboxes',
                    element: 'pin',
                }
            )
            applicationsForm(
                {
                    action: 'input',
                    value: location.state.email,
                },
                {
                    form: 'mailboxes',
                    element: 'email',
                }
            )
            applicationsForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'mailboxes',
                    element: 'company',
                },
                {
                    label: location.state?.company,
                    value: String(location.state?.companyId),
                }
            )
            applicationsForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'mailboxes',
                    element: 'soundPrompt',
                },
                {
                    label: location.state?.soundPrompt,
                    value: String(location.state.soundPromptId),
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function newPin() {
        let pin = Math.floor(100000 + Math.random() * 900000)
        mailboxes.map((item) => (item.password === pin ? newPin() : null))
        return pin
    }

    useEffect(() => {
        setTimeout(() => {
            if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0 && arrayLocutionsHook.length > 0) {
                data.steps.map((item) => {
                    item.input.map((item2) => {
                        if (item2.ref.element === 'company') {
                            arrayHeadquarterHook.forEach((item3) => {
                                if (item3.name === null) {
                                    item3.name = 'not defined'
                                }
                                const company = {
                                    label: item3.name,
                                    value: item3.id?.toString(),
                                }
                                const found = item2.options.some((e) => e.value === company.value)
                                if (!found && company.value !== undefined) {
                                    item2.options.push(company)
                                }
                            })
                        }
                        if (item2.ref.element === 'soundPrompt') {
                            arrayLocutionsHook.forEach((item3) => {
                                const soundPrompt = {
                                    label: item3.description,
                                    value: item3.id?.toString(),
                                }
                                const found = item2.options.some((e) => e.value === soundPrompt.value)
                                if (!found && soundPrompt.value !== undefined) {
                                    item2.options.push(soundPrompt)
                                }
                            })
                        }
                        if (item2.ref.element === 'pin') {
                            item2.defaultValues = newPin()
                            applicationsForm(
                                {
                                    action: 'input',
                                    value: item2.defaultValues,
                                },
                                {
                                    form: 'mailboxes',
                                    element: 'pin',
                                }
                            )
                        }
                        return item2
                    })
                    return item
                })
                if (crudType === 'edit' && (mailboxesForm.fullname === '' || mailboxesForm.name !== location.state.fullname)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit') {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'number':
                                    item2.defaultValues = mailboxesForm.number
                                    break
                                case 'name':
                                    item2.defaultValues = mailboxesForm.name
                                    break
                                case 'pin':
                                    item2.defaultValues = mailboxesForm.pin
                                    break
                                case 'email':
                                    item2.defaultValues = mailboxesForm.email
                                    break
                                case 'company':
                                    item2.options.filter((item3) => {
                                        if (item3.value === mailboxesForm.company.value) {
                                            return (item2.defaultValues = item3)
                                        }
                                        return false
                                    })
                                    break
                                case 'soundPrompt':
                                    item2.options.filter((item3) => {
                                        if (item3.value === mailboxesForm.soundPrompt.value) {
                                            return (item2.defaultValues = item3)
                                        }
                                        return false
                                    })
                                    break
                                default:
                                    break
                            }
                            return item2
                        })
                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 100)
    })

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            typeSwal('createLoader', '', applicationsForm, getMailboxes, `/${routesWide.applications}/${routesApplications.mailboxes}`, 'mailboxes', createMailboxes)
        } else if (crudType === 'edit') {
            typeSwal('createLoader', '', applicationsForm, getMailboxes, `/${routesWide.applications}/${routesApplications.mailboxes}`, 'mailboxes', updateMailboxes)
        }
    }

    return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={applicationsForm} dataSave={dataSave} crudType={crudType} />}</>
}
