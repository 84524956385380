import arrowDown from '../../assets/images/phone/arrowDown.svg'
import arrowUp from '../../assets/images/phone/arrowUp.svg'

export const inputNumberData = {
    arrowDown: {
        img: arrowDown,
        alt: 'arrowDown',
    },

    arrowUp: {
        img: arrowUp,
        alt: 'arrowUp',
    },
}
