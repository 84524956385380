import click from '../../assets/images/notFound/click.svg'
import arrowRight from '../../assets/images/outboundRoutes/arrow-forward-up.svg'
import trunksIcon from '../../assets/images/outboundRoutes/arrow-wave-right-up.svg'
import flag from '../../assets/images/outboundRoutes/flag.svg'
import groupIcon from '../../assets/images/outboundRoutes/grid-dots.svg'
import home from '../../assets/images/outboundRoutes/home.svg'
import phoneCard from '../../assets/images/outboundRoutes/phone.svg'

// Form Icons
import extensionIcon from '../../assets/images/forms/affiliate.svg'
import alarm from '../../assets/images/forms/alarm.svg'
import braces from '../../assets/images/forms/braces.svg'
import prefix from '../../assets/images/forms/phone-incoming.svg'
import code from '../../assets/images/forms/separator-vertical.svg'
import categories from '../../assets/images/forms/share.svg'
import stethoscope from '../../assets/images/forms/stethoscope.svg'
import textInput from '../../assets/images/forms/textInput.svg'
import toggle from '../../assets/images/forms/toggle-left.svg'

import { IconGridDots, IconBoxPadding, IconArrowWaveRightUp } from '@tabler/icons-react'

import { routesOutbound } from '../../navigation/routes'
import { typeRegex } from '../dataRegex'

export const menuOutbound = [
    {
        text: 'Grupo Categorías',
        path: routesOutbound.groupCategories,
        icon: IconGridDots,
        restricted: 'categories',
        id: 1,
        content: 'Mira el Grupo Categorías',
    },
    {
        text: 'Categorías',
        path: routesOutbound.categories,
        icon: IconBoxPadding,
        restricted: 'categories',
        id: 2,
        content: 'Mira las Categorías',
    },
    {
        text: 'Troncales',
        path: routesOutbound.trunks,
        icon: IconArrowWaveRightUp,
        restricted: 'trunks',
        id: 3,
        content: 'Mira las Troncales',
    },
]

export const dataScreenGroupCategories = {
    title: [
        {
            text: 'Grupo de categorias',
            icon: groupIcon,
        },
        {
            text: 'Prefijos',
            icon: flag,
        },
    ],
    option: [],
    icon: {
        flag,
        arrowRight,
        categories,
    },
    notFound: {
        icon: { img: click, alt: 'click' },

        title: 'No hay ningun grupo de categorías creado',
        subtitle: 'Empieza por configurar una nuevo grupo de categorías para visualizar en tu escritorio',
        textButton: 'Crear grupo categorías',
    },
}

export const dataScreenCategories = {
    notFound: {
        icon: { img: click, alt: 'click' },

        title: 'No hay ninguna categoría creada',
        subtitle: 'Empieza por configurar una nueva categoría para visualizar en tu escritorio',
        textButton: 'Crear categoría',
    },
    icon: {
        phoneCard,
        trunksIcon,
    },
}

export const dataScreenTrunks = {
    notFound: {
        icon: { img: click, alt: 'click' },

        title: 'No hay ninguna troncal creada',
        subtitle: 'Empieza por configurar una nueva troncal para visualizar en tu escritorio',
        textButton: 'Crear troncal',
    },
    icon: {
        code,
        home,
        alarm,
    },
}

export const formTrunks = {
    title: 'Configuración de una nueva ruta de salida',
    titleEdit: 'Edita la troncal',
    textButton: 'Crear Troncal',
    steps: [
        {
            number: '1',
            title: 'Nombre, Descripción, Sede, Contraseña, Dominio, Servidor',
            id: 1,
            nextStepText: 'Siguiente Paso',
            columns: 2,
            input: [
                {
                    cardType: 'id',
                    ref: { form: 'trunks', element: 'id' },
                },
                {
                    cardType: 'id',
                    ref: { form: 'trunks', element: 'sipPeerId' },
                },
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Nombre',
                    placeholder: 'Nombre',
                    icon: textInput,
                    ref: { form: 'trunks', element: 'name' },
                    defaultValues: null,
                    required: {
                        text: '* Nombre',
                        validation: true,
                        errorMessage: '* Puede contener letras números',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Descripción',
                    placeholder: 'Descripción',
                    icon: textInput,
                    ref: { form: 'trunks', element: 'description' },
                    defaultValues: null,
                    required: {
                        text: '* Descripción',
                        validation: true,
                        errorMessage: '* Puede contener letras números',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'select-option',
                    label: 'Sede',
                    placeholder: 'Sede',
                    icon: stethoscope,
                    ref: { form: 'trunks', element: 'companyId' },
                    options: [],
                    defaultValues: {},
                    required: {
                        text: '* Sede',
                        validation: true,
                        errorMessage: '* Sede obligatoria',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Contraseña',
                    icon: extensionIcon,
                    placeholder: 'Contraseña',
                    ref: { form: 'trunks', element: 'notPassword' },
                    defaultValues: null,
                    required: {
                        text: '* Contraseña',
                        validation: true,
                        errorMessage: '* 1 caracters mínimo',
                        regex: typeRegex.inputMinOneCharacter,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Dominio',
                    placeholder: 'Dominio',
                    icon: code,
                    ref: { form: 'trunks', element: 'fromDomain' },
                    defaultValues: null,
                    required: {
                        text: '* Dominio',
                        validation: true,
                        errorMessage: '* Dominio necesario',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Servidor',
                    placeholder: 'Servidor',
                    icon: braces,
                    ref: { form: 'trunks', element: 'server' },
                    defaultValues: null,
                    required: {
                        text: '* Servidor',
                        validation: true,
                        errorMessage: '* Debe ser un Ip válida',
                        regex: typeRegex.inputIP,
                    },
                },
            ],
        },
        {
            number: '2',
            title: 'Usuario, Port, IPADDR, Context',
            id: 2,
            nextStepText: 'Siguiente Paso',
            columns: 2,
            input: [
                {
                    layoutId: 2,
                    cardType: 'input',
                    label: 'Usuario',
                    placeholder: 'Usuario',
                    ref: { form: 'trunks', element: 'userDefault' },
                    icon: textInput,
                    defaultValues: null,
                    required: {
                        text: '* Usuario',
                        validation: true,
                        errorMessage: '* Añade usuario',
                        regex: typeRegex.inputText,
                    },
                },
                // {
                //     layoutId: 2,

                //     cardType: 'input',
                //     label: 'Prefix',
                //     icon: phoneOutgoing,
                //     placeholder: '4. Añade un outbound prefix',
                //     ref: { form: 'trunks', element: 'outboundPrefix' },
                //     defaultValues: null,
                //     required: {
                //         validation: true,
                //         errorMessage: 'Debe contener + y numeros',
                //         regex: /^(?:[+].*\d)$/,
                //     },
                // },

                {
                    layoutId: 2,
                    cardType: 'input',
                    label: 'Port',
                    placeholder: 'Port',
                    icon: textInput,
                    ref: { form: 'trunks', element: 'port' },
                    defaultValues: null,
                    required: {
                        text: '* Port',
                        validation: true,
                        errorMessage: '* Números y símbolos',
                        regex: typeRegex.inputNumber,
                    },
                },
                {
                    layoutId: 2,

                    cardType: 'toggle',
                    label: 'Activa o desactiva el registro',
                    icon: toggle,
                    ref: { form: 'trunks', element: 'regServer' },
                    defaultValues: true,
                    required: {
                        text: 'Activa o desactiva el registro',
                        validation: false,
                        errorMessage: '',
                        regex: /^/,
                    },
                },
                // {
                //     layoutId: 2,
                //     cardType: 'input',
                //     label: 'IPADDR',
                //     icon: code,
                //     placeholder: 'IPADDR',
                //     ref: { form: 'trunks', element: 'Ipaddr' },
                //     defaultValues: null,
                //     required: {
                //         text: '* IPADDR',
                //         validation: true,
                //         errorMessage: '* Añade IPADDR mínimo 3 caracteres',
                //         regex: typeRegex.inputText,
                //     },
                // },
                {
                    layoutId: 2,
                    cardType: 'input',
                    label: 'Context',
                    icon: textInput,
                    placeholder: 'Context',
                    ref: { form: 'trunks', element: 'context' },
                    defaultValues: null,
                    required: {
                        text: '* Context',
                        validation: true,
                        errorMessage: '* Añade context mínimo 3 caracteres',
                        regex: typeRegex.inputText,
                    },
                },
            ],
        },
        {
            number: '3',
            title: 'Permit, Deny, Transport, Nat, DTMF Mode, Allow,Disallow, Qualify',
            id: 3,
            nextStepText: 'Siguiente Paso',
            lastStep: true,
            input: [
                {
                    layoutId: 3,
                    cardType: 'input',
                    label: 'Permit',
                    icon: textInput,
                    placeholder: 'Permit',
                    ref: { form: 'trunks', element: 'permit' },
                    defaultValues: null,
                    required: {
                        text: 'Permit',
                        validation: true,
                        errorMessage: '* Añade Permit mínimo 3 caracteres',
                        regex: /^/,
                    },
                },
                {
                    layoutId: 3,
                    cardType: 'input',
                    label: 'Deny',
                    placeholder: 'Deny',
                    icon: textInput,
                    ref: { form: 'trunks', element: 'deny' },
                    defaultValues: null,
                    required: {
                        text: 'Deny',
                        validation: true,
                        errorMessage: '* Añade Deny mínimo 3 caracteres',
                        regex: /^/,
                    },
                },
                {
                    layoutId: 3,
                    cardType: 'select',
                    label: 'Transport',
                    placeholder: 'Transport',
                    icon: textInput,
                    ref: { form: 'trunks', element: 'transport' },
                    defaultValues: [],
                    options: [
                        { label: 'UDP', value: 'udp', id: 1 },
                        { label: 'TCP', value: 'tcp', id: 2 },
                        { label: 'UDP, TCP', value: 'udp,tcp', id: 3 },
                        { label: 'TCP, UDP', value: 'tcp,udp', id: 4 },
                    ],
                    required: {
                        text: '* Transport',
                        validation: true,
                        errorMessage: '* Añade transport ',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 3,
                    cardType: 'multiselect',
                    label: 'Nat',
                    placeholder: 'no',
                    icon: braces,
                    ref: { form: 'trunks', element: 'nat' },
                    defaultValues: [],
                    options: [
                        { label: 'Force_rport', value: 'force_rport', id: 2 },
                        { label: 'Comedia', value: 'comedia', id: 3 },
                    ],
                    required: {
                        text: 'Nat',
                        validation: true,
                        errorMessage: '',
                        regex: /^/,
                    },
                },
                {
                    layoutId: 3,
                    cardType: 'select',
                    label: 'DTMF Mode',
                    placeholder: 'DTMF Mode',
                    icon: toggle,
                    ref: { form: 'trunks', element: 'dtmfmode' },
                    defaultValues: [],
                    options: [
                        { label: 'rfc2833', value: 'rfc2833', id: 1 },
                        { label: 'Info', value: 'info', id: 2 },
                        { label: 'Shortinfo', value: 'shortinfo', id: 3 },
                        { label: 'Inband', value: 'inband', id: 4 },
                        { label: 'Auto', value: 'auto', id: 5 },
                    ],
                    required: {
                        text: 'DTMF Mode',
                        validation: false,
                        errorMessage: '* Añade el DTMF ',
                        regex: /^/,
                    },
                },
                {
                    layoutId: 3,
                    cardType: 'multiselect',
                    icon: textInput,
                    label: 'Allow',
                    placeholder: 'Allow',
                    ref: { form: 'trunks', element: 'allow' },
                    defaultValues: [],
                    options: [
                        { label: 'Allaw', value: 'alaw', id: 1 },
                        { label: 'Ulaw', value: 'ulaw', id: 2 },
                        { label: 'g729', value: 'g729', id: 3 },
                    ],
                    required: {
                        text: '* Allow',
                        validation: true,
                        errorMessage: '* Añade Allow ',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 4,
                    cardType: 'multiselect',
                    label: 'Disallow',
                    placeholder: 'All',
                    icon: textInput,
                    ref: { form: 'trunks', element: 'disallow' },
                    defaultValues: [],
                    options: [
                        { label: 'Allaw', value: 'alaw', id: 1 },
                        { label: 'Ulaw', value: 'ulaw', id: 2 },
                        { label: 'g729', value: 'g729', id: 3 },
                    ],
                    required: {
                        text: 'Disallow',
                        validation: true,
                        errorMessage: ' ',
                        regex: /^/,
                    },
                },
                {
                    layoutId: 4,
                    cardType: 'input',
                    label: 'Qualify',
                    placeholder: 'Qualify',
                    icon: textInput,
                    ref: { form: 'trunks', element: 'qualify' },
                    defaultValues: null,
                    required: {
                        text: 'Qualify',
                        validation: false,
                        errorMessage: '* Añade Qualify mínimo 3 caracteres',
                        regex: /^/,
                    },
                },
            ],
        },
    ],
}

export const optionsDTMF = [
    { label: 'rfc2833', value: 'rfc2833', id: 1 },
    { label: 'Info', value: 'info', id: 2 },
    { label: 'Shortinfo', value: 'shortinfo', id: 3 },
    { label: 'Inband', value: 'inband', id: 4 },
    { label: 'Auto', value: 'auto', id: 5 },
]

export const optionsNat = [
    { label: 'No', value: 'no', id: 1 },
    { label: 'Force_rport', value: 'force_rport', id: 2 },
    { label: 'Comedia', value: 'comedia', id: 3 },
]

export const optionsTransport = [
    { label: 'UDP', value: 'udp', id: 1 },
    { label: 'TCP', value: 'tcp', id: 2 },
    { label: 'UDP, TCP', value: 'udp,tcp', id: 3 },
    { label: 'TCP, UDP', value: 'tcp,udp', id: 4 },
]

export const optionsAllow = [
    { label: 'Allaw', value: 'alaw', id: 1 },
    { label: 'Ulaw', value: 'ulaw', id: 2 },
    { label: 'g729', value: 'g729', id: 3 },
]

export const optionsDisallow = [
    { label: 'Allaw', value: 'alaw', id: 1 },
    { label: 'Ulaw', value: 'ulaw', id: 2 },
    { label: 'g729', value: 'g729', id: 3 },
    { label: 'All', value: 'all', id: 4 },
]

export const formGroupCategories = {
    title: 'Creación de una nueva lista de grupo de categorías',
    titleEdit: 'Edita grupo de categories',
    textButton: 'Crear grupo Categorías',
    steps: [
        {
            number: '1',
            title: 'Nombre',
            id: 1,
            nextStepText: 'Siguiente Paso',
            input: [
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Nombre',
                    placeholder: 'Nombre',
                    icon: textInput,
                    ref: { form: 'groupCategories', element: 'name' },
                    defaultValues: null,
                    required: {
                        text: '* Nombre',
                        validation: true,
                        errorMessage: '* Debe tener entre 3 y 50 caracteres',
                        regex: typeRegex.inputText,
                    },
                },
            ],
        },
        {
            number: '2',
            title: 'Categorías',
            id: 2,
            nextStepText: 'Siguiente Paso',
            lastStep: true,
            input: [
                {
                    layoutId: 2,
                    cardType: 'multiselect',
                    label: 'Categoria',
                    icon: categories,
                    ref: { form: 'groupCategories', element: 'listCategories' },
                    options: [],
                    defaultValues: [],
                    required: {
                        text: '* Categoria',
                        validation: true,
                        errorMessage: '* Categoria obligatoria',
                        regex: typeRegex.inputText,
                    },
                },
            ],
        },
    ],
}

export const formCategories = {
    title: 'Creación de una nueva categoría',
    titleEdit: 'Edita la categoría',
    textButton: 'Crear Categoria',
    steps: [
        {
            number: '1',
            title: 'Nombre y Prefijo',
            id: 1,
            nextStepText: 'Siguiente Paso',
            columns: 2,
            input: [
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Nombre',
                    placeholder: 'Nombre',
                    icon: textInput,
                    ref: { form: 'categories', element: 'name' },
                    defaultValues: null,
                    required: {
                        text: '* Nombre',
                        validation: true,
                        errorMessage: '* Debe tener entre 3 y 50 caracteres',
                        regex: typeRegex.inputText,
                    },
                },
                {
                    layoutId: 1,
                    cardType: 'input',
                    label: 'Prefijo',
                    icon: prefix,
                    placeholder: 'Prefijo',
                    ref: { form: 'categories', element: 'prefix' },
                    defaultValues: null,
                    required: {
                        text: '* Prefijo',
                        validation: true,
                        errorMessage: '* Puede contener números y símbolos',
                        regex: typeRegex.inputNumber,
                    },
                },
            ],
        },
        {
            number: '2',
            title: 'Rutas Salientes, Backup',
            id: 2,
            nextStepText: 'Siguiente Paso',
            lastStep: true,
            columns: 2,
            input: [
                {
                    layoutId: 2,
                    cardType: 'select',
                    label: 'Ruta Saliente',
                    placeholder: 'Ruta Saliente',
                    icon: textInput,
                    ref: { form: 'categories', element: 'outboundRoutesId' },
                    options: [],
                    defaultValues: {},
                    required: {
                        text: 'Ruta Saliente',
                        validation: false,
                        errorMessage: '',
                        regex: /^/,
                    },
                },
                {
                    layoutId: 2,
                    cardType: 'select',
                    label: 'Ruta Saliente (Backup)',
                    placeholder: 'Ruta Saliente (Backup)',
                    icon: textInput,
                    ref: {
                        form: 'categories',
                        element: 'failOverOutboundRoutesId',
                    },
                    options: [],
                    defaultValues: {},
                    required: {
                        text: 'Ruta Saliente (Backup)',
                        validation: false,
                        errorMessage: '',
                        regex: /^/,
                    },
                },
            ],
        },
    ],
}

export const dataFilterCategories = {
    filters: [
        {
            label: 'Nombre',
            type: 'select',
            ref: 'name',
            options: [],
        },
    ],
}
