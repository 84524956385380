// trunks action creators

import { actionTypesOutboundRoutes } from './actionTypes'

export const getOutboundRoutesTrunksAction = (trunks) => {
    return {
        type: actionTypesOutboundRoutes.getTrunks,
        trunks,
    }
}

export const changeOutboundRoutesTrunksFormName = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormName,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormIpaddr = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormIpaddr,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormPort = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormPort,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormContext = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormContext,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormPermit = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormPermit,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormDeny = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormDeny,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormTransport = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormTransport,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormNat = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormNat,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormDFMDMode = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormDFMDMode,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormAllow = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormAllow,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormDisallow = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormDisallow,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormQualify = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormQualify,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormDescription = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormDescription,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormPassword = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormPassword,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormDomain = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormDomain,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormServer = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormServer,
        payload,
    }
}

export const addOutboundRoutesTrunksFormHeadquarters = (payload) => {
    return {
        type: actionTypesOutboundRoutes.addTrunksFormHeadquarters,
        payload,
    }
}

export const removeOutboundRoutesTrunksFormHeadquarters = (payload) => {
    return {
        type: actionTypesOutboundRoutes.removeTrunksFormHeadquarters,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormUser = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormUser,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormPrefix = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormPrefix,
        payload,
    }
}

export const changeOutboundRoutesTrunksFormRegister = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormRegister,
        payload,
    }
}

export const changeTrunksFormSipPeerId = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormSipPeerId,
        payload,
    }
}

export const removeTrunksFormAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.clearTrunksForm,
        payload,
    }
}

export const changeTrunksFormId = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormId,
        payload,
    }
}

export const clearTrunksFormAllowAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.clearTrunksFormAllow,
        payload,
    }
}

export const clearTrunksFormDisallowAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.clearTrunksFormDisallow,
        payload,
    }
}

export const clearTrunksFormNatAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.clearTrunksFormNat,
        payload,
    }
}

export const changeTrunksFormKamDispatcherIdAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeTrunksFormKamDispatcherId,
        payload,
    }
}

export const clearTrunksFormCompanyAction = () => {
    return {
        type: actionTypesOutboundRoutes.clearTrunksFormCompany,
    }
}

export const clearTrunksFormTransportAction = () => {
    return {
        type: actionTypesOutboundRoutes.clearTrunksFormTransport,
    }
}

export const clearTrunksFormDFMDModeAction = () => {
    return {
        type: actionTypesOutboundRoutes.clearTrunksFormDFMDMode,
    }
}

// categoriesLink action creators

export const getCategoriesLinkAction = (categories) => {
    return {
        type: actionTypesOutboundRoutes.getCategoriesLink,
        categories,
    }
}

// categoriesGroup action creators

export const getCategoriesGroupAction = (categoriesGroup) => {
    return {
        type: actionTypesOutboundRoutes.getCategoriesGroup,
        categoriesGroup,
    }
}

export const changeCategoriesGroupFormName = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeCategoriesGroupFormName,
        payload,
    }
}

export const addCategoryFormGroup = (payload) => {
    return {
        type: actionTypesOutboundRoutes.addCategoryFormGroup,
        payload,
    }
}

export const removeCategoryFormGroup = (payload) => {
    return {
        type: actionTypesOutboundRoutes.removeCategoryFormGroup,
        payload,
    }
}

export const clearCategoryFormGroup = (payload) => {
    return {
        type: actionTypesOutboundRoutes.clearCategoryFormGroup,
        payload,
    }
}

export const changeCategoriesGroupFormIdAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeCategoriesGroupFormId,
        payload,
    }
}

export const clearMultiselectCategoriesGroupFormAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.clearMultiselectCategoriesGroupForm,
        payload,
    }
}

// categories action

export const getCategoriesAction = (categories) => {
    return {
        type: actionTypesOutboundRoutes.getCategories,
        categories,
    }
}

export const changeCategoriesFormNameAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeCategoriesFormName,
        payload,
    }
}

export const changeCategoriesFormPrefixAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeCategoriesFormPrefix,
        payload,
    }
}

export const changeCategoriesFormIdAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.changeCategoriesFormId,
        payload,
    }
}

export const clearCategoriesFormAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.clearCategoriesForm,
        payload,
    }
}

export const addCategoriesOutboundRouteFormAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.addCategoriesOutboundRouteForm,
        payload,
    }
}

export const removeCategoriesOutboundRouteFormAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.removeCategoriesOutboundRouteForm,
        payload,
    }
}
export const addCategoriesOutboundRouteFailFormAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.addCategoriesOutboundRouteFailForm,
        payload,
    }
}

export const removeCategoriesOutboundRouteFailFormAction = (payload) => {
    return {
        type: actionTypesOutboundRoutes.removeCategoriesOutboundRouteFailForm,
        payload,
    }
}

export const clearCategoriesOutboundRouteFormAction = () => {
    return {
        type: actionTypesOutboundRoutes.clearCategoriesOutboundRouteForm,
    }
}

export const clearCategoriesOutboundRouteFailFormAction = () => {
    return {
        type: actionTypesOutboundRoutes.clearCategoriesOutboundRouteFailForm,
    }
}
