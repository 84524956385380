import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGird } from '../../../components/CardGrid/CardGird'
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../components/TableInfo/TableInfo'
import { dataFilterConferences, dataScreenConferencesRoom } from '../../../data/dataApplications'
import useApplications from '../../../hooks/useApplications'
import { useSwal } from '../../../hooks/useSwal'
import useView from '../../../hooks/useView'
import { routesApplications, routesWide } from '../../../navigation/routes'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'

import { ConferencesScreenWrapper } from './ConferencesScreen.style'

export const ConferenceScreen = () => {
    const { conferences, getConferences, deleteConference, uploadFileConferences, loading } = useApplications()
    const { typeSwal } = useSwal()
    const { changeView, isCardView } = useView()

    const [search, setSearch] = useState([])
    const [, setIsBottom] = useState(false)
    const [isFilter, setIsFilter] = useState({
        open: false,
        active: false,
    })

    const { notFound, icon } = dataScreenConferencesRoom

    let navigate = useNavigate()

    useEffect(() => {
        getConferences()
        setSearch([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSearch])

    const COLUMNS = [
        {
            Header: 'Nombre',
            accessor: 'name',
        },
        {
            Header: 'Número',
            accessor: 'number',
        },
        {
            Header: 'Pin',
            accessor: 'pin',
        },
        {
            Header: 'Grabar',
            accessor: 'record',
        },
        {
            Header: 'Sede',
            accessor: 'company',
        },
    ]

    const DATA = conferences.map(({ name, number, pin, id, record, companyId, Company }) => ({
        name,
        number,
        pin,
        record: record === true ? 'Si' : 'No',
        id,
        companyId,
        company: Company?.name,
    }))

    const dataExport = conferences.map(({ name, number, pin, record, Company }) => ({
        Nombre: name,
        Número: number,
        Pin: pin,
        Record: record === true ? 'Si' : 'No',
        Compañia: Company?.name,
    }))

    const dataCard = () => {
        if (search.length > 0) {
            return search.map(({ name, number, pin, record, id, companyId, company }) => ({
                title: name,
                icon: icon.users,
                subtitle: number,
                text: pin,
                icon3: icon.home,
                text2: company,
                name,
                number,
                pin,
                record,
                id,
                companyId,
                company,
            }))
        } else {
            return DATA.map(({ name, number, pin, id, record, companyId, Company }) => ({
                title: name,
                icon: icon.users,
                subtitle: number,
                text: pin,
                icon3: icon.home,
                text2: Company?.name,
                name,
                number,
                pin,
                id,
                record: record === true ? 'Si' : 'No',
                companyId,
                Company,
            }))
        }
    }

    const dataToCards = dataCard()

    const handleOpenFormCreate = () => {
        navigate(`/${routesWide.applications}/${routesApplications.createConferences}`, {})
    }

    const handleOpenFormEdit = (item) => {
        navigate(`/${routesWide.applications}/${routesApplications.updateConferences}`, {
            state: item.original ? item.original : item,
        })
    }

    const handleDelete = async (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response
                    if (item.original) {
                        response = await deleteConference(item.original)
                    } else {
                        response = await deleteConference(item)
                    }

                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        getConferences()
                        typeSwal('delete')
                    }
                } catch (err) {
                    return err
                }
            }
        })
    }

    const tableInstance = useRef(null)

    const template = [
        {
            Nombre: '',
            Número: '',
            Pin: '',
            Gravar: '',
            'Sede Id': '',
        },
    ]

    const handleUploadFile = async (file) => {
        try {
            let response = await uploadFileConferences(file)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                getConferences()
                typeSwal('upload')
            }
        } catch (err) {
            return err
        } finally {
            // window.location.reload()
        }
    }

    return (
        <ConferencesScreenWrapper isFilter={isFilter.open}>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },

                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        file: {
                            position: 'right',
                            data: dataExport,
                            template,
                        },
                        filter: {
                            position: 'left',
                        },
                    }}
                    importFile={handleUploadFile}
                    setSearch={setSearch}
                    setIsFilter={setIsFilter}
                    isFilter={isFilter}
                />
                {!conferences[0]?.status ? (
                    <div className={'contain__menuFilter'}>
                        <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterConferences} setSearch={setSearch} />
                    </div>
                ) : null}
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : conferences[0]?.status ? (
                <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
                </div>
            ) : (
                <>
                    <div className="contain__table">
                        <TableInfo
                            COLUMNS={COLUMNS}
                            DATA={search.length === 0 ? DATA : search}
                            format={'list'}
                            deleteFunction={handleDelete}
                            updateFunction={handleOpenFormEdit}
                            ref={tableInstance}
                            onClick={handleOpenFormEdit}
                            setIsBottom={setIsBottom}
                        />
                    </div>
                </>
            )}
        </ConferencesScreenWrapper>
    )
}
