import React, { useEffect, useState } from 'react'

import { PageScreen } from '../../../components/PageScreen/PageScreen'
import { dataConfiguracionAsterisk } from '../../../data/dataSettings'
import useSettings from '../../../hooks/useSettings'
import useApplications from '../../../hooks/useApplications'

import { ConfigurationAsteriskWrapper } from './ConfigurationAsteriskScreen.style'
import { useSwal } from '../../../hooks/useSwal'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'

export const ConfigurationAsteriskScreen = () => {
    const { settingForm, createConfiguration, getConfigData, configDataGet, configDataForm, loading } = useSettings()
    const { arrayLocutionsHook, getApplicationsLocutions } = useApplications()

    const { typeSwal } = useSwal()

    const [render, setRender] = useState(false)

    let data = dataConfiguracionAsterisk

    const onSubmit = async () => {
        typeSwal('config', createConfiguration)
    }

    useEffect(() => {
        getApplicationsLocutions()
        getConfigData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        arrayLocutionsHook.forEach((locution) => {
            let newLocution = {
                label: locution.description,
                value: locution.id,
            }
            let found = data.tabs[1].steps[3].input[0].options[6].selectData.options.some((item) => item.value === newLocution.value)
            if (!found && newLocution.value !== undefined) {
                data.tabs[1].steps[3].input[0].options[6].selectData.options.push(newLocution)
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arrayLocutionsHook])

    useEffect(() => {
        if (Object.keys(configDataGet).length !== 0) Object.assign(configDataForm, configDataGet)
        const { tabs } = data
        tabs.forEach((tab) => {
            tab.steps.forEach((step) => {
                step.input.forEach((item) => {
                    if (item.ref?.element) {
                        let refElement = item.ref?.element
                        if (configDataForm[refElement] !== '') {
                            if (refElement === 'maquina') {
                                if (configDataForm[refElement] === 'Monomáquina') {
                                    item.defaultValues = true
                                } else if (configDataForm[refElement] === 'Multimáquina') {
                                    item.defaultValues = false
                                } else {
                                    item.defaultValues = undefined
                                }
                            } else if (refElement === 'preferences') {
                                item.options.forEach((option) => {
                                    if (option.selectData) {
                                        option.selectData.options.map((item) => {
                                            let idAudio = +configDataForm[option.selectData.ref.element]

                                            if (item.value === idAudio) {
                                                return (option.selectData.defaultValues = item)
                                            }
                                            return {}
                                        })
                                    }
                                })
                                item.defaultValues = configDataForm[refElement]
                            } else {
                                item.defaultValues = configDataForm[refElement]
                            }
                        }
                    }
                })
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [render, loading, configDataForm, configDataGet])

    return (
        <>
            <ConfigurationAsteriskWrapper>
                {loading ? <LoaderSpinner /> : <PageScreen data={data} onSubmit={onSubmit} render={render} setRender={setRender} form={settingForm} crudType={'edit'} maxwidth={'1000px'} />}
            </ConfigurationAsteriskWrapper>
        </>
    )
}
