import React from 'react'
import { useDropzone } from 'react-dropzone'

import { MyImportFileWrapper } from './MyImportFile.style'

export const MyImportFile = ({ onChange, text }) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'text/xlsx': ['.xlsx'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            onChange(acceptedFiles)
        },
    })

    return (
        <>
            <MyImportFileWrapper>
                <div className="fileDrop">
                    <div {...getRootProps({})}>
                        <input {...getInputProps()} />
                        {text}
                    </div>
                </div>
            </MyImportFileWrapper>
        </>
    )
}
