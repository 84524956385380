import { useCallback, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
    addCompanyFormDepartmentAction,
    changeCompanyFormIdAction,
    changeCompanyFormIpAction,
    changeCompanyFormIpBackupAction,
    changeCompanyFormNameAction,
    changeCompanyFormPortAction,
    changeCompanyFormPortBackupAction,
    clearCompanyFormAction,
    clearMultiselectCompanyFormAction,
    removeCompanyFormDepartmentAction,
} from '../redux/actions/headquarter/actionCreator'
import {
    createCompanyThunk,
    deleteCompanyThunk,
    getHeadquarterThunk,
    updateCompanyThunk,
} from '../redux/thunks/headquarterThunks'

export const useHeadquarter = () => {
    const dispatch = useDispatch()
    const { getState } = useStore()
    const [loading, setLoading] = useState(true)

    const arrayHeadquarterHook = useSelector(
        (headquarterArray) => headquarterArray.arrayHeadquarters
    )

    const companyLink = useSelector((item) => item.getCompanyLinkReducer)

    const companyForm = useSelector((item) => item.companyFormReducer)

    const getHeadquarter = useCallback(() => {
        dispatch(getHeadquarterThunk(setLoading))
    }, [dispatch])

    // const getCompanyLink = useCallback(() => {
    //     dispatch(getCompanyLinkThunk(setLoading))
    // }, [dispatch])

    const createCompany = useCallback(() => {
        const company = getState()['companyFormReducer']
        let response = dispatch(createCompanyThunk(company, setLoading))
        return response
    }, [getState, dispatch])

    const updateCompany = useCallback(() => {
        const company = getState()['companyFormReducer']
        let response = dispatch(updateCompanyThunk(company, setLoading))
        return response
    }, [dispatch, getState])

    const deleteCompany = useCallback(
        (company) => {
            let response = dispatch(deleteCompanyThunk(company, setLoading))
            return response
        },
        [dispatch]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const FormCompany = useCallback((arg, ref, inputValue) => {
        switch (arg.action) {
            case 'select-option':
                dispatch(addCompanyFormDepartmentAction(arg.option))
                break
            case 'remove-value':
                dispatch(removeCompanyFormDepartmentAction(arg.removedValue))
                break
            case 'clear':
                if (arg.removedValues) {
                    dispatch(clearMultiselectCompanyFormAction())
                } else {
                    dispatch(clearCompanyFormAction())
                }

                break
            case 'input':
                switch (ref.element) {
                    case 'name':
                        dispatch(changeCompanyFormNameAction(arg.value))
                        break
                    case 'ip':
                        dispatch(changeCompanyFormIpAction(arg.value))
                        break
                    case 'port':
                        dispatch(changeCompanyFormPortAction(arg.value))
                        break
                    case 'ipBackup':
                        dispatch(changeCompanyFormIpBackupAction(arg.value))
                        break
                    case 'portBackup':
                        dispatch(changeCompanyFormPortBackupAction(arg.value))
                        break
                    default:
                        break
                }
                break
            case 'id':
                dispatch(changeCompanyFormIdAction(arg.value))
                break
            default:
                break
        }
    })

    return {
        arrayHeadquarterHook,
        getHeadquarter,
        companyLink,
        //getCompanyLink,
        companyForm,
        FormCompany,
        createCompany,
        updateCompany,
        deleteCompany,
        loading,
    }
}
