import React from "react";
import { Route, Routes } from "react-router-dom";
import { ExtensionsFormScreen } from "../../screens/NumberingScreen/ExtensionsScreen/ExtensionsFormScreen";

import { ExtensionsScreen } from "../../screens/NumberingScreen/ExtensionsScreen/ExtensionsScreen";
import { ListNumberFormScreen } from "../../screens/NumberingScreen/ListNumber/ListNumberFormScreen";
import { ListNumberScreen } from "../../screens/NumberingScreen/ListNumber/ListNumberScreen";
import { OverviewNumberingScreen } from "../../screens/NumberingScreen/OverviewNumberingScreen/OverviewNumberingScreen";
import { routesNumbering } from "../routes";
import { NumberingsNavigationWrapper } from "./NumberingNavigation.style";

export const NumberingNavigation = () => {
    return (
        <NumberingsNavigationWrapper>
            <Routes>
                <Route 
                    path="/" 
                    element={<OverviewNumberingScreen />} 
                />
                <Route
                    path={routesNumbering.numberingList}
                    element={<ListNumberScreen />}
                />
                <Route
                    path={routesNumbering.createNumberingList}
                    element={<ListNumberFormScreen crudType="create" />}
                />
                <Route
                    path={routesNumbering.updateNumberingList}
                    element={<ListNumberFormScreen crudType="edit" />}
                />
                <Route
                    path={routesNumbering.extensions}
                    element={<ExtensionsScreen />}
                />
                <Route
                    path={routesNumbering.createExtensions}
                    element={<ExtensionsFormScreen crudType="create" />}
                />
                <Route
                    path={routesNumbering.updateExtensions}
                    element={<ExtensionsFormScreen crudType="edit" />}
                />
            </Routes>
        </NumberingsNavigationWrapper>
    );
};
