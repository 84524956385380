import { actionsTypes } from '../../actions/numbering/actionsTypes'

export const numberingReducer = (listNumbering = [], action) => {
    let newListNumbering
    switch (action.type) {
        case actionsTypes.getNumberingList:
            newListNumbering = [...action.arrayNumbering]
            break

        default:
            newListNumbering = listNumbering
    }
    return newListNumbering
}

export const getDdiLinkReducer = (ddiLink = [], action) => {
    let newDdiLink
    switch (action.type) {
        case actionsTypes.getDdiLink:
            newDdiLink = [...action.payload]
            break

        default:
            newDdiLink = ddiLink
            break
    }
    return newDdiLink
}

export const numberingExtensionsReducer = (arrayNumberingExtensions = [], action) => {
    let newArrayNumberingExtensions
    switch (action.type) {
        case actionsTypes.getExtension:
            newArrayNumberingExtensions = [...action.arrayNumbering]
            break

        default:
            newArrayNumberingExtensions = arrayNumberingExtensions
    }

    return newArrayNumberingExtensions
}

export const FormListNumberingReducer = (
    state = {
        id: '',
        ddi: '',
        name: '',
        department: { label: '', value: '' },
        companyId: { label: '', value: '' },
        destinationCalendar: [
            {
                id: 1,
                calendar: {
                    label: 'Predeterminado',
                    value: 'Predeterminado',
                    initialState: true,
                },
                destinationOpen: [
                    {
                        id: 1,
                        schedule: {
                            label: '',
                            value: '',
                            initialState: true,
                        },
                        soundPrompt: {
                            label: '',
                            value: '',
                            initialState: true,
                        },
                        destination: {
                            label: '',
                            value: '',
                            initialState: true,
                        },
                    },
                ],
                soundPromptClose: {
                    label: '',
                    value: '',
                    initialState: true,
                },
                destinationClose: {
                    label: '',
                    value: '',
                    initialState: true,
                },
            },
        ],
    },
    action
) => {
    let destinationCalendar = [...state.destinationCalendar]
    switch (action.type) {
        case actionsTypes.changeNumberingListId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionsTypes.changeNumberingListNumber:
            state = {
                ...state,
                ddi: action.payload,
            }
            break
        case actionsTypes.changeNumberingListName:
            state = {
                ...state,
                name: action.payload,
            }
            break
        case actionsTypes.addNumberingListCompanyId:
            state = {
                ...state,

                companyId: action.payload,
            }
            break

        case actionsTypes.clearNumberingListCompanyId:
            state = {
                ...state,
                companyId: {},
            }
            break
        case actionsTypes.addNumberingListDepartment:
            state = {
                ...state,
                department: action.payload,
            }
            break
        case actionsTypes.clearListNumberingDepartment:
            state = {
                ...state,
                department: {},
            }
            break
        case actionsTypes.addNumberingListCalendar:
            let refCalendar = 'calendar'
            let optionRef = action.payload.optionRefPage

            let existingObj = destinationCalendar.find((obj) => obj.hasOwnProperty('id') && obj.id === optionRef)

            if (existingObj) {
                if (existingObj.hasOwnProperty(refCalendar)) {
                    existingObj[refCalendar].label = action.payload.label

                    existingObj[refCalendar].value = action.payload.value
                    existingObj[refCalendar].initialState = false
                } else {
                    existingObj[refCalendar] = {
                        label: action.payload.label,
                        value: action.payload.value,
                        initialState: false,
                    }
                }
            } else {
                let newObject = {
                    [refCalendar]: {
                        label: action.payload.label,
                        value: action.payload.value,
                        initialState: false,
                    },
                    destinationOpen: [
                        {
                            id: 1,
                            schedule: {
                                label: '',
                                value: '',
                                initialState: true,
                            },
                            soundPrompt: {
                                label: '',
                                value: '',
                                initialState: true,
                            },
                            destination: {
                                label: '',
                                value: '',
                                initialState: true,
                            },
                        },
                    ],
                    soundPromptClose: {
                        label: '',
                        value: '',
                        initialState: true,
                    },
                    destinationClose: {
                        label: '',
                        value: '',
                        initialState: true,
                    },
                    id: optionRef,
                }
                destinationCalendar.push(newObject)
            }

            state = { ...state, destinationCalendar }

            break

        case actionsTypes.addNumberingListSchedule:
            let refSchedule = 'schedule'
            let optionRefSchedule = action.payload.optionRefPage
            let optionRefScheduleDestinationOpen = action.payload.destinationOpen

            let existingObjSchedule = destinationCalendar.find((obj) => obj.hasOwnProperty('id') && obj.id === optionRefSchedule)
            let destinationOpenObjectRefSchedule = existingObjSchedule.destinationOpen.find((obj) => obj.hasOwnProperty('id') && obj.id === optionRefScheduleDestinationOpen)

            let updatedObjectSchedule = {
                ...destinationOpenObjectRefSchedule,
                [refSchedule]: {
                    label: action.payload.label,
                    value: action.payload.value,
                    initialState: false,
                },
            }

            existingObjSchedule.destinationOpen = existingObjSchedule.destinationOpen.map((obj) => {
                if (obj.id === optionRefScheduleDestinationOpen) {
                    return updatedObjectSchedule
                }

                return obj
            })

            const index = existingObjSchedule.destinationOpen.findIndex((obj) => obj.id === optionRefScheduleDestinationOpen)

            if (index === -1) {
                existingObjSchedule.destinationOpen.push({
                    id: optionRefScheduleDestinationOpen,
                    schedule: {
                        label: action.payload.label,
                        value: action.payload.value,
                        initialState: false,
                    },
                    soundPrompt: {
                        label: '',
                        value: '',
                        initialState: true,
                    },
                    destination: {
                        label: '',
                        value: '',
                        initialState: true,
                    },
                })
            }

            state = { ...state, destinationCalendar }

            break

        case actionsTypes.addNumberingListSoundPrompt:
            let refSoundPrompt = 'soundPrompt'
            let optionRefSoundPrompt = action.payload.optionRefPage
            let optionRefSoundPromptDestinationOpen = action.payload.destinationOpen

            let existingObjSoundPrompt = destinationCalendar.find((obj) => obj.hasOwnProperty('id') && obj.id === optionRefSoundPrompt)
            let destinationOpenObjectRefSoundPrompt = existingObjSoundPrompt.destinationOpen.find((obj) => obj.hasOwnProperty('id') && obj.id === optionRefSoundPromptDestinationOpen)

            let updatedObjectSoundPrompt = {
                ...destinationOpenObjectRefSoundPrompt,
                [refSoundPrompt]: {
                    label: action.payload.label,
                    value: action.payload.value,
                    initialState: false,
                },
            }

            existingObjSoundPrompt.destinationOpen = existingObjSoundPrompt.destinationOpen.map((obj) => {
                if (obj.id === optionRefSoundPromptDestinationOpen) {
                    return updatedObjectSoundPrompt
                }
                return obj
            })

            state = { ...state, destinationCalendar }

            break
        case actionsTypes.addNumberingListDestination:
            let refDestination = 'destination'
            let optionDestination = action.payload.optionRefPage
            let optionRefDestinationDestinationOpen = action.payload.destinationOpen

            let existingObjDestination = destinationCalendar.find((obj) => obj.hasOwnProperty('id') && obj.id === optionDestination)
            let destinationOpenObjectRefDestination = existingObjDestination.destinationOpen.find((obj) => obj.hasOwnProperty('id') && obj.id === optionRefDestinationDestinationOpen)

            let updatedObjectDestination = {
                ...destinationOpenObjectRefDestination,
                [refDestination]: {
                    label: action.payload.label,
                    value: action.payload.value,
                    initialState: false,
                },
            }

            existingObjDestination.destinationOpen = existingObjDestination.destinationOpen.map((obj) => {
                if (obj.id === optionRefDestinationDestinationOpen) {
                    return updatedObjectDestination
                }
                return obj
            })

            state = { ...state, destinationCalendar }

            break
        case actionsTypes.addNumberingListSoundPromptClose:
            let refSoundPromptClose = 'soundPromptClose'
            let optionSoundPromptClose = action.payload.optionRefPage

            let existingObjSoundPromptClose = destinationCalendar.find((obj) => obj.hasOwnProperty('id') && obj.id === optionSoundPromptClose)

            if (existingObjSoundPromptClose) {
                if (existingObjSoundPromptClose.hasOwnProperty(refSoundPromptClose)) {
                    existingObjSoundPromptClose[refSoundPromptClose].label = action.payload.label
                    existingObjSoundPromptClose[refSoundPromptClose].value = action.payload.value
                    existingObjSoundPromptClose[refSoundPromptClose].initialState = false
                } else {
                    existingObjSoundPromptClose[refSoundPromptClose] = {
                        label: action.payload.label,
                        value: action.payload.value,
                        initialState: false,
                    }
                }
            } else {
                let newObject = {
                    [refSoundPromptClose]: {
                        label: action.payload.label,
                        value: action.payload.value,
                        initialState: false,
                    },
                    id: optionSoundPromptClose,
                }
                destinationCalendar.push(newObject)
            }

            state = { ...state, destinationCalendar }

            break
        case actionsTypes.addNumberingListDestinationClose:
            let refDestinationClose = 'destinationClose'
            let optionDestinationClose = action.payload.optionRefPage

            let existingObDestinationClose = destinationCalendar.find((obj) => obj.hasOwnProperty('id') && obj.id === optionDestinationClose)

            if (existingObDestinationClose) {
                if (existingObDestinationClose.hasOwnProperty(refDestinationClose)) {
                    existingObDestinationClose[refDestinationClose].label = action.payload.label
                    existingObDestinationClose[refDestinationClose].value = action.payload.value
                    existingObDestinationClose[refDestinationClose].initialState = false
                } else {
                    existingObDestinationClose[refDestinationClose] = {
                        label: action.payload.label,
                        value: action.payload.value,
                        initialState: false,
                    }
                }
            } else {
                let newObject = {
                    [refDestinationClose]: {
                        label: action.payload.label,
                        value: action.payload.value,
                        initialState: false,
                    },
                    id: optionDestinationClose,
                }
                destinationCalendar.push(newObject)
            }

            state = { ...state, destinationCalendar }

            break

        case actionsTypes.addDestinationCalendar:
            const newId = action.payload
            const newObject = {
                id: newId,
                calendar: {
                    label: '',
                    value: '',
                    initialState: true,
                },
                destinationOpen: [
                    {
                        id: 1,
                        schedule: {
                            label: '',
                            value: '',
                            initialState: true,
                        },
                        soundPrompt: {
                            label: '',
                            value: '',
                            initialState: true,
                        },
                        destination: {
                            label: '',
                            value: '',
                            initialState: true,
                        },
                    },
                ],
                soundPromptClose: {
                    label: '',
                    value: '',
                    initialState: true,
                },
                destinationClose: {
                    label: '',
                    value: '',
                    initialState: true,
                },
            }

            const found = state.destinationCalendar.some((e) => e.id === newId)
            if (!found) {
                const updatedDestinationCalendar = state.destinationCalendar.concat(newObject)
                return {
                    ...state,
                    destinationCalendar: updatedDestinationCalendar,
                }
            } else {
                return state
            }

        case actionsTypes.removeDestinationCalendar:
            const idToDelete = action.payload
            const updatedDestinationCalendarDelete = state.destinationCalendar.filter((destinationCalendarObject) => destinationCalendarObject.id !== idToDelete)
            return {
                ...state,
                destinationCalendar: updatedDestinationCalendarDelete,
            }

        case actionsTypes.addDestinationOpen:
            let refIdDestination = action.payload.newDestinationOpen.id
            let refId = action.payload.refId
            let newDestinationOpen = {
                id: refIdDestination,
                schedule: {
                    label: '',
                    value: '',
                    initialState: true,
                },
                soundPrompt: {
                    label: '',
                    value: '',
                    initialState: true,
                },
                destination: {
                    label: '',
                    value: '',
                    initialState: true,
                },
            }

            destinationCalendar.forEach((item) => {
                if (item.id === refId) {
                    const found = item.destinationOpen.some((e) => e.id === refIdDestination)

                    if (!found) {
                        item.destinationOpen.push(newDestinationOpen)
                    }
                }
            })

            break

        case actionsTypes.removeDestinationOpen:
            let idPage = action.payload.idPage
            let idDestinationOpen = action.payload.idDestinationOpen
            // Buscar el objeto en destinationCalendar que coincide con la idPage
            const indexPage = state.destinationCalendar.findIndex((obj) => obj.id === idPage)

            if (indexPage !== -1) {
                // Buscar el objeto en destinationOpen que coincide con la idDestinationOpen
                const indexDestinationOpen = state.destinationCalendar[indexPage].destinationOpen.findIndex((obj) => obj.id === idDestinationOpen)

                if (indexDestinationOpen !== -1) {
                    // Eliminar el objeto que coincide con la idDestinationOpen
                    const updatedDestinationOpen = [...state.destinationCalendar[indexPage].destinationOpen]
                    updatedDestinationOpen.splice(indexDestinationOpen, 1)

                    // Crear una copia del objeto encontrado en destinationCalendar
                    const updatedObj = { ...state.destinationCalendar[indexPage] }

                    // Actualizar la propiedad destinationOpen del objeto encontrado en destinationCalendar
                    updatedObj.destinationOpen = updatedDestinationOpen

                    // Crear una copia de destinationCalendar y reemplazar el objeto encontrado con el objeto modificado
                    const updatedDestinationCalendar = [...state.destinationCalendar]
                    updatedDestinationCalendar[indexPage] = updatedObj

                    // Devolver el nuevo estado con el objeto modificado
                    return { ...state, destinationCalendar: updatedDestinationCalendar }
                }
            }

            // Si no se encuentra el objeto que coincide con la idPage o la idDestinationOpen, devolver el estado original
            return state
        // const pageIndex = state.destinationCalendar.findIndex((page) => page.id === idPage)

        // // Actualiza el array destinationOpen eliminando el último objeto
        // state.destinationCalendar[pageIndex].destinationOpen.splice(-1, 1)

        // // Devuelve el nuevo estado modificado
        // return { ...state }

        case actionsTypes.clearNumberingListCalendar:
            destinationCalendar.forEach((item) => {
                if (item.id === action.payload.optionRefPage) {
                    item.calendar = {
                        label: '',
                        value: '',
                        initialState: false,
                    }
                }
            })

            break
        case actionsTypes.clearNumberingListSchedule:
            destinationCalendar.forEach((item) => {
                if (item.id === action.payload.optionRefPage) {
                    item.destinationOpen.forEach((item2) => {
                        if (item2.id === action.payload.destinationOpen) {
                            item2.schedule = {
                                label: '',
                                value: '',
                                initialState: false,
                            }
                        }
                    })
                }
            })

            break
        case actionsTypes.clearNumberingListSoundPrompt:
            destinationCalendar.forEach((item) => {
                if (item.id === action.payload.optionRefPage) {
                    item.destinationOpen.forEach((item2) => {
                        if (item2.id === action.payload.destinationOpen) {
                            item2.soundPrompt = {
                                label: '',
                                value: '',
                                initialState: false,
                            }
                        }
                    })
                }
            })
            break
        case actionsTypes.clearNumberingListDestination:
            destinationCalendar.forEach((item) => {
                if (item.id === action.payload.optionRefPage) {
                    item.destinationOpen.forEach((item2) => {
                        if (item2.id === action.payload.destinationOpen) {
                            item2.destination = {
                                label: '',
                                value: '',
                                initialState: false,
                            }
                        }
                    })
                }
            })
            break
        case actionsTypes.clearNumberingListSoundPromptClose:
            destinationCalendar.forEach((item) => {
                if (item.id === action.payload.optionRefPage) {
                    item.soundPromptClose = {
                        label: '',
                        value: '',
                        initialState: false,
                    }
                }
            })
            break
        case actionsTypes.clearNumberingListDestinationClose:
            destinationCalendar.forEach((item) => {
                if (item.id === action.payload.optionRefPage) {
                    item.destinationClose = {
                        label: '',
                        value: '',
                        initialState: false,
                    }
                }
            })
            break

        case actionsTypes.clearNumberingListForm:
            state = {
                id: '',
                ddi: '',
                name: '',
                department: { label: '', value: '' },
                companyId: { label: '', value: '' },
                destinationCalendar: [
                    {
                        id: 1,
                        calendar: {
                            label: 'Predeterminado',
                            value: 'Predeterminado',
                        },
                        destinationOpen: [
                            {
                                id: 1,
                                schedule: {
                                    label: '',
                                    value: '',
                                    initialState: true,
                                },
                                soundPrompt: {
                                    label: '',
                                    value: '',
                                    initialState: true,
                                },
                                destination: {
                                    label: '',
                                    value: '',
                                    initialState: true,
                                },
                            },
                        ],
                        soundPromptClose: {
                            label: '',
                            value: '',
                            initialState: true,
                        },
                        destinationClose: {
                            label: '',
                            value: '',
                            initialState: true,
                        },
                    },
                ],
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const importFileListNumberingReducer = (file = [], action) => {
    let newFile
    switch (action.type) {
        case actionsTypes.importFileNumberingList:
            newFile = [...action.file]
            break

        default:
            newFile = file
            break
    }
    return newFile
}

//reducer for create extensions

export const formExtensionsReducer = (
    state = {
        id: '',
        number: '',
        name: '',
        password: '',
        identification: [],
        typeExtension: [],
        ringTimeout: '',
        callForward: '',
        callForwardNA: '',
        companyId: [],
        record: [],
        pikedGroup: [],
        voicemailActive: false,
        department: [],
        category: [],
        callWaiting: false,
        sipPeerId: '',
        allow: [],
    },
    action
) => {
    let arrayRecord = { ...state }.record
    let arrayPikedGroup = { ...state }.pikedGroup
    switch (action.type) {
        case actionsTypes.changeExtensionId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionsTypes.changeExtensionNumber:
            state = {
                ...state,
                number: action.payload,
            }
            break
        case actionsTypes.changeExtensionName:
            state = {
                ...state,
                name: action.payload,
            }
            break
        case actionsTypes.changeExtensionPassword:
            state = {
                ...state,
                password: action.payload,
            }
            break
        case actionsTypes.addExtensionIdentification:
            state = {
                ...state,
                identification: action.payload,
            }
            break
        case actionsTypes.removeExtensionIdentification:
            state = {
                ...state,
                identification: [],
            }
            break
        case actionsTypes.addTypeExtension:
            state = {
                ...state,
                typeExtension: action.payload,
            }
            break
        case actionsTypes.removeTypeExtension:
            state = {
                ...state,
                typeExtension: [],
            }
            break
        case actionsTypes.changeExtensionRingTimeout:
            state = {
                ...state,
                ringTimeout: action.payload,
            }
            break
        case actionsTypes.changeExtensionCallForward:
            state = {
                ...state,
                callForward: action.payload,
            }
            break
        case actionsTypes.changeExtensionCallForwardNA:
            state = {
                ...state,
                callForwardNA: action.payload,
            }
            break
        case actionsTypes.addExtensionCompanyId:
            state = {
                ...state,
                companyId: action.payload,
            }
            break
        case actionsTypes.addExtensionDepartment:
            state = {
                ...state,
                department: action.payload,
            }
            break
        case actionsTypes.removeExtensionDepartment:
            state = {
                ...state,
                department: [],
            }
            break
        case actionsTypes.removeExtensionCompanyId:
            state = {
                ...state,
                companyId: [],
            }
            break
        case actionsTypes.addExtensionRecord:
            let index = arrayRecord.map((p) => p.value).indexOf(action.payload.value)

            if (index > -1) {
                arrayRecord.splice(index, 1)
            } else {
                arrayRecord.push(action.payload)
            }
            state = {
                ...state,
                record: arrayRecord,
            }
            break

        case actionsTypes.addExtensionPikedGroup:
            state = {
                ...state,
                pikedGroup: action.payload,
            }
            break
        case actionsTypes.removeExtensionPikedGroup:
            let indexPikedGroup = arrayPikedGroup.map((p) => p.value).indexOf(action.payload)

            if (indexPikedGroup > -1) {
                arrayPikedGroup.splice(indexPikedGroup, 1)
            }
            state = {
                ...state,
                pikedGroup: arrayPikedGroup,
            }
            break
        case actionsTypes.clearExtensionsPikedGroup:
            state = {
                ...state,
                pikedGroup: [],
            }
            break

        case actionsTypes.changeExtensionVoicemailActive:
            state = {
                ...state,
                voicemailActive: action.payload,
            }
            break
        case actionsTypes.changeExtensionCallWaiting:
            state = {
                ...state,
                callWaiting: action.payload,
            }

            break
        case actionsTypes.addExtensionCategories:
            state = {
                ...state,
                category: action.payload,
            }
            break
        case actionsTypes.removeExtensionCategories:
            state = {
                ...state,
                category: [],
            }
            break

        case actionsTypes.changeExtensionSipPeerId:
            state = {
                ...state,
                sipPeerId: action.payload,
            }
            break
        case actionsTypes.addExtensionAllow:
            state = {
                ...state,
                allow: action.payload,
            }
            break
        case actionsTypes.clearExtensionAllow:
            state = {
                ...state,
                allow: [],
            }
            break
        case actionsTypes.clearExtensionForm:
            state = {
                id: '',
                number: '',
                name: '',
                password: '',
                identification: [],
                typeExtension: [],
                ringTimeout: '',
                callForward: '',
                callForwardNA: '',
                companyId: [],
                record: [],
                pikedGroup: [],
                voicemailActive: false,
                department: [],
                category: [],
                callWaiting: false,
                sipPeerId: '',
                allow: [],
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export const NBDeleteExtensionReducer = (extension = {}, action) => {
    let newExtensionDelete
    switch (action.type) {
        case actionsTypes.deleteNBExtension:
            newExtensionDelete = { ...action.extension }
            break
        default:
            newExtensionDelete = extension
    }
    return newExtensionDelete
}

export const importFileExtensionReducer = (file = [], action) => {
    let newFile
    switch (action.type) {
        case actionsTypes.importFileExtension:
            newFile = [...action.file]
            break

        default:
            newFile = file
            break
    }
    return newFile
}

export const createNumberingListReducer = (numberingList = {}, action) => {
    let newNumberingList
    switch (action.type) {
        case actionsTypes.createNumberingList:
            newNumberingList = { ...action.payload }
            break

        default:
            newNumberingList = numberingList
            break
    }
    return newNumberingList
}
