import styled from "styled-components";

export const OverviewEMWrapper = styled.div`

    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;
