import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const NavButtonsWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-bottom: 1px solid ${colors.greyLine};
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 36px;
    }

    .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 34px;

        button {
            margin-right: 10px;
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 34px;
    }

    .custom-file-input {
        background-color: ${colors.greyButton};
        border-radius: 8px;
        border: 0px;
        width: 100px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${colors.white};
    }

    .changeView {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        border-radius: 10px;
        width: 34px;
        cursor: pointer;
        color: ${colors.grey};
        background-color: ${colors.white};
        transition: all 0.3s;

        &:hover {
            background-color: ${colors.secondaryColor};
            transition: all 0.3s;
            color: ${colors.primaryColor};
        }
        &__card,
        &__table {
            margin-right: 4px;

            font-size: ${newFontSize.button};
            font-weight: ${weight.medium};
        }

        &__line {
            margin-right: 4px;
        }

        &__isActive {
            margin-right: 4px;
            cursor: pointer;
            color: ${colors.primaryColor};
            font-size: ${newFontSize.h3};
            font-weight: ${weight.medium};
        }
    }
`
