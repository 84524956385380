import React from 'react'
import { iconButton } from '../../data/dataButtons'
import { ButtonPasswordWrapper } from './ButtonPassword.style'

export const ButtonPassword = ({ onClick, text }) => {
    const { passwordWatcher } = iconButton
    return (
        <ButtonPasswordWrapper onClick={onClick}>
            <img src={passwordWatcher.icon} alt={passwordWatcher.alt} />
            <div className="text__password">
                <p>{text ? text : passwordWatcher.text}</p>
            </div>
        </ButtonPasswordWrapper>
    )
}
