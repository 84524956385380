import { StaticNavigation } from "./navigation/StaticNavigation/StaticNavigation.jsx";
import { AppContext } from "./components/AppContext/AppContext";

import "./App.css";

function App() {
    return (
        <div className="App">
            <AppContext>
                <StaticNavigation />
            </AppContext>
        </div>
    );
}

export default App;
