import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formTrunks, optionsAllow, optionsDisallow, optionsDTMF, optionsNat, optionsTransport } from '../../../data/dataOutbound'
import { useHeadquarter } from '../../../hooks/useHeadquarter'
import { useOutbound } from '../../../hooks/useOutboundRoutes'
import { useSwal } from '../../../hooks/useSwal'
import { routesOutbound, routesWide } from '../../../navigation/routes'

export const TrunksOutboundScreenForm = ({ crudType }) => {
    const { outboundRoutesForm, createOutboundRoutesTrunks, updateOutboundRoutesTrunks, outboundRoutesFormReducer, getOutboundRoutesTrunks } = useOutbound()
    const { typeSwal } = useSwal()

    const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter()

    const [loading, setLoading] = useState(true)
    const [dataSave, setDataSave] = useState(false)
    const [loadingCounter, setLoadingCounter] = useState(0)

    const location = useLocation()

    const data = formTrunks

    useEffect(() => {
        outboundRoutesForm(
            {
                action: 'clearForm',
            },
            {
                form: 'trunks',
            }
        )
        if (crudType === 'edit') {
            const nameAllow = location.state.allow === null ? '' : location.state.allow
            const nameDisallow = location.state.disallow === null ? '' : location.state.disallow
            const nameDTMF = location.state.dtmfmode === null ? '' : location.state.dtmfmode
            const nameNat = location.state.nat === null ? '' : location.state.nat
            const nameTransport = location.state.transport === null ? '' : location.state.transport

            const nameAllowArray = nameAllow.split(', ')
            const nameDisallowArray = nameDisallow.split(', ')
            const nameNatArray = nameNat.split(', ')

            const newAllow = optionsAllow.filter((item) => nameAllowArray.includes(item.value)).map((item) => ({ label: item.label, value: item.value, id: item.id }))
            const newDisallow = optionsDisallow.filter((item) => nameDisallowArray.includes(item.value)).map((item) => ({ label: item.label, value: item.value, id: item.id }))
            const newNat = optionsNat.filter((item) => nameNatArray.includes(item.value)).map((item) => ({ label: item.label, value: item.value, id: item.id }))
            const newTransport = optionsTransport.filter((item) => item.value === nameTransport).map((item) => ({ label: item.label, value: item.value, id: item.id }))
            const newDTMF = optionsDTMF.filter((item) => item.value === nameDTMF).map((item) => ({ label: item.label, value: item.value, id: item.id }))

            outboundRoutesForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'trunks',
                    element: 'allow',
                },
                newAllow
            )
            outboundRoutesForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'trunks',
                    element: 'disallow',
                },
                newDisallow
            )
            outboundRoutesForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'trunks',
                    element: 'dtmfmode',
                },
                newDTMF
            )
            outboundRoutesForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'trunks',
                    element: 'nat',
                },
                newNat
            )
            outboundRoutesForm(
                {
                    action: 'select-option',
                },
                {
                    form: 'trunks',
                    element: 'transport',
                },
                newTransport
            )
            outboundRoutesForm(
                {
                    action: 'id',
                    value: location.state.kamDispatcherId,
                },
                {
                    form: 'trunks',
                    element: 'kamDispatcherId',
                }
            )
            outboundRoutesForm(
                {
                    action: 'input',
                    value: location.state.password,
                },
                {
                    form: 'trunks',
                    element: 'notPassword',
                }
            )

            Object.keys(location.state).map((item) =>
                data.steps.map((item2) =>
                    item2.input.map((item3) => {
                        if (item === item3.ref.element) {
                            return outboundRoutesForm(
                                {
                                    action: item3.cardType,
                                    value: item3.cardType === 'toggle' ? (location.state.regServer.toggle === 0 ? false : true) : location.state[item],
                                },
                                {
                                    form: item3.ref.form,
                                    element: item3.ref.element,
                                },
                                {
                                    label: location.state[item],
                                    value: String(location.state[item]),
                                }
                            )
                        } else {
                            return null
                        }
                    })
                )
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getHeadquarter()
    }, [getHeadquarter])

    useEffect(() => {
        setTimeout(() => {
            if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0) {
                data.steps.map((item) => {
                    item.input.map((item2) => {
                        if (item2.cardType === 'select-option') {
                            arrayHeadquarterHook.forEach((item3) => {
                                if (item3.name === null) {
                                    item3.name = 'not defined'
                                }
                                const company = {
                                    label: item3.name,
                                    value: item3.id?.toString(),
                                }
                                const found = item2.options.some((e) => e.value === company.value)
                                if (!found && company.value !== undefined) {
                                    item2.options.push(company)
                                }
                            })
                        }
                        return item2
                    })
                    return item
                })

                if (crudType === 'edit' && (outboundRoutesFormReducer.name === '' || outboundRoutesFormReducer.name !== location.state.name || outboundRoutesFormReducer.id !== location.state.id)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit') {
                    data.steps.map((item) => {
                        item.input.map((item2) => {
                            switch (item2.ref.element) {
                                case 'name':
                                    item2.defaultValues = outboundRoutesFormReducer.name
                                    break
                                case 'Ipaddr':
                                    item2.defaultValues = outboundRoutesFormReducer.ipaddr
                                    break
                                case 'port':
                                    item2.defaultValues = outboundRoutesFormReducer.port
                                    break
                                case 'context':
                                    item2.defaultValues = outboundRoutesFormReducer.context
                                    break
                                case 'permit':
                                    item2.defaultValues = outboundRoutesFormReducer.permit
                                    break
                                case 'deny':
                                    item2.defaultValues = outboundRoutesFormReducer.deny
                                    break
                                case 'transport':
                                    item2.defaultValues = outboundRoutesFormReducer.transport
                                    break
                                case 'nat':
                                    item2.defaultValues = outboundRoutesFormReducer.nat
                                    break
                                case 'dtmfmode':
                                    item2.defaultValues = outboundRoutesFormReducer.dtmfmode
                                    break
                                case 'allow':
                                    item2.defaultValues = outboundRoutesFormReducer.allow
                                    break
                                case 'qualify':
                                    item2.defaultValues = outboundRoutesFormReducer.qualify
                                    break
                                case 'disallow':
                                    item2.defaultValues = outboundRoutesFormReducer.disallow
                                    break
                                case 'description':
                                    item2.defaultValues = outboundRoutesFormReducer.description
                                    break
                                case 'notPassword':
                                    item2.defaultValues = outboundRoutesFormReducer.password
                                    break
                                case 'fromDomain':
                                    item2.defaultValues = outboundRoutesFormReducer.domain
                                    break
                                case 'server':
                                    item2.defaultValues = outboundRoutesFormReducer.server
                                    break
                                case 'userDefault':
                                    item2.defaultValues = outboundRoutesFormReducer.defaultuser
                                    break
                                case 'outboundPrefix':
                                    item2.defaultValues = outboundRoutesFormReducer.prefix
                                    break
                                case 'regServer':
                                    item2.defaultValues = outboundRoutesFormReducer.register

                                    break
                                case 'companyId':
                                    item2.options.filter((item3) => {
                                        if (item3.value === outboundRoutesFormReducer.headquarters) {
                                            return (item2.defaultValues = item3)
                                        }
                                        return false
                                    })
                                    break
                                default:
                                    break
                            }
                            return item2
                        })

                        return item
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 10)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCounter, crudType])

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            typeSwal('createLoader', '', outboundRoutesForm, getOutboundRoutesTrunks, `/${routesWide.outboundRoutes}/${routesOutbound.trunks}`, 'trunks', createOutboundRoutesTrunks)
        } else if (crudType === 'edit') {
            typeSwal('createLoader', '', outboundRoutesForm, getOutboundRoutesTrunks, `/${routesWide.outboundRoutes}/${routesOutbound.trunks}`, 'trunks', updateOutboundRoutesTrunks)
        }
    }

    return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={outboundRoutesForm} dataSave={dataSave} crudType={crudType} />}</>
}
