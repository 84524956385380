import React from "react";
import { TutorialsScreenWrapper } from "./TutorialsScreen.style";

export const TutorialsScreen = () => {
    return (
        <TutorialsScreenWrapper>
            <h1>TutorialsScreen</h1>
        </TutorialsScreenWrapper>
    );
};
