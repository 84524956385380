import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGird } from '../../../components/CardGrid/CardGird'
import { DistributionTable } from '../../../components/DistributionTable/DistributionTable'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { dataScreenSongWaiting } from '../../../data/dataApplications'
import { menuButtonsDropDownTable } from '../../../data/dataButtons'
import useApplications from '../../../hooks/useApplications'
import { useSwal } from '../../../hooks/useSwal'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'

import { SongWaitingWrapper } from './SongWaiting.style'

export const SongWaitingScreen = () => {
    const { songWaiting, songWaitingLink, getSongWaitingLink, deleteSongWaiting, loading } = useApplications()

    const { typeSwal } = useSwal()

    let navigate = useNavigate()

    const [objectSongWaiting, setObjectSongWaiting] = useState({
        arraySongWaiting: [],
        title: '',
    })
    const [enable, setEnable] = useState({})
    const { notFound, title = [] } = dataScreenSongWaiting

    useEffect(() => {
        getSongWaitingLink()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let newSongWaiting = songWaitingLink.filter((item) => item.Moh?.id === songWaiting[0]?.id)
        setObjectSongWaiting({
            arraySongWaiting: newSongWaiting,
            title: songWaiting[0]?.name,
        })

        setEnable(songWaiting[0]?.id)
    }, [songWaitingLink, songWaiting])

    const handleClick = (item) => {
        let newSongWaiting = songWaitingLink.filter((object) => object.Moh.id === item.id)
        setObjectSongWaiting({
            arraySongWaiting: newSongWaiting,
            title: item.text,
        })
        setEnable(item.id)
    }

    const handleOpenFormCreate = () => {
        navigate('/applications/songWaiting/create', {})
    }

    const handleOpenFormEdit = (item) => {
        handleClick(item)
        navigate('/applications/songWaiting/update', {
            state: objectSongWaiting,
        })
    }

    const handleDelete = async (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response = await deleteSongWaiting(item)

                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        typeSwal('delete')
                        getSongWaitingLink()
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        })
    }

    const dataToCards = objectSongWaiting.arraySongWaiting.map((item) => {
        return {
            id: item.id,
            title: item.Soundprompt?.description,
            subtitle: item.Soundprompt?.filename,
            original: item,
        }
    })

    menuButtonsDropDownTable.forEach((item) => {
        if (item.id === 1) {
            item.function = handleOpenFormEdit
        } else if (item.id === 2) {
            item.function = handleDelete
        }
    })

    const option = songWaiting.map((item) => {
        return {
            id: item.id,
            text: item.name,
        }
    })

    return (
        <SongWaitingWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },
                    }}
                />
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : songWaiting[0]?.status ? (
                <div className="contain__table">
                    <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
                </div>
            ) : (
                <>
                    <div className="distributionTable">
                        <DistributionTable
                            title={title}
                            option={option}
                            component={<CardGird data={dataToCards} />}
                            handleClick={handleClick}
                            menuButtonsDropDown={menuButtonsDropDownTable}
                            enable={enable}
                        />
                    </div>
                </>
            )}
        </SongWaitingWrapper>
    )
}
