import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize, weight } from '../../theme/fonts'

export const ColorPickerWrapper = styled.div`
    position: relative;
    width: 100%;
    margin: 0;

    button {
        border: 1px solid ${colors.greyBorder};
        border-radius: 5px;
        font-weight: ${weight.regular};
        font-size: ${newFontSize.body};
        color: ${(props) => (props.color === 'Elige un color' ? 'black' : 'white')};
        width: 100%;
        height: 34px;
        padding: 0;
        background: ${(props) => (props.color === 'Elige un color' ? 'white' : props.color)};

        transition: all 0.5s ease-in-out;

        &:hover {
            cursor: pointer;
            background-color: ${colors.greyBorder};
        }
    }

    .picker {
        position: absolute;
        display: ${(props) => (props.pressed ? 'block' : 'none')};
        top: 45px;
        left: 20px;
    }
`
