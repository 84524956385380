import React from 'react'
import { KeyWrapper } from './Key.style'

export const Key = ({ item, onClick, backgroundColor, isDisable }) => {
    return (
        <KeyWrapper onClick={() => onClick(item)} backgroundColor={backgroundColor} isDisable={isDisable}>
            <div className="key">
                <div className="key__item">{item}</div>
            </div>
        </KeyWrapper>
    )
}
