import React, { useEffect, useState } from 'react'

import { MyDropzone } from '../MyDropzone/MyDropzone'
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner'
import { ToggleSwitch } from '../ToggleSwich/ToggleSwitch'
import { Radio } from '../Radio/Radio'
import ScheduleForm from '../ScheduleForm/ScheduleForm'
import ColorPicker from '../ColorPicker/ColorPicker'
import { MySelect } from '../MySelect/MySelect'
import { Input } from '../Input/Input'
import Calendar from '../Calendar/Calendar'
import { monthNames, weekDays } from '../../data/dataPreferences'

import { StepFormWrapper } from './StepForm.style'
import { colors } from '../../theme/colors'
import { Phone } from '../Phone/Phone'
import { RandomCodeGenerator } from '../RandomCodeGenerator/RandomCodeGenerator'
import { InputRandom } from '../InputRandom/InputRandom'
import { MultiFormPages } from '../MultiFormPages/MultiFormPages'
import { DestinationSelect } from '../DestinationSelect/DestinationSelect'
import { CardSlider } from '../CardSlider/CardSlider'
import { TitleInputPair } from '../TitleInputPair/TitleInputPair'
import { ToggleButton } from '../ToggleButton/ToggleButton'

export const StepForm = React.forwardRef(
    (
        {
            dataForm,
            initial,
            animate,
            exit,
            isOpen,
            form,
            crudType,
            stepForm,
            layoutId,
            variants,
            errorInput,
            setErrorInput,
            validationNormalInput,
            setValidationNormalInput,
            inputValue,
            setInputValue,
            allSteps,
            maxwidth,
            arrScreen,
            inputValueUnique,
            setInputValueUnique,
            //
            validateReducer,
            setValidateReducer,
            reducerForm,
            data,
            //
            dataModalButtonToTable,
        },
        ref
    ) => {
        const [loading, setLoading] = useState(true)
        const [loadingCounter, setLoadingCounter] = useState(0)
        const [toggleIsActive, setToggleIsActive] = useState({})
        const [editRadio, setEditRadio] = useState('')
        const [voiceMail, setVoiceMail] = useState({})
        const [callWaiting, setCallWaiting] = useState({})
        const [selectedDays, setSelectedDays] = useState([
            {
                name: 'Calendario creado',
                color: '',
                days: [],
            },
        ])

        useEffect(() => {
            setTimeout(() => {
                if (crudType === 'edit') {
                    dataForm.input.forEach((item) => {
                        if (!stepForm.inputFull) {
                            switch (item.cardType) {
                                case 'input':
                                    if (item.ref.element !== 'password') {
                                        setInputValue((prevState) => ({
                                            ...prevState,
                                            [item.ref.element]: '',
                                        }))
                                        setInputValue((prevState) => ({
                                            ...prevState,
                                            [item.ref.element]: item.defaultValues,
                                        }))
                                    }

                                    break
                                case 'select':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: item.defaultValues.label,
                                    }))
                                    break
                                case 'select-option':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: item.defaultValues.label,
                                    }))

                                    break
                                case 'multiselect':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    if (!item.defaultValues.label === undefined) {
                                        setInputValue((prevState) => ({
                                            ...prevState,
                                            [item.ref.element]: item.defaultValues.label,
                                        }))
                                    } else {
                                        setInputValue((prevState) => ({
                                            ...prevState,
                                            [item.ref.element]: item.defaultValues[0]?.label,
                                        }))
                                    }
                                    break
                                case 'toggle':
                                    setToggleIsActive({
                                        ...toggleIsActive,
                                        [item.ref.element]: item.defaultValues,
                                    })
                                    break
                                case 'dropzone':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: item.defaultValues,
                                    }))
                                    break
                                default:
                                    break
                                case 'radio':
                                    if (item.ref.element === 'record') setEditRadio(item.defaultValues)
                                    else if (item.ref.element === 'voicemailActive') setVoiceMail(item.defaultValues)
                                    else if (item.ref.element === 'callWaiting') setCallWaiting(item.defaultValues)
                                    else if (item.ref.element === 'preferences') setEditRadio(item.defaultValues)

                                    break
                            }
                        }
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    dataForm.input.forEach((item) => {
                        if (!stepForm.inputFull) {
                            switch (item.cardType) {
                                case 'input':
                                    if (item.ref.element === 'pin') {
                                        setInputValue((prevState) => ({
                                            ...prevState,
                                            [item.ref.element]: item.defaultValues,
                                        }))
                                    } else {
                                        setInputValue((prevState) => ({
                                            ...prevState,
                                            [item.ref.element]: '',
                                        }))
                                    }
                                    break
                                case 'select':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    item.defaultValues = {}
                                    break
                                case 'select-option':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    item.defaultValues = {}
                                    break
                                case 'multiselect':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    item.defaultValues = []
                                    break
                                case 'dropzone':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    break
                                case 'phone':
                                    setInputValue((prevState) => ({
                                        ...prevState,
                                        [item.ref.element]: '',
                                    }))
                                    break

                                default:
                                    break
                            }
                        }
                    })
                    setLoading(false)
                } else {
                    setLoadingCounter(loadingCounter + 1)
                }
            }, 100)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [loadingCounter])

        const handleToggleSwitchChange = (item, isOn, setIsOn) => {
            setIsOn(!isOn)
            setToggleIsActive({
                ...toggleIsActive,
                [item.ref.element]: !isOn,
            })
            let action = { action: 'toggle', value: !isOn }
            let ref = item.ref
            form(action, ref)
        }

        const handlePhoneChange = (item, value, refForm) => {
            if (!item.title) {
                let action = { action: 'addDestination' }
                let ref = {
                    form: refForm,
                }
                let inputValue = {
                    item,
                    value,
                }

                form(action, ref, inputValue)
            } else {
                let action = { action: 'removeDestination' }
                let ref = {
                    form: refForm,
                }
                let inputValue = {
                    item,
                }
                form(action, ref, inputValue)
            }
        }

        const handleRandomCodeGenerator = (item, refForm) => {
            const ref = { form: refForm, element: refForm }

            form(item, ref)
        }

        const handleDestinationSelect = (data, name, idPage, destinationOpen, itemForm) => {
            if (name) {
                const arg = {
                    action: name,
                }
                const ref = {
                    element: itemForm.ref.element,
                    form: itemForm.ref.form,
                }
                form(arg, ref)
            } else {
                const arg = {
                    action: itemForm.cardType,
                }
                const ref = {
                    element: itemForm.ref.element,
                    form: itemForm.ref.form,
                }

                form(arg, ref, data)
            }
        }

        const [isActiveToggleButton, setIsActiveToggleButton] = useState(false)

        const handleToggleButtonChange = (item) => {
            setIsActiveToggleButton(!isActiveToggleButton)
            const action = {
                action: item.cardType,
            }
            const ref = {
                form: item.ref.form,
                element: item.ref.element,
            }

            form(action, ref, !isActiveToggleButton)
        }

        return (
            <>
                {loading && crudType !== 'edit' ? (
                    <LoaderSpinner />
                ) : (
                    <StepFormWrapper initial={initial} variants={variants} animate={animate} exit={exit} layoutId={layoutId} ref={ref} data={dataForm} maxwidth={maxwidth}>
                        {isOpen ? (
                            <div className="stepForm">
                                <div className="stepForm__header">
                                    <div className="stepForm__number">
                                        <p className="number">{dataForm.number}</p>
                                    </div>
                                    <div className="stepForm__title">
                                        <p className="title">{dataForm.title}</p>
                                    </div>
                                </div>
                                <div className="stepForm__contain-typeInput">
                                    {dataForm.input.map((item, index) =>
                                        item.cardType === 'select' || item.cardType === 'select-option' ? (
                                            <div key={index} className="contain__select">
                                                {item.required && <span className="textRequired">{item.required.text}</span>}

                                                <MySelect
                                                    item={item}
                                                    placeholder={item.placeholder}
                                                    options={item.options}
                                                    inputRef={ref}
                                                    defaultValue={item.defaultValues}
                                                    isMulti={undefined}
                                                    isSearchable={false}
                                                    form={form}
                                                    dataForm={dataForm}
                                                    setInputValue={setInputValue}
                                                    setErrorInput={setErrorInput}
                                                    setValidationNormalInput={setValidationNormalInput}
                                                    validationNormalInput={validationNormalInput}
                                                    allSteps={allSteps}
                                                />
                                                {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                                            </div>
                                        ) : item.cardType === 'multiselect' ? (
                                            <div key={index} className="contain__select">
                                                {item.required && <span className="textRequired">{item.required.text}</span>}

                                                <MySelect
                                                    item={item}
                                                    placeholder={item.placeholder}
                                                    options={item.options}
                                                    inputRef={ref}
                                                    defaultValue={item.defaultValues}
                                                    isMulti
                                                    isSearchable={true}
                                                    form={form}
                                                    dataForm={dataForm}
                                                    setInputValue={setInputValue}
                                                    setErrorInput={setErrorInput}
                                                    setValidationNormalInput={setValidationNormalInput}
                                                    validationNormalInput={validationNormalInput}
                                                    allSteps={allSteps}
                                                />
                                                {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                                            </div>
                                        ) : item.cardType === 'input' ? (
                                            crudType === 'edit' && item.ref.element === 'password' ? null : (
                                                <div className="contain__input" key={index}>
                                                    {item.required ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}

                                                    <Input
                                                        item={item}
                                                        form={form}
                                                        inputValue={inputValue}
                                                        setErrorInput={setErrorInput}
                                                        setInputValue={setInputValue}
                                                        setValidationNormalInput={setValidationNormalInput}
                                                        validationNormalInput={validationNormalInput}
                                                        arrScreen={arrScreen}
                                                        inputValueUnique={inputValueUnique}
                                                        setInputValueUnique={setInputValueUnique}
                                                    />

                                                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : null}
                                                    {inputValueUnique.some((obj) => obj.element === item.ref.element) ? <span className="error"> {item.required.errorMessageUnique}</span> : null}
                                                </div>
                                            )
                                        ) : item.cardType === 'inputRandom' ? (
                                            crudType === 'edit' && item.ref.element === 'password' ? null : (
                                                <div className="contain__input" key={index}>
                                                    {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                                                    <InputRandom
                                                        item={item}
                                                        form={form}
                                                        inputValue={inputValue}
                                                        setErrorInput={setErrorInput}
                                                        setInputValue={setInputValue}
                                                        setValidationNormalInput={setValidationNormalInput}
                                                        validationNormalInput={validationNormalInput}
                                                    />
                                                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : null}
                                                </div>
                                            )
                                        ) : item.cardType === 'dropzone' ? (
                                            <div key={index} className="contain__form-dropzone">
                                                <div className="dropzone">
                                                    {item.required.validation && <span className="textRequired">{item.required.text}</span>}
                                                    <MyDropzone item={item} setInputValue={setInputValue} form={form} inputValue={inputValue} accept={item.acceptFile} />
                                                </div>
                                                {errorInput && validationNormalInput.includes(item.ref.element) ? (
                                                    <span className="error"> {item.required.errorMessage}</span>
                                                ) : (
                                                    <span className="error">&nbsp;</span>
                                                )}
                                            </div>
                                        ) : item.cardType === 'toggle' ? (
                                            <div key={index} className="stepForm__toggle">
                                                {item.required.text && <span className="textRequired">{item.required.text}</span>}

                                                <div className="toggle__item">
                                                    <ToggleSwitch toggleSwitch={handleToggleSwitchChange} isActive={toggleIsActive} color={colors.primaryColor} data={item} />
                                                </div>
                                            </div>
                                        ) : item.cardType === 'radio' ? (
                                            item.ref.element === 'voicemailActive' ? (
                                                <div key={index} className="stepForm__radio">
                                                    <span className="textRequired">{item.required.text}</span>

                                                    {item.required.validation && item.radioType === 'multi' && <span>{item.required.text}</span>}
                                                    <div className="contain__items-radio">
                                                        <Radio
                                                            form={form}
                                                            item={item}
                                                            defaultValueEdit={editRadio}
                                                            voiceMail={voiceMail}
                                                            dataForm={dataForm}
                                                            inputRef={ref}
                                                            setInputValue={setInputValue}
                                                            setErrorInput={setErrorInput}
                                                            setValidationNormalInput={setValidationNormalInput}
                                                            validationNormalInput={validationNormalInput}
                                                            allSteps={allSteps}
                                                        />{' '}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div key={index} className="stepForm__radio">
                                                    {item.required.text && <span className="textRequired">{item.required.text}</span>}

                                                    {item.required.validation && item.radioType === 'multi' && <span>{item.required.text}</span>}
                                                    <div className="contain__items-radio">
                                                        <Radio
                                                            item={item}
                                                            form={form}
                                                            defaultValueEdit={editRadio}
                                                            voiceMail={callWaiting}
                                                            inputValue={inputValue}
                                                            setInputValue={setInputValue}
                                                            setValidationNormalInput={setValidationNormalInput}
                                                            validationNormalInput={validationNormalInput}
                                                            setErrorInput={setErrorInput}
                                                            arrScreen={arrScreen}
                                                            inputValueUnique={inputValueUnique}
                                                            setInputValueUnique={setInputValueUnique}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        ) : item.cardType === 'schedule' ? (
                                            <div key={index} className="schedule_container">
                                                {item.required.text && <span className="textRequired">{item.required.text}</span>}

                                                <ScheduleForm item={item} form={form} crudType={crudType} />
                                            </div>
                                        ) : item.cardType === 'calendar' ? (
                                            <div key={index} className="calendar_container">
                                                {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                                                <div className="calendar_wrapper">
                                                    <Calendar
                                                        selectedDays={selectedDays}
                                                        setSelectedDays={setSelectedDays}
                                                        monthNames={monthNames}
                                                        weekDays={weekDays}
                                                        monthsDisplayed={2}
                                                        data={item}
                                                        crudType={crudType}
                                                    />
                                                </div>
                                            </div>
                                        ) : item.cardType === 'color' ? (
                                            <div key={index} className="color">
                                                {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                                                <ColorPicker crudType={crudType} data={item} />
                                            </div>
                                        ) : item.cardType === 'phone' ? (
                                            <div key={index} className={'containerPhone'}>
                                                {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                                                <Phone onChange={handlePhoneChange} refForm={item.ref.form} crudType={crudType} item={item} />
                                                {errorInput && validationNormalInput.includes(item.ref.element) ? (
                                                    <span className="error"> {item.required.errorMessage}</span>
                                                ) : (
                                                    <span className="error">&nbsp;</span>
                                                )}
                                            </div>
                                        ) : item.cardType === 'generateKey' ? (
                                            <div key={index} className={'generateKey'}>
                                                <RandomCodeGenerator refForm={item.ref.form} onChange={handleRandomCodeGenerator} />
                                            </div>
                                        ) : item.cardType === 'multiFormPages' ? (
                                            <div key={index} className={'container_multiFormPagesWrapper'}>
                                                <MultiFormPages
                                                    data={item}
                                                    form={form}
                                                    dataForm={dataForm}
                                                    setInputValue={setInputValue}
                                                    setErrorInput={setErrorInput}
                                                    setValidationNormalInput={setValidationNormalInput}
                                                    validationNormalInput={validationNormalInput}
                                                    allSteps={allSteps}
                                                    validateReducer={validateReducer}
                                                    //
                                                    setValidateReducer={setValidateReducer}
                                                    reducerForm={reducerForm}
                                                    firstData={data}
                                                />
                                            </div>
                                        ) : item.cardType === 'destinations' ? (
                                            <div key={index} className="contain__select">
                                                {item.required && <span className="textRequired">{item.required.text}</span>}

                                                <DestinationSelect
                                                    onChange={handleDestinationSelect}
                                                    item={item}
                                                    placeholder={item.placeholder}
                                                    options={item.options}
                                                    inputRef={ref}
                                                    defaultValue={item.defaultValues}
                                                    isMulti
                                                    isSearchable={true}
                                                    form={form}
                                                    dataForm={dataForm}
                                                    setInputValue={setInputValue}
                                                    setErrorInput={setErrorInput}
                                                    setValidationNormalInput={setValidationNormalInput}
                                                    validationNormalInput={validationNormalInput}
                                                    allSteps={allSteps}
                                                />
                                                {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                                            </div>
                                        ) : item.cardType === 'cardSlider' ? (
                                            <div key={index}>
                                                <span className="textRequired">{item.label}</span>
                                                <CardSlider
                                                    item={item}
                                                    setInputValue={setInputValue}
                                                    setErrorInput={setErrorInput}
                                                    setValidationNormalInput={setValidationNormalInput}
                                                    validationNormalInput={validationNormalInput}
                                                    allSteps={allSteps}
                                                    form={form}
                                                    dataModalButtonToTable={dataModalButtonToTable}
                                                    dataForm={dataForm}
                                                />
                                            </div>
                                        ) : item.cardType === 'titleInputPair' ? (
                                            <div key={index} className="contain__titleInputPair">
                                                <TitleInputPair
                                                    item={item}
                                                    form={form}
                                                    inputValue={inputValue}
                                                    setErrorInput={setErrorInput}
                                                    setInputValue={setInputValue}
                                                    setValidationNormalInput={setValidationNormalInput}
                                                    validationNormalInput={validationNormalInput}
                                                    arrScreen={arrScreen}
                                                    inputValueUnique={inputValueUnique}
                                                    setInputValueUnique={setInputValueUnique}
                                                    errorInput={errorInput}
                                                />
                                            </div>
                                        ) : item.cardType === 'toggleButton' ? (
                                            <div className="contain__toggleButton" key={index}>
                                                <span className="textRequired">{item.label}</span>

                                                <div className="contain__toggleButton__item" onClick={() => handleToggleButtonChange(item)}>
                                                    <ToggleButton
                                                        textLeft={item.textLeft}
                                                        textRight={item.textRight}
                                                        isActive={isActiveToggleButton}
                                                        setIsActive={setIsActiveToggleButton}
                                                        twoButtonActive="true"
                                                        defaultValue={item.defaultValues}
                                                    />
                                                </div>
                                            </div>
                                        ) : item.cardType === 'witheSpace' ? (
                                            <div key={index}></div>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </StepFormWrapper>
                )}
            </>
        )
    }
)
