import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FormScreen } from '../../../components/FormScreen/FormScreen'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { formCompany } from '../../../data/dataPreferences'
import { useDepartments } from '../../../hooks/useDepartments'
import { useHeadquarter } from '../../../hooks/useHeadquarter'
import { useSwal } from '../../../hooks/useSwal'
import { routesPreferences, routesWide } from '../../../navigation/routes'

export const CompanyForm = ({ crudType }) => {
    const { FormCompany, companyForm, createCompany, updateCompany, getHeadquarter } = useHeadquarter()
    const { getDepartments, departmentsHook } = useDepartments()
    const { typeSwal } = useSwal()

    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [loadingCounter, setLoadingCounter] = useState(0)
    const [dataSave, setDataSave] = useState(false)

    const data = formCompany

    useEffect(() => {
        getDepartments()
        FormCompany({ action: 'clear' })

        if (crudType === 'edit') {
            let actionId = {
                action: 'id',
                value: location.state.id,
            }
            FormCompany(actionId)

            FormCompany(
                {
                    action: 'input',
                    value: location.state.title,
                },
                { form: 'company', element: 'name' }
            )
            FormCompany(
                {
                    action: 'input',
                    value: location.state.ip,
                },
                { form: 'company', element: 'ip' }
            )
            FormCompany(
                {
                    action: 'input',
                    value: location.state.port,
                },
                { form: 'company', element: 'port' }
            )
            FormCompany(
                {
                    action: 'input',
                    value: location.state.ipBackup,
                },
                { form: 'company', element: 'ipBackup' }
            )
            FormCompany(
                {
                    action: 'input',
                    value: location.state.portBackup,
                },
                { form: 'company', element: 'portBackup' }
            )
            location.state.departments.map((item) =>
                FormCompany({
                    action: 'select-option',
                    option: {
                        label: item.Department.name,
                        value: String(item.Department.id),
                    },
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDepartments])

    useEffect(() => {
        setTimeout(() => {
            if (typeof departmentsHook === 'object' && departmentsHook.length > 0) {
                data.steps.map((step) => {
                    const result = step.input.map((input) => {
                        if (input.cardType === 'multiselect') {
                            departmentsHook.forEach((department) => {
                                if (department.name === null) {
                                    department.name = 'not defined'
                                }
                                const departmentValue = {
                                    label: department.name,
                                    value: String(department.id),
                                }
                                const foundDepartment = input.options.some((option) => option.value === departmentValue.value)
                                if (!foundDepartment && departmentValue.value !== undefined) {
                                    input.options.push(departmentValue)
                                }
                            })
                        }
                        return step
                    })
                    return result
                })
                if (crudType === 'edit' && (companyForm.name === '' || companyForm.name !== location.state.title)) {
                    setLoadingCounter(loadingCounter + 1)
                } else if (crudType === 'edit') {
                    data.steps.map((step) => {
                        step.input.map((item) => {
                            switch (item.ref.element) {
                                case 'name':
                                    item.defaultValues = companyForm.name
                                    break
                                case 'ip':
                                    item.defaultValues = companyForm.ip
                                    break
                                case 'port':
                                    item.defaultValues = companyForm.port
                                    break
                                case 'ipBackup':
                                    item.defaultValues = companyForm.ipBackup
                                    break
                                case 'portBackup':
                                    item.defaultValues = companyForm.portBackup
                                    break
                                case 'departments':
                                    item.defaultValues = []
                                    item.options.filter((option) => {
                                        if (companyForm.departments.map((department) => department.value.toString()).includes(option.value)) {
                                            if (!item.defaultValues.map((item2) => item2.value).includes(option.value)) {
                                                return item.defaultValues.push(option)
                                            }
                                        }
                                        return false
                                    })
                                    break
                                default:
                                    break
                            }
                            return item
                        })
                        return step
                    })
                    setLoading(false)
                } else if (crudType === 'create') {
                    setLoading(false)
                }
            } else {
                setLoadingCounter(loadingCounter + 1)
            }
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crudType, loadingCounter])

    const onSubmit = async () => {
        setDataSave(true)
        if (crudType === 'create') {
            typeSwal('createLoader', '', FormCompany, getHeadquarter, `/${routesWide.preferences}/${routesPreferences.company}`, 'company', createCompany)
        } else if (crudType === 'edit') {
            typeSwal('createLoader', '', FormCompany, getHeadquarter, `/${routesWide.preferences}/${routesPreferences.company}`, 'company', updateCompany)
        }
    }

    return (
        <>
            {loading ? (
                <LoaderSpinner />
            ) : (
                <FormScreen
                    data={data}
                    onSubmit={onSubmit}
                    form={FormCompany}
                    dataSave={dataSave}
                    // initialData={EMFormListExtensions}
                    crudType={crudType}
                />
            )}
        </>
    )
}
