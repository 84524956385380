import { useEffect, useRef, useState } from 'react'
import BlockPickerComponent from './BlockPicker'
import { ColorPickerWrapper } from './ColorPicker.style'

const ColorPicker = ({ crudType, data }) => {
    const [pressed, setPressed] = useState(false)
    const [color, setColor] = useState('#db9cd7')
    const [firstRender, setFirstRender] = useState(true)
    const refContainer = useRef(null)

    useEffect(() => {
        if (crudType === 'edit' && firstRender) {
            setColor(data.defaultValues)
            setFirstRender(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color])

    const handleOnClick = () => {
        setPressed(!pressed)
    }

    return (
        <ColorPickerWrapper pressed={pressed} color={color} ref={refContainer}>
            <button onClick={() => handleOnClick()} />
            <div className="picker">
                <BlockPickerComponent setColor={setColor} color={color} pressed={pressed} setPressed={setPressed} reference={refContainer} />
            </div>
        </ColorPickerWrapper>
    )
}

export default ColorPicker
