import styled from 'styled-components'
import { colors } from '../../theme/colors'

export const StaticWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    .container {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 100%;
    }

    .contain {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-left: 0.995763px solid ${colors.greyLine};
    }

    .fullScreen {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 100%;
    }
`
