import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useNumbering from '../../../hooks/useNumbering'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { TableInfo } from '../../../components/TableInfo/TableInfo'
import { routesNumbering, routesWide } from '../../../navigation/routes'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'
import { dataScreenListNumbering } from '../../../data/dataNumbering'
import { CardGird } from '../../../components/CardGrid/CardGird'
import { useSwal } from '../../../hooks/useSwal'
import { nameTables } from '../../../data/dataTable'
import useView from '../../../hooks/useView'

import { ListNumberWrapper } from './ListNumberScreen.style'

export const ListNumberScreen = () => {
    const { arrayNumberingHook, getNumberingList, deleteNumberingList, uploadFileListNumbering, loading, ddiLink } = useNumbering()
    const { typeSwal } = useSwal()
    const { changeView, isCardView } = useView()

    const [search, setSearch] = useState([])
    const [, setIsBottom] = useState(false)

    const { notFound, icon } = dataScreenListNumbering

    const navigate = useNavigate()

    useEffect(() => {
        setSearch([])
        getNumberingList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSearch])

    const COLUMNS = [
        {
            Header: 'Número',
            accessor: 'ddi',
        },
        {
            Header: 'Descripción',
            accessor: 'name',
        },
        {
            Header: 'Compañia',
            accessor: 'company',
        },
        {
            Header: 'Departamento',
            accessor: 'department',
        },
    ]

    function searchDestinationCalendar(id) {
        let ddiLinkId = ddiLink.filter((obj) => obj.ddiId === id)

        return ddiLinkId
    }

    const DATA = arrayNumberingHook.map(({ description, ddi, id, Company, Department, companyId }) => ({
        name: description,
        ddi: ddi,
        ddiId: id,
        company: Company?.name,
        department: Department?.name,
        departmentId: Department?.id,
        companyId,
        destinationCalendar: searchDestinationCalendar(id),
        id: id,
    }))

    const handleOpenFormCreate = () => {
        navigate(`/${routesWide.numbering}/${routesNumbering.createNumberingList}`, {})
    }

    const handleOpenFormEdit = (item) => {
        navigate(`/${routesWide.numbering}/${routesNumbering.updateNumberingList}`, {
            state: item.original ? item.original : item,
        })
    }

    const dataExport = arrayNumberingHook.map(({ description, ddi }) => ({
        Descripción: description,
        Número: ddi,
    }))

    const dataCard = () => {
        if (search.length > 0) {
            return search.map(({ name, ddi, ddiId }) => ({
                title: name,
                icon: icon.phoneCard,
                subtitle: ddi,
                ddiId,
                name,
                ddi,
            }))
        } else {
            return DATA.map(({ description, ddi, id }) => ({
                title: description,
                icon: icon.phoneCard,
                subtitle: ddi,
                id,
                name: description,
                ddi: ddi,
                ddiId: id,
            }))
        }
    }

    const dataToCards = dataCard()

    const handleDelete = async (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response
                    if (item.original) {
                        response = await deleteNumberingList(item.original)
                    } else {
                        response = await deleteNumberingList(item)
                    }
                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        getNumberingList()
                        typeSwal('delete')
                    }
                    if (response.status === 203) {
                        let table = Object.keys(nameTables)
                            .filter((p) => p.includes(response.data.table))
                            .reduce((obj, key) => {
                                obj[key] = nameTables[key]
                                return obj[key]
                            }, {})
                        typeSwal('error', table)
                        getNumberingList()
                    }
                } catch (err) {
                    return err
                }
            }
        })
    }

    const handleUploadFile = async (file) => {
        try {
            let response = await uploadFileListNumbering(file)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                typeSwal('upload')
                getNumberingList()
            }
        } catch (err) {
            return err
        }
    }

    const tableInstance = useRef(null)

    const template = [
        {
            Número: '',
            Descripción: '',
        },
    ]

    return (
        <ListNumberWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },

                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        file: {
                            position: 'right',
                            data: dataExport,
                            template,
                        },
                        // filter: {
                        //     position: 'left',
                        // },
                    }}
                    importFile={handleUploadFile}
                    setSearch={setSearch}
                />
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : arrayNumberingHook[0]?.status ? (
                <div className="contain__table">
                    <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
                </div>
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
                </div>
            ) : (
                <div className="contain__table">
                    <TableInfo
                        format={'list'}
                        COLUMNS={COLUMNS}
                        DATA={search.length === 0 ? DATA : search}
                        deleteFunction={handleDelete}
                        updateFunction={handleOpenFormEdit}
                        ref={tableInstance}
                        onClick={handleOpenFormEdit}
                        setIsBottom={setIsBottom}
                    />
                </div>
            )}
        </ListNumberWrapper>
    )
}
