import { useCallback, useState } from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux'
import {
    changeEMListFormNameAction,
    addEMListFormExtensionAction,
    removeEMListFormExtensionAction,
    clearEMListFormExtensionAction,
    changeEMAlertsNameAction,
    changeEMAlertsCodeAction,
    addEMAlertsListDiffusionAction,
    removeEMAlertsListDiffusionAction,
    addEMAlertsLocutionsAction,
    removeEMAlertsLocutionsAction,
    clearEMAlertsFormAction,
    changeEMListFormIdAction,
    changeEMAlertsFormIdAction,
    changeEMAlertsConfirmationNumberAction,
    addEMAlertsSecondaryListDiffusionAction,
    removeSecondayEMAlertsListDiffusionAction,
    clearMultiselectEMListFormExtensionAction,
    changeEMAlertsCallAndRecordAction,
    changeEMAlertsExternalAction,
    clearEMAlertsListDiffusionAction,
    clearEMAlertsSecondaryListDiffusionAction,
    clearEMAlertsLocutionsAction,
} from '../redux/actions/EM/actionCreators'
import {
    getEMAlertsThunk,
    createEMListExtensionThunk,
    getEMListExtensionThunk,
    getEMThunk,
    updateEMListExtensionThunk,
    createEMAlertsThunk,
    changeEMAlertsCallAndRecordThunk,
    deleteEMAlertsThunk,
    updateEMAlertsThunk,
    deleteEMListExtensionThunk,
    getHistoriesThunk,
    uploadFileAlertsThunk,
} from '../redux/thunks/EMThunks'

const useEM = () => {
    const [loading, setLoading] = useState()

    const dispatch = useDispatch()

    const arrayEM = useSelector((EMArray) => EMArray.arrayEM)

    const extensions = useSelector((extensionList) => extensionList.EMListExtensionReducer)

    const arrayEMAlerts = useSelector((alerts) => alerts.arrayEMAlerts)
    const loadListAlerts = useSelector((alerts) => alerts.loadListAlertsReducer)

    const EMFormListExtensions = useSelector((p) => p.arrayEMFormListExtensions)

    const EMFormAlerts = useSelector((p) => p.EMFormAlertsReducer)

    const { getState } = useStore()

    /* Dipatch functions */
    const getEM = useCallback(() => {
        dispatch(getEMThunk(setLoading))
    }, [dispatch])

    const getEMListExtension = useCallback(() => {
        dispatch(getEMListExtensionThunk(setLoading))
    }, [dispatch])

    const createEMListExtension = useCallback(() => {
        const diffusionList = getState()['arrayEMFormListExtensions']
        let response = dispatch(createEMListExtensionThunk(diffusionList, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const getListDiffusionExtension = async () => {
        await dispatch(getEMThunk(setLoading)).then(() => {
            dispatch(getEMListExtensionThunk(setLoading)).then(() => setLoading(false))
        })
    }

    const updateEMListExtension = useCallback(() => {
        const diffusionList = getState()['arrayEMFormListExtensions']
        let response = dispatch(updateEMListExtensionThunk(diffusionList, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const getEMAlerts = useCallback(() => {
        dispatch(getEMAlertsThunk(setLoading))
    }, [dispatch])

    const createEMAlerts = useCallback(() => {
        const alarm = getState()['EMFormAlertsReducer']
        let response = dispatch(createEMAlertsThunk(alarm, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const EMform = useCallback((arg, ref, inputValue) => {
        if (arg.action) {
            switch (ref.form) {
                case 'diffusionList':
                    switch (arg.action) {
                        case 'select-option':
                            dispatch(addEMListFormExtensionAction(arg.option))
                            break
                        case 'remove-value':
                            dispatch(removeEMListFormExtensionAction(arg.removedValue))
                            break
                        case 'pop-value':
                            dispatch(clearEMListFormExtensionAction())
                            break
                        case 'clear':
                            if (arg.removedValues) {
                                dispatch(clearMultiselectEMListFormExtensionAction())
                            } else {
                                dispatch(clearEMListFormExtensionAction())
                            }
                            break
                        case 'input':
                            dispatch(changeEMListFormNameAction(arg.value))
                            break
                        case 'id':
                            dispatch(changeEMListFormIdAction(arg.value))
                            break
                        default:
                            break
                    }

                    break
                case 'alerts':
                    switch (arg.action) {
                        case 'toggle':
                            if (ref.element === 'callAndRecord') {
                                dispatch(changeEMAlertsCallAndRecordAction(arg.value))
                            }
                            if (ref.element === 'external') {
                                dispatch(changeEMAlertsExternalAction(arg.value))
                            }
                            break

                        case 'select-option':
                            if (ref.element === 'diffusionList') {
                                dispatch(addEMAlertsListDiffusionAction(inputValue))
                            }
                            if (ref.element === 'locution') {
                                dispatch(addEMAlertsLocutionsAction(inputValue))
                            }
                            if (ref.element === 'secondaryDiffusionList') dispatch(addEMAlertsSecondaryListDiffusionAction(inputValue))

                            break
                        case 'remove-value':
                            if (ref.element === 'diffusionList') {
                                dispatch(removeEMAlertsListDiffusionAction(arg.removedValue.value))
                            }
                            if (ref.element === 'secondaryDiffusionList') {
                                dispatch(removeSecondayEMAlertsListDiffusionAction(arg.removedValue.value))
                            }
                            if (ref.element === 'locution') {
                                dispatch(removeEMAlertsLocutionsAction(arg.removedValue.value))
                            }
                            break
                        case 'input':
                            if (ref.element === 'name') {
                                dispatch(changeEMAlertsNameAction(arg.value))
                            }
                            if (ref.element === 'keyCall') {
                                dispatch(changeEMAlertsCodeAction(arg.value))
                            }
                            if (ref.element === 'confirmationNumber') dispatch(changeEMAlertsConfirmationNumberAction(arg.value))
                            break
                        case 'clearForm':
                            dispatch(clearEMAlertsFormAction())
                            break
                        case 'clear':
                            if (ref.element === 'diffusionList') {
                                dispatch(clearEMAlertsListDiffusionAction())
                            }
                            if (ref.element === 'secondaryDiffusionList') {
                                dispatch(clearEMAlertsSecondaryListDiffusionAction())
                            }
                            if (ref.element === 'locution') {
                                dispatch(clearEMAlertsLocutionsAction())
                            }
                            break
                        case 'id':
                            dispatch(changeEMAlertsFormIdAction(arg.value))
                            break
                        default:
                            break
                    }
                    break
                default:
                    break
            }
        }
    })

    const changeEMAlertsCallAndRecordHook = useCallback(
        (callAndRecord) => {
            dispatch(changeEMAlertsCallAndRecordThunk(callAndRecord, setLoading))
        },
        [dispatch]
    )

    const deleteEMAlerts = useCallback(
        (alert) => {
            let response = dispatch(deleteEMAlertsThunk(alert, setLoading))
            return response
        },
        [dispatch]
    )

    const updateEMAlerts = useCallback(() => {
        const alertsForm = getState()['EMFormAlertsReducer']
        const response = dispatch(updateEMAlertsThunk(alertsForm, setLoading))
        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const deleteEMListExtensions = useCallback(
        async (payload) => {
            let response = await dispatch(deleteEMListExtensionThunk(payload, setLoading))
            return response
        },
        [dispatch]
    )

    const uploadFileAlerts = useCallback(
        (file) => {
            const response = dispatch(uploadFileAlertsThunk(file, setLoading))
            return response
        },
        [dispatch]
    )

    const histories = useSelector((EM) => EM.EMHistoryReducer)
    const loadListHistories = useSelector((EM) => EM.loadListHistoryReducer)

    const getHistories = useCallback(() => {
        let response = dispatch(getHistoriesThunk(setLoading))
        return response
    }, [dispatch])

    return {
        arrayEM,
        extensions,
        arrayEMAlerts,
        loadListAlerts,
        EMFormAlerts,
        EMFormListExtensions,
        getEM,
        getEMListExtension,
        getListDiffusionExtension,
        createEMListExtension,
        updateEMListExtension,
        getEMAlerts,
        EMform,
        createEMAlerts,
        changeEMAlertsCallAndRecordHook,
        deleteEMAlerts,
        updateEMAlerts,
        uploadFileAlerts,
        deleteEMListExtensions,
        histories,
        getHistories,
        loadListHistories,
        loading,
    }
}

export default useEM
