import styled from 'styled-components'
import { colors } from '../../../theme/colors'
import { newFontSize, weight } from '../../../theme/fonts'

export const BodyMultiFormWrapper = styled.div`
    width: 100%;
    .bodyMultiForm {
        width: 100%;
        height: 100%;
        &__title {
            margin: 0;
            font-size: ${newFontSize.label};
            color: ${colors.black};
            font-weight: ${weight.medium};
            margin-top: 20px;
        }

        &__subtitle {
            margin: 0;
            margin-top: 5px;
            font-size: ${newFontSize.span};
            color: ${colors.black};
            font-weight: ${weight.regular};
        }
        &__span {
            color: ${colors.grey};
            font-size: ${newFontSize.label};
            margin-bottom: 2px;
        }
        &__selects {
            display: flex;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 5px;
            margin-top: 20px;
        }
        &__select {
            width: 100%;
            height: 100%;
            max-width: 280px;
            min-height: 70px;

            &__destinationSelect {
                display: flex;
                gap: 10px;
                position: relative;

                &__removed {
                    position: absolute;
                    right: -20px;
                    top: 10px;
                    color: ${colors.red};

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        &__button {
            color: ${colors.primaryColor};
            font-size: ${newFontSize.label};
            background-color: ${colors.white};
            border: none;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
                color: ${colors.grey};
            }
        }
    }
`
