import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ActivityScreen from '../../screens/CallRegisterScreen/ActivityScreen/ActivityScreen'
import ProductivityScreen from '../../screens/CallRegisterScreen/ProductivityScreen/ProductivityScreen'
import CostumerServiceScreen from '../../screens/CallRegisterScreen/CostumerServiceScreen/CostumerServiceScreen'
import { GeneralScreen } from '../../screens/CallRegisterScreen/Cdrs/GeneralScreen'
import { OverviewCallRegister } from '../../screens/CallRegisterScreen/OverviewCallRegister/OverviewCallRegister'
import { routesCallRegister } from '../routes'
import { CallRegisterNavigationWrapper } from './CallRegisterNavigation.style'

export const CallRegisterNavigation = () => {
    return (
        <CallRegisterNavigationWrapper>
            <Routes>
                <Route path="/" element={<OverviewCallRegister />} />
                <Route path={routesCallRegister.general} element={<GeneralScreen />} />
                <Route path={routesCallRegister.activity} element={<ActivityScreen />} />
                <Route path={routesCallRegister.productivity} element={<ProductivityScreen />} />
                <Route path={routesCallRegister.customer} element={<CostumerServiceScreen />} />
            </Routes>
        </CallRegisterNavigationWrapper>
    )
}
