import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box } from '../../../../components/Box/Box'
import { BoxStatistic } from '../../../../components/BoxStadistic/ BoxStatistic'
import { CardGird } from '../../../../components/CardGrid/CardGird'
import { NavButtons } from '../../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../../components/TableInfo/TableInfo'
import useEM from '../../../../hooks/useEM'
import useView from '../../../../hooks/useView'
import { HistoryToLinkedeIdWrapper } from './HistoryToLinkedeId.style'

export const HistoryToLinkedeId = () => {
    const { arrayEMAlerts, getEMAlerts } = useEM()
    const [search, setSearch] = useState([])
    const { changeView, isCardView } = useView()

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        setSearch([])
        getEMAlerts()
    }, [setSearch])

    const COLUMNS = [
        {
            Header: 'ID llamada',
            accessor: 'uniqueid',
        },
        {
            Header: 'Alerta',
            accessor: 'cidDnid',
        },
        {
            Header: 'Extensión receptora',
            accessor: 'receiverExtension',
        },
        {
            Header: 'Tiempo de escucha',
            accessor: 'calculateTime',
        },
        {
            Header: 'Hora recepción',
            accessor: 'hourReceived',
            Cell: ({ row }) => (
                <div
                    dangerouslySetInnerHTML={{
                        __html: row.original.hourReceived,
                    }}
                />
            ),
        },
        {
            Header: 'Estado',
            accessor: 'context',
            Cell: ({ row }) => (row.original.context === 'No' ?  <Box name={'NO ANSWER'} backgroundColor={'#ED7E63'} /> : row.original.context.find( call => call.eventtype === 'EM_STATUS_END') ? <Box name={'ANSWER'} backgroundColor={'#97DFD8'} /> : <Box name={'Error'} backgroundColor={'#ED7E63'} />),
        },
    ]

    const handleReturn = () => {
        navigate('/EM/history', {})
    }

    const timeForGraph = (totalCallsTime) => {
        const totalTime = totalCallsTime.reduce((total, call) => {
            if (call.time !== 'No escuchada') {
                const minutes = Number(call.time.slice(0, 2)) * 60
                const seconds = Number(call.time.slice(3, 5))

                return total + minutes + seconds
            } else return total + 0
        }, 0)

        return totalTime / totalCallsTime.length
    }

    function calculateTime(item) {
        let answer = item.eventtime
        let hangup = ''
        let diffTime = ''
        item.callFlow.forEach((item2) => {
            if (item2.userdeftype === 'EM_STATUS_END' || item2.userdeftype === 'EM_STATUS_FAIL') {
                hangup = item2.eventtime
            }
        })

        if (answer !== '' && hangup !== '') {
            diffTime = moment.utc(moment(hangup, 'YYYY/MM/DD HH:mm:ss').diff(moment(answer, 'YYYY/MM/DD HH:mm:ss'))).format('ss')
        }
        if (diffTime !== '') {
            return diffTime + ' segundos'
        } else {
            return 'No escuchada'
        }
    }

    let receiverExtensions = location.state.itemsSameLinkedId.filter((obj) => obj.eventtype !== 'EM_STATUS_START')

    function foundData(data, search) {
        let substrings = data.split(',')
        let src = substrings.find((substring) => substring.includes(search))
        if (src) {
            let srcValue = src.substring(src.indexOf(':') + 1)
            return srcValue
        }
        //else
        // {
        //     return 'No hay datos'
        // }
    }

    function foundTime(date) {
        let time = moment(date).format('YYYY-MM-DD <br/> HH:mm:ss')
        return time
    }

    function foundNameAlert(name) {
        let newName
        arrayEMAlerts.forEach((item) => {
            if (item.keyCall === name) {
                newName = item.name
            }
        })
        return newName
    }

    let originateStatus = receiverExtensions.map(
        ({
            accountcode,
            amaflags,
            appdata,
            appname,
            channame,
            cidAni,
            cidDnid,
            cidName,
            cidNum,
            cidRdnis,
            context,
            agent,
            eventtime,
            eventtype,
            exten,
            extra,
            id,
            linkedid,
            peer,
            peeraccount,
            uniqueid,
            userdeftype,
            userfield,
        }) => ({
            accountcode,
            amaflags,
            appdata,
            appname,
            channame,
            cidDnid: foundData(appdata, 'emcode:'),
            cidName,
            cidNum,
            cidRdnis,
            context: foundData(appdata, 'ORIGINATE_STATUS:'),
            agent: foundData(appdata, 'agent:'),
            eventtime,
            eventtype,
            exten,
            extra,
            id,
            linkedid,
            peer,
            peeraccount,
            uniqueid,
            userdeftype,
            userfield,
            receiverExtension: foundData(appdata, 'dst:'),
            hourReceived: foundTime(eventtime),
            callFlow: [],
        })
    )

    const groupCalls = []
    originateStatus.forEach((item) => {
        let index = groupCalls.map((ext) => ext.receiverExtension).indexOf(item.receiverExtension)
        if (index !== -1) {
            groupCalls[index].callFlow.push(item)
        } else {
            groupCalls.push(item)
        }
    })

    function addState(item) {
        item.callFlow.forEach((item2) => {
            if (item2.context === 'ANSWER') {
                item.action = item2.context
            }
            // if(item2.context === 'otra cosa'){
            //     item.action = item2.context
            // }
        })
    }

    groupCalls.forEach((item) => {
        if (item.callFlow.length > 0) {
            item.calculateTime = calculateTime(item)
            addState(item)
        }
        else {
            item.calculateTime = 'No escuchada'
        }
        // if (item.cidDnid) {
        //     item.cidDnid = foundNameAlert(item.cidDnid
        // }
    })

    const DATA = groupCalls.map((item) => ({
        uniqueid: item.uniqueid,
        receiverExtension: item.agent ? item.agent : item.receiverExtension,
        calculateTime: item.calculateTime,
        context: item.callFlow.length > 0 ? item.callFlow : 'No',
        hourReceived: item.hourReceived,
        cidDnid: item.cidDnid,
        id: item.id,
    }))

    const dataToCards = []

    const dataPie = [
        { name: 'Lunes', num: 100, color: '#7357F6' },
        { name: 'Martes', num: 313, color: '#ED7E63' },
        { name: 'Miércoles', num: 300, color: '#97DFD8' },
        { name: 'Jueves', num: 400, color: '#F0BD58' },
        { name: 'Viernes', num: 231, color: '#84BBF0' },
    ]

    let answerCount = groupCalls.filter((object) => object.callFlow.find( call => call.eventtype === 'EM_STATUS_END')).length
    let failedCount = DATA.filter((object) => object.userdeftype === 'EM_STATUS_FAIL').length

    const dataStatistics = [
        {
            title: 'Llamadas contestadas',
            value: answerCount,
            backgroundColor: '#97DFD8',
        },
        {
            title: 'Llamadas totales',
            value: DATA.length,
            backgroundColor: 'rgba(132,187,240,0.24)',
        },
    ]

    return (
        <HistoryToLinkedeIdWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        file: {
                            position: 'right',
                            data: DATA,
                            import: false,
                        },
                        return: {
                            position: 'left',
                            function: handleReturn,
                        },
                    }}
                    setSearch={setSearch}
                />
            </div>
            {search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={() => null} />
                </div>
            ) : (
                <div className="contain__table-statistics">
                    <div className="contain__statistics">
                        <TableInfo format={'list'} COLUMNS={COLUMNS} DATA={DATA} deleteFunction={null} updateFunction={null} isOptions={false} />
                    </div>
                    <div className="boxStatistic">
                        {dataStatistics.map((item, index) => (
                            <div className="box" key={index}>
                                <BoxStatistic backgroundColor={item.backgroundColor} title={item.title} value={item.value} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </HistoryToLinkedeIdWrapper>
    )
}
