import styled, { keyframes } from 'styled-components'
import { colors } from '../../theme/colors'
import { fontFamily, newFontSize, weight } from '../../theme/fonts'

const blob_turn = keyframes`

    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }

`

const blob_skew = keyframes`

    0%  { transform: skewY(0deg); }
    13% { transform: skewY( calc( (1.8deg) * 2) ); }
    18% { transform: skewY( calc( (2.2deg) * 2) ); }
    24% { transform: skewY( calc( (2.48deg) * 2) ); }
    25% { transform: skewY( calc( (2.5deg) * 2) ); }
    26% { transform: skewY( calc( (2.48deg) * 2) ); }
    32% { transform: skewY( calc( (2.2deg) * 2) ); }
    37% { transform: skewY( calc( (1.8deg) * 2) ); }
    50% { transform: skewY(0deg); }
    63% { transform: skewY( calc( (-1.8deg) * 2) ); }
    68% { transform: skewY( calc( (-2.2deg) * 2) ); }
    74% { transform: skewY( calc( (-2.48deg) * 2) ); }
    75% { transform: skewY( calc( (-2.5deg) * 2) ); }
    76% { transform: skewY( calc( (-2.48deg) * 2) ); }
    82% { transform: skewY( calc( (-2.2deg) * 2) ); }
    87% { transform: skewY( calc( (-1.8deg) * 2) ); }
    100%{ transform: skewY(0deg); }

`

const blob_scale = keyframes`

    0%  { transform: scaleX(.9) scaleY(1); }
    25% { transform: scaleX(.9) scaleY(.8); }
    50% { transform: scaleX(1) scaleY(.9); }
    75% { transform: scaleX(.9) scaleY(.8); }
    100%{ transform: scaleX(.9) scaleY(1); }

`

export const BubbleContainer = styled.div`
    opacity: 0.8;
    height: 190px;
    width: 260px;
    fill: #7f40a6;

    animation: ${blob_turn} ${(props) => props.time}s linear infinite;
    fill: ${colors.primaryColor};
    position: relative;
    transform-origin: center;

    svg {
        animation: ${blob_skew} calc(30s * 0.5) linear 0s infinite;
        transform-origin: center;
    }

    svg path {
        animation: ${blob_scale} calc(30s * 0.5) ease-in-out 0s infinite;
        transform-origin: center;
    }
`

export const LoginWrapper = styled.div`
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 0.6fr 1fr 0.6fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;

    height: 100%;
    width: 100%;

    .bubble1 {
        grid-row: 4 / 5;
        grid-column: 1;

        justify-self: center;
        align-self: center;

        height: 240px;
        width: 300px;

        img {
            height: 100%;
            width: 100%;
            overflow: hidden;
        }
    }

    .bubble2 {
        grid-column: 3;
        grid-row: 2 / 3;

        justify-self: center;
        align-self: center;

        height: 240px;
        width: 300px;

        img {
            height: 100%;
            width: 100%;
            overflow: hidden;
        }
    }

    .form {
        height: 100%;
        max-height: 700px;

        grid-column: 2;
        grid-row: 1 / 7;

        display: grid;
        grid-template-rows: 4fr 6fr 4fr 5fr 3fr 8fr 8fr 7fr 7fr;

        &__logo {
            grid-row: 2;

            justify-self: center;
            align-self: start;

            height: 32px;
            width: 180px;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__subtitle {
            grid-row: 3;

            justify-self: center;
            align-self: center;
            text-align: center;

            h3 {
                font-size: ${newFontSize.h3};
                font-weight: ${weight.medium};
                text-transform: uppercase;
                margin: 0;
            }
        }

        &__title {
            grid-row: 4;

            justify-self: center;
            align-self: center;
            text-align: start;

            h1 {
                margin: 0;
                font-size: ${newFontSize.h1};
                font-weight: ${weight.bold};
                color: ${colors.primaryColor};
                line-height: 56px;
                letter-spacing: 0.25px;
            }
        }

        &__contain {
            display: grid;

            grid-row: 6 / 10;
        }

        &__steps-contain {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 0.4fr 0.4fr 0.4fr 0.4fr;
        }

        &__contain-email {
            grid-row-start: 1;
            display: grid;
            grid-template-rows: 0.4fr 1fr;
            justify-self: center;
            width: 591.46px;
            align-self: start;
        }
        &__contain_email-label {
            display: flex;
            justify-content: start;
            align-items: center;

            img {
                margin-right: 10px;
            }

            label {
                font-size: ${newFontSize.label};
                font-weight: ${weight.regular};
                color: ${colors.greyLabel};
                line-height: 14px;
                letter-spacing: 0.25px;
            }
        }

        &__contain_email-input {
            height: 100%;
            width: 100%;
            margin-top: 15px;

            input {
                width: 94%;
                border: 1px solid ${colors.primaryColor};
                height: 63.05px;
                font-size: ${newFontSize.body};
                font-weight: ${weight.regular};
                padding-left: 30px;

                &::placeholder {
                    color: ${colors.black};
                }
                &:focus::placeholder {
                    color: transparent;
                }
            }
        }
        &__contain-password {
            grid-row-start: 2;
            display: grid;
            grid-template-rows: 0.4fr 1fr;
            width: 591.46px;
            justify-self: center;
            align-self: start;
        }
        &__contain_password-label {
            display: flex;
            justify-content: start;
            align-items: center;

            img {
                margin-right: 10px;
            }

            label {
                font-size: ${newFontSize.label};
                font-weight: ${weight.regular};
                color: ${colors.greyLabel};
                line-height: 14px;
                letter-spacing: 0.25px;
            }
        }

        &__contain_password-input {
            height: 100%;
            width: 100%;
            margin-top: 15px;
            input {
                width: 94%;
                border: 1px solid ${colors.primaryColor};
                height: 63.05px;
                font-size: ${newFontSize.body};
                font-weight: ${weight.regular};
                padding-left: 30px;

                &::placeholder {
                    color: ${colors.black};
                }

                &:focus::placeholder {
                    color: transparent;
                }
            }
        }

        &__contain-button {
            grid-row-start: 3;

            display: flex;
            align-items: end;
            height: 100%;
            width: 591.46px;
            border-radius: 10px;
            justify-self: center;

            .button {
                background-color: ${colors.primaryColor};
                height: 44px;
                width: 100%;
                border-radius: 10px;
                font-size: ${newFontSize.body};
                display: flex;
                justify-content: center;
                align-items: center;
                color: ${colors.white};
                box-shadow: 26px 32px 17px rgba(73, 80, 87, 0.01), 15px 18px 14px rgba(73, 80, 87, 0.04), 7px 8px 10px rgba(73, 80, 87, 0.06), 2px 2px 6px rgba(73, 80, 87, 0.07),
                    0px 0px 0px rgba(73, 80, 87, 0.07);
                border: 0px;
                font-family: ${fontFamily.font};
                font-weight: ${weight.medium};

                &:hover {
                    cursor: pointer;
                    background-color: ${colors.secondaryColor};
                    color: ${colors.primaryColor};
                    border: 0px;
                }
            }
        }
    }
`
