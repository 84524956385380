import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import DayWeek from './DayWeek'
import usePreferences from '../../hooks/usePreferences'

import { scheduleDaysForm } from '../../data/dataPreferences'

import { ScheduleFormWrapper } from './ScheduleForm.style'
import { useEffect } from 'react'

const ScheduleForm = ({ item, form, crudType }) => {
    const [schedule, setSchedule] = useState({
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
    })

    const { refactorSchedule } = usePreferences()

    const location = useLocation()

    const handleInputChange = (dayWeek, hours) => {
        setSchedule((prevState) => ({
            ...prevState,
            [dayWeek]: hours,
        }))
    }

    useEffect(() => {
        if (crudType === 'edit') {
            const scheduleRefactor = refactorSchedule(location.state.schedule)
            setSchedule(scheduleRefactor)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        form({ action: 'input', value: schedule }, { form: 'schedule', element: 'hour' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedule])

    return (
        <ScheduleFormWrapper>
            {scheduleDaysForm.map((day) => (
                <DayWeek key={day.value} dayWeek={day.label} dayValue={day.value} editHours={handleInputChange} crudType={crudType} />
            ))}
        </ScheduleFormWrapper>
    )
}

export default ScheduleForm
