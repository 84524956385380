import { actionTypesUser } from '../../../actions/settingsScreen/user/actionTypes'

export const usersReducer = (users = [], action) => {
    let newArrayUsers
    switch (action.type) {
        case actionTypesUser.get:
            newArrayUsers = [...action.users]
            break
        default:
            newArrayUsers = users
    }
    return newArrayUsers
}

export const usersPermissionReducer = (arrayPermissionUser = [], action) => {
    let newArrayPermissionsUser
    switch (action.type) {
        // action for looking permissions user connection
        case actionTypesUser.getUsersPermission:
            newArrayPermissionsUser = [...action.permissions]
            break
        default:
            newArrayPermissionsUser = arrayPermissionUser
    }

    return newArrayPermissionsUser
}

export const usersFormReducer = (
    state = {
        id: null,
        firstname: '',
        lastname: '',
        secondname: '',
        email: '',
        password: '',
        role: '',
    },
    action
) => {
    switch (action.type) {
        case actionTypesUser.changeUserFormId:
            state = {
                ...state,
                id: action.payload,
            }
            break
        case actionTypesUser.changeUserFormName:
            state = {
                ...state,
                firstname: action.payload,
            }
            break
        case actionTypesUser.changeUserFormFirstSurname:
            state = {
                ...state,
                lastname: action.payload,
            }
            break
        case actionTypesUser.changeUserFormSecondSurname:
            state = {
                ...state,
                secondname: action.payload,
            }
            break
        case actionTypesUser.changeUserFormEmail:
            state = {
                ...state,
                email: action.payload,
            }
            break
        case actionTypesUser.changeUserFormPassword:
            state = {
                ...state,
                password: action.payload,
            }
            break
        case actionTypesUser.addUserFormRoles:
            state = {
                ...state,
                role: action.payload,
            }
            break
        case actionTypesUser.removeUserFormRoles:
            state = {
                ...state,
                roles: '',
            }
            break
        case actionTypesUser.clearUserFormRoles:
            state = {
                ...state,
                role: '',
            }
            break
        case actionTypesUser.clearUserForm:
            state = {
                id: null,
                firstname: '',
                lastname: '',
                secondname: '',
                email: '',
                password: '',
                role: '',
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}
