import styled, { keyframes } from 'styled-components'
import { colors } from '../../theme/colors'

const fadeInOut = keyframes`
    0% {
        opacity: 0;
        }
        25% {
          opacity: 1;
          }
          75% {
            opacity: 1;
            }
          100% {
            opacity: 0;
            }
`

export const PasswordWatcherWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    .passwordWatcher__contain {
        width: 100%;
        display: flex;
        align-items: center;
        width: fit-content;
        border-radius: 10px;
        padding: 5px;
        border: 1px solid ${colors.greyLine};
        transition: all 0.3s ease-in-out;
        margin-left: 7px;
        position: relative;
        &:hover {
            background-color: ${colors.secondaryColor};
            border: 1px solid ${colors.primaryColor};
            transition: all 0.3s ease-in-out;
        }

        .passwordWatcher__copy {
            width: 16px;
            height: 16px;
        }
        .passwordWatcher__tooltip {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
    .tooltip {
        border-radius: 8px;
        font-size: 12px;
        z-index: 9999;
        animation: ${fadeInOut} 3s linear none;
        background-color: ${colors.grey};
        width: max-content;
        padding: 5px 10px;
    }
`
