
import { 
    BarChart, 
    ResponsiveContainer,
    CartesianGrid, 
    YAxis, 
    XAxis, 
    Tooltip,  
    Bar,  
    Cell
} from "recharts"

const BarCharts = ({ data, barProps }) => {

   
    return (
        <ResponsiveContainer width = "98%" height = "98%">

            <BarChart data = {data}>
                <CartesianGrid stroke = "#E1E1E1" strokeDasharray = "1" vertical = {false} />
                <XAxis dataKey = "name" tickLine = {false} axisLine = {{ stroke: "#E1E1E1" }}/>
                <YAxis tickLine = {false} axisLine = {false} />
                <Tooltip />
                <Bar dataKey = "num" barSize = {20} radius = {5}>
                    {data.map( (dat, index) => <Cell key = {index} fill = {dat.color} /> )}
                </Bar>
            </BarChart>

        </ResponsiveContainer>
    )
}

export default BarCharts
