import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGird } from '../../../components/CardGrid/CardGird'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../components/TableInfo/TableInfo'
import { dataFilterIvr, dataScreenIvr } from '../../../data/dataApplications'
import useApplications from '../../../hooks/useApplications'
import useView from '../../../hooks/useView'
import { routesApplications, routesWide } from '../../../navigation/routes'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter'

import { useSwal } from '../../../hooks/useSwal'
import { IvrScreenWrapper } from './IvrScreen.style'

export const IvrScreen = () => {
    const { ivr, getIvr, loading, deleteIvr } = useApplications()
    const { typeSwal } = useSwal()

    const { notFound } = dataScreenIvr
    const { changeView, isCardView } = useView()

    const [search, setSearch] = useState([])
    const [, setIsBottom] = useState(false)
    const [isFilter, setIsFilter] = useState({
        open: false,
        active: false,
    })

    let navigate = useNavigate()

    useEffect(() => {
        getIvr()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenFormCreate = () => {
        navigate(`/${routesWide.applications}/${routesApplications.createIvr}`, {})
    }

    const handleOpenFormEdit = (item) => {
        navigate(`/${routesWide.applications}/${routesApplications.updateIvr}`, {
            state: item.original ? item.original : item,
        })
    }

    const handleDelete = async (item) => {
        typeSwal('wantDelete').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response
                    if (item.original) {
                        response = await deleteIvr(item.original)
                    } else {
                        response = await deleteIvr(item)
                    }

                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        getIvr()
                        typeSwal('delete')
                    }
                } catch (err) {
                    return err
                }
            }
        })
    }

    const handleUploadFile = (file) => {}

    const DATA = ivr.map(
        ({
            Company,
            Destination,
            Soundprompt,
            id,
            name,
            destinationOneId,
            destinationTwoId,
            destinationThreeId,
            destinationFourId,
            destinationFiveId,
            destinationSixId,
            destinationSevenId,
            destinationEightId,
            destinationNineId,
            destinationTimeoutId,
            destinationZeroId,
            timeOut,
        }) => {
            return {
                id,
                name,
                company: Company?.name,
                companyId: Company?.id,
                destination: Destination,
                soundprompt: Soundprompt?.description,
                soundpromptId: Soundprompt?.id,
                soundpromptFileName: Soundprompt?.filename,
                destinationOneId,
                destinationTwoId,
                destinationThreeId,
                destinationFourId,
                destinationFiveId,
                destinationSixId,
                destinationSevenId,
                destinationEightId,
                destinationNineId,
                destinationTimeoutId,
                destinationZeroId,
                timeOut,
            }
        }
    )

    const COLUMNS = [
        {
            Header: 'Nombre',
            accessor: 'name',
        },
        {
            Header: 'Locución',
            accessor: 'soundprompt',
        },
        {
            Header: 'Sede',
            accessor: 'company',
        },
    ]
    const dataExport = ivr.map(({ Company, Soundprompt, name }) => {
        return {
            Nombre: name,
            Locución: Soundprompt?.description,
            Sede: Company?.name,
        }
    })

    const dataCard = () => {
        if (search.length > 0) {
            return search.map(
                ({
                    Company,
                    Destination,
                    Soundprompt,
                    id,
                    name,
                    destinationOneId,
                    destinationTwoId,
                    destinationThreeId,
                    destinationFourId,
                    destinationFiveId,
                    destinationSixId,
                    destinationSevenId,
                    destinationEightId,
                    destinationNineId,
                    destinationTimeoutId,
                    destinationZeroId,
                    timeOut,
                }) => ({
                    title: name,
                    subtitle: Soundprompt?.description,
                    text: Company?.name,
                    id,
                    name,
                    company: Company?.name,
                    companyId: Company?.id,
                    destination: Destination,
                    soundprompt: Soundprompt?.description,
                    soundpromptId: Soundprompt?.id,
                    soundpromptFileName: Soundprompt?.filename,
                    destinationOneId,
                    destinationTwoId,
                    destinationThreeId,
                    destinationFourId,
                    destinationFiveId,
                    destinationSixId,
                    destinationSevenId,
                    destinationEightId,
                    destinationNineId,
                    destinationTimeoutId,
                    destinationZeroId,
                    timeOut,
                })
            )
        } else
            return ivr.map(
                ({
                    Company,
                    Destination,
                    Soundprompt,
                    id,
                    name,
                    destinationOneId,
                    destinationTwoId,
                    destinationThreeId,
                    destinationFourId,
                    destinationFiveId,
                    destinationSixId,
                    destinationSevenId,
                    destinationEightId,
                    destinationNineId,
                    destinationTimeoutId,
                    destinationZeroId,
                    timeOut,
                }) => {
                    return {
                        title: name,
                        subtitle: Soundprompt?.description,
                        text: Company?.name,
                        id,
                        name,
                        company: Company?.name,
                        companyId: Company?.id,
                        destination: Destination,
                        soundprompt: Soundprompt?.description,
                        soundpromptId: Soundprompt?.id,
                        soundpromptFileName: Soundprompt?.filename,
                        destinationOneId,
                        destinationTwoId,
                        destinationThreeId,
                        destinationFourId,
                        destinationFiveId,
                        destinationSixId,
                        destinationSevenId,
                        destinationEightId,
                        destinationNineId,
                        destinationTimeoutId,
                        destinationZeroId,
                        timeOut,
                    }
                }
            )
    }

    const dataToCards = dataCard()

    const template = []

    return (
        <IvrScreenWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        add: {
                            position: 'left',
                            function: handleOpenFormCreate,
                        },

                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        file: {
                            position: 'right',
                            data: dataExport,
                            template,
                        },
                        filter: {
                            position: 'left',
                        },
                    }}
                    importFile={handleUploadFile}
                    setSearch={setSearch}
                    setIsFilter={setIsFilter}
                    isFilter={isFilter}
                />
                {!ivr[0]?.status ? (
                    <div className={'contain__menuFilter'}>
                        <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterIvr} setSearch={setSearch} />
                    </div>
                ) : null}
            </div>
            {loading ? (
                <LoaderSpinner />
            ) : ivr[0]?.status ? (
                <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
                </div>
            ) : (
                <>
                    <div className="contain__table">
                        <TableInfo
                            COLUMNS={COLUMNS}
                            DATA={search.length === 0 ? DATA : search}
                            format={'list'}
                            deleteFunction={handleDelete}
                            updateFunction={handleOpenFormEdit}
                            onClick={handleOpenFormEdit}
                            setIsBottom={setIsBottom}
                        />
                    </div>
                </>
            )}
        </IvrScreenWrapper>
    )
}
