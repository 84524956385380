import React from "react";
import { Header } from "../../components/Header/Header";
import { MenuTop } from "../../components/MenuTop/MenuTop";
import { menuHelp } from "../../data/dataHelp";
import { menuNavLeft } from "../../data/dataNavMenuLeft";
import { HelpNavigation } from "../../navigation/HelpNavigation/HelpNavigation";
import { HelpScreenWrapper } from "./HelpScreen.style";

export const HelpScreen = () => {
    return (
        <HelpScreenWrapper>
            <Header
                image={menuNavLeft.menuBottom[1].image}
                text={menuNavLeft.menuBottom[1].text}
            />
            <MenuTop menu={menuHelp} isRestricted={false} />
            <HelpNavigation />
        </HelpScreenWrapper>
    );
};
