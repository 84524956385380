import Axios from 'axios'

import {
    getCompanyLinkAction,
    getHeadquarterAction,
} from '../actions/headquarter/actionCreator'

const urlApi = process.env.REACT_APP_URL

export const getHeadquarterThunk = (setLoading) => async (dispatch) => {
    setLoading(true)
    try {
        const headquarter = await Axios.get(
            `${urlApi}headquarters/headquarters`
        )
        const companyLink = await Axios.get(`${urlApi}headquarters/companyLink`)
        dispatch(getCompanyLinkAction(companyLink.data))
        dispatch(getHeadquarterAction(headquarter.data))
    } catch (error) {
        return error
    } finally {
        setLoading(false)
    }
}

// export const getCompanyLinkThunk = (setLoading) => async (dispatch) => {
//     try {
//         const companyLink = await Axios.get(`${urlApi}headquarters/companyLink`)
//         dispatch(getCompanyLinkAction(companyLink.data))
//     } catch (err) {
//         return err
//     }
// }

export const createCompanyThunk = (company, setLoading) => async () => {
    try {
        var response = await Axios.post(
            `${urlApi}headquarters/headquarters/create`,
            { data: company }
        )
    } catch (error) {
        return error
    } finally {
        setLoading(false)
        return response
    }
}

export const updateCompanyThunk = (company, setLoading) => async () => {
    setLoading(true)
    try {
        var response = await Axios.patch(
            `${urlApi}headquarters/headquarters/update`,
            { data: company }
        )
    } catch (error) {
        return error
    } finally {
        setLoading(false)
        return response
    }
}

export const deleteCompanyThunk = (company, setLoading) => async () => {
    setLoading(true)
    company.refColumn = 'companyId'
    try {
        var response = await Axios.delete(
            `${urlApi}headquarters/headquarters/delete`,

            { data: company }
        )
    } catch (error) {
        return error
    } finally {
        return response
    }
}
