import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { newFontSize } from '../../theme/fonts'
import { weight } from '../../theme/fonts'

export const NavTableWrapper = styled.div`
    height: 36px;
    width: auto;
    background-color: white;
    position: absolute;
    z-index: 99;
    top: -47px;
    left: 35px;
    border: 1px solid ${colors.greyLine};
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 26px 32px 17px rgba(73, 80, 87, 0.01), 15px 18px 14px rgba(73, 80, 87, 0.04), 7px 8px 10px rgba(73, 80, 87, 0.06), 2px 2px 6px rgba(73, 80, 87, 0.07),
        2px 2px 6px rgba(73, 80, 87, 0.07), 0px 0px 0px rgba(73, 80, 87, 0.07);
    transition: box-shadow 0.3s;

    .navTable {
        display: inline-flex;
        height: 100%;

        &::last-child {
            border-radius: 0 10px 10px 0;
        }

        &__selectedItems {
            border-right: 1px solid ${colors.greyLine};
            margin: 0;
            font-size: ${newFontSize.body};
            color: ${colors.primaryColor};
            font-weight: ${weight.medium};
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 5px;
            width: 110px;
        }

        &__trash,
        &__copy,
        &__export {
            display: flex;
            align-items: center;
            padding: 0 10px;
            transition: all 0.3s;

            &__icon {
                color: ${colors.greyLine};
                transition: all 0.3s;
            }
            &__text {
                font-size: ${newFontSize.body};
                color: ${colors.greyLine};
                font-weight: ${weight.medium};
                transition: all 0.3s;
            }
            &:hover {
                display: flex;
                align-items: center;
                background-color: ${colors.greyHover};
                cursor: pointer;
                transition: all 0.3s;

                .navTable__trash__icon,
                .navTable__copy__icon,
                .navTable__export__icon {
                    color: ${colors.black};
                    transition: all 0.3s;
                }
                .navTable__trash__text,
                .navTable__copy__text,
                .navTable__export__text {
                    font-size: ${newFontSize.body};
                    color: ${colors.black};
                    font-weight: ${weight.medium};
                    transition: all 0.3s;
                }
            }
        }
        &__trash,
        &__copy {
            //border-right: 1px solid ${colors.greyLine};
        }
    }
`
