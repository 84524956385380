import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useStore } from 'react-redux'

import PermissionProvider from '../Permission/PermissionProvider'
import Restricted from '../Permission/PermissionConsumer'

import { LinkButtonBigWrapper } from './LinkButtonBig.style'

export const LinkButtonBig = ({ menuTop }) => {
    const { getState } = useStore()

    const permissions = getState()['arrayUsersPermission']

    const subtitlePermissionsUser = permissions.map((item) => item.subtitle)

    return (
        <PermissionProvider permissionsUser={subtitlePermissionsUser}>
            <LinkButtonBigWrapper>
                {menuTop.map((item, index) => (
                    <Restricted key={index} to={item.restricted}>
                        <Link to={item.path}>
                            <div>
                                <p>
                                    {' '}
                                    <item.icon height={13} />
                                    {item.text}
                                </p>
                                <p>{item?.description}</p>
                            </div>
                        </Link>
                    </Restricted>
                ))}
            </LinkButtonBigWrapper>
        </PermissionProvider>
    )
}
