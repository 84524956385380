import { actionTypesDepartment } from './actionTypes'

export const getDepartmentsAction = (departments) => {
    return {
        type: actionTypesDepartment.getDepartment,
        departments,
    }
}

export const changeDepartmentNameFormAction = (payload) => {
    return {
        type: actionTypesDepartment.changeDepartmentNameForm,
        payload,
    }
}

export const changeDepartmentIdFormAction = (payload) => {
    return {
        type: actionTypesDepartment.changeDepartmentIdForm,
        payload,
    }
}

export const clearDepartmentFormAction = () => {
    return {
        type: actionTypesDepartment.clearDepartmentForm,
    }
}

export const loadFirstItemsDepartmentAction = (departments) => {
    return {
        type: actionTypesDepartment.loadFirstItemsDepartment,
        departments,
    }
}

export const loadNextItemsDepartmentAction = (departments) => {
    return {
        type: actionTypesDepartment.loadNextItemsDepartment,
        departments,
    }
}
