import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { weight } from '../../theme/fonts'

export const OverviewTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    text-align: center;
    width: 580px;

    h1 {
        font-weight: ${weight.bold};
        color: ${colors.black};
        font-size: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        img {
            width: 38px;
        }
    }

    h3 {
        font-weight: ${weight.medium};
        font-size: 16px;
    }
`
