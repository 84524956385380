import { actionTypesView } from '../../actions/view/actionTypes'

export const view = (state = { isCardView: false }, action) => {
    switch (action.type) {
        case actionTypesView.changeView:
            return {
                ...state,
                isCardView: !state.isCardView,
            }
        default:
            return state
    }
}
