import styled from 'styled-components'
import { colors } from '../../theme/colors'
import { fontFamily, newFontSize, weight } from '../../theme/fonts'

export const SelectWrapper = styled.div`
    width: 100%;
    //select css

    .css-3iigni-container {
        height: 30px;
        .css-1insrsq-control {
            height: 30px;
            .css-319lph-ValueContainer {
                max-height: 30px;
                .css-lr9fot-singleValue {
                    font-weight: ${weight.regular};
                    font-size: ${newFontSize.body};
                    color: ${colors.black};
                }
            }
        }
    }
    .css-b62m3t-container {
        width: 100%;

        .css-1s2u09g-control {
            border: 1px solid ${colors.greyBorder};
            height: 34px;
            min-height: 34px;
            width: 100%;
            display: flex;
            align-content: center;
            cursor: pointer;
            border-radius: 5px;
        }
        .css-1okebmr-indicatorSeparator {
            width: 0px;
        }

        .css-26l3qy-menu {
            z-index: 6;
        }

        .css-1pahdxg-control {
            display: flex;
            align-content: center;
            height: 34px;
            min-height: 34px;
            width: 100%;
            border-color: ${colors.primaryColor};
            border: 1px solid ${colors.primaryColor};
            box-shadow: 0 0 1px ${colors.primaryColor};
            border-radius: 5px;
            &:hover {
                border-color: ${colors.secondaryColor};
            }
        }
    }
    .css-tj5bde-Svg {
        color: ${colors.primaryColor};
    }
    .css-1n7v3ny-option {
        background-color: ${colors.secondaryColor};
    }

    .css-g1d714-ValueContainer {
        display: flex;
        flex-wrap: nowrap;
    }
    .css-qc6sy-singleValue {
        font-weight: ${weight.regular};
        font-size: ${newFontSize.body};
        color: ${colors.black};
    }
    .css-14el2xx-placeholder {
        font-family: ${fontFamily.fontCard};
        font-weight: ${weight.light};
        font-size: ${newFontSize.placeholder};
        color: ${colors.grey};
    }
    .css-9gakcf-option {
        background-color: ${colors.primaryColor};
    }
`
