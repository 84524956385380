import React from 'react'
import { useEffect } from 'react'
import useApplications from '../../hooks/useApplications'
import useNumbering from '../../hooks/useNumbering'
import { Select } from '../Select/Select'
import { DestinationSelectWrapper } from './DestinationSelect.style'
import useEM from '../../hooks/useEM'

export const DestinationSelect = ({ onChange, name, placeholder, idPage, destinationOpen, item, defaultValue }) => {
    const { getConferences, getIvr, conferences, getMailboxes, mailboxes, ivr, getApplicationsLocutions, arrayLocutionsHook } = useApplications()
    const { getExtensions, arrayNumberingExtensionsHook } = useNumbering()
    const { getEMAlerts, arrayEMAlerts } = useEM()

    useEffect(() => {
        getConferences()
        getMailboxes()
        getIvr()
        getApplicationsLocutions()
        getExtensions()
        getEMAlerts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const options = [
        { value: 'conference', label: 'Conferencia', ref: 'conference', options: [] },
        {
            value: 'voicemail',
            label: 'Búzon',
            ref: 'voicemail',
            options: [],
        },
        {
            value: 'ivr',
            label: 'IVR',
            ref: 'ivr',
            options: [],
        },
        {
            value: 'soundPrompt',
            label: 'Locución',
            options: [],
            ref: 'soundPrompt',
        },
        {
            value: 'queue',
            label: 'Cola',
            options: [],
            ref: 'queue',
        },
        {
            value: 'extension',
            label: 'Extensiones',
            options: [],
            ref: 'extension',
        },
        {
            value: 'emergencyManager',
            label: 'Emergency Manager',
            options: [],
            ref: 'emergencyManager',
        },
        {
            value: 'external',
            label: 'Numero externo',
            type: 'input',
            ref: 'external',
        },
        {
            value: 'hangup',
            label: 'Colgar llamada',
            ref: 'hangup',
        },
    ]

    options.forEach((item3) => {
        if (item3.ref === 'conference') {
            conferences.forEach((item4) => {
                if (item4.name === null) {
                    item4.name = 'not defined'
                }
                const conference = {
                    label: item4.name,
                    value: item4.id?.toString(),
                    ref: 'conference',
                    name: item3.label,
                }
                const found = item3.options.some((e) => e.value === conference.value)
                if (!found) {
                    item3.options.push(conference)
                }
            })
        }
        if (item3.ref === 'voicemail') {
            mailboxes.forEach((item4) => {
                if (item4.name === null) {
                    item4.name = 'not defined'
                }
                const mailbox = {
                    label: item4.extension,
                    value: item4.id?.toString(),
                    ref: 'voicemail',
                    name: item3.label,
                }
                const found = item3.options.some((e) => e.value === mailbox.value)
                if (!found) {
                    item3.options.push(mailbox)
                }
            })
        }
        if (item3.ref === 'ivr') {
            ivr.forEach((item4) => {
                if (item4.name === null) {
                    item4.name = 'not defined'
                }
                const ivr = {
                    label: item4.name,
                    value: item4.id?.toString(),
                    ref: 'ivr',
                    name: item3.label,
                }
                const found = item3.options.some((e) => e.value === ivr.value)
                if (!found) {
                    item3.options.push(ivr)
                }
            })
        }
        if (item3.value === 'soundPrompt') {
            arrayLocutionsHook.forEach((item4) => {
                if (item4.name === null) {
                    item4.name = 'not defined'
                }
                const locution = {
                    label: item4.description,
                    value: item4.id?.toString(),
                    ref: 'soundPrompt',
                    name: item3.label,
                }
                const found = item3.options.some((e) => e.value === locution.value)
                if (!found) {
                    item3.options.push(locution)
                }
            })
        }
        if (item3.value === 'extension') {
            arrayNumberingExtensionsHook.forEach((item4) => {
                if (item4.name === null) {
                    item4.name = 'not defined'
                }
                const extension = {
                    label: item4.name,
                    value: item4.id?.toString(),
                    ref: 'extension',
                    name: item3.label,
                }
                const found = item3.options.some((e) => e.value === extension.value)
                if (!found) {
                    item3.options.push(extension)
                }
            })
        }
        if (item3.value === 'emergencyManager') {
            console.log(arrayEMAlerts)
            arrayEMAlerts.forEach((item4) => {
                if (item4.name === null) {
                    item4.name = 'not defined'
                }
                const destination = {
                    label: item4.name,
                    value: item4.id?.toString(),
                    ref: 'emergencyManager',
                    name: item3.label,
                }
                const found = item3.options.some((e) => e.value === destination.value)
                if (!found) {
                    item3.options.push(destination)
                }
            })
        }
    })

    if (Object.keys(defaultValue)?.length > 0) {
        options.forEach((option) => {
            if (option.ref === defaultValue.label) {
                if (option.options) {
                    option.options.forEach((option2) => {
                        if (option2.value === defaultValue.value) {
                            defaultValue = option2
                        }
                    })
                }
                if (option.ref === 'hangup') {
                    defaultValue = {
                        ...defaultValue,
                        label: 'Colgar llamada',
                        ref: 'hangup',
                    }
                }
            }
        })
    }

    return (
        <DestinationSelectWrapper>
            <div className="destination-select">
                <Select data={options} onChange={onChange} name={name} placeholder={placeholder} idPage={idPage} destinationOpen={destinationOpen} itemForm={item} defaultValue={defaultValue} />
            </div>
        </DestinationSelectWrapper>
    )
}
