
import { 
    Line, 
    LineChart, 
    CartesianGrid, 
    XAxis, 
    YAxis, 
    Tooltip, 
    Legend, 
    ResponsiveContainer 
} from "recharts"


const LineCharts = ({ data, dataKeys }) => {

    return (
        <ResponsiveContainer width = "100%" height = "100%">
            <LineChart data = {data}>

                {
                    dataKeys.map( (info, index) => 
                        <Line 
                            key = {index} 
                            type = "monotone" 
                            strokeWidth = {3} 
                            dot = {false} 
                            dataKey = {info.dataKey} 
                            stroke = {info.color} 
                        />
                    )
                }

                <CartesianGrid stroke="#E1E1E1" strokeDasharray="0" vertical = {false} />
                <XAxis dataKey="name"  tickLine = {false} axisLine = {{ stroke: "#E1E1E1" }} padding = {{ left: 15 }} />
                <YAxis  axisLine = {false} tickLine = {false} />
                <Legend iconType = {"circle"} verticalAlign = "top" iconSize = {10} wrapperStyle = {{ paddingBottom: "20px" }} />
                <Tooltip />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default LineCharts