import React from "react";
import { Header } from "../../components/Header/Header";
import { MenuTop } from "../../components/MenuTop/MenuTop";
import { menuNavLeft } from "../../data/dataNavMenuLeft";
import { menuPreferences } from "../../data/dataPreferences";
import { PreferencesNavigation } from "../../navigation/PreferencesNavigation/PreferencesNavigation";
import { PreferencesScreenWrapper } from "./PreferencesScreen.style";

export const PreferencesScreen = () => {
  return (
    <PreferencesScreenWrapper>
      <Header
        image={menuNavLeft.menuTop[5].image}
        text={menuNavLeft.menuTop[5].text}
      />
      <MenuTop menu={menuPreferences} />
      <PreferencesNavigation />
    </PreferencesScreenWrapper>
  );
};
